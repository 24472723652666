import React, { useState } from "react";
import { NavLink, Navigate } from "react-router-dom";
import Form from "react-bootstrap/Form";
import "../scss/style.scss";
import Aux from "../hoc/_Aux";
import Breadcrumb from "../Breadcrumb";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const SignUp1 = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [userLogged, setUserLogged] = useState(false);

  const handleSignUp = async () => {
    try {
      const response = await fetch("http://localhost:3001/signup", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, password }),
      });

      if (response.ok) {
        setTimeout(() => {
          setUserLogged(true);
        }, 5000);
        const data = await response.json();
        toast.success("Signup successful", {
          position: toast.POSITION.TOP_CENTER,
        });
        console.log("Signup successful:", data);
        // Redirect to the success route (replace '/dashboard' with your desired route)
        // history.push("/");
      } else {
        const errorMessage = await response.text();
        setErrorMessage(errorMessage);
        toast.error("Signup failed", {
          position: toast.POSITION.TOP_CENTER,
        });
        console.error("Signup failed:", errorMessage);
      }
    } catch (error) {
      console.error("Error during signup:", error);
      toast.error("Error during Signup", {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  if (userLogged) {
    return <Navigate to="/login" />;
  }

  return (
    <Aux>
      <Breadcrumb />
      <div className="auth-wrapper">
        <div className="auth-content">
          <div className="auth-bg">
            <span className="r" />
            <span className="r s" />
            <span className="r s" />
            <span className="r" />
          </div>
          <ToastContainer position="top-center" autoClose={5000} />
          <div className="card">
            <div className="card-body text-center">
              <div className="mb-4">
                <i className="feather icon-user-plus auth-icon" />
              </div>
              <h3 className="mb-4">Sign up</h3>
              <div className="input-group mb-3">
                <input
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  className="form-control"
                  placeholder="Username"
                />
              </div>
              <div className="input-group mb-3">
                <input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="form-control"
                  placeholder="Email"
                />
              </div>
              <div className="input-group mb-4">
                <input
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="form-control"
                  placeholder="password"
                />
              </div>
              <div className="form-group text-left">
                <div className="checkbox checkbox-fill d-inline">
                  <input
                    type="checkbox"
                    name="checkbox-fill-2"
                    id="checkbox-fill-2"
                  />
                </div>
              </div>
              <button
                className="btn btn-primary shadow-2 mb-4"
                onClick={handleSignUp}>
                Sign up
              </button>
              <p className="mb-0 text-muted">
                Already have an account? <NavLink to="/login">Login</NavLink>
              </p>
            </div>
          </div>
        </div>
      </div>
    </Aux>
  );
};

export default SignUp1;
