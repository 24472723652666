import React from "react";
import Bredcom from "../Bredcom/Main";
import BG from "../../assets/loan-pics/loans.jpg";
import PropertyInvestmentDetails from "../News/Mennewsdeatils/PropertyInvestementDetails";

const PropertyInvestmentResourses = () => {
  return (
    <>
      <Bredcom
        title={"Home"}
        subtitle={"Resourses"}
        link={"Property Investment"}
      />
      <PropertyInvestmentDetails />
    </>
  );
};

export default PropertyInvestmentResourses;
