import React from "react";
import Bredcom from "../Bredcom/Main";
import ConstructionLoansBody from "../Services/Menservisedeatils/ConstructionLoansBody";
import BG from "../../assets/images/Banners/construction-loans.jpg";

const ConstructionLoans = () => {
  return (
    <>
      <Bredcom
        title={"Home"}
        subtitle={"Home Loans"}
        link={"Construction Loans"}
        img={BG}
      />
      <ConstructionLoansBody />
    </>
  );
};

export default ConstructionLoans;
