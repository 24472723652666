// PropertyInvestmentDetails
import React from "react";
import { Link } from "react-router-dom";
import BG from "../../../assets/resourses/property-investment.png";

function PropertyInvestmentDetails() {
  return (
    <>
      <section className="blog-details pt-120 pb-120">
        <div className="container">
          <div className="row row-gutter-y-30">
            <div className="col-lg-8">
              <div className="blog-card__image">
                <div className="blog-card__date">
                  <span>19</span> Feb
                </div>
                <img src={BG} alt="" />
              </div>
              <div className="blog-card__meta">
                <Link to="/newsdetails">
                  <i className="far fa-user-circle"></i> by Admin
                </Link>
                <Link to="/newsdetails">
                  <i className="far fa-comments"></i> 2 Comments
                </Link>
              </div>
              <h1 className="blog-card__title">
                They’re back! Why property investors account for one-in-three
                new home loans
              </h1>
              <div className="blog-details__content">
                <p>
                  <b>
                    Lending to property investors is soaring once again. We lift
                    the lid on what’s driving investor interest – and what it
                    could mean for the property market throughout 2024.
                  </b>
                </p>
                <p>
                  It looks like property investors are back … and in a big way.
                </p>
                <p>
                  The latest ABS figures show that in December 2023, banks lent
                  over $26 billion in new home loans – and one-third of this
                  figure, a whopping $9.5 billion, was to property investors.
                </p>
                <p>
                  That equates to 36.2% of all housing loans – the highest
                  market share for property investors since mid-2017.
                </p>
                <p>
                  It’s also quite an uptick from December 2020, when the ABS
                  says investors took out just 23.6% of mortgages.
                </p>
                <p>So why the big shift in recent times?</p>
                <br />
                <h4 style={{ color: "#01BBBB", fontWeight: "bold" }}>
                  What makes an investment property so attractive?
                </h4>
                <p>
                  There are many reasons why people may love owning a
                  rental/investment property.
                </p>
                <p>
                  An investment property can be a source of extra income, and
                  right now, some investors are pocketing very attractive rental
                  yields (that’s annual rent divided by the purchase price of
                  the property).
                </p>
                <p>
                  PropTrack, for example, is reporting yields as high as 9% in
                  some suburbs.
                </p>
                <p>
                  Investors may also expect to see their property grow in value
                  over time, which could add up to some pretty impressive
                  capital gains.
                </p>
                <p>
                  CoreLogic looked at the results of 86,000 property resales in
                  the third quarter of 2023, and found 93.5% were sold for a
                  profit, with the median gain coming at $298,000. Not bad at
                  all.
                </p>
                <p>
                  And home values are tipped to jump a further 6% in 2024,
                  according to ANZ Bank.
                </p>
                <p>
                  Add in rental vacancy rates hitting record lows of 1.1% in
                  January 2024, and many investors are attracting good tenants,
                  which can be great for cash flow.
                </p>
              </div>
              <h4 style={{ color: "#01BBBB", fontWeight: "bold" }}>
                How could the return of investors impact the market?
              </h4>

              <div className="blog-details__content">
                <p>
                  On a personal level, buying an investment property could
                  potentially be a boost for your long-term financial
                  well-being.
                </p>
                <p>
                  ABS has acknowledged that rising household wealth in Australia
                  is being supported by house prices that have continued to grow
                  despite higher rates.
                </p>
                <p>
                  More broadly, PropTrack points out that the re-emergence of
                  investor activity “heralds good news for the overall health of
                  the market, helping to drive more new construction”.
                </p>
                <p>
                  Long story short, the benefits of more rental properties could
                  extend beyond individual investors.
                </p>
              </div>
              <h4 style={{ color: "#01BBBB", fontWeight: "bold" }}>
                Is an investment property on your radar?
              </h4>
              <div className="blog-details__content">
                <p>
                  <p>
                    If you’re thinking about buying a rental property, or you’d
                    like to add to your current property portfolio, talk to us
                    today about your options for an investment loan.
                  </p>
                  <p>
                    We can help you work out how much equity you may be able to
                    leverage, as well as your overall borrowing capacity.
                  </p>
                  <p>
                    From there, we can help you track down a suitable mortgage
                    with a competitive rate from our broad suite of lenders,
                    leaving you free to focus on finding your ideal investment
                    property.
                  </p>
                </p>
              </div>
              <h4 style={{ color: "#01BBBB", fontWeight: "bold" }}>
                So when might be the right time to buy?
              </h4>
              <div className="blog-details__content">
                <p>
                  We believe the ideal time to buy a home is when you feel ready
                  to do so.
                </p>
                <p>
                  And a good way to find out if you’re ready is to speak to us
                  about your borrowing power.
                </p>
                <p>
                  We can help you crunch the numbers to let you know how much
                  you could borrow, which in turn helps you figure out what kind
                  of property you could afford to buy.
                </p>
                <p>
                  If that sounds like a good plan to you, give us a call today.
                </p>
                <p>
                  <b>Disclaimer:</b> The content of this article is general in
                  nature and is presented for informative purposes. It is not
                  intended to constitute tax or financial advice, whether
                  general or personal nor is it intended to imply any
                  recommendation or opinion about a financial product. It does
                  not take into consideration your personal situation and may
                  not be relevant to circumstances. Before taking any action,
                  consider your own particular circumstances and seek
                  professional advice. This content is protected by copyright
                  laws and various other intellectual property laws. It is not
                  to be modified, reproduced or republished without prior
                  written consent.
                </p>
              </div>

              <div className="blog-details__meta">
                <div className="blog-details__tags">
                  <span>Tags</span>
                  <Link to="#">Mortgage</Link>
                  <Link to="#">Business Loan</Link>
                </div>
                {/* <div className="blog-details__social">
                  <Link to="#">
                    <i className="fab fa-twitter"></i>
                  </Link>
                  <Link to="#">
                    <i className="fab fa-facebook"></i>
                  </Link>
                  <Link to="#">
                    <i className="fab fa-instagram"></i>
                  </Link>
                  <Link to="#">
                    <i className="fab fa-pinterest-p"></i>
                  </Link>
                </div> */}
              </div>
              {/* <div className="blog-details__author">
                <div className="blog-details__author__image">
                  <img src="assets/images/blog/blog-author-1-1.jpg" alt="" />
                </div>
                <div className="blog-details__author__content">
                  <h3 className="blog-details__author__name">Author Details</h3>
                  <p className="blog-details__author__text">
                    Lorem ipsum is simply free text by copytyping refreshing.
                    Neque porro est qui dolorem ipsum quia quaed veritatis et
                    quasi architecto.
                  </p>
                </div>
              </div> */}
              {/* <div className="blog-details__comment">
                <h3 className="blog-details__title">2 Comments</h3>
                <div className="blog-details__comment__item">
                  <div className="blog-details__comment__image">
                    <img src="assets/images/blog/blog-comment-1-1.jpg" alt="" />
                  </div>
                  <div className="blog-details__comment__content">
                    <h3 className="blog-details__comment__name">
                      Kevin Martin
                    </h3>
                    <p className="blog-details__comment__text">
                      Lorem ipsum is simply free text used by copytyping
                      refreshing. Neque porro est qui dolorem ipsum quia quaed
                      inventore veritatis et quasi architecto beatae vitae dicta
                      sunt explicabo.
                    </p>
                  </div>
                  <Link to="#" className="thm-btn">
                    Reply
                  </Link>
                </div>
                <div className="blog-details__comment__item">
                  <div className="blog-details__comment__image">
                    <img src="assets/images/blog/blog-comment-1-2.jpg" alt="" />
                  </div>
                  <div className="blog-details__comment__content">
                    <h3 className="blog-details__comment__name">
                      Kevin Martin
                    </h3>
                    <p className="blog-details__comment__text">
                      Lorem ipsum is simply free text used by copytyping
                      refreshing. Neque porro est qui dolorem ipsum quia quaed
                      inventore veritatis et quasi architecto beatae vitae dicta
                      sunt explicabo.
                    </p>
                  </div>
                  <Link to="#" className="thm-btn">
                    Reply
                  </Link>
                </div>
              </div> */}
              {/* <div className="blog-details__form">
                <h3 className="blog-details__title">Leave a comment</h3>
                <form
                  action="assets/sendemail.php"
                  className="form-one contact-form-validated">
                  <div className="row row-gutter-y-20 row-gutter-x-20">
                    <div className="col-md-6">
                      <input type="text" placeholder="Full name" name="name" />
                    </div>
                    <div className="col-md-6">
                      <input
                        type="email"
                        placeholder="Email address"
                        name="email"
                      />
                    </div>
                    <div className="col-md-6">
                      <input
                        type="text"
                        placeholder="Phone number"
                        name="phone"
                      />
                    </div>
                    <div className="col-md-6">
                      <input type="text" placeholder="Subject" name="subject" />
                    </div>
                    <div className="col-md-12">
                      <textarea placeholder="Message" name="message"></textarea>
                    </div>
                    <div className="col-md-12">
                      <button type="submit" className="thm-btn">
                        Send a Message
                      </button>
                    </div>
                  </div>
                </form>
              </div> */}
            </div>
            <div className="col-lg-4">
              <div className="blog-sidebar">
                <div className="blog-sidebar__item blog-sidebar__item--search">
                  <form action="#" className="blog-sidebar__search">
                    <input type="search" placeholder="Search" />
                    <button type="submit" className="blog-sidebar__search__btn">
                      <i className="icon-magnifying-glass"></i>
                    </button>
                  </form>
                </div>
                {/* <div className="blog-sidebar__item blog-sidebar__item--posts">
                  <h3 className="blog-sidebar__title">Recent Posts</h3>
                  <ul className="list-unstyled blog-sidebar__post">
                    <li className="blog-sidebar__post__item">
                      <div className="blog-sidebar__post__image">
                        <img src="assets/images/blog/lp-1-1.jpg" alt="" />
                      </div>
                      <div className="blog-sidebar__post__content">
                        <span className="blog-sidebar__post__author">
                          <i className="far fa-user-circle"></i>
                          by admin
                        </span>
                        <h3 className="blog-sidebar__post__title">
                          <Link to="/newsdetails">Types of Loans</Link>
                        </h3>
                      </div>
                    </li>
                    <li className="blog-sidebar__post__item">
                      <div className="blog-sidebar__post__image">
                        <img src="assets/images/blog/lp-1-2.jpg" alt="" />
                      </div>
                      <div className="blog-sidebar__post__content">
                        <span className="blog-sidebar__post__author">
                          <i className="far fa-user-circle"></i>
                          by admin
                        </span>
                        <h3 className="blog-sidebar__post__title">
                          <Link to="/newsdetails">How to Buy a House</Link>
                        </h3>
                      </div>
                    </li>
                    <li className="blog-sidebar__post__item">
                      <div className="blog-sidebar__post__image">
                        <img src="assets/images/blog/lp-1-3.jpg" alt="" />
                      </div>
                      <div className="blog-sidebar__post__content">
                        <span className="blog-sidebar__post__author">
                          <i className="far fa-user-circle"></i>
                          by admin
                        </span>
                        <h3 className="blog-sidebar__post__title">
                          <Link to="/newsdetails">
                            Lenders Mortgage Insurance (LMI)
                          </Link>
                        </h3>
                      </div>
                    </li>
                  </ul>
                </div> */}
                <div className="blog-sidebar__item blog-sidebar__item--categories">
                  <h3 className="blog-sidebar__title">Categories</h3>
                  <ul className="list-unstyled blog-sidebar__categories">
                    <li>
                      <Link to="#" className="active">
                        Property Investment
                      </Link>
                    </li>
                    <li>
                      <Link to="/home-loan-applications">
                        Home Loan Applications
                      </Link>
                    </li>
                    <li>
                      <Link to="/interest-rates">Interest Rates</Link>
                    </li>
                    <li>
                      <Link to="/home-buyers-rejoice">Home Buyers Rejoice</Link>
                    </li>
                    <li>
                      <Link to="/home-bargain-suburbs">
                        Home Bargain Suburbs
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="blog-sidebar__item blog-sidebar__item--tags">
                  <h3 className="blog-sidebar__title">Tags</h3>
                  <ul className="list-unstyled blog-sidebar__tags">
                    <li>
                      <Link to="#">Property Investors</Link>
                    </li>
                    <li>
                      <Link to="#">Home Loan</Link>
                    </li>
                    <li>
                      <Link to="#">Personal Loan</Link>
                    </li>
                    <li>
                      <Link to="#">Banking</Link>
                    </li>
                    <li>
                      <Link to="#">Finance</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default PropertyInvestmentDetails;
