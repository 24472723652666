import React, { useState } from "react";
import axios from "axios";
import { Form, Button, Card } from "react-bootstrap";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const CreateBlog = () => {
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [data, setData] = useState("");
  const [mainImage, setMainImage] = useState(null);

  const editorConfiguration = {};
  const handleEditorChange = (event, editor) => {
    // const data = editor.getData();
    // setEditorData(data);
    console.log(event, editor);
  };
  // Example using axios in your React component to store the CKEditor blog
  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("title", title);
    formData.append("content", content);
    if (mainImage) {
      formData.append("mainImage", mainImage);
    }
    console.log(
      formData.get("title"),
      formData.get("mainImage"),
      formData.get("content")
    );
    try {
      const response = await axios.post(
        "http://localhost:3001/api/blogs",
        formData
      );
      handleRetrieveAllBlog();
      console.log(response.data.message);
    } catch (error) {
      console.error("Error:", error.response.data.message);
    }
  };

  // Example using axios in your React component to retrieve the CKEditor blog
  // Retrieve Blogs by id
  const handleRetrieveBlog = async () => {
    try {
      const blogId = "your_blog_id";
      const response = await axios.get(
        `http://localhost:3001/api/blogs/${blogId}`
      );

      console.log("Retrieved Blog:", response.data);
    } catch (error) {
      console.error("Error:", error.response.data.message);
    }
  };
  // Retrieve All Blogs
  const handleRetrieveAllBlog = async () => {
    try {
      const response = await axios.get("http://localhost:3001/api/blogs");

      console.log("Retrieved Blogs:", response.data);
    } catch (error) {
      console.error("Error:", error.response.data.message);
    }
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setMainImage(file);
  };
  const createFormData = () => {
    const formData = new FormData();
    formData.set("title", title);
    if (mainImage instanceof File) {
      formData.set("mainImage", mainImage, mainImage.name);
    }
    formData.set("content", content);
    return formData;
  };
  // const handleSubmit = async (e) => {
  //   e.preventDefault();

  //   const formData = new FormData();

  //   formData.append("title", title);
  //   formData.append("mainImage", mainImage);
  //   formData.append("content", content);
  //   console.log(formData.get("title"));

  //   const apiUrl = "https://example.com/api/upload-blog";

  //   try {
  //     const response = await fetch(apiUrl, {
  //       method: "POST",
  //       body: formData,
  //     });

  //     if (response.ok) {
  //       console.log("Blog uploaded successfully!");
  //       // You can redirect the user or perform any other actions upon successful upload
  //     } else {
  //       console.error("Failed to upload blog:", response.statusText);
  //     }
  //   } catch (error) {
  //     console.error("Error during blog upload:", error.message);
  //   }
  // };
  //   const handleSave = async () => {
  //     try {
  //       await axios.post("http://localhost:3001/api/blogs", { title, content });
  //       console.log("Blog saved successfully");
  //     } catch (error) {
  //       console.error("Error saving blog:", error.message);
  //     }
  //   };
  const API_URL = "https://noteyard-backend.herokuapp.com";
  const UPLOAD_ENDPOINT = "api/blogs/uploadImg";
  function uploadAdapter(loader) {
    return {
      upload: () => {
        return new Promise((resolve, reject) => {
          const body = new FormData();
          loader.file.then((file) => {
            body.append("uploadImg", file);
            fetch(`${API_URL}/${UPLOAD_ENDPOINT}`, {
              method: "post",
              body: body,
            })
              .then((res) => res.json())
              .then((res) => {
                resolve({ default: `${API_URL}/${res.url}` }).catch((err) => {
                  reject(err);
                });
              });
          });
        });
      },
    };
  }
  function uploadPlugin(editor) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      return new uploadAdapter(loader);
    };
  }
  return (
    <>
      {/* <div>
        <h3>Editor Output:</h3>
        <div dangerouslySetInnerHTML={{ __html: editorData }} />
      </div> */}
      {/* <EditorWrapper>
        <ReactQuill
          value={content}
          onChange={handleQuillChange}
          modules={quillModules}
          formats={quillFormats}
          placeholder="Write something amazing..."
        />
      </EditorWrapper> */}
      <Card
        style={{
          margin: "50px",
          border: "1px solid #dee2e6",
          borderRadius: "10px",
          padding: "50px",
        }}>
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="title">
            <Form.Label>Title:</Form.Label>
            <Form.Control
              type="text"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </Form.Group>

          <Form.Group controlId="mainImage" className="col-md-12">
            <Form.Label>Main Image:</Form.Label>
            <Form.Control
              className="col-md-6"
              type="file"
              accept="image/*"
              onChange={handleImageChange}
            />
            {mainImage && (
              <Card
                style={{
                  margin: "50px",
                  border: "1px solid #dee2e6",
                  borderRadius: "10px",
                  padding: "50px",
                  width: "50%",
                  height: "50%",
                }}>
                <img
                  src={URL.createObjectURL(mainImage)}
                  alt="Preview"
                  style={{ marginTop: "10px", width: "100%", height: "auto" }}
                />
              </Card>
            )}
          </Form.Group>

          <Form.Group controlId="content">
            <Form.Label>Content:</Form.Label>
            {/* <CKEditor
              editor={ClassicEditor}
              data={content}
              onChange={(event, editor) => setContent(editor.getData())}
            /> */}
            <CKEditor
              editor={ClassicEditor}
              config={{
                extraPlugins: [uploadPlugin],
              }}
              // onInit={(editor) => {
              //   editor.plugins.get("FileRepository").createUploadAdapter = (
              //     loader
              //   ) => {
              //     return new MyUploadAdapter(loader);
              //   };
              // }}
              // config={{
              //   ckfinder: {
              //     uploadUrl: "https:/localhost:3001/api/uploads",
              //   },
              //   toolbar: [
              //     "selectAll",
              //     "undo",
              //     "redo",
              //     "bold",
              //     "italic",
              //     "blockQuote",
              //     "ckfinder",
              //     "imageTextAlternative",
              //     "imageUpload",
              //     "heading",
              //     "imageStyle:full",
              //     "imageStyle:side",
              //     "indent",
              //     "outdent",
              //     "link",
              //     "numberedList",
              //     "bulletedList",
              //     "mediaEmbed",
              //     "insertTable",
              //     "tableColumn",
              //     "tableRow",
              //     "mergeTableCells",
              //     "fontBackgroundColor",
              //     "fontColor",
              //   ],
              //   image: {
              //     // Configure the available styles.
              //     styles: ["alignLeft", "alignCenter", "alignRight"],
              //     sizes: ["50%", "75%", "100%"],

              //     // Configure the available image resize options.
              //     resizeOptions: [
              //       {
              //         name: "imageResize:original",
              //         label: "Original",
              //         value: null,
              //       },
              //       {
              //         name: "imageResize:50",
              //         label: "50%",
              //         value: "50",
              //       },
              //       {
              //         name: "imageResize:75",
              //         label: "75%",
              //         value: "75",
              //       },
              //     ],

              //     // You need to configure the image toolbar, too, so it shows the new style
              //     // buttons as well as the resize buttons.
              //     toolbar: [
              //       "imageStyle:alignLeft",
              //       "imageStyle:alignCenter",
              //       "imageStyle:alignRight",
              //       "|",
              //       "imageResize",
              //       "|",
              //       "imageTextAlternative",
              //     ],
              //   },
              // }}
              data={content}
              onChange={(event, editor) => {
                const data = editor.getData();
                setContent(data);
                console.log({ event, editor, data });
              }}
              // onBlur={(event, editor) => {
              //   console.log("Blur.", editor);
              //   console.log(Array.from(editor.ui.componentFactory.names()));
              // }}
              // onFocus={(event, editor) => {
              //   console.log("Focus.", editor);
              // }}
            />
          </Form.Group>

          <Button variant="primary" type="submit">
            Submit
          </Button>
        </Form>
      </Card>
    </>
  );
};

export default CreateBlog;
