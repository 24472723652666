import React from "react";
import { Link } from "react-router-dom";
import TreeOrSeaChangeIcon from "../../../assets/resourses/seaAndTree.jpg";

export default function TreeOrSeaChange() {
  return (
    <>
      <section className="blog-details pt-100 pb-120">
        <div className="container">
          <div className="row row-gutter-y-30">
            <div className="col-lg-8">
              <div className="blog-card__image">
                <div className="blog-card__date">
                  <span>14</span> June
                </div>
                <img src={TreeOrSeaChangeIcon} alt="" />
              </div>
              <div className="blog-card__meta">
                <Link to="/newsdetails">
                  <i className="far fa-user-circle"></i> by Admin
                </Link>
                <Link to="/newsdetails">
                  <i className="far fa-comments"></i>
                </Link>
              </div>
              <h3 className="blog-card__title">
                Is a tree or sea change on your horizon?
              </h3>

              <div className="blog-details__content">
                <br />
                <b>
                  Fresh air, no bumper-to-bumper traffic and more affordable
                  home prices. There’s plenty of appeal in regional living,
                  including a chance to potentially reduce your home loan.
                </b>
                <br />
                <br />
                <p>
                  The classic tune ‘Home among the gum trees’ is fast becoming a
                  lifestyle anthem for a growing number of Aussies.
                </p>
                <p>
                  A surging number of city-slickers are heading to the bush or
                  bay, new{" "}
                  <a
                    href="https://www.commbank.com.au/articles/newsroom/2024/05/metro-to-regional-movers-multiply.html"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Commonwealth Bank research
                  </a>{" "}
                  shows.
                </p>
                <p>
                  In fact, metro to regional relocations are now 20% higher than
                  pre-Covid.
                </p>
                <p>
                  It goes to show that regional towns and cities have a lot
                  going for them.
                </p>
                <p>So what’s the appeal?</p>
                <p>
                  Along with a laidback lifestyle and the chance to see Skippy
                  on your way to work, rather than countless sets of traffic
                  lights, a key drawcard of regional living is more affordable
                  housing.
                </p>
              </div>
              <h4 style={{ color: "#01BBBB" }}>Where are people moving?</h4>
              <div className="blog-details__content">
                <p>
                  The Sunshine Coast in South East Queensland is currently the
                  nation’s most popular destination for Australian movers,
                  securing a 16% share of net internal migration over the past
                  12 months.
                </p>
                <p>
                  Other popular areas outside our nation’s capital cities
                  include the Gold Coast, Wollongong, Newcastle, Lake Macquarie,
                  Moorabool, Geelong, the Alexandrina region, the Fraser Coast
                  and Launceston.
                </p>
                <p>
                  Western Australia is also becoming an increasingly attractive
                  destination with Busselton, Capel, Greater Geraldton, Northam
                  and Albany all making their way onto various hotspot lists
                  this quarter.
                </p>

                <br />
                <h4 style={{ color: "#01BBBB" }}>
                  Regional home values vs city prices
                </h4>

                <p>
                  Across Australia’s capital cities, the median home value is
                  about $864,780, according to{" "}
                  <a
                    href="https://www.corelogic.com.au/__data/assets/pdf_file/0028/22969/CoreLogic-HVI-JUN-2024-FINAL.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    CoreLogic.
                  </a>{" "}
                </p>
                <p>
                  By comparison, the median value across regional markets is
                  $626,888.
                </p>
                <p>That’s a whopping $237,892 difference.</p>
                <p>
                  The price gap can be far bigger depending on where you’re
                  moving from and moving to.
                </p>
                <p>
                  In Sydney, for instance, the median house value is $1,441,957.
                  Head to regional NSW, and you could pay closer to $760,000 for
                  a house – a saving of around $680,000!
                </p>

                <br />
                <h4 style={{ color: "#01BBBB" }}>
                  Regional living can help cut loan repayments
                </h4>

                <p>
                  Buying a more affordable home can have other flow-on benefits,
                  such as a lower stamp duty bill.
                </p>
                <p>It can also have a huge impact on home loan repayments.</p>
                <p>
                  For example, let’s use the above figures and pretend you’re
                  deciding between purchasing an $864,780 capital city home and
                  a $626,888 regional area home.
                </p>
                <p>
                  To keep things simple, let’s say you’ve saved up $173,000 for
                  a 20% deposit on the $864,780 home – and you’ve also got extra
                  money set aside to cover any stamp duty expenses or other fees
                  (the exact amount would vary state to state).
                </p>
                <p>
                  Let’s also assume a home loan rate of 6.4%, which the{" "}
                  <a
                    href="https://www.rba.gov.au/statistics/interest-rates/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Reserve Bank of Australia
                  </a>{" "}
                  says is about the current average principal and interest
                  variable rate, and a 30-year loan term.
                </p>
                <p>
                  On this basis, the initial mortgage for the city home would be
                  about $692,000 and the monthly mortgage repayments on the city
                  home would come to around $4,329 each each month.
                </p>
                <p>
                  For the regional property, your initial mortgage would be
                  about $454,000 (assuming you put the full $173,000 towards the
                  deposit) with monthly repayments in the order of $2,840.
                </p>
                <p>
                  That’s a monthly saving of $1,489 by moving to a regional area
                  – extra money to spend on your home, yourself or your
                  lifestyle.
                </p>

                <br />
                <h4 style={{ color: "#01BBBB" }}>What about capital growth?</h4>

                <p>
                  No one can say with certainty how property values will perform
                  in the future.
                </p>
                <p>
                  What we can do however is look at how house prices have
                  performed across regional areas in recent years.
                </p>
                <p>
                  {" "}
                  <a
                    href="https://www.corelogic.com.au/__data/assets/pdf_file/0028/22969/CoreLogic-HVI-JUN-2024-FINAL.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    CoreLogic
                  </a>{" "}
                  says values in regional areas have jumped 51.1% ($212,000)
                  nationally since March 2020, compared to an average of 31.5%
                  ($207,000) across our state capitals.
                </p>
                <p>
                  So in terms of dollar values, the capital gains across both
                  markets have been fairly similar in recent years.
                </p>
                <br />
                <h4 style={{ color: "#01BBBB" }}>
                  Ready for your home among the gum trees?
                </h4>

                <p>Okay, regional living isn’t for everyone.</p>
                <p>
                  Even for committed fans, moving from a capital city to a
                  regional area calls for careful planning and research.
                </p>
                <p>
                  But if you’re hankering for a home with a more manageable
                  mortgage, give us a call today to discuss loan options that
                  could help you get that tree or sea change happening sooner.
                </p>

                <p>
                  Disclaimer: The content of this article is general in nature
                  and is presented for informative purposes. It is not intended
                  to constitute tax or financial advice, whether general or
                  personal nor is it intended to imply any recommendation or
                  opinion about a financial product. It does not take into
                  consideration your personal situation and may not be relevant
                  to circumstances. Before taking any action, consider your own
                  particular circumstances and seek professional advice. This
                  content is protected by copyright laws and various other
                  intellectual property laws. It is not to be modified,
                  reproduced or republished without prior written consent.
                </p>
                <p>
                  {" "}
                  <a
                    href="https://www.dropbox.com/scl/fo/1pbuofh2tfkd8n9feahbl/ACnhgywaJdz9KdoREK05seI?rlkey=08h90fj7g5q4t9qe7k9ay2awm&e=1&st=hns3e8wk&dl=0"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Pictures of varying shapes located here
                  </a>{" "}
                </p>
                <br />
                <p>
                  {" "}
                  <a
                    href="https://www.canva.com/design/DAGH5un_34k/wUZQNW_3pIBSzlsTkzSnlg/edit"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    High-resolution image download here
                  </a>{" "}
                </p>
                <p>
                  Social media teaser. Fresh air, no bumper-to-bumper traffic
                  and more affordable home prices. There’s plenty of appeal in
                  regional living, including a chance to potentially reduce your
                  home loan. 🏡
                </p>
                <p>Subheader. Ever dreamed of leaving the rat race behind?</p>
                <p>
                  Suggested MailChimp subject line: Is a tree or sea change on
                  your horizon?
                </p>
                <p>
                  Suggested MailChimp preview text: Fresh air, no
                  bumper-to-bumper traffic and more affordable home prices.
                  There’s plenty of appeal in regional living.
                </p>

                {/* <div className="blog-details__social">
              <Link to="#">
                <i className="fab fa-twitter"></i>
              </Link>
              <Link to="#">
                <i className="fab fa-facebook"></i>
              </Link>
              <Link to="#">
                <i className="fab fa-instagram"></i>
              </Link>
              <Link to="#">
                <i className="fab fa-pinterest-p"></i>
              </Link>
            </div> */}
              </div>
              {/* <div className="blog-details__author">
            <div className="blog-details__author__image">
              <img src="assets/images/blog/blog-author-1-1.jpg" alt="" />
            </div>
            <div className="blog-details__author__content">
              <h3 className="blog-details__author__name">Author Details</h3>
              <p className="blog-details__author__text">
                Lorem ipsum is simply free text by copytyping refreshing.
                Neque porro est qui dolorem ipsum quia quaed veritatis et
                quasi architecto.
              </p>
            </div>
          </div> */}
              {/* <div className="blog-details__comment">
            <h3 className="blog-details__title">2 Comments</h3>
            <div className="blog-details__comment__item">
              <div className="blog-details__comment__image">
                <img src="assets/images/blog/blog-comment-1-1.jpg" alt="" />
              </div>
              <div className="blog-details__comment__content">
                <h3 className="blog-details__comment__name">
                  Kevin Martin
                </h3>
                <p className="blog-details__comment__text">
                  Lorem ipsum is simply free text used by copytyping
                  refreshing. Neque porro est qui dolorem ipsum quia quaed
                  inventore veritatis et quasi architecto beatae vitae dicta
                  sunt explicabo.
                </p>
              </div>
              <Link to="#" className="thm-btn">
                Reply
              </Link>
            </div>
            <div className="blog-details__comment__item">
              <div className="blog-details__comment__image">
                <img src="assets/images/blog/blog-comment-1-2.jpg" alt="" />
              </div>
              <div className="blog-details__comment__content">
                <h3 className="blog-details__comment__name">
                  Kevin Martin
                </h3>
                <p className="blog-details__comment__text">
                  Lorem ipsum is simply free text used by copytyping
                  refreshing. Neque porro est qui dolorem ipsum quia quaed
                  inventore veritatis et quasi architecto beatae vitae dicta
                  sunt explicabo.
                </p>
              </div>
              <Link to="#" className="thm-btn">
                Reply
              </Link>
            </div>
          </div> */}
              {/* <div className="blog-details__form">
            <h3 className="blog-details__title">Leave a comment</h3>
            <form
              action="assets/sendemail.php"
              className="form-one contact-form-validated">
              <div className="row row-gutter-y-20 row-gutter-x-20">
                <div className="col-md-6">
                  <input type="text" placeholder="Full name" name="name" />
                </div>
                <div className="col-md-6">
                  <input
                    type="email"
                    placeholder="Email address"
                    name="email"
                  />
                </div>
                <div className="col-md-6">
                  <input
                    type="text"
                    placeholder="Phone number"
                    name="phone"
                  />
                </div>
                <div className="col-md-6">
                  <input type="text" placeholder="Subject" name="subject" />
                </div>
                <div className="col-md-12">
                  <textarea placeholder="Message" name="message"></textarea>
                </div>
                <div className="col-md-12">
                  <button type="submit" className="thm-btn">
                    Send a Message
                  </button>
                </div>
              </div>
            </form>
          </div> */}
            </div>
            <div className="col-lg-4">
              <div className="blog-sidebar">
                <div className="blog-sidebar__item blog-sidebar__item--search">
                  <form action="#" className="blog-sidebar__search">
                    <input type="search" placeholder="Search" />
                    <button type="submit" className="blog-sidebar__search__btn">
                      <i className="icon-magnifying-glass"></i>
                    </button>
                  </form>
                </div>
                {/* <div className="blog-sidebar__item blog-sidebar__item--posts">
              <h3 className="blog-sidebar__title">Recent Posts</h3>
              <ul className="list-unstyled blog-sidebar__post">
                <li className="blog-sidebar__post__item">
                  <div className="blog-sidebar__post__image">
                    <img src="assets/images/blog/lp-1-1.jpg" alt="" />
                  </div>
                  <div className="blog-sidebar__post__content">
                    <span className="blog-sidebar__post__author">
                      <i className="far fa-user-circle"></i>
                      by admin
                    </span>
                    <h3 className="blog-sidebar__post__title">
                      <Link to="/newsdetails">Types of Loans</Link>
                    </h3>
                  </div>
                </li>
                <li className="blog-sidebar__post__item">
                  <div className="blog-sidebar__post__image">
                    <img src="assets/images/blog/lp-1-2.jpg" alt="" />
                  </div>
                  <div className="blog-sidebar__post__content">
                    <span className="blog-sidebar__post__author">
                      <i className="far fa-user-circle"></i>
                      by admin
                    </span>
                    <h3 className="blog-sidebar__post__title">
                      <Link to="/newsdetails">How to Buy a House</Link>
                    </h3>
                  </div>
                </li>
                <li className="blog-sidebar__post__item">
                  <div className="blog-sidebar__post__image">
                    <img src="assets/images/blog/lp-1-3.jpg" alt="" />
                  </div>
                  <div className="blog-sidebar__post__content">
                    <span className="blog-sidebar__post__author">
                      <i className="far fa-user-circle"></i>
                      by admin
                    </span>
                    <h3 className="blog-sidebar__post__title">
                      <Link to="/newsdetails">
                        Lenders Mortgage Insurance (LMI)
                      </Link>
                    </h3>
                  </div>
                </li>
              </ul>
            </div> */}
                <div className="blog-sidebar__item blog-sidebar__item--categories">
                  <h3 className="blog-sidebar__title">Categories</h3>
                  <ul className="list-unstyled blog-sidebar__categories">
                    <li>
                      <Link to="#" className="active">
                        Home Bargain Suburbs
                      </Link>
                    </li>
                    <li>
                      <Link to="/home-buyers-rejoice">Home Buyers Rejoice</Link>
                    </li>
                    <li>
                      <Link to="/home-loan-applications">
                        Home Loan Applications
                      </Link>
                    </li>
                    <li>
                      <Link to="/interest-rates">Interest Rates</Link>
                    </li>
                    <li>
                      <Link to="/property-investment">Property Investment</Link>
                    </li>
                  </ul>
                </div>
                <div className="blog-sidebar__item blog-sidebar__item--tags">
                  <h3 className="blog-sidebar__title">Tags</h3>
                  <ul className="list-unstyled blog-sidebar__tags">
                    <li>
                      <Link to="#">Mortage</Link>
                    </li>
                    <li>
                      <Link to="#">Business Loan</Link>
                    </li>
                    <li>
                      <Link to="#">Personal Loan</Link>
                    </li>
                    <li>
                      <Link to="#">Banking</Link>
                    </li>
                    <li>
                      <Link to="#">Finance</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
