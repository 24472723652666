import React, { useState } from "react";
import Modal from "react-modal";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const VirtualMeeting = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };
  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      className="modal-overlay"
      overlayClassName="custom-overlay">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}>
        <button className="close-icon" onClick={closeModal}>
          <Link className="close-button">
            <FontAwesomeIcon icon={faTimes} />
          </Link>
        </button>
        <div className="col-md-5">
          <h1
            style={{
              fontSize: "24px",
              marginBottom: "20px",
              lineHeight: "1.5",
              textAlign: "justify",
            }}>
            We’ve identified 450 loans that may be suitable for you.
          </h1>
          {/* <h3
          style={{
            fontSize: "14px",
            color: "#575E6C",
            lineHeight: "1.5",
            textAlign: "justify",
          }}>
          Please confirm your contact details below so we can qualify
          you for the best unadvertised deals.
        </h3> */}

          <iframe
            title="Calendly Inline Widget"
            src="https://calendly.com/securefinance-meetings"
            style={{ width: "100%", height: "800px", border: "0" }}
            scrolling="no"></iframe>
        </div>
      </div>
    </Modal>
  );
};

export default VirtualMeeting;
