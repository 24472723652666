import React from "react";
import { Helmet } from "react-helmet";
import "../../../assets/css/calc.css";

function LumpSumRepaymentCalculator() {
  return (
    <>
      <Helmet>
        <title>Lump Sum Repayment Calculator - Secure Finance</title>
        <meta
          name="description"
          content="Use our Lump Sum Repayment Calculator to see how a lump 
          sum payment can save you time and interest on your loan. Input 
          your loan amount, interest rate, term, repayment frequency, and
           lump sum details to view your potential savings."
        />
        <link
          rel="canonical"
          href="https://securefinance.com.au/lump-sum-repayment-calculator"
        />
      </Helmet>
      <section className="benefit-one pt-60 pb-60">
        <div className="container">
          <div className="row row-gutter-y-60">
            <iframe
              className="VisiCalcClass"
              id="Lump_Sum_Repayment_Calculator"
              src="//www.visionabacus.net/Tools/B3/SuiteA/A300/Lump_Sum_Repayment_Calculator/SecureFinance"
              frameBorder="0"
              scrolling="no"
              title="Lump Sum Repayment Calculator"></iframe>
          </div>
        </div>
      </section>
    </>
  );
}

export default LumpSumRepaymentCalculator;
