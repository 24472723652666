import React from 'react'

function Maptwo() {
    return (
        <>
            <div className="google-map__default">
                <iframe title="template google map" 
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3318.0924137124575!2d150.95820861170455!3d-33.732426373167705!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6b12a2302a70d4c5%3A0x3996fecf023adb4e!2sSecure%20Finance!5e0!3m2!1sen!2sau!4v1700639119856!5m2!1sen!2sau" 
                width="600" height="450" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
            </div>
        </>
    )
}

export default Maptwo