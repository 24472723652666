import React, { useState } from "react";
import Firstslider from "../Menhome/Firstslider";
import Company from "../Menhome/Company";
import Safe from "../Menhome/Safe";
import Video from "../Menhome/Video";
import Clint from "../Menhome/Clint";
import What from "../Menhome/What";
import Calcuter from "../Menhome/Calcuter";
import Testimonials from "../Menhome/Testimonials";
import Counter from "../Menhome/Counter";
import Benefit from "../Menhome/Benefit";
import Offer from "../Menhome/Offer";
import Get from "../Menhome/Get";
import Loan from '../Menhometwo/Loan'
// import { Helmet } from 'react-helmet';
import LiveChatButton from "../Menhome/LiveChatButton";
const Main = () => {

  return (
    <>
    
    {/* <Helmet>
        <script type="text/javascript" dangerouslySetInnerHTML={{ __html: `
          var Tawk_API=Tawk_API||{}, Tawk_LoadStart=new Date();
          (function(){
            var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
            s1.async=true;
            s1.src='https://embed.tawk.to/655c6aed91e5c13bb5b22159/1hfog3ces';
            s1.charset='UTF-8';
            s1.setAttribute('crossorigin','*');
            s0.parentNode.insertBefore(s1,s0);
          })();
        ` }} />
      </Helmet> */}
      {/* <LiveChatButton/> */}
      <Firstslider/>
      <Company/>
      <Safe/>
      <Video/>
      <Clint/>
      <What/>
      <Calcuter/>
      <Testimonials/>
      <Counter/>
      <Loan/>
      {/* <Benefit/> */}
      {/* <Offer/> */}
      <Get/>
    </>
  );
};

export default Main;
