import React, { useEffect, useState } from "react";
import { Route, Routes, useLocation, Switch } from "react-router-dom";

import Navbar from "../components/Navbar/Main";
import Footer from "../components/Footer/Main";
import Home from "../components/Home/Main";
import Home2 from "../components/Home2/Main";
import Home3 from "../components/Home3/Main";
import About from "../components/About/Main";
import Service from "../components/Services/Service/Main";
import ServiceDetails from "../components/Services/ServiceDetails/Main";
import Team from "../components/Teams/Team/Main";
import TeamDetails from "../components/Teams/TeamDetails/Main";
import GouthamiDetails from "../components/Teams/TeamDetails/GouthamiDetails";
import SakibDetails from "../components/Teams/TeamDetails/SakibDetails";
import AshDetails from "../components/Teams/TeamDetails/AshDetails";

import AjayDetails from "../components/Teams/TeamDetails/AjayDetails";

import CreditAudit from "../components/Credit/CreditAudit/Main";
import CreditRepair from "../components/Credit/CreditRepair/Main";
import Careers from "../components/Careers/Main";
import Faqs from "../components/Faqs/Main";
import ApplyNow from "../components/ApplyNow/Main";
import CompareEMI from "../components/Features/CompareEMI/Main";
import HomeLoan from "../components/Features/HomeLoan/Main";
import HomeLoanEligibility from "../components/Features/HomeLoanEligibility/Main";
import LoanEligibility from "../components/Features/LoanEligibility/Main";
import MonthlyLoan from "../components/Features/MonthlyLoan/Main";
import PersonalLoan from "../components/Features/PersonalLoan/Main";
import NewsMain from "../components/News/NewsMain/Main";
import NewsDetails from "../components/News/NewsDetails/Main";
import Contact from "../components/Contact/Main";
import FaceToFaceMeeting from "../components/Menhome/virtualmeeting";
import VirtualMeeting from "../components//Calendly/VirtualMeeting";

import Maptwo from "../components/Menhometwo/Maptwo";
import SignIn from "../components/Menhome/SignIn";
import SignIn1 from "../components/authentication/SignIn/SignIn1";

import GuideForFirstHomeBuyers from "../components/Blogs/GuideForFirstHomeBuyers";

import FirstHomeBuyersLoans from "../components/LoanTypes/FirstHomeBuyersLoan";
import OwnerOccupiedLoans from "../components/LoanTypes/OwnerOccupiedLoans";
import InvestmentLoans from "../components/LoanTypes/InvestmentLoans";
import RefinanceLoans from "../components/LoanTypes/RefinanceLoans";
import SMSFLoans from "../components/LoanTypes/SMSFLoans";
import ConstructionLoans from "../components/LoanTypes/ConstructionLoans";
import GuarantorHomeLoans from "../components/LoanTypes/GuarantorHomeLoans";
import LowDocHomeLoans from "../components/LoanTypes/LowDocHomeLoans";
import CommercialPropertyLoans from "../components/LoanTypes/CommercialPropertyLoans";
import LoanForProfessionals from "../components/LoanTypes/LoanForProfessionals";
import AssetFinance from "../components/LoanTypes/AssetFinance";

import AllLoans from "../components/LoanTypes/AllLoans";

import Partners from "../components/Partners/Main";
import Forms from "../components/Forms/NewHomeForm/Forms";
import RefinanceForms from "../components/Forms/Refinance/RefinanceForms";

import NotFound from "../components/NotFound/NotFound";
import User from "../components/UserDashbaord/index";

import FourBoxRadio from "../components/Forms/NewHomeForm/FourBoxRadio";
import BlogsForm from "../components/BlogForm/BlogsForm";

import Blogs from "../components/BlogForm/Blogs";
import HomeLoanCalculator from "../components/Menhome/Calculators/homelonecalculator";
import BorrowingCapacityCalculator from "../components/Menhome/Calculators/BorrowingCapacityCalculator";
import IncomeTaxCalculator from "../components/Menhome/Calculators/IncomeTaxCalculator";
import StampDutyCalculator from "../components/Menhome/Calculators/StampDutyCalculator";
import LMICalculator from "../components/Menhome/Calculators/LMICalculator";
import RepaymentCalculator from "../components/Menhome/Calculators/RepaymentCalculator";
import Dashboard from "../components/Dashboard/Default";
import SignUp1 from "../components/authentication/SignUp/SignUp1";
import HomeLoanApplicationsResourses from "../components/Blogs/HomeLoanApplicationsResourses";
import HomeBuyersRejoiceResourses from "../components/Blogs/HomBuyersRejoiceResourses";
import HomeBargainSuburbResourses from "../components/Blogs/HomeBargainSuburbResourses";
import FirstHomeBuyer from "../components/Blogs/FirstHomeBuyer";
import TurnToBroker from "../components/Blogs/TurnToBroker";

import InterestRatesResourses from "../components/Blogs/InterestRatesResourses";
import PropertyInvestmentResourses from "../components/Blogs/PropertyInvestmentResourses";
import Calcuter from "../components/Menhome/Calcuter";

import AllCalculators from "../components/Menhome/Calculators/AllCalculators";
import InterestOnlyCalculator from "../components/Menhome/Calculators/InterestOnlyCalculator";
// import BorrowingCapacityCalculator from "../components/Menhome/Calculators/BorrowingCapacityCalculator";
import BorrowingCapacityCalculator2 from "../components/Menhome/Calculators/BorrowingCapacityCalculator2";
import BudgetPlannerCalculator from "../components/Menhome/Calculators/BudgetPlannerCalculator";
import ComparisonRateCalculator from "../components/Menhome/Calculators/ComparisonRateCalculator";
import CompundInterestCalculator from "../components/Menhome/Calculators/CompundInterestCalculator";
import CreditCardCalculator from "../components/Menhome/Calculators/CreditCardCalculator";
import ExtraRepaymentCalculator from "../components/Menhome/Calculators/ExtraRepaymentCalculator";
import FortnightlyRepaymentCalculator from "../components/Menhome/Calculators/FortnightlyRepaymentCalculator";
import HomeLoanOffsetCalculator from "../components/Menhome/Calculators/HomeLoanOffsetCalculator";
import Homelonecalculator from "../components/Menhome/Calculators/homelonecalculator";
import HowLongRepayCalculator from "../components/Menhome/Calculators/HowLongRepayCalculator";
import HowMuchtoDepositCalculator from "../components/Menhome/Calculators/HowMuchtoDepositCalculator";
import IncomeAnnualisationCalculator from "../components/Menhome/Calculators/IncomeAnnualisationCalculator";
import IncomeGrossUpCalculator from "../components/Menhome/Calculators/IncomeGrossUpCalculator";
// import IncomeTaxCalculator from "../components/Menhome/Calculators/IncomeTaxCalculator";
// import InterestOnlyCalculator from "../components/Menhome/Calculators/InterestOnlyCalculator";
import IntroductoryLoanCalculator from "../components/Menhome/Calculators/IntroductoryLoanCalculator";
import LeasingCalculator from "../components/Menhome/Calculators/LeasingCalculator";
import LeasingCarCalculator from "../components/Menhome/Calculators/LeasingCarCalculator";
// import LMICalculator from "../components/Menhome/Calculators/LMICalculator";
import LoanComparisonCalculator from "../components/Menhome/Calculators/LoanComparisonCalculator";
// import LoanRepaymentCalculator from "../components/Menhome/Calculators/LoanRepaymentCalculator";
import LumpSumRepaymentCalculator from "../components/Menhome/Calculators/LumpSumRepaymentCalculator";
import MortgageSwitchingCalculator from "../components/Menhome/Calculators/MortgageSwitchingCalculator";
import PropertyBuyingCostCalculator from "../components/Menhome/Calculators/PropertyBuyingCostCalculator";
import PropertySellingCostCalculator from "../components/Menhome/Calculators/PropertySellingCostCalculator";
import RentvsBuyCalculator from "../components/Menhome/Calculators/RentvsBuyCalculator";
// import RepaymentCalculator from "../components/Menhome/Calculators/RepaymentCalculator";
import ReverseMortgageCalculator from "../components/Menhome/Calculators/ReverseMortgageCalculator";
import SavingsCalculator from "../components/Menhome/Calculators/SavingsCalculator";
import SplitLoanCalculator from "../components/Menhome/Calculators/SplitLoanCalculator";
// import StampDutyCalculator from "../components/Menhome/Calculators/StampDutyCalculator";
import HowLongtoSaveCalculator from "../components/Menhome/Calculators/HowLongtoSaveCalculator";
import LoanRepaymentMonthlyCalculator from "../components/Menhome/Calculators/LoanRepaymentMonthlyCalculator";
import TreeOrSeaChange from "../components/Blogs/TreeOrSeaChange";

const Routing = () => {
  const [homepage, sethomepage] = useState(false);
  const location = useLocation();
  useEffect(() => {
    if (
      location.pathname === "/login" ||
      location.pathname === "/signup" ||
      location.pathname === "/not-found" ||
      location.pathname === "/forms" ||
      location.pathname === "/refinance"
    ) {
      sethomepage(false);
    } else {
      sethomepage(true);
    }
  }, [location]);

  return (
    <>
      {homepage && <Navbar />}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/home-02" element={<Home2 />} />
        <Route path="/home-03" element={<Home3 />} />
        <Route path="/about" element={<About />} />
        <Route path="/service" element={<Service />} />
        <Route path="/servicedetails" element={<ServiceDetails />} />
        <Route path="/team" element={<Team />} />
        <Route path="/teamdetails" element={<TeamDetails />} />
        <Route path="/gouthami-minupala" element={<GouthamiDetails />} />
        <Route path="/sakib-manzoor" element={<SakibDetails />} />
        <Route path="/ash" element={<AshDetails />} />
        <Route path="/ajay-banda" element={<AjayDetails />} />
        <Route path="/creditaudit" element={<CreditAudit />} />
        <Route path="/creditrepair" element={<CreditRepair />} />
        <Route path="/careers" element={<Careers />} />
        <Route path="/faqs" element={<Faqs />} />
        <Route path="/applynow" element={<ApplyNow />} />
        <Route path="/compareEMI" element={<CompareEMI />} />
        <Route path="/homeloan" element={<HomeLoan />} />
        <Route path="/homeloaneligibility" element={<HomeLoanEligibility />} />
        <Route path="/loaneligibility" element={<LoanEligibility />} />
        <Route path="/monthlyloan" element={<MonthlyLoan />} />
        <Route path="/personalloan" element={<PersonalLoan />} />
        <Route path="/newsmain" element={<NewsMain />} />
        <Route path="/newsdetails" element={<NewsDetails />} />
        <Route path="/face-to-face-meeting" element={<FaceToFaceMeeting />} />
        <Route path="/user" element={<User />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/virtual-meeting" element={<VirtualMeeting />} />
        <Route path="/map" element={<Maptwo />} />
        <Route path="/login" element={<SignIn1 />} />
        <Route path="/otp-login" element={<SignIn />} />
        <Route path="/calculator" element={<AllCalculators />} />

        <Route path="/our-partners" element={<Partners />} />
        <Route
          path="/first-home-buyers-loan"
          element={<FirstHomeBuyersLoans />}
        />
        <Route path="/owner-occupied-loans" element={<OwnerOccupiedLoans />} />
        <Route path="/investment-loans" element={<InvestmentLoans />} />
        <Route path="/refinance-loans" element={<RefinanceLoans />} />
        <Route path="/smsf-loans" element={<SMSFLoans />} />
        <Route path="/construction-loans" element={<ConstructionLoans />} />
        <Route path="/guarantor-home-loans" element={<GuarantorHomeLoans />} />
        <Route path="/low-doc-home-loans" element={<LowDocHomeLoans />} />
        <Route
          path="/commercial-property-loans"
          element={<CommercialPropertyLoans />}
        />
        <Route
          path="/loan-for-professionals"
          element={<LoanForProfessionals />}
        />
        <Route path="/asset-finance" element={<AssetFinance />} />
        <Route path="/all-loans" element={<NewsMain />} />

        <Route path="/resources" element={<Service />} />
        <Route
          path="/guide-for-first-home-buyers"
          element={<GuideForFirstHomeBuyers />}
        />
        <Route
          path="/home-loan-applications"
          element={<HomeLoanApplicationsResourses />}
        />
        <Route
          path="/home-buyers-rejoice"
          element={<HomeBuyersRejoiceResourses />}
        />
        <Route path="/interest-rates" element={<InterestRatesResourses />} />
        <Route
          path="/property-investment"
          element={<PropertyInvestmentResourses />}
        />
        <Route path="/turn-to-broker" element={<TurnToBroker />} />
        <Route path="/tree-or-sea-change" element={<TreeOrSeaChange />} />
        <Route path="/first-home-buyers" element={<FirstHomeBuyer />} />
        <Route
          path="/home-bargain-suburbs"
          element={<HomeBargainSuburbResourses />}
        />

        <Route path="/refinance" element={<RefinanceForms />} />
        <Route path="/forms" element={<Forms />} />
        <Route path="/not-found" element={<NotFound />} />
        <Route path="/upload-blog" element={<BlogsForm />} />
        <Route path="/blogs" element={<Blogs />} />
        <Route path="/homeLoanCalculator" element={<HomeLoanCalculator />} />
        <Route
          path="/borrowing-capacity-calculator"
          element={<BorrowingCapacityCalculator />}
        />
        <Route
          path="/income-tax-calculator"
          element={<IncomeTaxCalculator />}
        />
        <Route
          path="/stamp-duty-calculator"
          element={<StampDutyCalculator />}
        />
        <Route path="/lmi-calculator" element={<LMICalculator />} />
        <Route path="/repayment-calculator" element={<RepaymentCalculator />} />
        <Route path="/login" element={<SignIn1 />} />
        <Route path="/signup" element={<SignUp1 />} />
        <Route path="/talk-to-a-broker" element={<Contact />} />

        <Route
          path="/interest-only-mortgage-calculator"
          element={<InterestOnlyCalculator />}
        />
        <Route
          path="/borrowing-capacity-calculator"
          element={<BorrowingCapacityCalculator />}
        />
        <Route
          path="/borrowing-capacity-calculator-2"
          element={<BorrowingCapacityCalculator2 />}
        />
        <Route
          path="/budget-planner-calculator"
          element={<BudgetPlannerCalculator />}
        />
        <Route
          path="/comparison-rate-calculator"
          element={<ComparisonRateCalculator />}
        />
        <Route
          path="/compound-interest-calculator"
          element={<CompundInterestCalculator />}
        />
        <Route
          path="/credit-card-calculator"
          element={<CreditCardCalculator />}
        />
        <Route
          path="/extra-repayment-calculator"
          element={<ExtraRepaymentCalculator />}
        />
        <Route
          path="/fortnightly-repayment-calculator"
          element={<FortnightlyRepaymentCalculator />}
        />
        <Route
          path="/home-loan-offset-calculator"
          element={<HomeLoanOffsetCalculator />}
        />
        {/* <Route path="/" element={<Homelonecalculator />} /> */}
        <Route
          path="/how-long-to-repay-calculator"
          element={<HowLongRepayCalculator />}
        />
        <Route
          path="/how-long-to-save-calculator"
          element={<HowLongtoSaveCalculator />}
        />
        <Route
          path="/how-mucch-to-deposit-calculator"
          element={<HowMuchtoDepositCalculator />}
        />
        <Route
          path="/income-annualisation-calculator"
          element={<IncomeAnnualisationCalculator />}
        />
        <Route
          path="/income-grossUp-calculator"
          element={<IncomeGrossUpCalculator />}
        />
        <Route
          path="/introductory-rate-loan-calculator"
          element={<IntroductoryLoanCalculator />}
        />
        <Route path="/leasing-calculator" element={<LeasingCalculator />} />
        <Route
          path="/leasing-car-calculator"
          element={<LeasingCarCalculator />}
        />
        <Route
          path="/loan-comparison-calculator"
          element={<LoanComparisonCalculator />}
        />
        {/* <Route path="/loan-repayment-monthly-calculator" element={<LoanRepaymentCalculator />} /> */}
        <Route
          path="/loan-repayment-monthly-calculator"
          element={<LoanRepaymentMonthlyCalculator />}
        />
        <Route
          path="/lump-sum-repayment-calculator"
          element={<LumpSumRepaymentCalculator />}
        />
        <Route
          path="/mortgage-switching-calculator"
          element={<MortgageSwitchingCalculator />}
        />
        <Route
          path="/property-buying-cost-calculator"
          element={<PropertyBuyingCostCalculator />}
        />
        <Route
          path="/property-selling-cost-calculator"
          element={<PropertySellingCostCalculator />}
        />
        <Route
          path="/rent-vs-buy-calculator"
          element={<RentvsBuyCalculator />}
        />
        <Route
          path="/reverse-mortgage-calculator"
          element={<ReverseMortgageCalculator />}
        />
        <Route path="/saving-calculator" element={<SavingsCalculator />} />
        <Route
          path="/split-loan-calculator"
          element={<SplitLoanCalculator />}
        />
        <Route path="*" element={<NotFound />} />
      </Routes>
      {homepage && <Footer />}
    </>
  );
};

export default Routing;
