import BGS1 from "../../../assets/loan-pics/Guarantor pic 2.jpg";
import BGS from "../../../assets/new-images/SMSFLoansprocess.png";

import { Link } from "react-router-dom";
import React, { useState } from "react";

function GuarantorHomeLoansBody() {
  const [clicked, setClicked] = useState(null);
  const faqs = [
    {
      question: "What are other names for guarantor loans?",
      answer: `<p>As guarantors for home loans are usually family members, they are sometimes referred to as ‘family guarantee’, ‘family support’, ‘family equity’ or ‘family pledge’ home loans. Some even call them ‘fast track’ loans, because they enable a borrower to access funds sooner. However, not all lenders offer these types of loans.</p>
      <p>It’s also important not to confuse any loans involving a guarantor with co-borrower or co-applicant loan. Co-borrowers/co-applicants are jointly and individually responsible for servicing the loan from its inception until it’s fully repaid. An example of a co-borrower or co-applicant is a spouse or partner of the borrower if they are taking out a loan to buy a home to live in together. Guarantors on the other hand only become liable for servicing the loan repayments if the borrower(s) default.</p>
      `,
    },
    {
      question: "How do guarantor loans work",
      answer: `<p>A guarantor can provide extra security for your loan, usually via the lender taking a mortgage over the guarantor's property in addition to the borrower's property. The guarantor’s mortgage doesn’t directly support the borrower’s home loan. Instead, it acts as additional security for the lender’s interests if the borrower defaults on their repayments and they need to subsequently enforce the guarantor’s guarantee.</p>

      <p>For a home loan where a borrower defaults on their repayments, the lender will usually take action against the borrower first (e.g. they may attempt to sell the property before attempting to recover any outstanding debts from the guarantor). It’s important to note that lenders will only take repossession action if home loan repayments are in arrears by at least 90 days (and they may be prepared to negotiate a revised repayment schedule to avoid repossession). However, if repossession proceeds and the sale of the property isn’t enough to cover the home loan, the lender will take action to recover the shortfall from the guarantor.</p>
      `,
    },
    {
      question: "What are the benefits of a guarantor loan?",
      answer: `
      <p>A guarantor can help a borrower to qualify for a loan approval. For example, many parents are prepared to act as guarantors to enable their adult children to enter the home loan market sooner than they would be able to on their own.</p>

      
      <p>A guarantor loan may also help a borrower avoid lender’s mortgage insurance (LMI) on home loans. Mortgage insurance protects the lender if a borrower defaults on their future repayments. The policies of different lenders vary, but many require mortgage insurance if the loan-to-value ratio exceeds 80%. The premium for this mortgage insurance is an additional cost for the home loan borrower. However, if you have a guarantor, some lenders won’t require you to take out LMI.</p>

      <p>In addition, if you have other debt, opting for a guarantor home loan usually allows you to borrow more. You can consolidate any other high interest debt into your lower interest home loan, saving you on repayments and making money management easier.</p>

      `,
    },
    {
      question: "How much can I borrow with a guarantor loan?",
      answer: `
      <p>The security provided by the guarantor can help a person to borrow up to 105% of the value of the property they want to buy. This can help to assist with additional home-buying costs, such as stamp duty, furniture and landscaping. Ultimately, maximum LVR will depend on the policy of the lender - and it’s important for both the guarantor and the borrower that the borrower isn’t overstretched in terms of their repayment commitments. Get in touch with us for a full assessment of your borrowing capacity.</p>
      `,
    },
    {
      question: "Do I need to prove any savings?",
      answer: `<p>Again, this depends on the policy of the lender, but you may not have to. However, when you’re entering into a long-term financial commitment like a home loan, 
      ideally you should have a track record of being able to save money. This is a habit that will stand you in good stead with the lender and may also make a person feel more confident in signing on to be your guarantor.</p>`,
    },
    {
      question: "Can I buy an investment property?",
      answer: `<p>Not many lenders in Australia accept guarantors for investment property loans. The general philosophy of those that don’t is that people borrowing for investment purposes should be able “to stand on their own two feet”. In addition, a good investment property should generate enough income to enable the borrower to meet their debt repayment commitments, even it is bought for negative-gearing purposes. However, we may be able to assist so please get in touch to discuss your situation.</p>`,
    },
    {
      question: "What types of guarantees are there?",
      answer: `<p>Depending on the characteristics of the borrower and the lender’s policies, a guarantor may be required to provide:</p>
        <ul>
        <li>A security guarantee only (e.g. the equity they have in their own home)</li>
        <li>A security and income guarantee (e.g. a parent’s income could be considered in the lender’s assessment to prove that the loan is affordable for the borrower)</li>
        </ul>`,
    },
    {
      question: "How much is the guarantee limited to?",
      answer: `<p>This depends on the terms and conditions of the loan contract, but generally a guarantor provides a guarantee for the entire loan amount. If this isn’t necessary for the loan to be approved, the extent of the guarantee (e.g. 60% of the debt) will be specified in the contract.</p>`,
    },
    {
      question: "How long will I need a guarantor for?",
      answer: `<p>If you have a home loan over a term of 30 years, that doesn't mean you need to have a guarantor in place for the entire term of the contract. From the guarantor’s perspective, ideally the arrangement should cease once a portion of the loan is paid off that satisfies the lender’s requirements (i.e. that the borrower has built up enough equity in their home for their LVR to be acceptable to the lender without the need for the guarantor - generally once the LVR falls below 80%). Ideally, this should happen within five years, but varies on a case by case basis.</p>
        <p>Ultimately, from the lender’s perspective, they want to ensure that a strong relationship exists between the borrower and the guarantor as it makes it more likely that the debt will be repaid.</p>
        `,
    },
    {
      question: "Who can be a guarantor?",
      answer: `<p>A guarantor must meet a lender’s criteria. They will want to see that the guarantor has sufficient security and potentially the income to cover the debt if the borrower defaults on their repayments. Most lenders require guarantors to be immediate family members of the borrower (e.g. parents, siblings or grandparents). Some lenders may allow others to act as guarantors, but that’s less common.</p>`,
    },
    {
      question: "What are the responsibilities of being a guarantor?",
      answer: `<p>If a borrower defaults on their loan repayments, the lender will be able to take legal action against both the borrower and the guarantor to recover the debt if necessary. Exactly what action they can take depends on the terms and conditions of the loan contract. They will take action against the borrower first, but the guarantor is usually ultimately liable for any outstanding debt once that action has been taken.</p>
        
        `,
    },
    {
      question: "What are the risks of being a guarantor?",
      answer: `<p>If you’re considering becoming a guarantor you need to also consider the worst-case scenario. That is, how will you repay the debt you have guaranteed if the borrower does default on their repayments? Don’t naively assume it can’t or won’t happen. If you do agree to enter into a guarantor arrangement, you need to go in with your eyes open.</p>
        <p>A guarantor can be put in a risky situation if the borrower defaults and property prices have fallen while the amount owing is still very high. The default may be through no fault of the borrower (e.g. they might come down with a serious illness and lose their earning capacity).</p>
        <p>If the home loan is not fixed (i.e. variable interest), a guarantor also should consider what will happen if interest rates rise, because the repayments will also rise. That may place the borrower at a higher risk of default, increasing the risk that the guarantor will become liable for any outstanding debt.</p>
             <p>If the borrower defaults and you can’t meet your obligations as the guarantor, it will negatively affect your credit rating. You run the risk that the security you’ve provided may be seized by the lender to pay the outstanding debt. You could potentially lose your home if that has been provided as security or face bankruptcy.</p>
             <p>You can reduce your risk as a guarantor by:</p>
             <ul>
             <li>Providing property assets instead of your family home as security (e.g. an investment property)</li>
             <li>Not agreeing to become a guarantor on a home loan with a high LVR (e.g. above 90%). Instead, you could insist that the borrower comes up with a higher deposit or buys a cheaper home.</li>
             <li>Limiting your guarantee in terms of the amount covered, so as long as it meets the lender’s criteria. For example, your guarantee may be limited to a portion of the loan (e.g. 20% of the outstanding debt). This portion would be the maximum that you would be required to pay if the borrower defaults, even if that amount doesn’t fully cover the outstanding debt.</li>
             </ul>
             <p>In addition, if you become a guarantor, you need to understand that your future borrowing capacity will be reduced while the arrangement remains in place. That’s because your guarantee commitment will be considered in any future credit application you make.</p>
             <p>An alternative to signing on as a guarantor is to help in other ways. For example, you could help the borrower with a deposit so that they meet the lender’s maximum LVR, without signing on as a guarantor and having the additional legal obligations that entails.</p>
             `,
    },
    {
      question: "Should I act as a guarantor?",
      answer: `<p>That’s not an easy question to answer, because it depends on your individual circumstances. If you’ve been asked to be a guarantor or are considering it, ensure you obtain independent legal and financial advice before signing any agreement.</p>
      <p>You should also ensure you and the borrower understand all the implications and responsibilities that go along with it. The last thing you want is to irreparably damage family relationships or long-standing friendships if a guarantor arrangement goes awry. Have an open and honest discussion before you enter into any agreement and consider what might happen in a worst-case scenario.</p>`,
    },
    {
      question: "When and how can I remove the guarantor from the loan?",
      answer: `<p>Once you’ve built up equity (i.e. a share of ownership) in your home loan over time by making your repayments on time, you can ask for the guarantor to be removed. Your equity will also be improved by:</p>
      <ul>
             <li>Your home appreciating in value (which lowers your LVR)</li>
             <li>Making extra loan repayments</li>
             </ul>
             <p>Be aware there may be fees involved in altering your loan arrangement to remove the guarantee. This ultimately depends on the lender’s policies.</p>
      `,
    },
    {
      question: "What if my parents already have a home loan?",
      answer: `<p>If you need your parents to act as a guarantor for your home loan, ideally they should own their own home outright. Many lenders won’t accept a second mortgage as a guarantee, because the first mortgage is already being used as security against the guarantor’s own home loan debt.</p>
      <p>However, if your parents have a lower LVR loan, a second mortgage / security guarantee may still be possible. Please get in touch with us for an obligation-free assessment of your situation.</p>
      `,
    },
    {
      question: "What if my parents are retired?",
      answer: `<p>Many lenders won’t consider a person as being suitable to be a guarantor if they’re over 65, but some may consider one that meets their lending criteria (e.g. a self-funded retiree with significant assets that could be used as security). We also have access to a small number lenders who will allow security guarantees from retired individuals without assessing their serviceability at all, so please get in touch if you would like assistance in this area.</p>`,
    },
    {
      question: "Is a 100% construction loan possible with a guarantor loan?",
      answer: `<p>A construction loan is used for the building of a new home as opposed to buying an established property. As such, the structure of construction loans differs from an established home loan. They allow for loan amounts to be progressively provided as construction milestones on the home are completed by the builder. Some lenders will approve up to 100% of the value of the home to be provided as a construction loan, but others may have stricter LVR’s. It depends on each lender’s respective policies.</p>`,
    },
    {
      question: "Why would I use a mortgage broker for a guarantor loan?",
      answer: `<p>As you can see, there are a lot of considerations for this type of loan for both the borrower and the guarantor. Mortgage brokers like us are fully aware of the guarantor policies of the major lenders and the implications of these types of loans. We can use that knowledge to help you to:</p>
        <ul>
             <li>Prepare a guarantor loan application so that it will have the best possible chance of approval.</li>
             <li>Find a lender that will offer both the borrower and the guarantor the best possible deal.</li>
             <li>Understand loan documentation, terms and conditions in terms of the commitments being made by both the borrower and the guarantor</li>
             <li>Provide expert advice to minimise the risk of borrower repayment default.</li>
             <li>FProvide expert advice to limit the guarantor’s risk while still meeting the lender’s criteria, including developing an exit strategy.</li>
             
             </ul>
             <p>We can do all this by taking the time to discuss and understand your situation. Remember, any home loan is a major long-term financial commitment and you need to get the best possible advice before committing as a borrower or a guarantor. We can provide that professional advice so you can benefit from our experience and expertise.</p>
        `,
    },
  ];

  return (
    <>
      <section className="service-details pt-120 pb-120">
        <div className="container">
          <div className="row row-gutter-y-30">
            <div className="col-lg-3">
              <div className="service-sidebar">
                <div className="service-sidebar__item service-sidebar__item--menu">
                  <ul className="service-sidebar__menu">
                    <li>
                      <Link to="/first-home-buyers-loan">
                        First Home Buyers Loan
                      </Link>
                    </li>
                    <li>
                      <Link to="/owner-occupied-loans">
                        Owner Occupied Loans
                      </Link>
                    </li>
                    <li>
                      <Link to="/investment-loans">Investment Loans</Link>
                    </li>
                    <li>
                      <Link to="/refinance-loans">Refinance Loans</Link>
                    </li>
                    <li>
                      <Link to="/construction-loans">Construction Loans</Link>
                    </li>
                    <li className="active">
                      <Link to="#">Guarantor Loans</Link>
                    </li>
                    <li>
                      <Link to="/loan-for-professionals">
                        Loans for Professionals
                      </Link>
                    </li>
                    <li>
                      <Link to="/smsf-loans">SMSF Loans</Link>
                    </li>
                    <li>
                      <Link to="/low-doc-home-loans">Low-Doc Loans</Link>
                    </li>
                    <li>
                      <Link to="/asset-finance">Asset Finance</Link>
                    </li>
                    <li>
                      <Link to="/commercial-property-loans">
                        Commercial Loans
                      </Link>
                    </li>
                  </ul>
                </div>
                {/* <div className="service-sidebar__item service-sidebar__item--contact">
                  <div
                    className="service-sidebar__bg"
                    style={{ backgroundImage: `url(${BGS1})` }}></div>
                  <div className="service-sidebar__contact">
                    <div className="service-sidebar__icon">
                      <i className="icon-phone-ringing"></i>
                    </div>
                    <h3 className="service-sidebar__title">
                      Quick loan proccess
                    </h3>
                    <hr className="service-sidebar__separator" />
                    <p className="service-sidebar__tagline">
                      Talk to an expert
                    </p>
                    <Link
                      to="tel:+1-(246)333-0089"
                      className="service-sidebar__phone">
                      + 1- (246) 333-0089
                    </Link>
                  </div>
                </div> */}
              </div>
            </div>
            <div className="col-lg-9">
              <h3 className="service-details__title">Guarantor Home Loans</h3>

              <div className="service-details__image">
                <img src={BGS1} alt="" />
                <div className="service-details__icon">
                  <i className="icon-payment"></i>
                </div>
              </div>
              <div className="service-details__content">
                <p>Borrow up to 105% with a guarantor or family pledge</p>
                <br />
                <h4 style={{ color: "#0983bc" }}>
                  <b>What is a guarantor home loan?</b>
                </h4>
                <p>
                  Guarantor home loans are a great option for borrowers who do
                  not have the deposit required to purchase a home, or may
                  enable you to avoid paying lenders mortgage insurance (LMI).
                  Depending on your situation, you may be able to borrow up to
                  105% of the property’s value.
                </p>
                <p>
                  Whether you need a guarantor for your orwn loan or you’ve been
                  asked to be a guarantor for someone else on their loan, you
                  need to understand what’s involved. We go through all the key
                  points below.
                </p>
                <p>
                  A guarantor provides additional security to a lender for a
                  loan. For example, a guarantor on a home loan may be asked to
                  provide the equity in their own home as additional security
                  for another borrower’s loan (usually for an immediate family
                  member).
                </p>
                <p>
                  This additional security is typically a requirement for the
                  lender to approve a loan application when a borrower has
                  either poor credit history or insufficient deposit to meet the
                  lender’s maximum loan-to-value ratio (LVR).
                </p>
                <p>
                  The guarantor is legally liable for the loan (or a specified
                  amount) if the borrower fails to make their repayments. This
                  includes any additional loan fees, charges and interest.
                  Guarantors don’t have the right to any property or other
                  assets bought with the loan funds.
                </p>
                <p>
                  It’s important to note that under the responsible lending
                  provisions of Australian credit law, the lender must assess
                  that the borrower has the capacity to make their repayments
                  before they approve a loan application. The guarantor is
                  additional security if those circumstances change in the
                  future.
                </p>
                <br />
                <h4 style={{ color: "#0983bc" }}>
                  <b>Frequently Asked Questions</b>
                </h4>
                <p>
                  We go through a number of frequently asked questions about
                  guarantor home loans below. If you have any further queries or
                  would like assistance in organising a guarantor loan, please
                  get in touch for an obligation-free conversation about your
                  needs.
                </p>

                <br />
                <div className="row">
                  <div className="col-md-12">
                    <div
                      className="accrodion-grp service-details__accrodion"
                      data-grp-name="service-details__accrodion-1">
                      {faqs.map((item, index) => (
                        <div
                          className={`accrodion  wow fadeInUp ${
                            index === clicked && "active"
                          }`}
                          key={index}
                          data-wow-delay="0ms">
                          <div
                            className="accrodion-title"
                            onClick={() =>
                              setClicked(index === clicked ? null : index)
                            }>
                            <h4>{item.question}</h4>
                            <span className="accrodion-icon"></span>
                          </div>
                          {index === clicked && (
                            <div className="accrodion-content">
                              <div className="inner">
                                <p
                                  style={{
                                    paddingLeft: "20px",
                                    textAlign: "justify",
                                  }}
                                  dangerouslySetInnerHTML={{
                                    __html: item.answer,
                                  }}
                                />
                                {/* Additional content if needed */}
                              </div>
                            </div>
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                {/* <div className="row row-gutter-y-30">
                                <div className="col-md-6">
                                    <img src="assets/images/services/service-d-1-2.png" alt="" />
                                </div>
                                <div className="col-md-6">
                                    <h3 className="service-details__subtitle">Service benefits</h3>
                                    <p className="service-details__text">Duis aute irure dolor in reprehenderit in voluptate velit
                                        esse cillum.</p>
                                    <ul className="list-unstyled ml-0 service-details__list">
                                        <li>
                                            <i className="fa fa-check-circle"></i>
                                            Refresing to get such a personal touch.
                                        </li>
                                        <li>
                                            <i className="fa fa-check-circle"></i>
                                            Duis aute irure dolor in in voluptate.
                                        </li>
                                        <li>
                                            <i className="fa fa-check-circle"></i>
                                            Velit esse cillum eu fugiat pariatur.
                                        </li>
                                        <li>
                                            <i className="fa fa-check-circle"></i>
                                            Duis aute irure dolor in in voluptate.
                                        </li>
                                    </ul>
                                </div>
                            </div> */}

                {/* <div className="row row-gutter-y-20">
                  <div className="col-md-12 text-center">
                    <Link to="#">Engage our Broker today</Link>
                  </div>
                </div> */}
                <div className="row row-gutter-y-0">
                  <div className="col-md-12 text-center">
                    <Link
                      to="/talk-to-a-broker"
                      className="thm-btn thm-btn-broker-btn rounded-pill">
                      <b>Engage our Broker today</b>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default GuarantorHomeLoansBody;
