import React from "react";
import { Link } from "react-router-dom";
import BG from "../../../assets/loan-pics/loanss.jpg";

function First() {
  return (
    <>
      <section className="blog-details pt-120 pb-120">
        <div className="container">
          <div className="row row-gutter-y-30">
            <div className="col-lg-8">
              <div className="blog-card__image">
                <div className="blog-card__date">
                  <span>05</span> Mar
                </div>
                <img src={BG} alt="" />
              </div>
              <div className="blog-card__meta">
                <Link to="/newsdetails">
                  <i className="far fa-user-circle"></i> by Admin
                </Link>
                <Link to="/newsdetails">
                  <i className="far fa-comments"></i> 2 Comments
                </Link>
              </div>
              <h3 className="blog-card__title">Guide For First Home Buyers</h3>
              <div className="blog-details__content">
                <p>
                  Applying for your first home loan can be a daunting and
                  exciting experience all at once. There are so many questions…{" "}
                </p>
                <div className="row row-gutter-y-30 mt-1">
                  <div className="col-md-12">
                    <ul className="list-unstyled ml-0 blog-details__list">
                      <li>
                        <i className="fa fa-check-circle"></i>
                        How much you can afford to borrow? At what rates and how
                        much deposit is required for your mortgage?
                      </li>
                      <li>
                        <i className="fa fa-check-circle"></i>
                        Which type of home loan is right for you? What home loan
                        features you might need and what miscellaneous costs may
                        be involved?
                      </li>
                      <li>
                        <i className="fa fa-check-circle"></i>
                        What are Governmental First Homeowner grants? What are
                        the benefits and concessions, and do you qualify?
                      </li>
                      <li>
                        <i className="fa fa-check-circle"></i>
                        What if you do not have sufficient funds for the
                        deposit? Can another family member’s home be used to
                        reduce or eliminate your home loan deposit? Guarantor
                        home loans may be an option for you.
                      </li>
                    </ul>
                  </div>
                </div>
                <p>
                  But worry not! Our dedicated brokers are here to guide you
                  through the process, answer any questions you may have (big or
                  small), and provide our expert opinion to ensure you get the
                  most out of your purchase or investment.
                </p>
              </div>
              <h3 className="blog-card__title">Guide For First Home Buyers</h3>
              <div className="blog-details__content">
                <h4 style={{ color: "#01BBBB" }}>Borrowing Capacity</h4>
                <p>
                  <p>
                    But worry not! Our dedicated brokers are here to guide you
                    through the process, answer any questions you may have (big
                    or small), and provide our expert opinion to ensure you get
                    the most out of your purchase or investment.
                  </p>
                  <p>
                    There are 3 main financial sources that lenders will be
                    establishing your creditworthiness and that would be your
                    income, expenses, and credit score.
                  </p>
                  <p>
                    If you do not have a steady form of employment, you might
                    have to showcase that you have a reliable history of saving.
                  </p>
                  <p>
                    And while a lender may be prepared to loan you up to the
                    standard 80% - 90% of the home price, you’ll also need to
                    ask yourself “How much am I willing to spend monthly on a 30
                    to 35-year mortgage?” There is much to consider –
                    fluctuation of interest rates, unforeseen circumstances,
                    changes in standard of living, and much more.
                  </p>
                </p>
              </div>
              <div className="blog-details__meta">
                <div className="blog-details__tags">
                  <span>Tags</span>
                  <Link to="#">Mortgage</Link>
                  <Link to="#">Business Loan</Link>
                </div>
                {/* <div className="blog-details__social">
                  <Link to="#">
                    <i className="fab fa-twitter"></i>
                  </Link>
                  <Link to="#">
                    <i className="fab fa-facebook"></i>
                  </Link>
                  <Link to="#">
                    <i className="fab fa-instagram"></i>
                  </Link>
                  <Link to="#">
                    <i className="fab fa-pinterest-p"></i>
                  </Link>
                </div> */}
              </div>
              {/* <div className="blog-details__author">
                <div className="blog-details__author__image">
                  <img src="assets/images/blog/blog-author-1-1.jpg" alt="" />
                </div>
                <div className="blog-details__author__content">
                  <h3 className="blog-details__author__name">Author Details</h3>
                  <p className="blog-details__author__text">
                    Lorem ipsum is simply free text by copytyping refreshing.
                    Neque porro est qui dolorem ipsum quia quaed veritatis et
                    quasi architecto.
                  </p>
                </div>
              </div> */}
              {/* <div className="blog-details__comment">
                <h3 className="blog-details__title">2 Comments</h3>
                <div className="blog-details__comment__item">
                  <div className="blog-details__comment__image">
                    <img src="assets/images/blog/blog-comment-1-1.jpg" alt="" />
                  </div>
                  <div className="blog-details__comment__content">
                    <h3 className="blog-details__comment__name">
                      Kevin Martin
                    </h3>
                    <p className="blog-details__comment__text">
                      Lorem ipsum is simply free text used by copytyping
                      refreshing. Neque porro est qui dolorem ipsum quia quaed
                      inventore veritatis et quasi architecto beatae vitae dicta
                      sunt explicabo.
                    </p>
                  </div>
                  <Link to="#" className="thm-btn">
                    Reply
                  </Link>
                </div>
                <div className="blog-details__comment__item">
                  <div className="blog-details__comment__image">
                    <img src="assets/images/blog/blog-comment-1-2.jpg" alt="" />
                  </div>
                  <div className="blog-details__comment__content">
                    <h3 className="blog-details__comment__name">
                      Kevin Martin
                    </h3>
                    <p className="blog-details__comment__text">
                      Lorem ipsum is simply free text used by copytyping
                      refreshing. Neque porro est qui dolorem ipsum quia quaed
                      inventore veritatis et quasi architecto beatae vitae dicta
                      sunt explicabo.
                    </p>
                  </div>
                  <Link to="#" className="thm-btn">
                    Reply
                  </Link>
                </div>
              </div> */}
              {/* <div className="blog-details__form">
                <h3 className="blog-details__title">Leave a comment</h3>
                <form
                  action="assets/sendemail.php"
                  className="form-one contact-form-validated">
                  <div className="row row-gutter-y-20 row-gutter-x-20">
                    <div className="col-md-6">
                      <input type="text" placeholder="Full name" name="name" />
                    </div>
                    <div className="col-md-6">
                      <input
                        type="email"
                        placeholder="Email address"
                        name="email"
                      />
                    </div>
                    <div className="col-md-6">
                      <input
                        type="text"
                        placeholder="Phone number"
                        name="phone"
                      />
                    </div>
                    <div className="col-md-6">
                      <input type="text" placeholder="Subject" name="subject" />
                    </div>
                    <div className="col-md-12">
                      <textarea placeholder="Message" name="message"></textarea>
                    </div>
                    <div className="col-md-12">
                      <button type="submit" className="thm-btn">
                        Send a Message
                      </button>
                    </div>
                  </div>
                </form>
              </div> */}
            </div>
            <div className="col-lg-4">
              <div className="blog-sidebar">
                <div className="blog-sidebar__item blog-sidebar__item--search">
                  <form action="#" className="blog-sidebar__search">
                    <input type="search" placeholder="Search" />
                    <button type="submit" className="blog-sidebar__search__btn">
                      <i className="icon-magnifying-glass"></i>
                    </button>
                  </form>
                </div>
                {/* <div className="blog-sidebar__item blog-sidebar__item--posts">
                  <h3 className="blog-sidebar__title">Recent Posts</h3>
                  <ul className="list-unstyled blog-sidebar__post">
                    <li className="blog-sidebar__post__item">
                      <div className="blog-sidebar__post__image">
                        <img src="assets/images/blog/lp-1-1.jpg" alt="" />
                      </div>
                      <div className="blog-sidebar__post__content">
                        <span className="blog-sidebar__post__author">
                          <i className="far fa-user-circle"></i>
                          by admin
                        </span>
                        <h3 className="blog-sidebar__post__title">
                          <Link to="/newsdetails">Types of Loans</Link>
                        </h3>
                      </div>
                    </li>
                    <li className="blog-sidebar__post__item">
                      <div className="blog-sidebar__post__image">
                        <img src="assets/images/blog/lp-1-2.jpg" alt="" />
                      </div>
                      <div className="blog-sidebar__post__content">
                        <span className="blog-sidebar__post__author">
                          <i className="far fa-user-circle"></i>
                          by admin
                        </span>
                        <h3 className="blog-sidebar__post__title">
                          <Link to="/newsdetails">How to Buy a House</Link>
                        </h3>
                      </div>
                    </li>
                    <li className="blog-sidebar__post__item">
                      <div className="blog-sidebar__post__image">
                        <img src="assets/images/blog/lp-1-3.jpg" alt="" />
                      </div>
                      <div className="blog-sidebar__post__content">
                        <span className="blog-sidebar__post__author">
                          <i className="far fa-user-circle"></i>
                          by admin
                        </span>
                        <h3 className="blog-sidebar__post__title">
                          <Link to="/newsdetails">
                            Lenders Mortgage Insurance (LMI)
                          </Link>
                        </h3>
                      </div>
                    </li>
                  </ul>
                </div> */}
                <div className="blog-sidebar__item blog-sidebar__item--categories">
                  <h3 className="blog-sidebar__title">Categories</h3>
                  <ul className="list-unstyled blog-sidebar__categories">
                    <li>
                      <Link to="#">Mortage</Link>
                    </li>
                    <li>
                      <Link to="#">Business Loan</Link>
                    </li>
                    <li>
                      <Link to="#">Studies</Link>
                    </li>
                    <li>
                      <Link to="#">Personal Loans</Link>
                    </li>
                    <li>
                      <Link to="#">Finance</Link>
                    </li>
                  </ul>
                </div>
                <div className="blog-sidebar__item blog-sidebar__item--tags">
                  <h3 className="blog-sidebar__title">Tags</h3>
                  <ul className="list-unstyled blog-sidebar__tags">
                    <li>
                      <Link to="#">Mortage</Link>
                    </li>
                    <li>
                      <Link to="#">Business Loan</Link>
                    </li>
                    <li>
                      <Link to="#">Personal Loan</Link>
                    </li>
                    <li>
                      <Link to="#">Banking</Link>
                    </li>
                    <li>
                      <Link to="#">Finance</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default First;
