import React from "react";
import Bredcom from "../Bredcom/Main";
import Contactfile from "../Mencontact/Contactfile";
import Map from "../Mencontact/Map";
import Maptwo from "../Menhometwo/Maptwo";
import BGImg from "../../assets/images/Banners/talk-to-broker.jpg";

const Contact = () => {
  return (
    <>
      <Bredcom title={"Home"} link={"Talk To A Broker"} img={BGImg} />
      {/* <Contactfile /> */}
      <Map />
      <Maptwo />
    </>
  );
};

export default Contact;
