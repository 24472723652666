import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyDw5lOlu1aPtqd8Rk-IhDq5WDBBFHoc1Rw",
  authDomain: "finance-496ac.firebaseapp.com",
  projectId: "finance-496ac",
  storageBucket: "finance-496ac.appspot.com",
  messagingSenderId: "46895283110",
  appId: "1:46895283110:web:3313affdb2481512f9ed67",
};

const firebaseApp = initializeApp(firebaseConfig);
const auth = getAuth(firebaseApp);

export { auth };
