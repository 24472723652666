import React from "react";
import Bredcom from "../../Bredcom/Main";
import Clint from "../Menteamdeatils/Clint";
import Ajay from "../Menteamdeatils/Ajay";

const AjayDetails = () => {
  return (
    <>
      <Bredcom title={"Home"} subtitle={"TEAM"} link={"Ajay Banda"} />
      <Ajay />
      {/* <Clint /> */}
    </>
  );
};

export default AjayDetails;
