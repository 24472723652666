import React, { useState } from "react";
import FourBoxRadio from "./FourBoxRadio";

const Forms = () => {
  const [selectedValue, setSelectedValue] = useState("");

  const handleRadioChange = (value) => {
    setSelectedValue(value);
  };

  return (
    <div>
      {/* Other components or content */}
      <FourBoxRadio
        onChange={handleRadioChange}
        selectedValue={selectedValue}
      />
      {/* Other components or content */}
    </div>
  );
};

export default Forms;
