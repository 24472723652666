import React from "react";
import Bredcom from "../Bredcom/Main";
import BG from "../../assets/loan-pics/loans.jpg";
import InterestRatesDetails from "../News/Mennewsdeatils/InterestRatesDetails";

const InterestRatesResourses = () => {
  return (
    <>
      <Bredcom title={"Home"} subtitle={"Resourses"} link={"Interest Rates"} />
      <InterestRatesDetails />
    </>
  );
};

export default InterestRatesResourses;
