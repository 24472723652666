import React from "react";
import Bredcom from "../Bredcom/Main";
import OurPartners from "../MenPartners/OurPartners";
import IMG from "../../assets/pexels-3.jpg";

const Partners = () => {
  return (
    <>
      <Bredcom
        title={"Home"}
        subtitle={"About"}
        link={"OUR PARTNERS"}
        img={IMG}
      />
      <OurPartners />
      {/* <Gettwo /> */}
      {/* <Testimonials/> */}
      {/* <Counter/> */}
      {/* <Meet/> */}
      {/* <Clint/> */}
    </>
  );
};

export default Partners;
