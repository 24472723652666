import React from "react";
import { Helmet } from "react-helmet";
import "../../../assets/css/calc.css";

function IncomeAnnualisationCalculator() {
  return (
    <>
      <Helmet>
        <title>Income Annualisation Calculator - Secure Finance</title>
        <meta
          name="description"
          content="Use our Income Annualisation Calculator to estimate your annualised income based on your year-to-date earnings. Input your financial year start date, latest pay period end date, and year-to-date income to view your results."
        />
        <link
          rel="canonical"
          href="https://securefinance.com.au/income-annualisation-calculator"
        />
      </Helmet>
      <section className="benefit-one pt-60 pb-60">
        <div className="container">
          <div className="row row-gutter-y-60">
            <iframe
              className="VisiCalcClass"
              id="Income_Annualisation_Calculator"
              src="//www.visionabacus.net/Tools/B3/SuiteA/A100/Income_Annualisation_Calculator/SecureFinance"
              frameBorder="0"
              scrolling="no"
              title="Income Annualisation Calculator "></iframe>
          </div>
        </div>
      </section>
    </>
  );
}

export default IncomeAnnualisationCalculator;
