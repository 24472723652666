import React, { useState } from "react";
import { Link } from "react-router-dom";
import BGImg from "../../assets/user.png";
import Fade from "react-reveal/Fade";

function Testimonials() {
  const [expandedTestimonials, setExpandedTestimonials] = useState({});

  const toggleReadMore = (index) => {
    setExpandedTestimonials((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  const testimonials = [
    {
      imageSrc: BGImg,
      icon: "icon-user",
      name: "Chandar Sodha",
      text: "Sakib is an absolute legend. He helped me with everything and saved me a ton of money. Would highly recommend!",
    },
    {
      imageSrc: BGImg,
      icon: "icon-user",
      name: "Russell Binny",
      text: "I was introduced to Secure Finance by my brother and was welcomed as a valued customer. Sakib was very professional and gave great advice! His fellow brokers, Ajay and Gouthami were also very thorough. They are a highly organized team and I would recommend them to anyone seeking home loans. Well done Sakib, Ajay and Team and thank you very much.",
    },
    {
      imageSrc: BGImg,
      icon: "icon-user",
      name: "Gailiene Warren",
      text: "We have been with Sakib & his team (Ajay and Gouthami) for a few years now and they are wonderful to deal with. Sakib is very approachable and goes out of his way to give us personalized service that we value highly. We recommended him to our brothers, friends, and he has now secured finances for our 4 children on their first homes. We trust that he will always have our best interests at heart and look forward to future dealings with him and his team. Thank you Sakib, Ajay, and Gouthami for helping us make our dreams come true!",
    },
  ];

  return (
    <>
      <section className="testimonials-one pt-40 pb-40">
        <div className="container">
          <div className="block-title text-center">
            <div>
              <p className="block-title__tagline">our testimonials</p>
              <h2 className="block-title__title">
                What our clients say about us
              </h2>
            </div>
          </div>
          <div className="row row-gutter-y-20">
            {testimonials.map((testimonial, index) => (
              <div
                className="col-lg-4 col-md-12 wow fadeInUp"
                data-wow-duration="1500ms"
                data-wow-delay={`${index * 100}ms`}
                key={index}
              >
                <div
                  className="testimonial-card"
                  style={{ justifyContent: "center" }}
                >
                  <div className="testimonial-card__info">
                    <div className="testimonial-card__meta text-center">
                      <h3 className="testimonial-card__name ">
                        {testimonial.name}
                      </h3>
                      <div className="testimonial-card__stars">
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                      </div>
                    </div>
                  </div>
                  <div className="testimonial-card__text">
                    <p>
                      {expandedTestimonials[index]
                        ? testimonial.text
                        : `${testimonial.text.substring(0, 100)}...`}
                      <br />
                    </p>
                  </div>
                  <p
                    className="text-center"
                    style={{ position: "absolute", left: "37%" }}
                  >
                    <Link
                      style={{
                        textDecoration: "underline",
                        cursor: "pointer",
                      }}
                      className="m-1"
                      onClick={() => toggleReadMore(index)}
                    >
                      {expandedTestimonials[index] ? "Read Less" : "Read More"}
                    </Link>
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
}

export default Testimonials;
