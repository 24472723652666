import BGS1 from "../../../assets/loan-pics/first-home-buyers-loans.jpg";

import { Link } from "react-router-dom";
import React, { useState } from "react";

function First() {
  const styles = {
    display: "inline",
    margin: 0,
    color: "#000",
    fontFamily: "Montserrat",
  };

  const [clicked, setClicked] = useState(0);
  const [clicked1, setClicked1] = useState(0);
  const [clicked2, setClicked2] = useState(0);

  const faqs = [
    {
      question: "Purchase Price",
      answer:
        "This is the price you have agreed to pay for the property of your choice. This price will be indicated in the Contract of Sale after negotiations are carried out and finalized between buyer and seller.",
    },
    {
      question: "Deposit",
      answer: `The minimum deposit required by a lender is between 10% to 20%. If you’re borrowing more than 80% of the property value, you will likely need to take out Lender's Mortgage Insurance.
      <br/>
      <br/><p>As you may already know, the larger your deposit, the smaller your loan and the less interest you will be liable to pay.</p>`,
    },
    {
      question: "Stamp Duty",
      answer: `Stamp duty is tax charged by the state and territory on certain documentation (mainly property transactions).
      <br/><br/>
        <p style="text-decoration: underline">It typically ranges between:</p>
        2-3% for properties < A$500,000
        <br/>
        3-4% for properties between $500,000 to $1 million
        <br/>
        4-7% for properties > $1 million
        <br/><br/>
        Stamp duty is calculated based on 3 core variables:
        <ul>
        <li>Property purchase price</li>
        <li>Location, such as NSW</li>
        <li>Intended purpose, e.g. Owner Occupied or Investment</li>
      </ul>
        `,
    },
    {
      question: "Government fees",
      answer: `<h4 style="color: #0983bc"><b>Registration Fee (Property)</b></h4>
        This fee will be lodged and registered by your conveyancer/solicitor to record the change of ownership. And occurs when a home is being purchased or refinanced.
        <br/>
        <br/>

        <p>The cost varies according to state and territory (typically less than $200).</p>
        <h4 style="color: #0983bc"><b>Registration Fee (Mortgage)</b></h4>
        This fee is charged when you register your mortgage with your state and territory Land Registry.
        <p>The cost varies according to state and territory (typically less than $200).</p>
        `,
    },
    {
      question: "Conveyancer/solicitor",
      answer: `This is the legal fees you pay when buying a house. It covers the cost of exchanging contracts and carrying out all the legal steps needed to complete your purchase or sale.
        <p>They are the basic fee that you pay a conveyancer or a solicitor and typically falls between $720 to $2,200.</p>
        `,
    },
    {
      question: "Building and Pest Inspections",
      answer: `Inspections are necessary to ensure that there are no roof and/or underground cavities. The report also includes if there is any structural or cosmetic damage that may cause you issues down the line and presence of pests or future risks of pests - like termites.
        <p>The Inspection Report typically ranges around $500.</p>
        `,
    },
  ];

  const faqs2 = [
    {
      question: "First Home Guarantee (aka First Home Loan Deposit Scheme)",
      answer: `An offset account can help you better manage the interest payable on your home loan.
      <br/><br/>
      <h4 style="color: #0983bc"><b>How does it work?</b></h4>
      <p>An offset account works as an everyday bank account that is linked to your home loan. so similarly, you are able to deposit your salary and savings into the account. The balance will then be offset against the outstanding amount owing to your home loan.
      </p>
      <h5 style="color: #0983bc"><b>Example</b></h5>
      <p>Current Home Loan: $250,000      <br/>
      Amount in Offset Account: $30,000 <br/>
      Interest will only be charged on loan balance: $220,000 ($250,000 - $30,000).
      </p>  
      
      <p>And like an everyday account, you would still be able to withdraw the $30,000 when you need it (even while it reduces your overall interest payments).</p>
<p>Hence, the more much money you have sitting in the account, the more interest you reduce off on your home loan.  </p>
<p>This feature is extremely beneficial to help you pay off your loan sooner.</p>


      `,
    },
    {
      question: "First Home Super Saver Scheme ",
      answer: `
      <h4 style="color: #0983bc"><b>How does it work?</b></h4>
      <p>Taking out a home loan, you are required to minimum repayment every week, fortnight or month over the duration of your loan. The Redraw facility allows to make additional payments which is known as ‘Available Redraw’. </p>
      <p>
      The funds available in your redraw reduces the balance owing on your home loan. This means you’ll be paying less interest on your home loan. However, if you later withdraw the additional money paid from your available redraw, your loan balance, repayments and payment timeframe will revert or increase accordingly.      
      </p>
      <h5 style="color: #0983bc"><b>Benefits</b></h5>
      
      <li>Less interest</li>
      <li>Flexibility in managing your available redraw</li>
      <li>Repayment holiday</li>
      <br/>
      <h5 style="color: #0983bc"><b>Difference between an Offset Account & Redraw Facility?</b></h5>
      <p>An offset account works like a regular savings account, you will be given a debit card to make payments and make fund transfers. A redraw facility isn’t an account but a feature attached to your home loan. While it allows you to withdraw the additional repayments you made on your home loan, it essentially doesn’t offer the same flexibility as an offset account.
      </p>
      `,
    },
    {
      question: "Family Home Guarantee ",
      answer: `
      <h4 style="color: #0983bc">
      <b>What is it?</b>
      </h4>
      <p>When buying or selling a property, your home loan moves with you so you won't have to refinance your home loan.</p>

      <h4 style="color: #0983bc"><b>How does it work?</b></h4>
      <p>Portability allows you to keep your home loan by changing the property securing the mortgage from your current property to the new one. It is also known as 'substitution of security'.</p>
      <p>Through this feature, you can also adjust the interest rate and increase your home loan amount.</p>
      However, this feature is not available to all loan types
      <p>Portability is not available for the following:</p>   
      <li>Using commercial security as substitute</li>
      <li>Loans under non-residents</li>
      <li>Loans offering 3rd party guarantors</li>
      <li>Loans with a delinquency history</li>
      <li>Existing bridging loans</li>
      <li>Construction loans</li>

      <br/>
      <h5 style="color: #0983bc"><b>Benefits</b></h5>
      <li>Faster turnaround</li>
      <li>No hassles & no break costs</li>
      <li>Avoid upfront costs</li>
      `,
    },
    {
      question: "Regional First Home Buyer Support Scheme ",
      answer: `
      <h4 style="color: #0983bc">
      <b>What is it?</b>
      </h4>
      
Sometimes life happens and this may require you to make adjustments to your home loan repayment arrangements. A repayment holiday works as a pause, a relief period on your home loan repayments.
      <br/><br/>
      <h4 style="color: #0983bc"><b>How does it work?</b></h4>
      <p>Repayment holidays can occur under certain circumstances:</p>
      <li>When changing jobs</li>
      <li>Hospitalization or experiencing short-term injury</li>
      <li>Maternity / Paternity leave</li>
      <li>Others (subjected to review)</li>


      <p>A repayment holiday pauses the repayments of your principal and interest for a period of time. But remember, the policy and terms are different for each lender. Also, interest will still be attracted during the repayment holiday.</p>
      <p>After the repayment holiday, your lender can choose to change your home loan repayment arrangements -- increase the amount of your repayments or extend your loan term.</p>
      <p>Hence, it would be advisable that this be amongst the last options when facing a financial change.</p>
      `,
    },
    {
      question: "Help to Buy scheme ",
      answer: `
      <h4 style="color: #0983bc">
      <b>What is it?</b>
      </h4>
      <p>Choosing a fixed or variable interest rate for your home loans are often the first loan feature to determine. Both offer unique advantages that can impact your decision, depending on your personal and financial capabilities.</p>

      <h4 style="color: #0983bc"><b>Fixed Rate Home Loan</b></h4>
      <p>The main advantage of this feature is CERTAINTY. Your interest is locked in for a certain period (often 1 - 5 years). This allows you to then plan around the periodic loan repayments you will need to fork out.</p>
      <h5 style="color: #0983bc"><b>Key points:</b></h5>
      <li>Avoid interest rate hikes</li>
      <li>You can set financial goals with confidence</li>
      <br/>
      <h4 style="color: #0983bc"><b>Variable Rate Home Loan</b></h4>
      <p>With a variable rate, the interest on your home loan will rise or fall. Your interest rate will differ based on different lenders but will mostly be influenced by the official cash rate set by the Reserve Bank of Australia (RBA).</p>
      <h5 style="color: #0983bc"><b>Key points:</b></h5>
      <li>May have additional features added on such as, unlimited repayments or unlimited redraws</li>
      <li>Save when the interest rate falls</li>
      <li>May link your Offset accounts to your home loan and save on interest</li>
      
      <h4 style="color: #0983bc"><b>Splitting & Switching your Home Loan</b></h4>
      <p>This is also a common choice of first home buyers by choosing to split their home loan into a fixed portion of the loan and the remaining on a variable rate. If everything goes as planned, this will help reduce the risk of uncertainty.</p>

      `,
    },
  ];
  const faqs3 = [
    {
      question: "New South Wales (NSW)",
      answer: `Schemes available to first-home buyers:
      <br/><br/>
      <h5 style="color: #0983bc"><b>First Home Buyers Assistance Scheme</b></h5>
      <h6><b>What does it offer?</b></h6>
      
      <p>Exemption from paying transfer duty (may be full or partial).</p>
      <h6><b>Who is eligible?</b></h6>
      <ul>
      <li>First-home buyers who are Australian citizens or permanent residents.</li>
      <li>Applicants must be over 18 years old.</li>
      <li>The property value must be less than $800,000.</li>
      </ul>
      <h5 style="color: #0983bc"><b>First Home Owner Grant (New Home)</b></h5>
      <h6><b>What does it offer?</b></h6>
      <ul>
      <li>It's your first home (buying or building).</li>
      <li>No one has lived in the home before.</li>
      <li>It can't be above $750,000 in value.</li>
      </ul>
      `,
    },
    {
      question: "Queensland (QLD)",
      answer: `Grants and schemes available for first-home buyers:
      <br/><br/>
      <ul>
      <li>You can receive $15,000 through the FHOG scheme. It applies to those buying or building a new house. But must be worth less than $750,000.</li>
      <li>If you're intending to purchase in regional Queensland, you can receive $5,000 through the Regional Home Building Boost Grant. The property value must be less than $750,000.</li>
      <li>Pay some or no stamp duty through the First Home Concession. It only applies to properties valued under $550,000.</li>
      <li>Pay some or no stamp duty through the First Home Vacant Land Concession. It only applies to vacant land valued under $400,000.</li>
      </ul>
      `,
    },
    {
      question: "Victoria",
      answer: `Grants and concessions available to first-home buyers:
      <br/><br/>
      <ul>
      <li>The $10,000 FHOG is available for first-home buyers. The value of the home must be $750,000 or less. The home must also be less than 5 years old.</li>
      <li>Stamp (transfer) duty exemption, reduction, and concessions are available. Exemption or 50% duty reduction is available for new or established properties valued at up to $600,000. The duty concession kicks in for properties valued between $600,000 and $750,000.</li>
      </ul>
      `,
    },
    {
      question: "Northern Territory (NT)",
      answer: `Schemes available to first-home buyers:
      <br/><br/>
      <ul>
      <li>$10,000 First Home Owners Grant for buying or building a home in the state.</li>
      <li>Stamp duty exemption for house and land packages under the House and Land Package Exemption.</li>
      <li>A $10,000 Home Renovation Grant for renovations.</li>
      <li>A $2,000 Household Goods Grant Scheme.</li>
      </ul>
      `,
    },
    {
      question: "South Australia (SA)",
      answer: `
      First-home buyers in South Australia access a one-off $15 000 one-off FHOG for buying or building a new home.
      <br/><br/>
      <p>Unfortunately, SA has no stamp duty exemption or concessions for first-home buyers.</p>

      `,
    },
    {
      question: "Australian Capital Territory (ACT)",
      answer: `In the ACT, the FHOG has been replaced with the Home Buyers Concession Scheme.
      <br/><br/>
      <p>Under this scheme, you won't need to pay stamp duty on your property purchase.</p>
      <h6>
      <b>Who is eligible?</b>
      </h6>
      <ul>
      <li>Those 18 and older.</li>
      <li>You must meet certain income thresholds.</li>
      <li>All buyers must not have owned any other property in the past 2 years.</li>
      <li>At least one buyer has to live in the home for a year.</li>
      </ul>
      `,
    },
    {
      question: "Tasmania (TAS)",
      answer: `Concessions available to first-home buyers:
      <br/><br/>
      <ul>
      <li>$30,000 FHOG first home buyers either building or buying a new property.</li>
      <li>50% stamp duty discount when buying an established home that's worth $ 400,000 or less.</li>
      </ul>
      `,
    },
    {
      question: "Western Australia (WA)",
      answer: `Schemes available to first-home buyers:
      <br/><br/>
      <ul>
      <li>$10,000 FHOG which can be used by first-home buyers purchasing or building their new residential property.</li>
      <li>Stamp duty waiver for first-home buyers as long as the home value is less than $430,000. If your property is between $430,001 and $530,000, you might still get discounted or concessional rates.</li>
      </ul>
      <p>For both schemes, the home must be your principal place of residence.</p>

      `,
    },
  ];
  return (
    <>
      <section className="service-details pt-120 pb-120">
        <div className="container">
          <div className="row row-gutter-y-30">
            <div className="col-lg-3">
              <div className="service-sidebar">
                <div className="service-sidebar__item service-sidebar__item--menu">
                  <ul className="service-sidebar__menu">
                    <li className="active">
                      <Link to="/first-home-buyers-loan">
                        First Home Buyers Loan
                      </Link>
                    </li>
                    <li>
                      <Link to="/owner-occupied-loans">
                        Owner Occupied Loans
                      </Link>
                    </li>
                    <li>
                      <Link to="/investment-loans">Investment Loans</Link>
                    </li>
                    <li>
                      <Link to="/refinance-loans">Refinance Loans</Link>
                    </li>
                    <li>
                      <Link to="/construction-loans">Construction Loans</Link>
                    </li>
                    <li>
                      <Link to="/guarantor-home-loans">Guarantor Loans</Link>
                    </li>
                    <li>
                      <Link to="/loan-for-professionals">
                        Loans for Professionals
                      </Link>
                    </li>
                    <li>
                      <Link to="/smsf-loans">SMSF Loans</Link>
                    </li>
                    <li>
                      <Link to="/low-doc-home-loans">Low-Doc Loans</Link>
                    </li>
                    <li>
                      <Link to="/asset-finance">Asset Finance</Link>
                    </li>
                    <li>
                      <Link to="/commercial-property-loans">
                        Commercial Loans
                      </Link>
                    </li>
                  </ul>
                </div>
                {/* <div className="service-sidebar__item service-sidebar__item--contact">
                  <div
                    className="service-sidebar__bg"
                    style={{ backgroundImage: `url(${BGS1})` }}></div>
                  <div className="service-sidebar__contact">
                    <div className="service-sidebar__icon">
                      <i className="icon-phone-ringing"></i>
                    </div>
                    <h3 className="service-sidebar__title">
                      Quick loan proccess
                    </h3>
                    <hr className="service-sidebar__separator" />
                    <p className="service-sidebar__tagline">
                      Talk to an expert
                    </p>
                    <Link
                      to="tel:+1-(246)333-0089"
                      className="service-sidebar__phone">
                      + 1- (246) 333-0089
                    </Link>
                  </div>
                </div> */}
              </div>
            </div>
            <div className="col-lg-9">
              <h3 className="service-details__title">First Home Buyers Loan</h3>

              <div className="service-details__image">
                <img src={BGS1} alt="" />
                <div className="service-details__icon">
                  <i className="icon-payment"></i>
                </div>
              </div>
              <div className="service-details__content mt-5">
                <p>
                  You’ll never forget the first time you applied for a home
                  loan. Just like you’ll never forget the time you were handed
                  your first set of keys.
                </p>
                <p>
                  There is much to consider before taking any tangible steps,
                  and being on this page is already your first win – you have
                  started doing your research!
                </p>
                {/* <p>
                  If you are new to the process, we have created an end-to-end
                  manual to see you through your journey to homeownership:{" "}
                </p> */}
                {/* <div className="row row-gutter-y-30">
                                <div className="col-md-6">
                                    <img src="assets/images/services/service-d-1-2.png" alt="" />
                                </div>
                                <div className="col-md-6">
                                    <h3 className="service-details__subtitle">Service benefits</h3>
                                    <p className="service-details__text">Duis aute irure dolor in reprehenderit in voluptate velit
                                        esse cillum.</p>
                                    <ul className="list-unstyled ml-0 service-details__list">
                                        <li>
                                            <i className="fa fa-check-circle"></i>
                                            Refresing to get such a personal touch.
                                        </li>
                                        <li>
                                            <i className="fa fa-check-circle"></i>
                                            Duis aute irure dolor in in voluptate.
                                        </li>
                                        <li>
                                            <i className="fa fa-check-circle"></i>
                                            Velit esse cillum eu fugiat pariatur.
                                        </li>
                                        <li>
                                            <i className="fa fa-check-circle"></i>
                                            Duis aute irure dolor in in voluptate.
                                        </li>
                                    </ul>
                                </div>
                            </div> */}
                <div className="row">
                  <div className="col-md-12">
                    <h2 style={{ color: "#013148" }}>
                      <b>Where To Start?</b>
                    </h2>
                    <p>Here's a general guide to help you get started:</p>
                  </div>
                  <div className="col-md-12">
                    <br />
                    <ol style={{ color: "#0983bc" }}>
                      <li>
                        <h6 style={styles}>
                          <b style={{ color: "#0983bc" }}>
                            Assess Your Financial Situation:
                          </b>
                        </h6>
                        <p style={styles}>
                          &nbsp;&nbsp;Determine your budget and how much you can
                          afford to spend on a house. Consider factors like your
                          income, savings, existing debts, and expenses.
                        </p>
                      </li>
                      <br />
                      <li>
                        <h6 style={styles}>
                          <b style={{ color: "#0983bc" }}>
                            Save for a Deposit:
                          </b>
                        </h6>
                        <p style={styles}>
                          &nbsp;&nbsp;In Australia, you typically need to
                          provide a deposit of at least 5% to 20% of the
                          property's purchase price. The larger your deposit,
                          the better your chances of securing a favourable
                          mortgage deal.
                        </p>
                      </li>
                      <br />
                      <li>
                        <h6 style={styles}>
                          <b style={{ color: "#0983bc" }}>
                            Get Pre-Approval for a Home Loan:
                          </b>
                        </h6>
                        <p style={styles}>
                          &nbsp;&nbsp;Before you start house hunting, it's wise
                          to get pre-approval for a home loan from a bank or
                          lender. This will give you a clear idea of how much
                          you can borrow and help you narrow down your search to
                          properties within your budget.
                        </p>
                      </li>
                      <br />
                      <li>
                        <h6 style={styles}>
                          <b style={{ color: "#0983bc" }}>
                            Research the Property Market:
                          </b>
                        </h6>
                        <p style={styles}>
                          &nbsp;&nbsp;Familiarize yourself with the housing
                          market in the area where you want to buy. Look at
                          property prices, trends, and amenities to find a
                          location that suits your needs and budget.
                        </p>
                      </li>
                      <br />
                      <li>
                        <h6 style={styles}>
                          <b style={{ color: "#0983bc" }}>
                            Start House Hunting:
                          </b>
                        </h6>
                        <p style={styles}>
                          &nbsp;&nbsp;Once you have a clear idea of your budget
                          and preferences, start looking for properties that
                          meet your criteria. You can search online listings,
                          attend open houses, and work with real estate agents
                          to find potential homes.
                        </p>
                      </li>
                      <br />
                      <li>
                        <h6 style={styles}>
                          <b style={{ color: "#0983bc" }}>
                            Conduct Property Inspections:
                          </b>
                        </h6>
                        <p style={styles}>
                          &nbsp;&nbsp;Before making an offer on a property, it's
                          important to inspect it thoroughly. Look for any
                          structural issues, defects, or problems that may
                          affect its value or your future living experience.
                        </p>
                      </li>
                      <br />
                      <li>
                        <h6 style={styles}>
                          <b style={{ color: "#0983bc" }}>
                            Make an Offer and Negotiate:
                          </b>
                        </h6>
                        <p style={styles}>
                          &nbsp;&nbsp;If you find a property you like, you can
                          make an offer to the seller either directly or through
                          your real estate agent. Negotiate the price and terms
                          of the sale until you reach an agreement that works
                          for both parties.
                        </p>
                      </li>
                      <br />
                      <li>
                        <h6 style={styles}>
                          <b style={{ color: "#0983bc" }}>
                            Arrange Legal and Financial Assistance:
                          </b>
                        </h6>
                        <p style={styles}>
                          &nbsp;&nbsp;Once your offer is accepted, you'll need
                          to arrange legal representation (a conveyancer or
                          solicitor) to handle the legal aspects of the
                          purchase. You'll also finalize your mortgage and other
                          financial arrangements.
                        </p>
                      </li>
                      <br />
                      <li>
                        <h6 style={styles}>
                          <b style={{ color: "#0983bc" }}>
                            Exchange Contracts:
                          </b>
                        </h6>
                        <p style={styles}>
                          &nbsp;&nbsp;After all the legal and financial details
                          are sorted out, you and the seller will exchange
                          contracts. This legally binds both parties to the
                          sale, and you'll usually need to pay a deposit at this
                          stage.
                        </p>
                      </li>
                      <br />
                      <li>
                        <h6 style={styles}>
                          <b style={{ color: "#0983bc" }}>Settlement:</b>
                        </h6>
                        <p style={styles}>
                          &nbsp;&nbsp;On the settlement day, you'll pay the
                          remaining balance of the purchase price, and ownership
                          of the property will be transferred to you. You'll
                          receive the keys to your new home, and the process
                          will be complete.
                        </p>
                      </li>
                    </ol>
                  </div>
                  <div className="col-md-12">
                    <p>
                      Remember that buying a house is a significant financial
                      commitment, so it's essential to do thorough research,
                      seek professional advice, and carefully consider your
                      options before making any decisions.
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <h2 style={{ color: "#013148" }}>
                      <b>Things You Need To Know</b>
                    </h2>
                    <h6 className="mt-3" style={{ color: "#00A8A8" }}>
                      <b>Borrowing Capacity</b>
                    </h6>
                  </div>
                  <div className="col-md-12">
                    <p>
                      The more financially credible you come across, the larger
                      the loan you’ll be offered. This is a rule of thumb for
                      all lenders.
                    </p>
                    <p>
                      There are 3 main financial sources that lenders will be
                      establishing your creditworthiness and that would be your
                      income, expenses, and credit score.{" "}
                    </p>
                    <p>
                      If you do not have a steady form of employment, you might
                      have to showcase that you have a reliable history of
                      saving.
                    </p>
                    <p>
                      And while a lender may be prepared to loan you up to the
                      standard 80% - 90% of the home price, you’ll also need to
                      ask yourself “How much am I willing to spend monthly on a
                      30 to 35-year mortgage?” There is much to consider –
                      fluctuation of interest rates, unforeseen circumstances,
                      changes in standard of living, and much more.
                    </p>
                    <p>
                      Use our{" "}
                      <Link to="/borrowing-capacity-calculator">
                        Borrowing Calculator
                      </Link>{" "}
                      to get an estimate of your borrowing power.
                    </p>
                  </div>
                </div>

                <div className="row row-gutter-y-20">
                  <h6 style={{ color: "#00A8A8" }}>
                    <b>Fees & Cost Involved (Legal & non-Legal)</b>
                  </h6>
                  <div className="row">
                    <div className="col-md-12">
                      <div
                        className="accrodion-grp service-details__accrodion"
                        data-grp-name="service-details__accrodion-1">
                        {faqs.map((item, index) => (
                          <div
                            className={`accrodion  wow fadeInUp ${
                              index === clicked && "active"
                            }`}
                            key={index}
                            data-wow-delay="0ms">
                            <div
                              className="accrodion-title"
                              onClick={() =>
                                setClicked(index === clicked ? null : index)
                              }>
                              <h4>{item.question}</h4>
                              <span className="accrodion-icon"></span>
                            </div>
                            {index === clicked && (
                              <div className="accrodion-content">
                                <div className="inner">
                                  <p
                                    style={{
                                      paddingLeft: "20px",
                                      textAlign: "justify",
                                    }}
                                    dangerouslySetInnerHTML={{
                                      __html: item.answer,
                                    }}
                                  />
                                  {/* Additional content if needed */}
                                </div>
                              </div>
                            )}
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-12">
                    <h6 style={{ color: "#00A8A8" }}>
                      <b>Government First Home Buyers Schemes and Grants</b>
                    </h6>
                    <p>
                      As it’s your first time buying a home, there are tons of
                      available tools & resources available to help you cross
                      this new milestone. Amongst them would be the Government
                      First Home Buyers Schemes and Grants, and we have
                      consolidated them for your ease of understanding.
                    </p>
                    <p>
                      There are 2 main categories for first-home buyer schemes:
                    </p>
                    <h6>
                      <br />
                      <b>National Initiatives</b>
                    </h6>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div
                        className="accrodion-grp service-details__accrodion"
                        data-grp-name="service-details__accrodion-1">
                        {faqs2.map((item, index) => (
                          <div
                            className={`accrodion  wow fadeInUp ${
                              index === clicked1 && "active"
                            }`}
                            key={index}
                            data-wow-delay="0ms">
                            <div
                              className="accrodion-title"
                              onClick={() =>
                                setClicked1(index === clicked1 ? null : index)
                              }>
                              <h4>{item.question}</h4>
                              <span className="accrodion-icon"></span>
                            </div>
                            {index === clicked1 && (
                              <div className="accrodion-content">
                                <div className="inner">
                                  <p
                                    style={{
                                      paddingLeft: "20px",
                                      textAlign: "justify",
                                    }}
                                    dangerouslySetInnerHTML={{
                                      __html: item.answer,
                                    }}
                                  />
                                  {/* Additional content if needed */}
                                </div>
                              </div>
                            )}
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>

                  <h6>
                    <br />
                    <br />
                    <b>State-Specific Initiatives</b>
                  </h6>
                  <div className="row">
                    <div className="col-md-12">
                      <div
                        className="accrodion-grp service-details__accrodion"
                        data-grp-name="service-details__accrodion-1">
                        {faqs3.map((item, index) => (
                          <div
                            className={`accrodion  wow fadeInUp ${
                              index === clicked2 && "active"
                            }`}
                            key={index}
                            data-wow-delay="0ms">
                            <div
                              className="accrodion-title"
                              onClick={() =>
                                setClicked2(index === clicked2 ? null : index)
                              }>
                              <h4>{item.question}</h4>
                              <span className="accrodion-icon"></span>
                            </div>
                            {index === clicked2 && (
                              <div className="accrodion-content">
                                <div className="inner">
                                  <p
                                    style={{
                                      paddingLeft: "20px",
                                      textAlign: "justify",
                                    }}
                                    dangerouslySetInnerHTML={{
                                      __html: item.answer,
                                    }}
                                  />
                                  {/* Additional content if needed */}
                                </div>
                              </div>
                            )}
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default First;
