import React, { useState } from "react";
import { Link } from "react-router-dom";
import BG from "../../../assets/images/team/Ajay_Picture 2.jpg";

function Ajay() {
  return (
    <>
      <section className="team-details pt-80">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div
                className="team-details__image wow fadeInUp"
                data-wow-duration="1500ms">
                <img src={BG} alt="" />
              </div>
            </div>
            <div className="col-lg-6">
              <div
                className="team-details__content"
                style={{ textAlign: "justify", paddingRight: "60px" }}>
                <div className="team-details__floated">Ajay</div>
                <div className="block-title text-left">
                  <p className="block-title__tagline">Senior Finance Broker</p>
                  <h2 className="block-title__title">Ajay Banda</h2>
                </div>
                {/* <div className="team-details__social">
                  <Link to="#">
                    <i className="fab fa-twitter"></i>
                  </Link>
                  <Link to="#">
                    <i className="fab fa-facebook"></i>
                  </Link>
                  <Link to="#">
                    <i className="fab fa-pinterest"></i>
                  </Link>
                  <Link to="#">
                    <i className="fab fa-instagram"></i>
                  </Link>
                </div> */}
                <p className="team-details__highlight">MASTER ADVISOR</p>
                <p className="team-details__text">
                  Ajay is a notable broker and brings over 10 years of
                  invaluable experience to the finance industry. He possesses a
                  profound understanding and knowledge of all matters of
                  finance. Coupled with his strong analytical skills and
                  experience in solving complex problems, he joins Sakib at the
                  helm of leading the team.
                </p>
              </div>
            </div>
            <div className="container">
              <hr className="block-separator" />
            </div>
            <div
              className="col-lg-12"
              style={{ textAlign: "justify", padding: "40px" }}>
              <p className="team-details__text">
                Despite operating via a logic-first approach, Ajay takes on a
                positive approach to all matters of business and tasks. Clients
                feel naturally at ease while working with Ajay as he cultivates
                an environment where no problem is too big or impossible.
              </p>
              <p className="team-details__text">
                Ajay believes that the process doesn&#39;t need to be stressful,
                &quot;I enjoy the challenge of problem-solving and managing the
                process to ensure the client&#39;s needs are met with the
                optimal solution.&quot;
              </p>
              <p className="team-details__text">
                Ajay is certified with a Certificate IV and a Diploma in Finance
                &amp; Mortgage Broking. Specializing in residential lending, he
                works closely with many first-time home buyers, guiding them
                through what can often be a foreign and intimidating process.
              </p>
              <p className="team-details__text">
                Understanding his importance in making the dream of ownership
                come true, Ajay ensures that the solutions provided to the
                clients are of the very best.
              </p>
              <p className="team-details__text" style={{ fontStyle: "italic" }}>
                <b>
                  &quot;It&#39;s gratifying to witness individuals enter the
                  property market. Once they&#39;ve taken that initial step,
                  they can progressively build on it over the years, ultimately
                  achieving financial security.&quot;
                </b>
              </p>
            </div>
          </div>
        </div>
      </section>
      <div className="container">
        <hr className="block-separator" />
      </div>
      <section className="contact-info-one">
        <div className="container">
          <div className="row">
            <div className="col-md-4 col-sm-12">
              <div className="contact-info-one__item">
                <div className="contact-info-one__icon">
                  <i className="icon-telephone"></i>
                </div>
                <div className="contact-info-one__content">
                  <p className="contact-info-one__text">Have any question?</p>
                  <a
                    className="contact-info-one__link"
                    href="tel:+61 (430) 457 880">
                    0430 457 880
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-sm-12">
              <div className="contact-info-one__item">
                <div className="contact-info-one__icon">
                  <i className="icon-email"></i>
                </div>
                <div className="contact-info-one__content">
                  <p className="contact-info-one__text">Write us email</p>
                  <a
                    className="contact-info-one__link"
                    href="mailto:needhelp@company.com">
                    info@securefinance.com.au
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-sm-12">
              <div className="contact-info-one__item">
                <div className="contact-info-one__icon">
                  <i className="icon-pin"></i>
                </div>
                <div className="contact-info-one__content">
                  <p className="contact-info-one__text">Visit anytime</p>
                  <a className="contact-info-one__link" href="#">
                    204/11-13 Solent Circuit <br /> Norwest NSW 2153
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Ajay;
