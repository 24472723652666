import React from "react";
import { Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import BGImg from "../../assets/anz.png";
// import BGImg1 from "../../assets/wes.png";
import BGImg2 from "../../assets/ing.png";
import BGImg3 from "../../assets/com.png";
import BGImg4 from "../../assets/nab.png";
import BGImg5 from "../../assets/st.png";

function Clint() {
  const imageSources = [BGImg, BGImg2, BGImg3, BGImg4, BGImg5];

  return (
    <>
      <div className="client-carousel @@extraclassNameName">
        <div className="container">
          <Swiper
            className="thm-swiper__slider swiper-container"
            modules={[Autoplay]}
            loop={true}
            spaceBetween={30}
            slidesPerView={4}
            autoplay={{ delay: 3000 }}
            breakpoints={{
              0: { spaceBetween: 15, slidesPerView: 2 },
              375: { spaceBetween: 15, slidesPerView: 2 },
              575: { spaceBetween: 15, slidesPerView: 3 },
              767: { spaceBetween: 15, slidesPerView: 4 },
              991: { spaceBetween: 15, slidesPerView: 5 },
              1199: { spaceBetween: 15, slidesPerView: 5 },
            }}>
            <div className="swiper-wrapper">
              {imageSources.map((imageSrc, index) => (
                <SwiperSlide key={index}>
                  <div className="swiper-slide">
                    <img
                      src={imageSrc}
                      style={{
                        minWidth: 80,
                        maxHeight: 80,
                        whiteSpace: "nowrap",
                        display: "inline",
                      }}
                      alt=""
                    />
                  </div>
                </SwiperSlide>
              ))}
            </div>
          </Swiper>
        </div>
      </div>
    </>
  );
}

export default Clint;
