// Sidebar.js
import React from "react";

const Sidebar = ({ user }) => {
  return (
    <div className="sidebar">
      <div className="user-profile">
        <img src={user.profilePic} alt="Profile" className="profile-pic" />
        <span className="user-name">{user.name}</span>
      </div>
      <ul className="menu">
        <li className="menu-item">Profile</li>
        <li className="menu-item">Applications</li>
        <li className="menu-item dropdown">
          Referrals
          <ul className="dropdown-menu">
            <li>Leads</li>
            <li>Events</li>
          </ul>
        </li>
        <li className="menu-item">Settings</li>
        <li className="menu-item">FAQs</li>
        <li className="menu-item">Talk To A Broker</li>
        <li className="menu-item">Log Out</li>
      </ul>
    </div>
  );
};

export default Sidebar;
