import React from "react";
import Bredcom from "../Bredcom/Main";
import RefinanceLoansBody from "../Services/Menservisedeatils/RefinanceLoansBody";
import BG from "../../assets/images/Banners/refinance-loans.jpg";

const RefinanceLoans = () => {
  return (
    <>
      <Bredcom
        title={"Home"}
        subtitle={"Home Loans"}
        link={"Refinance Loans"}
        img={BG}
      />
      <RefinanceLoansBody />
    </>
  );
};

export default RefinanceLoans;
