import React from "react";
import Bredcom from "../Bredcom/Main";
import BG from "../../assets/loan-pics/loans.jpg";
import TreeOrSeaChangeDetails from "../News/Mennewsdeatils/TreeOrSeaChange";

const TreeOrSeaChange = () => {
  return (
    <>
      <Bredcom
        title={"Home"}
        subtitle={"Resourses"}
        link={"Tree Or Sea"}
      />
      <TreeOrSeaChangeDetails />
    </>
  );
};

export default TreeOrSeaChange;
