import React from "react";
import { Helmet } from "react-helmet";
import "../../../assets/css/calc.css";

function RentvsBuyCalculator() {
  return (
    <>
      <Helmet>
        <title>Rent VS Buy Calculator - Secure Finance</title>
        <meta
          name="description"
          content="Use our Rent vs Buy Calculator to compare the financial outcomes of renting versus buying a home. Input your savings, rent details, and home purchase information to view long-term financial projections and make an informed decision."
        />
        <link
          rel="canonical"
          href="https://securefinance.com.au/rent-vs-buy-calculator"
        />
      </Helmet>
      <section className="benefit-one pt-60 pb-60">
        <div className="container">
          <div className="row row-gutter-y-60">
            <iframe
              className="VisiCalcClass"
              id="Rent_vs_Buy_Calculator"
              src="//www.visionabacus.net/Tools/B3/SuiteA/G300/Rent_vs_Buy_Calculator/SecureFinance"
              frameBorder="0"
              scrolling="no"
              title="Rent VS Buy Calculator"></iframe>
          </div>
        </div>
      </section>
    </>
  );
}

export default RentvsBuyCalculator;
