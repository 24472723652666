import React from "react";
import { Helmet } from "react-helmet";
import "../../../assets/css/calc.css";

function ExtraRepaymentCalculator() {
  return (
    <>
      <Helmet>
        <title>Extra Repayment Calculator - Secure Finance</title>
        <meta
          name="description"
          content="Use our Extra Repayment Calculator to see how making additional 
          contributions to your loan can save you time and interest. Input your 
          loan amount, interest rate, term, repayment frequency, and extra 
          repayment details to view your potential savings."
        />
        <link
          rel="canonical"
          href="https://securefinance.com.au/extra-repayment-calculator"
        />
      </Helmet>
      <section className="benefit-one pt-60 pb-60">
        <div className="container">
          <div className="row row-gutter-y-60">
            <iframe
              className="VisiCalcClass"
              id="Extra_Repayment_Calculator"
              src="//www.visionabacus.net/Tools/B3/SuiteA/A300/Extra_Repayment_Calculator/SecureFinance"
              frameBorder="0"
              scrolling="no"
              title="Extra Repayment Calculator"></iframe>
          </div>
        </div>
      </section>
    </>
  );
}

export default ExtraRepaymentCalculator;
