import React from "react";
import { Link } from "react-router-dom";
import TurnToBroker from "../../../assets/resourses/turnToBroker.jpg";

export default function TurnToBrokerDetails() {
  return (
    <>
      <section className="blog-details pt-100 pb-120">
        <div className="container">
          <div className="row row-gutter-y-30">
            <div className="col-lg-8">
              <div className="blog-card__image">
                <div className="blog-card__date">
                  <span>05</span> Mar
                </div>
                <img src={TurnToBroker} alt="" />
              </div>
              <div className="blog-card__meta">
                <Link to="/newsdetails">
                  <i className="far fa-user-circle"></i> by Admin
                </Link>
                <Link to="/newsdetails">
                  <i className="far fa-comments"></i>
                </Link>
              </div>
              <h3 className="blog-card__title">
                Why three-in-four Aussies turn to a broker for home loan help
              </h3>

              <div className="blog-details__content">
                <br />
                <b>
                  You might have seen a headline or two about a particular big
                  bank being at war with brokers. Nothing could be further from
                  the truth. Our mission is – and always will be – putting you
                  first. That’s why three in every four borrowers now come to us
                  for help.
                </b>
                <br />
                <br />
                <p>
                  Borrowers are more spoilt for choice than ever before when it
                  comes to home loans.
                </p>
                <p>
                  But who has time to sort through{" "}
                  <a
                    href="https://www.mfaa.com.au/news/competition-not-war-when-it-comes-to-mortgage-brokers"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    over 100 lenders in the market
                  </a>{" "}
                  to pick out a loan that’s suited to your needs?
                </p>
                <p>Your mortgage broker does.</p>
                <p>
                  But for the big end of town, increased competition can mean
                  lower profit margins (and unhappy shareholders!).
                </p>
                <p>
                  That doesn’t mean brokers are at war with any particular bank
                  though, as a few articles stated in the Australian Financial
                  Review over recent weeks{" "}
                  <a
                    href="https://www.mfaa.com.au/news/competition-not-war-when-it-comes-to-mortgage-brokers"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    (here’s a great non-paywalled response)
                  </a>{" "}
                  .
                </p>
                <p>
                  As Mortgage and Finance Association of Australia (MFAA) CEO{" "}
                  <a
                    href="https://www.mfaa.com.au/news/competition-not-war-when-it-comes-to-mortgage-brokers"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Anja Pannek succinctly
                  </a>{" "}
                  put it: “Positioning banks as competing with brokers is like
                  saying Hilton hotels is competing with travel agents, instead
                  of Hyatt and Sofitel. It completely misrepresents how the
                  mortgage broking industry works”.
                </p>
                <p>
                  What brokers do is streamline the home loan process. It’s just
                  one of the reasons why{" "}
                  <a
                    href="https://www.mfaa.com.au/news/mortgage-brokers-remain-the-first-choice-for-homebuyers"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    mortgage brokers are the go-to choice for 74.1% of home
                    buyers
                  </a>{" "}
                  (and that figure has been steadily increasing!).
                </p>
                <p>
                  But our role isn’t just about helping you find a
                  competitively-priced home loan with the features you may need.
                </p>

                <p>We go much further.</p>
                <p>
                  Here are three other ways you can benefit from the support of
                  a mortgage broker.
                </p>
              </div>
              <h4 style={{ color: "#01BBBB" }}>
                We work in your best interest
              </h4>
              <div className="blog-details__content">
                <p>
                  Behind the friendly face of your mortgage broker is a serious
                  legal obligation.
                </p>
                <p>
                  We are bound by a{" "}
                  <a
                    href="https://asic.gov.au/regulatory-resources/find-a-document/regulatory-guides/rg-273-mortgage-brokers-best-interests-duty/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Best Interests Duty.
                  </a>{" "}
                </p>
                <p>
                  It means we are required by law to always put your best
                  interests first, providing home loan options that are based on
                  your unique needs.
                </p>
                <p>
                  That matters because if a loan isn’t the right choice for you,
                  it may not save you money in the long run, no matter how low
                  the rate is.
                </p>
                <p>Banks are not bound by the best interests duty.</p>
                <br />
                <h4 style={{ color: "#01BBBB" }}>
                  Brokers can help guide the way
                </h4>

                <p>
                  Buying a home is possibly the biggest purchase you’ll ever
                  make.
                </p>
                <p>
                  It’s also something you’ll probably only do a handful of times
                  over your life. But this is something we help people through
                  every day.
                </p>
                <p>
                  We can act as a trusted guide to help you navigate the complex
                  process of buying a home with confidence.
                </p>
                <p>
                  We can also help you assess your borrowing capacity, so you
                  can buy with confidence, and we can explain where you can
                  consider making shifts in your budget to become home
                  loan-ready sooner.
                </p>
                <p>
                  And because we’re focused on making things more
                  straightforward for you, we take the jargon out of home buying
                  – we can help you get your head around complex issues like
                  lenders’ mortgage insurance, or how to prepare if you’re
                  buying at auction.
                </p>
                <p>
                  It’s all about mentoring our customers at every stage of their
                  property journey.
                </p>

                <br />
                <h4 style={{ color: "#01BBBB" }}>
                  We’re here for the long term
                </h4>

                <p>
                  You and your home loan are likely to be together for a while.
                  And we’ll be right there with you.
                </p>
                <p>
                  Our regular home loan reviews provide reassurance that your
                  loan continues to be the right option for you, even as your
                  life changes and evolves.
                </p>
                <p>
                  And when you’re ready to kick new goals – from renovating, to
                  buying your next home, investing in a rental property, or
                  simply refinancing – we’ll be ready to help guide you through
                  the process.
                </p>
                <p>
                  Like to know more about how we can help? Call us today and
                  discover why three out of every four Australian families come
                  to a broker first.
                </p>
                <p>
                  Disclaimer: The content of this article is general in nature
                  and is presented for informative purposes. It is not intended
                  to constitute tax or financial advice, whether general or
                  personal nor is it intended to imply any recommendation or
                  opinion about a financial product. It does not take into
                  consideration your personal situation and may not be relevant
                  to circumstances. Before taking any action, consider your own
                  particular circumstances and seek professional advice. This
                  content is protected by copyright laws and various other
                  intellectual property laws. It is not to be modified,
                  reproduced or republished without prior written consent.
                </p>
                <p>
                  {" "}
                  <a
                    href="https://www.dropbox.com/scl/fo/83pse778zzo796hf6tnff/AErXTnpCpqqKVQon6dQqblo?rlkey=shnj0ee75diwon96km2vo0mdr&e=1&st=wbiigr5f&dl=0"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Pictures of varying shapes located here
                  </a>{" "}
                </p>
                <br />
                <p>
                  {" "}
                  <a
                    href="https://unsplash.com/photos/women-forming-heart-gestures-during-daytime-tSlvoSZK77c"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    High-resolution image download here
                  </a>{" "}
                </p>
                <p>
                  Social media teaser. You might have seen a headline or two
                  about a particular big bank being at war with brokers. Nothing
                  could be further from the truth. Our mission is – and always
                  will be – putting you first. That’s why three in every four
                  borrowers now come to us for help. 🤜 🤛
                </p>
                <p>Subheader. We’ve got your back</p>
                <p>
                  Suggested MailChimp subject line: Why three-in-four Aussies
                  turn to a broker for home loan help
                </p>
                <p>
                  Suggested MailChimp preview text: You might have seen a
                  headline or two about a particular big bank being at war with
                  brokers. Nothing could be further from the truth.
                </p>
                {/* <div className="blog-details__social">
              <Link to="#">
                <i className="fab fa-twitter"></i>
              </Link>
              <Link to="#">
                <i className="fab fa-facebook"></i>
              </Link>
              <Link to="#">
                <i className="fab fa-instagram"></i>
              </Link>
              <Link to="#">
                <i className="fab fa-pinterest-p"></i>
              </Link>
            </div> */}
              </div>
              {/* <div className="blog-details__author">
            <div className="blog-details__author__image">
              <img src="assets/images/blog/blog-author-1-1.jpg" alt="" />
            </div>
            <div className="blog-details__author__content">
              <h3 className="blog-details__author__name">Author Details</h3>
              <p className="blog-details__author__text">
                Lorem ipsum is simply free text by copytyping refreshing.
                Neque porro est qui dolorem ipsum quia quaed veritatis et
                quasi architecto.
              </p>
            </div>
          </div> */}
              {/* <div className="blog-details__comment">
            <h3 className="blog-details__title">2 Comments</h3>
            <div className="blog-details__comment__item">
              <div className="blog-details__comment__image">
                <img src="assets/images/blog/blog-comment-1-1.jpg" alt="" />
              </div>
              <div className="blog-details__comment__content">
                <h3 className="blog-details__comment__name">
                  Kevin Martin
                </h3>
                <p className="blog-details__comment__text">
                  Lorem ipsum is simply free text used by copytyping
                  refreshing. Neque porro est qui dolorem ipsum quia quaed
                  inventore veritatis et quasi architecto beatae vitae dicta
                  sunt explicabo.
                </p>
              </div>
              <Link to="#" className="thm-btn">
                Reply
              </Link>
            </div>
            <div className="blog-details__comment__item">
              <div className="blog-details__comment__image">
                <img src="assets/images/blog/blog-comment-1-2.jpg" alt="" />
              </div>
              <div className="blog-details__comment__content">
                <h3 className="blog-details__comment__name">
                  Kevin Martin
                </h3>
                <p className="blog-details__comment__text">
                  Lorem ipsum is simply free text used by copytyping
                  refreshing. Neque porro est qui dolorem ipsum quia quaed
                  inventore veritatis et quasi architecto beatae vitae dicta
                  sunt explicabo.
                </p>
              </div>
              <Link to="#" className="thm-btn">
                Reply
              </Link>
            </div>
          </div> */}
              {/* <div className="blog-details__form">
            <h3 className="blog-details__title">Leave a comment</h3>
            <form
              action="assets/sendemail.php"
              className="form-one contact-form-validated">
              <div className="row row-gutter-y-20 row-gutter-x-20">
                <div className="col-md-6">
                  <input type="text" placeholder="Full name" name="name" />
                </div>
                <div className="col-md-6">
                  <input
                    type="email"
                    placeholder="Email address"
                    name="email"
                  />
                </div>
                <div className="col-md-6">
                  <input
                    type="text"
                    placeholder="Phone number"
                    name="phone"
                  />
                </div>
                <div className="col-md-6">
                  <input type="text" placeholder="Subject" name="subject" />
                </div>
                <div className="col-md-12">
                  <textarea placeholder="Message" name="message"></textarea>
                </div>
                <div className="col-md-12">
                  <button type="submit" className="thm-btn">
                    Send a Message
                  </button>
                </div>
              </div>
            </form>
          </div> */}
            </div>
            <div className="col-lg-4">
              <div className="blog-sidebar">
                <div className="blog-sidebar__item blog-sidebar__item--search">
                  <form action="#" className="blog-sidebar__search">
                    <input type="search" placeholder="Search" />
                    <button type="submit" className="blog-sidebar__search__btn">
                      <i className="icon-magnifying-glass"></i>
                    </button>
                  </form>
                </div>
                {/* <div className="blog-sidebar__item blog-sidebar__item--posts">
              <h3 className="blog-sidebar__title">Recent Posts</h3>
              <ul className="list-unstyled blog-sidebar__post">
                <li className="blog-sidebar__post__item">
                  <div className="blog-sidebar__post__image">
                    <img src="assets/images/blog/lp-1-1.jpg" alt="" />
                  </div>
                  <div className="blog-sidebar__post__content">
                    <span className="blog-sidebar__post__author">
                      <i className="far fa-user-circle"></i>
                      by admin
                    </span>
                    <h3 className="blog-sidebar__post__title">
                      <Link to="/newsdetails">Types of Loans</Link>
                    </h3>
                  </div>
                </li>
                <li className="blog-sidebar__post__item">
                  <div className="blog-sidebar__post__image">
                    <img src="assets/images/blog/lp-1-2.jpg" alt="" />
                  </div>
                  <div className="blog-sidebar__post__content">
                    <span className="blog-sidebar__post__author">
                      <i className="far fa-user-circle"></i>
                      by admin
                    </span>
                    <h3 className="blog-sidebar__post__title">
                      <Link to="/newsdetails">How to Buy a House</Link>
                    </h3>
                  </div>
                </li>
                <li className="blog-sidebar__post__item">
                  <div className="blog-sidebar__post__image">
                    <img src="assets/images/blog/lp-1-3.jpg" alt="" />
                  </div>
                  <div className="blog-sidebar__post__content">
                    <span className="blog-sidebar__post__author">
                      <i className="far fa-user-circle"></i>
                      by admin
                    </span>
                    <h3 className="blog-sidebar__post__title">
                      <Link to="/newsdetails">
                        Lenders Mortgage Insurance (LMI)
                      </Link>
                    </h3>
                  </div>
                </li>
              </ul>
            </div> */}
                <div className="blog-sidebar__item blog-sidebar__item--categories">
                  <h3 className="blog-sidebar__title">Categories</h3>
                  <ul className="list-unstyled blog-sidebar__categories">
                    <li>
                      <Link to="#" className="active">
                        Home Bargain Suburbs
                      </Link>
                    </li>
                    <li>
                      <Link to="/home-buyers-rejoice">Home Buyers Rejoice</Link>
                    </li>
                    <li>
                      <Link to="/home-loan-applications">
                        Home Loan Applications
                      </Link>
                    </li>
                    <li>
                      <Link to="/interest-rates">Interest Rates</Link>
                    </li>
                    <li>
                      <Link to="/property-investment">Property Investment</Link>
                    </li>
                  </ul>
                </div>
                <div className="blog-sidebar__item blog-sidebar__item--tags">
                  <h3 className="blog-sidebar__title">Tags</h3>
                  <ul className="list-unstyled blog-sidebar__tags">
                    <li>
                      <Link to="#">Mortage</Link>
                    </li>
                    <li>
                      <Link to="#">Business Loan</Link>
                    </li>
                    <li>
                      <Link to="#">Personal Loan</Link>
                    </li>
                    <li>
                      <Link to="#">Banking</Link>
                    </li>
                    <li>
                      <Link to="#">Finance</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
