import React from "react";
import Bredcom from "../Bredcom/Main";
import BG from "../../assets/loan-pics/loans.jpg";
import First from "../News/Mennewsdeatils/First";

const GuideForFirstHomeBuyers = () => {
  return (
    <>
      <Bredcom
        title={"Home"}
        subtitle={"Resourses"}
        link={"First Home Buyers"}
      />
      <First />
    </>
  );
};

export default GuideForFirstHomeBuyers;
