import React from "react";
import { useNavigate, Link } from "react-router-dom";

const NotFound = () => {
  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1); // Equivalent to history.goBack()
  };
  const notFoundStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
    color: "white",
    textAlign: "center",
  };

  return (
    <>
      <div style={notFoundStyle}>
        <h2>404 - Page Not Found</h2>
        <p>Sorry, the page you are looking for does not exist.</p>
        <Link onClick={goBack}>
          <b>Go Back</b>
        </Link>
      </div>
    </>
  );
};

export default NotFound;
