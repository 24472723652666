import React from "react";
import Bredcom from "../Bredcom/Main";
import GuarantorHomeLoansBody from "../Services/Menservisedeatils/GuarantorHomeLoansBody";
import BG from "../../assets/images/Banners/garantor-loans.jpg";

const GuarantorHomeLoans = () => {
  return (
    <>
      <Bredcom
        title={"Home"}
        subtitle={"Home Loans"}
        link={"Guarantor Home Loans"}
        img={BG}
      />
      <GuarantorHomeLoansBody />
    </>
  );
};

export default GuarantorHomeLoans;
