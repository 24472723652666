import React from "react";
import Bredcom from "../../Bredcom/Main";
import Clint from "../Menteamdeatils/Clint";
import Ash from "../Menteamdeatils/Ash";

const AshDetails = () => {
  return (
    <>
      <Bredcom title={"Home"} subtitle={"TEAM"} link={"Akansha Patel"} />
      <Ash />
      {/* <Clint /> */}
    </>
  );
};

export default AshDetails;
