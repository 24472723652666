import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
import BGImg from "../../assets/new-icons/low-doc-loans.png";
import BGImg1 from "../../assets/new-icons/owner-occupied-loans.png";
import BGImg2 from "../../assets/icons/property removed bg.png";
import Img from "../../assets/new-icons/investment-loans.png";
import Img1 from "../../assets/new-icons/refinance.png";
import Img2 from "../../assets/new-icons/construction-loans.png";
import Img3 from "../../assets/new-icons/guarantor-loans.png";
import Img4 from "../../assets/new-icons/loans-for-professionals.png";
import Img5 from "../../assets/new-icons/asset-finance.png";
import Img6 from "../../assets/new-icons/commercial-loans.png";
import Img7 from "../../assets/new-icons/first-home-buyers-loans.png";
import Img8 from "../../assets/new-icons/SMSF-loans.png";

import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, Pagination } from "swiper";
import SlideUpOnScroll from "./SlideUpOnScroll";
// import Fade from "react-reveal/Fade";

function What() {
  const services = [
    {
      imageSrc: Img7,
      title: "First Home Buyers Loans",
      text: "Have your eyes on your first home? How much are you comfortable affording? Which loan works best for your financial standing? We are here for you!",
      link: "/servicedetails",
    },
    {
      imageSrc: BGImg1,
      title: "Owner Occupied Loans",
      text: "Buying a house for your own stay comes with lower interest rates as buyers are viewed as lower risk. However, your loan may come with restrictions from renting it out. ",
      link: "/servicedetails",
    },
    {
      imageSrc: Img,
      title: "Investment Loans",
      text: "Loans for Investment Properties generally come with higher interest, and you’d also be required to have a more credible financial standing to meet its requirements.",
      link: "/servicedetails",
    },
    {
      imageSrc: Img1,
      title: "Refinance Loans",
      text: "This is a new loan to take up (mostly with better rates and benefits) in replacement of your old or current home loan.",
      link: "/servicedetails",
    },
    {
      imageSrc: Img2,
      title: "Construction Loans",
      text: "An added feature to a construction loan, it allows you to stagger your payments to your builder once the agreed development stages have been met.",
      link: "/servicedetails",
    },
    {
      imageSrc: Img3,
      title: "Guarantor Loans",
      text: `Use a family member's property as security for a loan; this allows you to borrow more than the standard 80%.`,
      link: "/servicedetails",
    },
    {
      imageSrc: Img4,
      title: "Loans for Professionals",
      text: "Certain line of profession offers you eligibility for an LMI waiver up to 90% - 95% of your home loan. Find out now if you qualify!",
      link: "/servicedetails",
    },
    {
      imageSrc: Img8,
      title: "SMSF Loans",
      text: `Did you know that you can use your retirement savings as a home loan deposit? It's time to get that dream house you always wanted!`,
      link: "/servicedetails",
    },
    {
      imageSrc: BGImg,
      title: "Low-Doc Loans",
      text: "Typically for self-employed borrowers where their home loan can be approved without the standard income documentation required.",
      link: "/servicedetails",
    },
    {
      imageSrc: Img5,
      title: "Asset Finance",
      text: "Asset financing relates to utilizing an existing business asset as collateral for a line of credit.",
      link: "/servicedetails",
    },
    {
      imageSrc: Img6,
      title: "Commercial Loans",
      text: "Commercial property loans generally work in the same as the standard home loan, but they would require a minimum of a 30% deposit.",
      link: "/servicedetails",
    },
  ];

  const sliderOptions = {
    navigation: {
      nextEl: ".testimonials-two__btn__right",
      prevEl: ".testimonials-two__btn__left",
    },
  };
  return (
    <>
      <section className="service-one pt-40 pb-40">
        <img
          src="assets/images/shapes/service-h-1.png"
          className="service-one__icon-1"
          alt=""
        />
        <img
          src="assets/images/shapes/service-h-2.png"
          className="service-one__icon-2"
          alt=""
        />
        <img
          src="assets/images/shapes/service-h-3.png"
          className="service-one__icon-3"
          alt=""
        />
        <div className="container">
          <div className="block-title text-center">
            {/* <Fade bottom big cascade> */}
            <div>
              {/* <SlideUpOnScroll
              id="section1"
              duration={1200}
              tension={100}
              friction={14}> */}
              <p className="block-title__tagline">WHAT ARE YOU LOOKING FOR</p>
              {/* </SlideUpOnScroll> */}
              {/* <SlideUpOnScroll
              id="section2"
              duration={1500}
              tension={300}
              friction={12}> */}
              <h2
                className="block-title__title"
                style={{ marginBottom: "80px" }}
              >
                We offer our expertise in various types of loans!
                {/* <br /> for your loans{' '} */}
              </h2>
            </div>
            {/* </Fade> */}
            {/* </SlideUpOnScroll> */}
          </div>
          <Swiper
            style={{
              "--swiper-navigation-color": "#01BBBB",
              "--swiper-pagination-color": "#01BBBB",
              "--swiper-pagination-bullet-inactive-color": "#fff",
              marginTop: "-30px",
            }}
            centeredSlides={false}
            autoplay={{ delay: 2500, disableOnInteraction: false }}
            navigation={false}
            {...sliderOptions}
            slidesPerView={1}
            breakpoints={{
              0: {
                slidesPerView: 1,
              },
              400: {
                slidesPerView: 1,
              },
              639: {
                slidesPerView: 1,
              },
              865: {
                slidesPerView: 1,
              },
              1000: {
                slidesPerView: 3,
              },
              1500: {
                slidesPerView: 3,
              },
              1700: {
                slidesPerView: 3,
              },
            }}
            modules={[Autoplay, Navigation]}
            prevE1=".testimonials-two__btn__left"
            nextE1=".testimonials-two__btn__right"
          >
            <div className="row row-gutter-y-0">
              {services.map((service, index) => (
                <SwiperSlide className="item" style={{ cursor: "pointer" }}>
                  <Link to={service.link} style={{ color: "inherit" }}>
                    <div
                      className="col-lg-12 col-md-12 col-sm-12"
                      style={{ marginTop: "30px", marginBottom: "30px" }}
                      key={index}
                    >
                      <div className="service-card">
                        <div
                          className="service-card__image text-center"
                          style={{ zIndex: 1, position: "absolute" }}
                        >
                          {/* <img
                          src={service.imageSrc}
                          alt={service.title}
                          style={{
                            zIndex: 2,
                          }}
                        /> */}
                          {/* <Link to={service.link}></Link> */}
                        </div>
                        <div className="service-card__content">
                          <div className="service-card__content__inner">
                            <div className="service-card__icon2">
                              {/* <i className="icon-car"></i> */}
                              <img
                                src={service.imageSrc}
                                alt={service.title}
                                style={{
                                  zIndex: 2,
                                  width: "150px",
                                  height: "150px",
                                }}
                              />
                            </div>
                            <h3
                              className="service-card__title"
                              style={{
                                marginTop: "15px",
                              }}
                            >
                              <Link to={service.link}>{service.title}</Link>
                            </h3>
                            <p
                              className="service-card__text"
                              style={{
                                height: "150px",
                              }}
                            >
                              {service.text}
                            </p>
                            <Link
                              to={service.link}
                              className="service-card__link"
                              style={{ marginLeft: "110px" }}
                            >
                              <i className="fa fa-angle-right"></i>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </SwiperSlide>
              ))}
            </div>
          </Swiper>
        </div>
      </section>
    </>
  );
}

export default What;
