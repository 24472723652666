import React from "react";
import { Helmet } from "react-helmet";
import "../../../assets/css/calc.css";

function SplitLoanCalculator() {
  return (
    <>
      <Helmet>
        <title>Split Loan Calculator - Secure Finance</title>
        <meta
          name="description"
          content="Use our Split Loan Calculator to estimate the repayments and interest payable on a loan with both fixed and variable interest rates. Input your loan amount, fixed portion, interest rates, and loan term to view your results."
        />
        <link
          rel="canonical"
          href="https://securefinance.com.au/split-loan-calculator"
        />
      </Helmet>
      <section className="benefit-one pt-60 pb-60">
        <div className="container">
          <div className="row row-gutter-y-60">
            <iframe
              className="VisiCalcClass"
              id="Split_Loan_Calculator"
              src="//www.visionabacus.net/Tools/B3/SuiteA/A300/Split_Loan_Calculator/SecureFinance"
              frameBorder="0"
              scrolling="no"
              title="Split Loan Calculator"></iframe>
          </div>
        </div>
      </section>
    </>
  );
}

export default SplitLoanCalculator;
