import React from "react";
import { Link } from "react-router-dom";
import firstHomeBuyers from "../../../assets/resourses/firstHomeBuyersSmall.jpg";

export default function FirstHomeBuyerDetails() {
  return (
    <>
      <section className="blog-details pt-100 pb-120">
        <div className="container">
          <div className="row row-gutter-y-30">
            <div className="col-lg-8">
              <div className="blog-card__image">
                <div className="blog-card__date">
                  <span>05</span> Mar
                </div>
                <img src={firstHomeBuyers} alt="" />
              </div>
              <div className="blog-card__meta">
                <Link to="/newsdetails">
                  <i className="far fa-user-circle"></i> by Admin
                </Link>
                <Link to="/newsdetails">
                  <i className="far fa-comments"></i>
                </Link>
              </div>
              <h3 className="blog-card__title">
                First home buyers turn to Bank of Nan and Pop
              </h3>

              <div className="blog-details__content">
                <br />
                <b>
                  Nan and Pop have always been good for birthday money, but
                  one-in-10 grandparents are taking their generosity to the next
                  level: helping their grandkids buy a first home.
                </b>
                <br />
                <br />
                <p>
                  Most of us have special memories of pocketing a few treats
                  from Granny and Gramps.
                </p>
                <p>
                  But it turns out those small gestures of affection we knew as
                  kids are morphing into something far more valuable than a few
                  sneaky lollies before dinner or a surprise Lego set.
                </p>
                <p>
                  Research by Compare the Market shows almost three-quarters of{" "}
                  <a
                    href="https://www.comparethemarket.com.au/news/bank-of-grandma-and-grandad-keeping-young-families-afloat/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Aussie grandparents are giving their families a financial
                    helping hand.
                  </a>{" "}
                </p>
                <p>
                  Around 13% are lending money, 9% are chipping in with
                  household bills, and one-in-10 are helping their grandkids buy
                  a first home.
                </p>
                <p>
                  It goes to show that we’re never too old for grandparents’
                  treats.
                </p>

                <p>
                  But if your Gramps and Granny are keen to help you get started
                  in the property market, it’s important to have some open
                  conversations first.
                </p>
              </div>
              <h4 style={{ color: "#01BBBB" }}>How grandparents can help</h4>
              <div className="blog-details__content">
                <p>
                  It’s not unusual for first home buyers to need support from
                  family – especially in this day and age – and it can come in a
                  variety of ways.
                </p>
                <p>
                  One option is for a close relative to act as a{" "}
                  <a
                    href="https://moneysmart.gov.au/loans/going-guarantor-on-a-loan"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    guarantor for a first home buyer’s loan.
                  </a>{" "}
                </p>
                <p>It’s a big ask for grandparents though.</p>
                <p>
                  If the borrower can’t keep up the loan repayments, a lender
                  can ask the guarantor to pay off the debt – something that
                  could leave Nan and Pop financially skewered.
                </p>
                <p>
                  If they can afford it, another way for grandparents to help
                  their grandkids buy a home is by gifting money.
                </p>
                <br />
                <h4 style={{ color: "#01BBBB" }}>What to be aware of</h4>
                <p>A cash gift doesn’t have to be huge to make a difference.</p>
                <p>
                  It can help grow a deposit or go towards upfront buying costs
                  such as lenders’ mortgage insurance.
                </p>
                <p>However, there are traps to be aware of.</p>
                <p>
                  You could get a ‘please explain’ from a lender when they see a
                  lump sum of cash land in your bank account.
                </p>
                <p>
                  The bank may want to be sure it’s not a loan that grandma and
                  grandpa expect to be repaid.
                </p>
                <p>
                  So, it can be a good idea for grandparents to write a letter
                  spelling out that they are gifting the money unconditionally
                  with no strings attached.
                </p>
                <p>
                  And while this should go without saying, it would be negligent
                  of us not to stress the importance of nan and/or pop being
                  completely sound of mind when gifting any money.
                </p>
                <p>
                  The last thing you’d want to do is leave them short in funding
                  their retirement, or start a rift (or legal battle) with other
                  family members who love and care for them as much as you.
                </p>
                <br />
                <h4 style={{ color: "#01BBBB" }}>
                  Talk to us to find out how family can help
                </h4>
                <p>
                  Buying a first home is a special milestone, and it’s extra
                  special when family members rally around to lend a hand.
                </p>
                <p>
                  But as we’ve outlined today, it’s not without its potential
                  pitfalls.
                </p>
                <p>
                  So call us today to find out the different ways your family
                  might be able to help you buy a place of your own.
                </p>
                <p>
                  Disclaimer: The content of this article is general in nature
                  and is presented for informative purposes. It is not intended
                  to constitute tax or financial advice, whether general or
                  personal nor is it intended to imply any recommendation or
                  opinion about a financial product. It does not take into
                  consideration your personal situation and may not be relevant
                  to circumstances. Before taking any action, consider your own
                  particular circumstances and seek professional advice. This
                  content is protected by copyright laws and various other
                  intellectual property laws. It is not to be modified,
                  reproduced or republished without prior written consent.
                </p>
                <p>
                  {" "}
                  <a
                    href="https://www.dropbox.com/scl/fo/pz32gaz9xd4r6aa0c88i7/AEc71JwjxsZ9mjor_SmUJ8M?rlkey=40ljsul9wwedv87cyw7ivahx1&e=1&st=smyxoxeh&dl=0"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Pictures of varying shapes located here
                  </a>{" "}
                </p>
                <br />
                <p>
                  {" "}
                  <a
                    href="https://www.canva.com/design/DAGGlkvc_2I/w9M2n-jNseaIbhR6QKCC9A/edit"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    High-resolution image download here
                  </a>{" "}
                </p>
                <p>
                  Social media teaser. Nan and Pop have always been good for
                  birthday money, but one-in-10 grandparents are taking their
                  generosity to the next level: helping their grandkids buy a
                  first home. 🏡⁣⁣
                </p>
                <p>Subheader. Established: before Bank of Mum and Dad</p>
                <p>
                  Suggested MailChimp subject line: First home buyers turn to
                  Bank of Nan and Pop
                </p>
                <p>
                  Suggested MailChimp preview text: Nan and Pop have always been
                  good for birthday money, but one-in-10 grandparents are taking
                  their generosity to the next level
                </p>
                {/* <div className="blog-details__social">
                  <Link to="#">
                    <i className="fab fa-twitter"></i>
                  </Link>
                  <Link to="#">
                    <i className="fab fa-facebook"></i>
                  </Link>
                  <Link to="#">
                    <i className="fab fa-instagram"></i>
                  </Link>
                  <Link to="#">
                    <i className="fab fa-pinterest-p"></i>
                  </Link>
                </div> */}
              </div>
              {/* <div className="blog-details__author">
                <div className="blog-details__author__image">
                  <img src="assets/images/blog/blog-author-1-1.jpg" alt="" />
                </div>
                <div className="blog-details__author__content">
                  <h3 className="blog-details__author__name">Author Details</h3>
                  <p className="blog-details__author__text">
                    Lorem ipsum is simply free text by copytyping refreshing.
                    Neque porro est qui dolorem ipsum quia quaed veritatis et
                    quasi architecto.
                  </p>
                </div>
              </div> */}
              {/* <div className="blog-details__comment">
                <h3 className="blog-details__title">2 Comments</h3>
                <div className="blog-details__comment__item">
                  <div className="blog-details__comment__image">
                    <img src="assets/images/blog/blog-comment-1-1.jpg" alt="" />
                  </div>
                  <div className="blog-details__comment__content">
                    <h3 className="blog-details__comment__name">
                      Kevin Martin
                    </h3>
                    <p className="blog-details__comment__text">
                      Lorem ipsum is simply free text used by copytyping
                      refreshing. Neque porro est qui dolorem ipsum quia quaed
                      inventore veritatis et quasi architecto beatae vitae dicta
                      sunt explicabo.
                    </p>
                  </div>
                  <Link to="#" className="thm-btn">
                    Reply
                  </Link>
                </div>
                <div className="blog-details__comment__item">
                  <div className="blog-details__comment__image">
                    <img src="assets/images/blog/blog-comment-1-2.jpg" alt="" />
                  </div>
                  <div className="blog-details__comment__content">
                    <h3 className="blog-details__comment__name">
                      Kevin Martin
                    </h3>
                    <p className="blog-details__comment__text">
                      Lorem ipsum is simply free text used by copytyping
                      refreshing. Neque porro est qui dolorem ipsum quia quaed
                      inventore veritatis et quasi architecto beatae vitae dicta
                      sunt explicabo.
                    </p>
                  </div>
                  <Link to="#" className="thm-btn">
                    Reply
                  </Link>
                </div>
              </div> */}
              {/* <div className="blog-details__form">
                <h3 className="blog-details__title">Leave a comment</h3>
                <form
                  action="assets/sendemail.php"
                  className="form-one contact-form-validated">
                  <div className="row row-gutter-y-20 row-gutter-x-20">
                    <div className="col-md-6">
                      <input type="text" placeholder="Full name" name="name" />
                    </div>
                    <div className="col-md-6">
                      <input
                        type="email"
                        placeholder="Email address"
                        name="email"
                      />
                    </div>
                    <div className="col-md-6">
                      <input
                        type="text"
                        placeholder="Phone number"
                        name="phone"
                      />
                    </div>
                    <div className="col-md-6">
                      <input type="text" placeholder="Subject" name="subject" />
                    </div>
                    <div className="col-md-12">
                      <textarea placeholder="Message" name="message"></textarea>
                    </div>
                    <div className="col-md-12">
                      <button type="submit" className="thm-btn">
                        Send a Message
                      </button>
                    </div>
                  </div>
                </form>
              </div> */}
            </div>
            <div className="col-lg-4">
              <div className="blog-sidebar">
                <div className="blog-sidebar__item blog-sidebar__item--search">
                  <form action="#" className="blog-sidebar__search">
                    <input type="search" placeholder="Search" />
                    <button type="submit" className="blog-sidebar__search__btn">
                      <i className="icon-magnifying-glass"></i>
                    </button>
                  </form>
                </div>
                {/* <div className="blog-sidebar__item blog-sidebar__item--posts">
                  <h3 className="blog-sidebar__title">Recent Posts</h3>
                  <ul className="list-unstyled blog-sidebar__post">
                    <li className="blog-sidebar__post__item">
                      <div className="blog-sidebar__post__image">
                        <img src="assets/images/blog/lp-1-1.jpg" alt="" />
                      </div>
                      <div className="blog-sidebar__post__content">
                        <span className="blog-sidebar__post__author">
                          <i className="far fa-user-circle"></i>
                          by admin
                        </span>
                        <h3 className="blog-sidebar__post__title">
                          <Link to="/newsdetails">Types of Loans</Link>
                        </h3>
                      </div>
                    </li>
                    <li className="blog-sidebar__post__item">
                      <div className="blog-sidebar__post__image">
                        <img src="assets/images/blog/lp-1-2.jpg" alt="" />
                      </div>
                      <div className="blog-sidebar__post__content">
                        <span className="blog-sidebar__post__author">
                          <i className="far fa-user-circle"></i>
                          by admin
                        </span>
                        <h3 className="blog-sidebar__post__title">
                          <Link to="/newsdetails">How to Buy a House</Link>
                        </h3>
                      </div>
                    </li>
                    <li className="blog-sidebar__post__item">
                      <div className="blog-sidebar__post__image">
                        <img src="assets/images/blog/lp-1-3.jpg" alt="" />
                      </div>
                      <div className="blog-sidebar__post__content">
                        <span className="blog-sidebar__post__author">
                          <i className="far fa-user-circle"></i>
                          by admin
                        </span>
                        <h3 className="blog-sidebar__post__title">
                          <Link to="/newsdetails">
                            Lenders Mortgage Insurance (LMI)
                          </Link>
                        </h3>
                      </div>
                    </li>
                  </ul>
                </div> */}
                <div className="blog-sidebar__item blog-sidebar__item--categories">
                  <h3 className="blog-sidebar__title">Categories</h3>
                  <ul className="list-unstyled blog-sidebar__categories">
                    <li>
                      <Link to="#" className="active">
                        Home Bargain Suburbs
                      </Link>
                    </li>
                    <li>
                      <Link to="/home-buyers-rejoice">Home Buyers Rejoice</Link>
                    </li>
                    <li>
                      <Link to="/home-loan-applications">
                        Home Loan Applications
                      </Link>
                    </li>
                    <li>
                      <Link to="/interest-rates">Interest Rates</Link>
                    </li>
                    <li>
                      <Link to="/property-investment">Property Investment</Link>
                    </li>
                  </ul>
                </div>
                <div className="blog-sidebar__item blog-sidebar__item--tags">
                  <h3 className="blog-sidebar__title">Tags</h3>
                  <ul className="list-unstyled blog-sidebar__tags">
                    <li>
                      <Link to="#">Mortage</Link>
                    </li>
                    <li>
                      <Link to="#">Business Loan</Link>
                    </li>
                    <li>
                      <Link to="#">Personal Loan</Link>
                    </li>
                    <li>
                      <Link to="#">Banking</Link>
                    </li>
                    <li>
                      <Link to="#">Finance</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
