// ReferAFriendCard.js
import React from "react";

const ReferAFriendCard = ({ referrals }) => {
  return (
    <div className="card">
      <h2>Refer A Friend</h2>
      <p>Referrals: {referrals}</p>
    </div>
  );
};

export default ReferAFriendCard;
