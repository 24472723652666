// HomeLoanApplicationsDetails
import React from "react";
import { Link } from "react-router-dom";
import BG from "../../../assets/resourses/home-buyers-rejoice.png";

function HomeBuyersRejoiceDetails() {
  return (
    <>
      <section className="blog-details pt-100 pb-120">
        <div className="container">
          <div className="row row-gutter-y-30">
            <div className="col-lg-8">
              <div className="blog-card__image">
                <div className="blog-card__date">
                  <span>05</span> Mar
                </div>
                <img src={BG} alt="" />
              </div>
              <div className="blog-card__meta">
                <Link to="/newsdetails">
                  <i className="far fa-user-circle"></i> by Admin
                </Link>
                <Link to="/newsdetails">
                  <i className="far fa-comments"></i> 2 Comments
                </Link>
              </div>
              <h3 className="blog-card__title">
                Home buyers rejoice! More listings are hitting the market
              </h3>

              <div className="blog-details__content">
                <br />
                <b>
                  Great news for home buyers! After an extended run of low
                  listings, the number of homes coming onto the market is
                  skyrocketing. So could this have an impact on the property
                  market? Let’s take a look.
                </b>
                <br />
                <br />

                <p>
                  Take a look around your local suburb, and chances are you’ll
                  see freshly minted For Sale signs popping up all over the
                  place.
                </p>

                <p>
                  That’s because a large number of homes are coming onto the
                  market.
                </p>

                <p>
                  Research firm{" "}
                  <a
                    href="https://rea3.irmau.com/site/pdf/6c8ff520-56f5-495f-b36e-0df40caddc6d/New-listings-record-busy-start-to-2024-with-a-jump-in-January.pdf"
                    target="_blank"
                    rel="noopener noreferrer">
                    PropTrack
                  </a>{" "}
                  says the property market is off to a strong start for the
                  year, with the number of new listings nationally on
                  realestate.com.au up 12% year-on-year in January.
                </p>

                <p>
                  Melbourne and Sydney had their busiest January in over a
                  decade.
                </p>

                <p>
                  Activity was also strong in Hobart, Brisbane and Adelaide,
                  with Canberra experiencing its busiest-ever January for new
                  listings.
                </p>
                <p>
                  Only Perth bucked the trend, recording slightly fewer new
                  listings this year compared to January 2023.
                </p>
              </div>
              <h4 style={{ color: "#01BBBB" }}>Why the uptick in listings?</h4>
              <div className="blog-details__content">
                <p>
                  The rise in new listings reflects strong demand, very low
                  unemployment and population growth.
                </p>
                <p>
                  Home buyers are also enjoying a more stable interest rate
                  outlook.
                </p>
                <p>
                  February saw rates remain on hold, and PropTrack says
                  financial markets are now expecting a reasonable chance that
                  interest rates may start to fall later in the year.
                </p>
                <br />
                <br />

                <h4 style={{ color: "#01BBBB" }}>
                  What does more listings mean for home buyers?
                </h4>
                <div className="blog-details__content">
                  <p>
                    More homes coming onto the market gives buyers the benefit
                    of increased choice, and that’s a real plus if you are
                    looking for your first home or upgrading to your next place.
                  </p>
                  <p>But the rise in listings may not push home prices down.</p>
                  <p>
                    That’s because we are still seeing plenty of keen buyers.
                  </p>
                  <p>
                    As a guide,{" "}
                    <a
                      href="https://www.corelogic.com.au/__data/assets/pdf_file/0012/21207/CoreLogic-HVI-FEB-2024-FINAL-wCPI.pdf"
                      target="_blank"
                      rel="noopener noreferrer">
                      CoreLogic estimates
                    </a>{" "}
                    115,241 homes were sold over the three months ending January
                    31 – an 11.9% increase on the same period last year, with
                    high levels of migration being a big driver of demand.
                  </p>
                  <p>
                    CoreLogic adds that{" "}
                    <a
                      href="https://www.corelogic.com.au/news-research/news/2024/busiest-combined-capital-city-auction-week-so-far-this-year"
                      target="_blank"
                      rel="noopener noreferrer">
                      expectations of lower rates
                    </a>{" "}
                    later this year could see house price growth accelerate.
                  </p>
                </div>
                <h4 style={{ color: "#01BBBB" }}>How you can prepare</h4>
                <div className="blog-details__content">
                  <p>
                    More choice can be a good thing for buyers. However, it can
                    become easy to lose track of what you’re looking for in a
                    property, especially if you’ve attended a large number of
                    inspections.
                  </p>
                  <p>
                    That’s when it helps to draw up a list of must-have home
                    features (such as aspect, block size or parking
                    requirements) followed by nice-but-not-necessary features
                    (like, say, a swimming pool or a shed) to assess each home
                    you visit.
                  </p>
                  <p>
                    It also makes sense to be ready to act when you see a
                    property you’d like to buy.
                  </p>
                  <p>
                    Having home loan pre-approval in place lets you set a buying
                    budget, so you can focus on homes within your price range.
                    It also means you can make an offer with confidence – and
                    stay one step ahead of less-organised buyers.
                  </p>
                  <p>
                    Talk to us today to get your home loan ducks in a row and
                    take advantage of a wider choice of homes listed for sale.
                  </p>
                </div>
                <p>
                  <b>Disclaimer: </b>The content of this article is general in
                  nature and is presented for informative purposes. It is not
                  intended to constitute tax or financial advice, whether
                  general or personal nor is it intended to imply any
                  recommendation or opinion about a financial product. It does
                  not take into consideration your personal situation and may
                  not be relevant to circumstances. Before taking any action,
                  consider your own particular circumstances and seek
                  professional advice. This content is protected by copyright
                  laws and various other intellectual property laws. It is not
                  to be modified, reproduced or republished without prior
                  written consent.
                </p>
              </div>
              <br />
              <div className="blog-details__meta">
                <div className="blog-details__tags">
                  <span>Tags</span>
                  <Link to="#">Mortgage</Link>
                  <Link to="#">Home Loan</Link>
                  <Link to="#">First Home</Link>
                </div>
                {/* <div className="blog-details__social">
                  <Link to="#">
                    <i className="fab fa-twitter"></i>
                  </Link>
                  <Link to="#">
                    <i className="fab fa-facebook"></i>
                  </Link>
                  <Link to="#">
                    <i className="fab fa-instagram"></i>
                  </Link>
                  <Link to="#">
                    <i className="fab fa-pinterest-p"></i>
                  </Link>
                </div> */}
              </div>
              {/* <div className="blog-details__author">
                <div className="blog-details__author__image">
                  <img src="assets/images/blog/blog-author-1-1.jpg" alt="" />
                </div>
                <div className="blog-details__author__content">
                  <h3 className="blog-details__author__name">Author Details</h3>
                  <p className="blog-details__author__text">
                    Lorem ipsum is simply free text by copytyping refreshing.
                    Neque porro est qui dolorem ipsum quia quaed veritatis et
                    quasi architecto.
                  </p>
                </div>
              </div> */}
              {/* <div className="blog-details__comment">
                <h3 className="blog-details__title">2 Comments</h3>
                <div className="blog-details__comment__item">
                  <div className="blog-details__comment__image">
                    <img src="assets/images/blog/blog-comment-1-1.jpg" alt="" />
                  </div>
                  <div className="blog-details__comment__content">
                    <h3 className="blog-details__comment__name">
                      Kevin Martin
                    </h3>
                    <p className="blog-details__comment__text">
                      Lorem ipsum is simply free text used by copytyping
                      refreshing. Neque porro est qui dolorem ipsum quia quaed
                      inventore veritatis et quasi architecto beatae vitae dicta
                      sunt explicabo.
                    </p>
                  </div>
                  <Link to="#" className="thm-btn">
                    Reply
                  </Link>
                </div>
                <div className="blog-details__comment__item">
                  <div className="blog-details__comment__image">
                    <img src="assets/images/blog/blog-comment-1-2.jpg" alt="" />
                  </div>
                  <div className="blog-details__comment__content">
                    <h3 className="blog-details__comment__name">
                      Kevin Martin
                    </h3>
                    <p className="blog-details__comment__text">
                      Lorem ipsum is simply free text used by copytyping
                      refreshing. Neque porro est qui dolorem ipsum quia quaed
                      inventore veritatis et quasi architecto beatae vitae dicta
                      sunt explicabo.
                    </p>
                  </div>
                  <Link to="#" className="thm-btn">
                    Reply
                  </Link>
                </div>
              </div> */}
              {/* <div className="blog-details__form">
                <h3 className="blog-details__title">Leave a comment</h3>
                <form
                  action="assets/sendemail.php"
                  className="form-one contact-form-validated">
                  <div className="row row-gutter-y-20 row-gutter-x-20">
                    <div className="col-md-6">
                      <input type="text" placeholder="Full name" name="name" />
                    </div>
                    <div className="col-md-6">
                      <input
                        type="email"
                        placeholder="Email address"
                        name="email"
                      />
                    </div>
                    <div className="col-md-6">
                      <input
                        type="text"
                        placeholder="Phone number"
                        name="phone"
                      />
                    </div>
                    <div className="col-md-6">
                      <input type="text" placeholder="Subject" name="subject" />
                    </div>
                    <div className="col-md-12">
                      <textarea placeholder="Message" name="message"></textarea>
                    </div>
                    <div className="col-md-12">
                      <button type="submit" className="thm-btn">
                        Send a Message
                      </button>
                    </div>
                  </div>
                </form>
              </div> */}
            </div>
            <div className="col-lg-4">
              <div className="blog-sidebar">
                <div className="blog-sidebar__item blog-sidebar__item--search">
                  <form action="#" className="blog-sidebar__search">
                    <input type="search" placeholder="Search" />
                    <button type="submit" className="blog-sidebar__search__btn">
                      <i className="icon-magnifying-glass"></i>
                    </button>
                  </form>
                </div>
                {/* <div className="blog-sidebar__item blog-sidebar__item--posts">
                  <h3 className="blog-sidebar__title">Recent Posts</h3>
                  <ul className="list-unstyled blog-sidebar__post">
                    <li className="blog-sidebar__post__item">
                      <div className="blog-sidebar__post__image">
                        <img src="assets/images/blog/lp-1-1.jpg" alt="" />
                      </div>
                      <div className="blog-sidebar__post__content">
                        <span className="blog-sidebar__post__author">
                          <i className="far fa-user-circle"></i>
                          by admin
                        </span>
                        <h3 className="blog-sidebar__post__title">
                          <Link to="/newsdetails">Types of Loans</Link>
                        </h3>
                      </div>
                    </li>
                    <li className="blog-sidebar__post__item">
                      <div className="blog-sidebar__post__image">
                        <img src="assets/images/blog/lp-1-2.jpg" alt="" />
                      </div>
                      <div className="blog-sidebar__post__content">
                        <span className="blog-sidebar__post__author">
                          <i className="far fa-user-circle"></i>
                          by admin
                        </span>
                        <h3 className="blog-sidebar__post__title">
                          <Link to="/newsdetails">How to Buy a House</Link>
                        </h3>
                      </div>
                    </li>
                    <li className="blog-sidebar__post__item">
                      <div className="blog-sidebar__post__image">
                        <img src="assets/images/blog/lp-1-3.jpg" alt="" />
                      </div>
                      <div className="blog-sidebar__post__content">
                        <span className="blog-sidebar__post__author">
                          <i className="far fa-user-circle"></i>
                          by admin
                        </span>
                        <h3 className="blog-sidebar__post__title">
                          <Link to="/newsdetails">
                            Lenders Mortgage Insurance (LMI)
                          </Link>
                        </h3>
                      </div>
                    </li>
                  </ul>
                </div> */}
                <div className="blog-sidebar__item blog-sidebar__item--categories">
                  <h3 className="blog-sidebar__title">Categories</h3>
                  <ul className="list-unstyled blog-sidebar__categories">
                    <li>
                      <Link to="/home-buyers-rejoice" className="active">
                        Home Buyers Rejoice
                      </Link>
                    </li>
                    <li>
                      <Link to="/home-loan-applications">
                        Home Loan Applications
                      </Link>
                    </li>
                    <li>
                      <Link to="/interest-rates">Interest Rates</Link>
                    </li>
                    <li>
                      <Link to="/property-investment">Property Investment</Link>
                    </li>
                    <li>
                      <Link to="/home-bargain-suburbs">
                        Home Bargain Suburbs
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="blog-sidebar__item blog-sidebar__item--tags">
                  <h3 className="blog-sidebar__title">Tags</h3>
                  <ul className="list-unstyled blog-sidebar__tags">
                    <li>
                      <Link to="#">Mortage</Link>
                    </li>
                    <li>
                      <Link to="#">Business Loan</Link>
                    </li>
                    <li>
                      <Link to="#">Personal Loan</Link>
                    </li>
                    <li>
                      <Link to="#">Banking</Link>
                    </li>
                    <li>
                      <Link to="#">Finance</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default HomeBuyersRejoiceDetails;
