import React from "react";
import "../../../assets/css/calc.css";

function BorrowingCapacityCalculator() {
  return (
    <>
      <section className="benefit-one pt-60 pb-60">
        <div className="container">
          <div className="row row-gutter-y-60">
            <iframe
              class="VisiCalcClass"
              id="Borrowing_Power_Calculator"
              src="//www.visionabacus.net/Tools/B3/SuiteA/A200/Borrowing_Power_Calculator/SecureFinance"
              frameborder="0"
              scrolling="no"></iframe>
          </div>
        </div>
      </section>
    </>
  );
}

export default BorrowingCapacityCalculator;
