import React, { useState } from "react";
import RefinanceBoxRadio from "./RefinanceBoxRadio";

const RefinanceForms = () => {
  const [selectedValue, setSelectedValue] = useState("");

  const handleRadioChange = (value) => {
    setSelectedValue(value);
  };

  return (
    <div>
      {/* Other components or content */}
      <RefinanceBoxRadio
        onChange={handleRadioChange}
        selectedValue={selectedValue}
      />
      {/* Other components or content */}
    </div>
  );
};

export default RefinanceForms;
