import BGS1 from "../../../assets//loan-pics/owner occupied pic.jpg";

import { Link } from "react-router-dom";
import React, { useState } from "react";

function OwnerOccupiedBody() {
  const styles = {
    display: "inline",
    margin: 0,
    color: "#000",
    fontFamily: "Montserrat",
  };
  const [clicked, setClicked] = useState(null);
  const faqs = [
    {
      question: "Offset Account",
      answer: `An offset account is a type of transaction account linked to a mortgage or home loan. It allows borrowers to offset the balance of their savings and/or everyday transaction account against the outstanding balance of their loan. The balance in the offset account is subtracted from the principal amount of the loan before interest is calculated, effectively reducing the interest payable on the mortgage.
      <br/><br/>
      <h4 style="color: #0983bc"><b>How it works:</b></h4>

      <ul>
      <li><b>Linking to a Mortgage:</b>  The offset account is linked to a specific mortgage or home loan. The borrower and the lender agree to establish the offset account as part of the loan arrangement.</li>
      <li><b>Deposit and Withdrawal:</b>  The borrower can deposit funds into the offset account, just like any other transaction account. They can also withdraw funds as needed for everyday expenses or other purposes.</li>
      <li><b>Offsetting Interest:</b>  The balance in the offset account is offset against the outstanding balance of the linked mortgage or home loan. For example, if the borrower has a mortgage of $300,000 and an offset account balance of $50,000, interest is only calculated on the net balance of $250,000 ($300,000 - $50,000).</li>
      <li><b>Reducing Interest Costs:</b>  By offsetting the balance of the loan with funds in the offset account, borrowers can effectively reduce the amount of interest payable on their mortgage. This can lead to significant interest savings over the life of the loan.</li>
      <li><b>Flexibility and Accessibility:</b>  Borrowers retain full access to the funds in the offset account, allowing them to deposit and withdraw money as needed without restrictions. This provides flexibility and liquidity while still benefiting from interest savings on the mortgage.</li>
      <li><b>Tax Efficiency:</b>  Interest saved through an offset account is not considered taxable income, unlike interest earned on savings accounts. This can provide additional tax benefits for borrowers.</li>
      <li><b>No Direct Reduction of Loan Principal:</b>  It's important to note that while an offset account reduces the amount of interest payable on the mortgage, it does not directly reduce the principal balance of the loan. However, the interest savings can help borrowers pay off their loan faster by allowing them to allocate more funds towards principal repayments.</li>
</ul>
      <p>Offset accounts can be particularly beneficial for borrowers with substantial savings or regular income. They offer a practical way to minimize interest costs and accelerate the repayment of the mortgage while maintaining accessibility to funds for everyday expenses.</p>  
      `,
    },
    {
      question: "Redraw Facility",
      answer: `A redraw facility is a feature offered by many mortgage lenders as part of their home loan products. It allows borrowers to make additional repayments on their home loan beyond the required minimum, and then access those extra funds if needed in the future. 
      <br/><br/>
      <h4 style="color: #0983bc"><b>How it works:</b></h4>
      <ul>
      <li><b>Extra Repayments:</b> Borrowers make additional repayments on their home loan, either through regular payments or lump sum contributions. These extra repayments go towards reducing the principal balance of the loan.</li>
      <li><b>Availability of Funds:</b>  Once the extra repayments are made and processed by the lender, the funds become available for redraw. Borrowers can access these funds if they need to cover unexpected expenses, make a large purchase, or for any other purpose.</li>
      <li><b>Accessing Funds:</b>  Borrowers can access the funds in the redraw facility through various methods, depending on the lender's policies. Common methods include online banking, phone banking, or visiting a branch. Some lenders may also offer the option to redraw funds using a debit card or chequebook linked to the home loan account.</li>
      <li><b>Limits and Conditions:</b>  Lenders may impose limits and conditions on the use of the redraw facility. These can include minimum, and maximum redraw amounts, restrictions on the frequency of redraws, and any associated fees or charges. Borrowers should review their loan contract or speak with their lender to understand the specific terms and conditions of the redraw facility.</li>
      <li><b>Effect on Loan Term:</b>  Redrawing funds from the facility does not typically affect the loan term or repayment schedule. Borrowers are still required to continue making regular repayments as scheduled, and the redrawn funds do not alter the original loan term.</li>
      <li><b>Interest Savings:</b>  Like an offset account, making extra repayments and using the redraw facility can help borrowers save on interest costs over the life of the loan. By reducing the principal balance, borrowers pay less interest on the outstanding loan amount.</li>
      <li><b>Tax Considerations:</b> Unlike an offset account, the funds in a redraw facility are considered part of the loan account and are not held separately. Therefore, interest savings from a redraw facility are not typically considered taxable income.</li>
</ul>
      <p>It's essential for borrowers to understand the terms and conditions of the redraw facility offered by their lender, including any fees, limits, and access methods. While redraw facilities provide flexibility and convenience, borrowers should consider their financial needs and goals carefully before accessing funds, as redrawn funds may not be available for future use if needed.</p>  
      `,
    },
    {
      question: "Home Loan Portability",
      answer: `Home loan portability is a feature offered by many lenders that allows borrowers to transfer their existing home loan from one property to another without having to refinance the entire loan. This can be beneficial for borrowers who are selling their current home and purchasing a new one, as it allows them to maintain their existing loan terms, including interest rates, loan amount, and repayment schedule. 
      <h4 style="color: #0983bc"><b>How it works:</b></h4>
      <ul>
      <li><b>Eligibility:</b>Borrowers must meet certain eligibility criteria to qualify for home loan portability. These criteria may vary between lenders but generally include being up-to-date with loan repayments, having a good credit history, and meeting any specific requirements set by the lender.</li>
      <li><b>Property Sale and Purchase:</b>When selling their current property and purchasing a new one, borrowers notify their lender of their intention to transfer their existing loan to the new property. The lender assesses the new property to ensure it meets their lending criteria.</li>
      <li><b>Loan Transfer:</b>Once the new property is approved by the lender, the existing home loan is transferred from the old property to the new one. The terms of the loan, including the interest rate, loan amount, and repayment schedule, remain unchanged.</li>
      <li><b>Legal and Administrative Process:</b>The transfer of the home loan involves a legal and administrative process, including updating the mortgage documents and property title to reflect the new property. Borrowers may incur fees or charges associated with this process, such as legal fees or administrative fees.</li>
      <li><b>Continued Loan Terms:</b>Borrowers continue to make repayments on the loan according to the existing terms and conditions. The loan term and repayment schedule remain the same, and borrowers are still subject to the terms of the original loan contract.</li>
      <li><b>Benefits of Portability:</b>Home loan portability offers several benefits for borrowers, including:</li>
      <li><b>Retaining existing loan terms:</b> Borrowers can maintain their current interest rate, loan amount, and repayment schedule, which may be more favourable than the terms available for a new loan.</li>
      <li><b>Cost savings:</b>Porting a home loan typically involves lower costs compared to refinancing, as borrowers may avoid fees such as loan establishment fees, discharge fees, and mortgage registration fees associated with taking out a new loan.</li>
      <li><b>Convenience:</b>Home loan portability is a straightforward process that allows borrowers to transfer their loan seamlessly to a new property without the need to renegotiate terms or reapply for a loan.</li>
      <li><b>Considerations:</b>While home loan portability offers benefits, borrowers should consider factors such as any fees or charges associated with the process, the compatibility of the existing loan with the new property, and whether the loan still meets their financial needs and goals.</li> 
      </ul>
      <p>It's essential for borrowers to discuss their options with their lender and seek professional advice if needed to determine whether home loan portability is the right choice for their circumstances.</p>  
      `,
    },
    {
      question: "Repayment Holiday",
      answer: `A repayment holiday, also known as a repayment, pause or mortgage holiday, is a feature that allows borrowers to temporarily suspend or reduce their mortgage repayments for a specified period. This feature can provide financial relief to borrowers facing short-term financial difficulties, such as unexpected expenses, loss of income, or significant life events.
      <h4 style="color: #0983bc"><b>How does it work?</b></h4>
      <ul>
      <li><b>Eligibility:</b>Borrowers must meet certain eligibility criteria to qualify for a repayment holiday. These criteria may vary between lenders but generally include being up to date with loan repayments, having a good credit history, and experiencing genuine financial hardship.</li>
      <li><b>Application Process:</b>Borrowers must apply to their lender for a repayment holiday, providing information about their financial situation and reasons for requesting the pause in repayments. Lenders may require supporting documentation, such as bank statements, proof of income, or evidence of financial hardship.</li>
      <li><b>Approval:</b>The lender assesses the borrower's application and determines whether they meet the eligibility criteria for a repayment holiday. If approved, the lender notifies the borrower of the terms and conditions of the repayment pause, including the duration of the holiday and any changes to the loan agreement.</li>
      <li><b>Duration:</b>The duration of a repayment holiday varies depending on the lender and the borrower's circumstances. It typically ranges from one to six months, although longer repayment holidays may be available in certain cases.</li>
      <li><b>Interest Accrual:</b>While repayments are paused during the holiday period, interest continues to accrue on the outstanding loan balance. This means that borrowers may end up paying more interest over the life of the loan, as the unpaid interest is added to the principal amount of the loan.</li>
      <li><b>Repayment Resumption:</b>At the end of the repayment holiday, borrowers are required to resume making regular repayments on their mortgage. The lender may adjust the loan term or repayment schedule to account for the missed payments and any accrued interest.</li>
      <li><b>Impact on Credit Score:</b>Taking a repayment holiday may have implications for the borrower's credit score, as lenders may report the paused repayments to credit reporting agencies. While a repayment holiday itself is not necessarily negative, it may be viewed by future lenders as an indication of financial hardship.</li>
      <li><b>Alternatives to Repayment Holiday:</b>Borrowers should explore alternative options before requesting a repayment holiday, such as negotiating a temporary reduction in repayments, switching to interest-only payments, or accessing other financial assistance programs.</li>
     </ul>
      <p>It's important for borrowers to carefully consider the implications of taking a repayment holiday, including the potential impact on their overall loan balance and credit rating. Borrowers should communicate openly with their lender and seek financial advice if needed to explore all available options for managing temporary financial difficulties.</p>
      `,
    },
    {
      question: "Fixed Interest Rate Vs Variable Interest Rate",
      answer: `Home loans typically offer borrowers the option to choose between fixed interest rates and variable interest rates. Each type of interest rate has its advantages and disadvantages, and understanding the differences between them is essential for borrowers when selecting a mortgage. 
      <h4 style="color: #0983bc">
      <b>How it works:</b>
      </h4>
  
      <h4 style="color: #0983bc"><b>Fixed Rate Home Loan</b></h4>
      <ul>
      <li><b>Consistency:</b>With a fixed interest rate, the interest rate remains constant for a predetermined period, usually between one and five years. This means that borrowers have the certainty of knowing exactly how much their repayments will be during the fixed term, regardless of changes in market interest rates.</li>
      <li><b>Budgeting and Planning:</b>Fixed-rate loans are popular among borrowers who prefer stability and certainty in their repayments. Knowing the exact repayment amount allows borrowers to budget and plan their finances more effectively, making it easier to manage household expenses.</li>
      <li><b>Protection against Rate Increases:</b>Fixed-rate loans offer protection against potential interest rate increases in the market. If interest rates rise during the fixed term, borrowers are insulated from higher repayments, providing financial security and peace of mind.</li>
      <li><b>Limited Flexibility:</b>Fixed-rate loans often come with limited flexibility compared to variable-rate loans. Borrowers may face restrictions or penalties for making extra repayments, switching loans, or paying off the loan early during the fixed term.</li>
      <li><b>Potential Disadvantage in Falling Rate Environment:</b>If market interest rates decrease during the fixed term, borrowers with fixed-rate loans may miss out on potential interest savings, as their rate remains unchanged until the end of the fixed term.</li>
     </ul>

      <br/>
      <h4 style="color: #0983bc"><b>Variable Rate Home Loan</b></h4>
      <ul>
      <li><b>Flexibility:</b>Variable interest rates can fluctuate over time in response to changes in the official cash rate set by the Reserve Bank of Australia (RBA) and other market factors. Borrowers benefit from the flexibility of variable-rate loans, which may offer features such as redraw facilities, offset accounts, and the ability to make extra repayments without penalties.</li>
      <li><b>Potential for Interest Savings:</b>Variable-rate loans offer the potential for interest savings if market interest rates decrease. Borrowers can take advantage of lower interest rates by reducing their repayments or paying off their loan sooner, saving money over the life of the loan.</li>
      <li><b>Risk of Rate Increases:</b>Variable-rate loans expose borrowers to the risk of interest rate increases. If market interest rates rise, borrowers' repayments will increase accordingly, potentially impacting their affordability and financial stability.</li>
      <li><b>Less Predictability:</b>Unlike fixed-rate loans, variable-rate loans lack the predictability of future repayments, as interest rates can change at any time. This uncertainty may make it more challenging for borrowers to budget and plan their finances.</li>
     </ul>
      <p>In summary, the main difference between fixed and variable interest rates for home loans lies in the level of certainty and flexibility they offer borrowers. Fixed-rate loans provide stability and protection against rate increases, while variable-rate loans offer flexibility and the potential for interest savings. Borrowers should carefully consider their individual financial circumstances, risk tolerance, and long-term goals when choosing between fixed and variable interest rates for their home loan.</p>
      `,
    },
    {
      question: "Top-Up",
      answer: `A top-up feature is an option offered by some mortgage lenders that allows borrowers to access additional funds on top of their existing home loan. This feature enables borrowers to borrow more money against the equity they have built up in their property without having to refinance their entire loan.
      <h4 style="color: #0983bc">
      <b>How it works:</b>
      </h4>
      <ul>
      <li><b>Equity Consideration:</b>Before applying for a top-up, borrowers must have built up sufficient equity in their property. Equity is the difference between the property's current market value and the outstanding balance of the existing home loan. Lenders typically require a minimum level of equity to be eligible for a top-up.</li>
      <li><b>Application Process:</b>Borrowers apply to their lender for a top-up by submitting a formal application, similar to the process for obtaining their initial home loan. The lender assesses the borrower's financial situation, credit history, and the current value of the property to determine eligibility for the top-up.</li>
      <li><b>Loan Increase:</b>If the top-up application is approved, the lender increases the borrower's existing home loan by the requested amount. The additional funds are typically added to the existing loan balance, and the borrower continues to make repayments based on the revised loan amount and terms.</li>
      <li><b>Purpose of Funds:</b>Borrowers can use the funds obtained through the top-up for various purposes, such as home renovations, property improvements, debt consolidation, investment opportunities, or other personal expenses. There are generally no restrictions on the use of top-up funds, although borrowers should use them responsibly.</li>
      <li><b>Interest Rate and Repayment Terms:</b>The interest rate and repayment terms for the top-up portion of the loan are typically aligned with the borrower's existing home loan. This means that borrowers may have the option to choose between fixed or variable interest rates and select a repayment schedule that suits their needs.</li>
      <li><b>Tax Considerations:</b>Borrowers should consider the tax implications of using a top-up feature, particularly if the funds are used for investment purposes. Interest expenses incurred on the top-up portion of the loan may be tax-deductible if the funds are used for income-producing purposes, such as investment properties or shares. Borrowers should seek advice from a tax professional to understand the specific tax implications of using a top-up feature.</li>
      <li><b>Fees and Charges:</b>Borrowers may incur fees and charges associated with the top-up, such as application fees, valuation fees, legal fees, and lender's mortgage insurance (LMI) if the loan-to-value ratio (LVR) exceeds a certain threshold. It's essential for borrowers to review the terms and conditions of the top-up offer carefully and consider any associated costs.</li>
      </ul>  

      <p>Overall, the top-up feature provides borrowers with a convenient way to access additional funds for various purposes without the need to refinance their entire home loan. Borrowers should carefully consider their financial needs and objectives before applying for a top-up and seek advice from a mortgage broker or financial adviser if needed.</p>
      `,
    },
  ];

  return (
    <>
      <section className="service-details pt-120 pb-120">
        <div className="container">
          <div className="row row-gutter-y-30">
            <div className="col-lg-3">
              <div className="service-sidebar">
                <div className="service-sidebar__item service-sidebar__item--menu">
                  <ul className="service-sidebar__menu">
                    <li>
                      <Link to="/first-home-buyers-loan">
                        First Home Buyers Loan
                      </Link>
                    </li>
                    <li className="active">
                      <Link to="/owner-occupied-loans">
                        Owner Occupied Loans
                      </Link>
                    </li>
                    <li>
                      <Link to="/investment-loans">Investment Loans</Link>
                    </li>
                    <li>
                      <Link to="/refinance-loans">Refinance Loans</Link>
                    </li>
                    <li>
                      <Link to="/construction-loans">Construction Loans</Link>
                    </li>
                    <li>
                      <Link to="/guarantor-home-loans">Guarantor Loans</Link>
                    </li>
                    <li>
                      <Link to="/loan-for-professionals">
                        Loans for Professionals
                      </Link>
                    </li>
                    <li>
                      <Link to="/smsf-loans">SMSF Loans</Link>
                    </li>
                    <li>
                      <Link to="/low-doc-home-loans">Low-Doc Loans</Link>
                    </li>
                    <li>
                      <Link to="/asset-finance">Asset Finance</Link>
                    </li>
                    <li>
                      <Link to="/commercial-property-loans">
                        Commercial Loans
                      </Link>
                    </li>
                  </ul>
                </div>
                {/* <div className="service-sidebar__item service-sidebar__item--contact">
                  <div
                    className="service-sidebar__bg"
                    style={{ backgroundImage: `url(${BGS1})` }}></div>
                  <div className="service-sidebar__contact">
                    <div className="service-sidebar__icon">
                      <i className="icon-phone-ringing"></i>
                    </div>
                    <h3 className="service-sidebar__title">
                      Quick loan proccess
                    </h3>
                    <hr className="service-sidebar__separator" />
                    <p className="service-sidebar__tagline">
                      Talk to an expert
                    </p>
                    <Link
                      to="tel:+1-(246)333-0089"
                      className="service-sidebar__phone">
                      + 1- (246) 333-0089
                    </Link>
                  </div>
                </div> */}
              </div>
            </div>
            <div className="col-lg-9">
              <h3 className="service-details__title">Owner Occupied Loans</h3>

              <div className="service-details__image">
                <img src={BGS1} alt="" />
                <div className="service-details__icon">
                  <i className="icon-payment"></i>
                </div>
              </div>
              <div className="service-details__content">
                <br />
                {/* <h4 style={{ color: "#0983bc" }}>
                  <b>What is it?</b>
                </h4> */}
                <p>
                  As the term suggests, an Owner-Occupied Loan is a loan you
                  take to purchase a property you intend to reside in long term.
                  This means that you will be responsible for the mortgage and
                  will not be collecting rent or any form of profit from owning
                  the property.
                </p>
                <br />
                <div className="row">
                  <div className="col-md-12">
                    <h2 style={{ color: "#013148" }}>
                      <b>Where To Start?</b>
                    </h2>
                  </div>
                  <div className="col-md-12">
                    <br />
                    <ol style={{ color: "#0983bc" }}>
                      <li>
                        <h6 style={styles}>
                          <b style={{ color: "#0983bc" }}>
                            Application Process:
                          </b>
                        </h6>
                        <p style={styles}>
                          &nbsp;&nbsp;The borrower applies for a home loan from
                          a bank, credit union, or other financial institution.
                          The lender assesses the borrower's financial
                          situation, credit history, and the property being
                          purchased to determine eligibility and loan terms.
                        </p>
                      </li>
                      <br />
                      <li>
                        <h6 style={styles}>
                          <b style={{ color: "#0983bc" }}>Loan Approval</b>
                        </h6>
                        <p style={styles}>
                          &nbsp;&nbsp;If the borrower meets the lender's
                          criteria, the loan is approved, and the borrower
                          receives a loan offer outlining the terms and
                          conditions, including the loan amount, interest rate,
                          repayment schedule, and any fees or charges.
                        </p>
                      </li>
                      <br />
                      <li>
                        <h6 style={styles}>
                          <b style={{ color: "#0983bc" }}>Property Purchase:</b>
                        </h6>
                        <p style={styles}>
                          &nbsp;&nbsp;Once the loan is approved, the borrower
                          proceeds with purchasing the property. This may
                          involve engaging a conveyancer or solicitor to handle
                          the legal aspects of the transaction, such as
                          conducting property searches, preparing contracts, and
                          facilitating the settlement process.
                        </p>
                      </li>
                      <br />
                      <li>
                        <h6 style={styles}>
                          <b style={{ color: "#0983bc" }}>Loan Settlement:</b>
                        </h6>
                        <p style={styles}>
                          &nbsp;&nbsp;On the settlement date, the lender
                          provides the funds to complete the purchase of the
                          property. The borrower becomes the legal owner of the
                          property, and the lender registers a mortgage over the
                          property as security for the loan.
                        </p>
                      </li>
                      <br />
                      <li>
                        <h6 style={styles}>
                          <b style={{ color: "#0983bc" }}>Repayment:</b>
                        </h6>
                        <p style={styles}>
                          &nbsp;&nbsp;The borrower is required to make regular
                          repayments to the lender according to the agreed-upon
                          schedule. Repayments typically include both principal
                          and interest and can be made weekly, fortnightly, or
                          monthly, depending on the loan agreement.
                        </p>
                      </li>
                      <br />
                      <li>
                        <h6 style={styles}>
                          <b style={{ color: "#0983bc" }}>Interest Rate:</b>
                        </h6>
                        <p style={styles}>
                          &nbsp;&nbsp;The interest rate on an owner-occupied
                          loan may be fixed or variable. Fixed-rate loans have a
                          set interest rate for a specified period, providing
                          certainty of repayments, while variable-rate loans
                          have an interest rate that can fluctuate over time in
                          line with market conditions.
                        </p>
                      </li>
                      <br />
                      <li>
                        <h6 style={styles}>
                          <b style={{ color: "#0983bc" }}>Loan Features</b>
                        </h6>
                        <p style={styles}>
                          &nbsp;&nbsp;Owner-occupied loans may offer various
                          features and options, such as redraw facilities,
                          offset accounts, and the ability to make extra
                          repayments. These features can help borrowers manage
                          their loan more effectively and potentially save on
                          interest costs.
                        </p>
                      </li>
                      <br />
                      <li>
                        <h6 style={styles}>
                          <b style={{ color: "#0983bc" }}>
                            Government Incentives:
                          </b>
                        </h6>
                        <p style={styles}>
                          &nbsp;&nbsp;First home buyers may be eligible for
                          government incentives such as the First Home Owner
                          Grant (FHOG) or the First Home Loan Deposit Scheme
                          (FHLDS), which can assist with the purchase of their
                          first home.
                        </p>
                      </li>
                      <br />
                      <li>
                        <h6 style={styles}>
                          <b style={{ color: "#0983bc" }}>
                            Loan Repayment Term
                          </b>
                        </h6>
                        <p style={styles}>
                          &nbsp;&nbsp;Owner-occupied loans typically have a loan
                          term of 25 to 30 years, although shorter terms are
                          also available. Borrowers can choose to repay the loan
                          sooner by making additional repayments or refinancing
                          to a shorter-term loan.
                        </p>
                      </li>
                    </ol>
                  </div>
                  <div className="col-md-12">
                    <p>
                      Additionally, when it comes to choosing a home loan, there
                      are many factors to consider on top of interest rates.
                      Having the right facilities could save you financial
                      margins in the long run, so it's worth understanding
                      what's available in the market and the benefits offered by
                      different loan features.
                    </p>
                  </div>
                </div>
                <div className="row row-gutter-y-20">
                  <h6 style={{ color: "#00A8A8" }}>
                    <b>Understanding Loan Features</b>
                  </h6>
                  <div className="row">
                    <div className="col-md-12">
                      <div
                        className="accrodion-grp service-details__accrodion"
                        data-grp-name="service-details__accrodion-1">
                        {faqs.map((item, index) => (
                          <div
                            className={`accrodion  wow fadeInUp ${
                              index === clicked && "active"
                            }`}
                            key={index}
                            data-wow-delay="0ms">
                            <div
                              className="accrodion-title"
                              onClick={() =>
                                setClicked(index === clicked ? null : index)
                              }>
                              <h4>{item.question}</h4>
                              <span className="accrodion-icon"></span>
                            </div>
                            {index === clicked && (
                              <div className="accrodion-content">
                                <div className="inner">
                                  <p
                                    style={{
                                      paddingLeft: "20px",
                                      textAlign: "justify",
                                    }}
                                    dangerouslySetInnerHTML={{
                                      __html: item.answer,
                                    }}
                                  />
                                  {/* Additional content if needed */}
                                </div>
                              </div>
                            )}
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className="row row-gutter-y-30">
                                <div className="col-md-6">
                                    <img src="assets/images/services/service-d-1-2.png" alt="" />
                                </div>
                                <div className="col-md-6">
                                    <h3 className="service-details__subtitle">Service benefits</h3>
                                    <p className="service-details__text">Duis aute irure dolor in reprehenderit in voluptate velit
                                        esse cillum.</p>
                                    <ul className="list-unstyled ml-0 service-details__list">
                                        <li>
                                            <i className="fa fa-check-circle"></i>
                                            Refresing to get such a personal touch.
                                        </li>
                                        <li>
                                            <i className="fa fa-check-circle"></i>
                                            Duis aute irure dolor in in voluptate.
                                        </li>
                                        <li>
                                            <i className="fa fa-check-circle"></i>
                                            Velit esse cillum eu fugiat pariatur.
                                        </li>
                                        <li>
                                            <i className="fa fa-check-circle"></i>
                                            Duis aute irure dolor in in voluptate.
                                        </li>
                                    </ul>
                                </div>
                            </div> */}

                <div className="row row-gutter-y-0">
                  <div className="col-md-12 text-center">
                    <Link
                      to="/talk-to-a-broker"
                      className="thm-btn thm-btn-broker-btn rounded-pill">
                      <b>Engage our Broker today</b>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default OwnerOccupiedBody;
