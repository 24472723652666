import BGS1 from "../../../assets/loan-pics/Assets Loan pic.jpg";
import BGS from "../../../assets/new-images/SMSFLoansprocess.png";

import { Link } from "react-router-dom";
import React, { useState } from "react";

function AssetFinanceBody() {
  const [clicked, setClicked] = useState(null);
  const faqs = [
    {
      question: "How do I prove my income for a commercial property loan?",
      answer: `<p>There are a variety of ways you can prove your income for a commercial property loan, including:</p>
      <p>If you have at least 20% equity in your home, you'll have more to bargain with. Having a good credit score will also help with negotiations.</p>
      <p>Also, some lenders will only refinance with a new 25- or 30-year loan term. You could end up with a longer loan term than the years left to pay off your current mortgage.</p>
      <p>The longer you have a loan, the more you'll pay in interest. If you do decide to switch, negotiate a loan of a similar length to your current one.</p>
      `,
    },
    {
      question: "How much can you borrow? Loan to Value (LVR) ratio",
      answer: `<p>Lenders will generally only lend up to 80% of the value of your home. This is known as the loan-to-value ratio or LVR. If the property had to be sold to repay the loan, the other 20% helps cover the costs of selling and any reduction in the market value.</p>
      <li>Full Documentation. This is where you provide standard proof of income documentation as part of your application, such as tax returns, pay slips, or business financial statements prepared by your accountant.</li>
      <li>Lease Documentation. If you’re planning to lease your commercial property to a tenant, your lease documentation can be used to show that their lease payments will fully (or significantly) cover your future loan repayments.</li>
      <li>Low documentation. This can include your bank or BAS statements, business financial forecasts, and/or a letter from your accountant to verify your capacity to make loan repayments.</li>
      <li>No documentation. Some specialist lenders may be prepared to provide you with a commercial property loan with no documentation. But they will charge you higher interest rates and potentially additional fees to compensate for the increased risk.</li>
      <p>The more documentation you can provide to demonstrate that your commercial property loan will be low-risk for the lender, the more you will be able to negotiate the loan’s terms, conditions, fees, and interest rates.</p>
      `,
    },
    {
      question: "What are the typical features of a commercial property loan?",
      answer: `
      <p>The features of a commercial property loan depend on:</p>
      <li>Whether the interest rate is fixed or variable. Interest rates can generally be fixed for up to 5 years, but you can’t make additional repayments on fixed interest loans like you can with variable rate loans.</li>
      <li>Whether the repayments are for principal and interest, or interest-only. The standard commercial loan term is 15 years for a principal and interest loan, and 5 years for an interest-only loan. However, you can negotiate for longer periods (especially if you have residential property as security against the loan).</li>
      <li>The lender and the type of loan. You might be able to negotiate additional features. For example, a redraw facility (to enable you to withdraw any additional repayments you make later, if necessary), a line of credit (allowing you to borrow more funds up to a pre-set limit), or perhaps even an offset account to reduce your interest. But you will usually be charged either a higher interest rate or additional fees for these features.</li>
      `,
    },
    {
      question: "What is a general security arrangement?",
      answer: `<p>When you apply for a commercial property loan, lenders will sometimes require you to provide residential property as collateral security. This means that they may be able to repossess your home and sell it to recoup any outstanding debt, if you default on your repayments.</p>
        <p>In addition, lenders might also ask you to sign a general security agreement, which provides the commercial property and/or your business assets as additional security. However, this shouldn’t be necessary if your residential security is sufficient to cover the loan.</p>
      `,
    },
    {
      question:
        "Do I need to switch my business banking to get a commercial property loan?",
      answer: `<p>If you apply for a commercial property loan with a different lender to the one you do your business banking with, most will require you to switch all your business accounts to them as part of the approval process. It increases their business and allows them to have a more complete picture of your financial affairs.</p>
          <p>However, if switching banks is a hassle you’d like to avoid, you might be able to keep your business banking with your current bank, if you have residential property as collateral security and it covers your entire commercial property loan.</p>
        `,
    },
    {
      question: "The commercial property loan application process",
      answer: `<p>There are three important things you need to do:</p>
            <ol>
            <li>Apply to the right lender. Different lenders specialise in different types of commercial property finance. It’s important to choose a lender who has experience with the type of commercial property you’re investing in and has a good reputation. You’ll be more likely to have your loan application approved and have less hassle if you do this.</li>
            <li>Have a strong application. Provide as much evidence as you can to demonstrate you are a low-risk borrower and that your commercial property investment is attractive.</li>
            <li>Be prepared to negotiate with the lender. For larger commercial loans (e.g. over $1 million), your negotiating power increases if you have a strong application.</li>
            </ol>`,
    },
    {
      question:
        "What else should I consider when taking out a commercial property loan?",
      answer: `<p>Your commercial property should be in a high traffic area to attract plenty of customers for you or your tenant. It should also be accessible to public transport and surrounded by other complementary businesses. This will help your property to both retain its value over time as well as generate a relatively secure income.</p>
                `,
    },
    {
      question:
        "What are annual reviews of commercial property loans, and do they matter?",
      answer: `<p>Commercial property loans that lenders classify as higher risk or and/or that are for large amounts will sometimes be subject to an annual review. As part of this process, the lender will require you to provide your latest financial statements and business forecasts. They might also take the opportunity to re-value your commercial property if market conditions have changed.</p>
                      <p>This evaluation might place you in a higher risk category, enabling your lender to increase the loan’s interest rate or change other terms and conditions (for example, impose additional fees). It’s therefore important that you present this annual review information as comprehensively and accurately as possible so that your risk profile isn’t adversely affected.</p>
                      <p>But if your circumstances have improved, you can take the opportunity to potentially negotiate a lower risk profile to improve your loan’s terms and conditions.</p>
                    `,
    },
    {
      question: "How can a mortgage broker get you a better commercial loan?",
      answer: `<p>There are many more considerations when applying for a commercial property loan than there are for most other types of finance. You need to do your homework and research the market before making any decision. But because commercial loan terms and conditions are negotiable, lenders don’t make that information readily available to allow you to compare their offerings.</p>
                          <p>Mortgage brokers like us regularly deal with commercial property loans from a wide variety of lenders. Accordingly, we are well informed about the commercial property loan market, including the lending criteria of different institutions and how far they may be prepared to negotiate.</p>
                        <p>We can help you find the best commercial property loan for your needs by taking the time to understand your situation. This includes liaising with your accountant and/or solicitor so their advice is taken on board. We can then help you to choose the right lender, as well as prepare and submit your application. Finally, we can negotiate the best possible commercial property loan deal on your behalf.</p>
                          `,
    },
  ];

  return (
    <>
      <section className="service-details pt-120 pb-120">
        <div className="container">
          <div className="row row-gutter-y-30">
            <div className="col-lg-3">
              <div className="service-sidebar">
                <div className="service-sidebar__item service-sidebar__item--menu">
                  <ul className="service-sidebar__menu">
                    <li>
                      <Link to="/first-home-buyers-loan">
                        First Home Buyers Loan
                      </Link>
                    </li>
                    <li>
                      <Link to="/owner-occupied-loans">
                        Owner Occupied Loans
                      </Link>
                    </li>
                    <li>
                      <Link to="/investment-loans">Investment Loans</Link>
                    </li>
                    <li>
                      <Link to="/refinance-loans">Refinance Loans</Link>
                    </li>
                    <li>
                      <Link to="/construction-loans">Construction Loans</Link>
                    </li>
                    <li>
                      <Link to="/guarantor-home-loans">Guarantor Loans</Link>
                    </li>
                    <li>
                      <Link to="/loan-for-professionals">
                        Loans for Professionals
                      </Link>
                    </li>
                    <li>
                      <Link to="/smsf-loans">SMSF Loans</Link>
                    </li>
                    <li>
                      <Link to="/low-doc-home-loans">Low-Doc Loans</Link>
                    </li>
                    <li className="active">
                      <Link to="/asset-finance">Asset Finance</Link>
                    </li>
                    <li>
                      <Link to="/commercial-property-loans">
                        Commercial Loans
                      </Link>
                    </li>
                  </ul>
                </div>
                {/* <div className="service-sidebar__item service-sidebar__item--contact">
                  <div
                    className="service-sidebar__bg"
                    style={{ backgroundImage: `url(${BGS1})` }}></div>
                  <div className="service-sidebar__contact">
                    <div className="service-sidebar__icon">
                      <i className="icon-phone-ringing"></i>
                    </div>
                    <h3 className="service-sidebar__title">
                      Quick loan proccess
                    </h3>
                    <hr className="service-sidebar__separator" />
                    <p className="service-sidebar__tagline">
                      Talk to an expert
                    </p>
                    <Link
                      to="tel:+1-(246)333-0089"
                      className="service-sidebar__phone">
                      + 1- (246) 333-0089
                    </Link>
                  </div>
                </div> */}
              </div>
            </div>
            <div className="col-lg-9">
              <h3 className="service-details__title">Asset Finance</h3>
              <div className="service-details__image">
                <img src={BGS1} alt="" />
                <div className="service-details__icon">
                  <i className="icon-payment"></i>
                </div>
              </div>
              <div className="service-details__content">
                <h4 style={{ color: "#0983bc" }}>
                  <b>What is it?</b>
                </h4>
                <p>
                  Sometimes known as 'vehicle and equipment loan' Asset and
                  Equipment Finance is a type of credit that is used to purchase
                  a piece of equipment or asset for the purpose of own use (eg,
                  a car loan, purchase of a computer, and more).
                </p>
                <p>
                  While asset and equipment finance loans can be hire purchase
                  and lease agreements, they are usually secured loans such as
                  chattel mortgages. Unlike a mortgage, where the finance is
                  secured against the property, asset, and equipment finance is
                  secured against the asset/equipment that is being purchased.
                </p>
                <br />

                <h4 style={{ color: "#0983bc" }}>
                  <b>How do Chattel Mortgages Work?</b>
                </h4>
                <p>
                  Chattel mortgages differ from other types of asset finance in
                  several ways. Hire purchases involve renting a car or
                  equipment from a lender, and repaying in installments to
                  ultimately purchase the car or equipment.
                </p>
                <p>
                  With a financial lease, the financier owns the vehicle or
                  equipment. The borrower is then given the opportunity to buy
                  it for an additional price. In a chattel mortgage, once the
                  loan is completely paid off, the borrower owns the vehicle or
                  equipment outright.
                </p>
                <h4 style={{ color: "#0983bc" }}>
                  <b>Benefits of a Chattel Mortgage</b>
                </h4>
                <ul className="list-unstyled ml-0 service-details__list">
                  <li>
                    <i className="fa fa-check-circle"></i>
                    Interest rates are typically lower than an unsecured loan.
                  </li>
                  <li>
                    <i className="fa fa-check-circle"></i>
                    Interest rates are typically lower than an unsecured loan.
                  </li>
                  <li>
                    <i className="fa fa-check-circle"></i>
                    You (your business) can claim tax deductions.
                  </li>
                  <li>
                    <i className="fa fa-check-circle"></i>
                    Repayments can be structured over a range of terms.
                  </li>
                </ul>

                <div className="row">
                  <div className="col-md-12">
                    <div
                      className="accrodion-grp service-details__accrodion"
                      data-grp-name="service-details__accrodion-1">
                      {/* {faqs.map((item, index) => (
                        <div
                          className={`accrodion  wow fadeInUp ${
                            index === clicked && "active"
                          }`}
                          key={index}
                          data-wow-delay="0ms">
                          <div
                            className="accrodion-title"
                            onClick={() =>
                              setClicked(index === clicked ? null : index)
                            }>
                            <h4>{item.question}</h4>
                            <span className="accrodion-icon"></span>
                          </div>
                          {index === clicked && (
                            <div className="accrodion-content">
                              <div className="inner">
                                <p
                                  style={{
                                    paddingLeft: "20px",
                                    textAlign: "justify",
                                  }}
                                  dangerouslySetInnerHTML={{
                                    __html: item.answer,
                                  }}
                                />
                              </div>
                            </div>
                          )}
                        </div>
                      ))} */}
                    </div>
                  </div>
                </div>
                {/* <div className="row row-gutter-y-30">
                                <div className="col-md-6">
                                    <img src="assets/images/services/service-d-1-2.png" alt="" />
                                </div>
                                <div className="col-md-6">
                                    <h3 className="service-details__subtitle">Service benefits</h3>
                                    <p className="service-details__text">Duis aute irure dolor in reprehenderit in voluptate velit
                                        esse cillum.</p>
                                    <ul className="list-unstyled ml-0 service-details__list">
                                        <li>
                                            <i className="fa fa-check-circle"></i>
                                            Refresing to get such a personal touch.
                                        </li>
                                        <li>
                                            <i className="fa fa-check-circle"></i>
                                            Duis aute irure dolor in in voluptate.
                                        </li>
                                        <li>
                                            <i className="fa fa-check-circle"></i>
                                            Velit esse cillum eu fugiat pariatur.
                                        </li>
                                        <li>
                                            <i className="fa fa-check-circle"></i>
                                            Duis aute irure dolor in in voluptate.
                                        </li>
                                    </ul>
                                </div>
                            </div> */}

                {/* <div className="row row-gutter-y-20">
                  <div className="col-md-12 text-center">
                    <Link to="#">Engage our Broker today</Link>
                  </div>
                </div> */}
                <div className="row row-gutter-y-0">
                  <div className="col-md-12 text-center">
                    <Link
                      to="/talk-to-a-broker"
                      className="thm-btn thm-btn-broker-btn rounded-pill">
                      <b>Engage our Broker today</b>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default AssetFinanceBody;
