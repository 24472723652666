import React from "react";
import Bredcom from "../../Bredcom/Main";
import Clint from "../Menteamdeatils/Clint";
import SakibManzoor from "../Menteamdeatils/SakibManzoor";

const SakibDetails = () => {
  return (
    <>
      <Bredcom title={"Home"} subtitle={"TEAM"} link={"Sakib Manzoor "} />
      <SakibManzoor />
      {/* <Clint /> */}
    </>
  );
};

export default SakibDetails;
