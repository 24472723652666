import React from "react";
import Bredcom from "../Bredcom/Main";
import BG from "../../assets/loan-pics/loans.jpg";
import HomeBargainSuburbsDetails from "../News/Mennewsdeatils/HomeBargainSuburbsDetails";

const HomeBargainSuburbResourses = () => {
  return (
    <>
      <Bredcom
        title={"Home"}
        subtitle={"Resourses"}
        link={"Home Bargain Suburbs"}
      />
      <HomeBargainSuburbsDetails />
    </>
  );
};

export default HomeBargainSuburbResourses;
