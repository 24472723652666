import React from "react";
import Bredcom from "../Bredcom/Main";
import LoanForProfessionalsBody from "../Services/Menservisedeatils/LoanForProfessionalsBody";
import BG from "../../assets/images/Banners/professional-loans.jpg";

const LoanForProfessionals = () => {
  return (
    <>
      <Bredcom
        title={"Home"}
        subtitle={"Home Loans"}
        link={"Loan For Professionals"}
        img={BG}
      />
      <LoanForProfessionalsBody />
    </>
  );
};

export default LoanForProfessionals;
