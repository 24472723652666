import React from "react";
import { Helmet } from "react-helmet";
import "../../../assets/css/calc.css";

function PropertyBuyingCostCalculator() {
  return (
    <>
      <Helmet>
        <title>Property Buying Cost Calculator - Secure Finance</title>
        <meta
          name="description"
          content="Use our Property Buying Cost Calculator to estimate the total costs of purchasing a property, including government charges, lender fees, and other buying costs. Input your details to view your estimated total property buying cost."
        />
        <link
          rel="canonical"
          href="https://securefinance.com.au/property-buying-cost-calculator"
        />
      </Helmet>
      <section className="benefit-one pt-60 pb-60">
        <div className="container">
          <div className="row row-gutter-y-60">
            <iframe
              className="VisiCalcClass"
              id="Property_Buying_Cost_Calculator"
              src="//www.visionabacus.net/Tools/B3/SuiteA/A400/Property_Buying_Cost_Calculator/SecureFinance"
              frameBorder="0"
              scrolling="no"
              title="Property Buying Cost Calculator"></iframe>
          </div>
        </div>
      </section>
    </>
  );
}

export default PropertyBuyingCostCalculator;
