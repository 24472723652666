import React from "react";
import { Link } from "react-router-dom";
import BGI from "../../../assets/calculations/calculator_1.png";
import BGI1 from "../../../assets/calculations/calculator_2.png";
import BGI2 from "../../../assets/calculations/calculator_3.png";
import BGI3 from "../../../assets/calculations/calculator_4.png";
import BGI4 from "../../../assets/calculations/calculator_5.png";
import BGI5 from "../../../assets/calculations/calculator_6.png";
import BGI6 from "../../../assets/calculations/calculator_7.png";
import BGI7 from "../../../assets/calculations/calculator_8.png";
import BGI8 from "../../../assets/calculations/calculator_9.png";
import BGI9 from "../../../assets/calculations/calculator_10.png";
import BGI10 from "../../../assets/calculations/calculator_11.png";
import BGI11 from "../../../assets/calculations/calculator_12.png";
import BGI12 from "../../../assets/calculations/calculator_13.png";
import BGI13 from "../../../assets/calculations/calculator_14.png";
import BGI14 from "../../../assets/calculations/calculator_15.png";
import BGI15 from "../../../assets/calculations/calculator_16.png";
import BGI16 from "../../../assets/calculations/calculator_17.png";
import BGI17 from "../../../assets/calculations/calculator_18.png";
import BGI18 from "../../../assets/calculations/calculator_19.png";
import BGI19 from "../../../assets/calculations/calculator_20.png";
import BGI20 from "../../../assets/calculations/calculator_21.png";
import BGI21 from "../../../assets/calculations/calculator_22.png";
import BGI22 from "../../../assets/calculations/calculator_23.png";
import BGI23 from "../../../assets/calculations/calculator_24.png";
import BGI24 from "../../../assets/calculations/calculator_25.png";
import BGI25 from "../../../assets/calculations/calculator_26.png";
import BGI26 from "../../../assets/calculations/calculator_27.png";
import BGI27 from "../../../assets/calculations/calculator_28.png";
import BGI28 from "../../../assets/calculations/calculator_29.png";
import BGI29 from "../../../assets/calculations/calculator_30.png";
import BGI30 from "../../../assets/calculations/calculator_31.png";
import BGI31 from "../../../assets/calculations/calculator_32.png";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import Bredcom from "../../Bredcom/Main";
import { Helmet } from "react-helmet";

const AllCalcCardsData = [
  {
    image: BGI,

    title: "Loan Repayment Calculator",

    Link: "/repayment-calculator",
  },
  {
    image: BGI1,

    title: "Borrowing Capacity Calculator",

    Link: "/borrowing-capacity-calculator",
  },
  {
    image: BGI2,

    title: "Interest Only Mortgage Calculator",

    Link: "/interest-only-mortgage-calculator",
  },
  {
    image: BGI3,

    title: "Stamp Duty Calculator",

    Link: "/stamp-duty-calculator",
  },
  {
    image: BGI4,

    title: "Income Tax Calculator",

    Link: "/income-tax-calculator",
  },
  {
    image: BGI5,

    title: "Saving Calculator",

    Link: "/saving-calculator",
  },
  {
    image: BGI6,

    title: "Comparison Rate Calculator",

    Link: "/comparison-rate-calculator",
  },

  {
    image: BGI7,

    title: "Lump Sum Repayment Calculator",

    Link: "/lump-sum-repayment-calculator",
  },
  {
    image: BGI8,

    title: "Extra Repayment Calculator",

    Link: "/extra-repayment-calculator",
  },
  {
    image: BGI9,

    title: "How Long To Repay Calculator",

    Link: "/how-long-to-repay-calculator",
  },
  {
    image: BGI10,

    title: "Split Loan Calculator",

    Link: "/split-loan-calculator",
  },
  {
    image: BGI11,

    title: "Home Loan Offset Calculator",

    Link: "/home-loan-offset-calculator",
  },
  {
    image: BGI12,

    title: "Introductory Rate Loan Calculator",

    Link: "/introductory-rate-loan-calculator",
  },
  {
    image: BGI13,

    title: "Loan Comparison Calculator",

    Link: "/loan-comparison-calculator",
  },
  {
    image: BGI14,

    title: "Leasing Calculator",

    Link: "/leasing-calculator",
  },
  {
    image: BGI15,

    title: "Property Buying Cost Calculator",

    Link: "/property-buying-cost-calculator",
  },
  {
    image: BGI16,

    title: "Property Selling Cost Calculator",

    Link: "/property-selling-cost-calculator",
  },
  {
    image: BGI17,

    title: "Compound Interest Calculator",

    Link: "/compound-interest-calculator",
  },
  {
    image: BGI18,

    title: "Budget Planner Calculator",

    Link: "/budget-planner-calculator",
  },
  {
    image: BGI19,

    title: "Credit Card Calculator",

    Link: "/credit-card-calculator",
  },
  {
    image: BGI20,

    title: "Reverse Mortgage Calculator",

    Link: "/reverse-mortgage-calculator",
  },
  {
    image: BGI21,

    title: "Income Annualisation Calculator",

    Link: "/income-annualisation-calculator",
  },
  {
    image: BGI22,

    title: "Income GrossUp Calculator",

    Link: "/income-grossUp-calculator",
  },
  {
    image: BGI23,

    title: "How Long To Save Calculator",

    Link: "/how-long-to-save-calculator",
  },
  {
    image: BGI24,

    title: "How Mucch To Deposit Calculator",

    Link: "/how-mucch-to-deposit-calculator",
  },
  {
    image: BGI25,

    title: "Fortnightly Repayment Calculator",

    Link: "/fortnightly-repayment-calculator",
  },
  {
    image: BGI26,

    title: "Mortgage Switching Calculator",

    Link: "/mortgage-switching-calculator",
  },
  {
    image: BGI27,

    title: "Rent VS Buy Calculator",

    Link: "/rent-vs-buy-calculator",
  },
  {
    image: BGI28,

    title: "Borrowing Capacity Calculator-2",

    Link: "/borrowing-capacity-calculator-2",
  },
  {
    image: BGI29,

    title: "Loan Repayment Monthly Calculator",

    Link: "/loan-repayment-monthly-calculator",
  },
  {
    image: BGI30,

    title: "Loan Repayment Calculator",

    Link: "/repayment-calculator",
  },
  {
    image: BGI31,

    title: "Leasing Car Calculator",

    Link: "/leasing-car-calculator",
  },
];

function AllCalculators() {
  return (
    <>
      <Helmet>
        <title>All Calculators - Secure Finance</title>
        <meta
          name="description"
          content="Find all the calculators you need for loan repayment, 
          borrowing capacity, interest-only mortgage, and more."
        />
        <link
          rel="canonical"
          href="https://securefinance.com.au/all-Calculators"
        />
      </Helmet>
      <Bredcom title={"Calculators"} link={"All Calculators"} />
      <section className=" pt-40 pb-40">
        <Container>
          <Row className="m-auto p-auto">
            {AllCalcCardsData.map((card, index) => (
              <Col key={index} lg={3} md={3} sm={12} xs={12} xl={3}>
                <Card className="text-center my-5 mx-2">
                  <Card.Body>
                    <Card.Title>
                      <div className="service-card__icon blue">
                        <i
                          key={index}
                          className="image-icon"
                          style={{
                            backgroundImage: `url(${card.image})`,
                          }}
                        ></i>
                      </div>
                    </Card.Title>
                  </Card.Body>
                  <Card.Footer className="p-0 m-0">
                    <Card.Text style={{ height: "20px" }}>
                      <h3 className="service-card__title">
                        <Link to={card.Link}>{card.title}</Link>
                      </h3>
                    </Card.Text>
                    <Link to={card.Link} style={{ height: "20px" }}>
                      <Button className="mt-5" variant="primary">
                        Calculate
                      </Button>
                    </Link>
                  </Card.Footer>
                </Card>
              </Col>
            ))}
          </Row>
        </Container>
      </section>
    </>
  );
}

export default AllCalculators;
