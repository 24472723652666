import React from "react";

function AboutUS() {
  return (
    <>
      <section className="about-two pt-40">
        <div className="container">
          <div className="row">
            {/* <div className="col-lg-12">
              <div className="about-two__image">
                <img src="assets/images/resources/about-2-1.png" alt="" />
              </div>
            </div> */}
            <div className="col-lg-12">
              <div className="about-two__content">
                <div className="block-title text-left">
                  <p className="block-title__tagline text-center">
                    WHO ARE WE?
                  </p>
                  {/* <h2 className="block-title__title">
                    Get to know about our company
                  </h2> */}
                </div>
                <p className="about-two__text">
                  Secure Finance Services was founded by Sakib Manzoor in 2014
                  and since its inception, our business has experienced
                  significant growth year-on-year.
                  <p>
                    From $20M in home loan settlements in the first year, we
                    have now passed the $1B mark in settlements.
                  </p>
                  <p>
                    Such phenomenal growth necessitated us to continually grow
                    our workforce and from a one-man army we are now a family of
                    12 strong, providing personalized and consistent home loan
                    services and consultation to our clients.
                  </p>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default AboutUS;
