import BGS1 from "../../../assets/resourses/mortgage-blog-image.jpg";

import { Link } from "react-router-dom";
import React, { useState } from "react";

function RefinanceLoansBody() {
  const styles = {
    display: "inline",
    margin: 0,
    color: "#000",
    fontFamily: "Montserrat",
  };
  const [clicked, setClicked] = useState(null);
  const faqs = [
    {
      question: "Ask your current lender for a better deal",
      answer: `<p>Tell your current lender you are planning to switch to a cheaper loan offered by a different lender. To keep your business, your lender may reduce the interest rate on your current loan.</p>
      <p>You will have more bargaining power if:</p>
      <li>You have at least 20% equity in your current loan</li>
      <li>Have a good credit score</li>
      <p>Also, some lenders only offer loans with a new 25- or 30-year loan term. Hence, refinancing could potentially increase your loan term taking you a longer time to complete the ownership.</p>
      `,
    },
    {
      question: "How much can you borrow?",
      answer: `<p>Lenders will generally lend up to 80% of the current value of your home (loan-to-value ratio or LVR).</p>
      <p>You can find out your LVR by adding your total secured loans, dividing by current property value and multiplying by 100.</p>
      <h5 style="color: #0983bc"><b>Example</b></h5>
      <p>Current home loan: $500,000<br/>
      Property Currently Valued: $625,000<br/>
      LVR: 80%<br/>
      <br/>
      If your LVR is 80% or higher, you may need to pay Lenders Mortgage Insurance (LMI) Mortgage insurance which protects the lender if the loan isn’t repaid. You may also be subjected to a higher interest rate.      
      </p>
      `,
    },
    {
      question: "Compare the Fees and Charges",
      answer: `
      <p>Get at least two different quotes on home loans for your situation.</p>

      Your choice of loan and repayment type will determine the average interest rate for new home loans. As the Reserve Bank of Australia (RBA) has announced an increase in interest rates (December 2023), so the average rate may now be higher.

      <p style="color: #0983bc"><b>Fees to Compare:</b></p>
      <li>Fixed interest rate loan: Compare the amount of interest you will be paying.</li>
      <li>Discharge (or termination) Fee: This is a fee charged to close your current loan when you take on a new one.</li>
      <li>Application fee: This is charged when you apply for a new loan.</li>
      <li>Switching fee: This is charged when refinancing internally (same lender, different loan product).</li>
      <li>Stamp duty: Check with your Broker or Lender if you will be liable for this fee if you choose to refinance.</li>
      <p>You may also use our Calculators to get an estimate of the new fees.</p>
      `,
    },
    {
      question: "Check if you'll actually be saving by switching",
      answer: `
      <p>There are 3 main ways to go about this:</p>
<ol>
<li style="color: #0983bc"><b>Speak to a Lender directly</b></li>
</br>
<p>When checking with a lender, be sure they have a valid Australian Credit License.</p>

<p>Despite getting the information from the source, there are also a few restrictions when it comes to liaising with a lender directly. It will take time to deal with each lender, you will be missing out on other (potentially better) options from other lenders and you may not understand the terms different lenders use.</p>
<li style="color: #0983bc"><b>Use Comparison Sites</b></li>
<br/>
<p>You can narrow your search via sections or keywords relating to your goal for refinancing. They normally have a range of product features in tables ordered by lenders, to easily compare similar features and fees.</p>

<p>Most info on comparison websites comes directly from the lenders. So, it’s worth reading up on how they get paid.</p>
<p>Similar to speaking directly with a lender, you will then be the judge of the type of loan you’d find beneficial to you.</p>

<p>However similarly, you may be missing out of greater deals with better features.</p>

<li style="color: #0983bc"><b>Speak to a Broker, Speak to Us</b></li>
<br/>
<p>One of the significant key benefits when speaking to a broker is that we will complete your application and deal with the lenders on your behalf.</p>

<p>Brokers are paid by the lenders via commissions, based on loan amounts and your home loan term.</p>

<p>This serves as a convenience and a significant reduction of risk as the broker will do a lender comparison for you. A broker may identify lenders you may not have considered.</p>

<p>The biggest incentive for using a Broker for many would be that a Broker understands bank jargon and translates it for you and knows the information lenders need and the know-how to complete the application forms.</p>
<br/>
<p>Get started today!</p>
</ol>
      `,
    },
  ];

  return (
    <>
      <section className="service-details pt-120 pb-120">
        <div className="container">
          <div className="row row-gutter-y-30">
            <div className="col-lg-3">
              <div className="service-sidebar">
                <div className="service-sidebar__item service-sidebar__item--menu">
                  <ul className="service-sidebar__menu">
                    <li>
                      <Link to="/first-home-buyers-loan">
                        First Home Buyers Loan
                      </Link>
                    </li>
                    <li>
                      <Link to="/owner-occupied-loans">
                        Owner Occupied Loans
                      </Link>
                    </li>
                    <li>
                      <Link to="/investment-loans">Investment Loans</Link>
                    </li>
                    <li className="active">
                      <Link to="/refinance-loans">Refinance Loans</Link>
                    </li>
                    <li>
                      <Link to="/construction-loans">Construction Loans</Link>
                    </li>
                    <li>
                      <Link to="/guarantor-home-loans">Guarantor Loans</Link>
                    </li>
                    <li>
                      <Link to="/loan-for-professionals">
                        Loans for Professionals
                      </Link>
                    </li>
                    <li>
                      <Link to="/smsf-loans">SMSF Loans</Link>
                    </li>
                    <li>
                      <Link to="/low-doc-home-loans">Low-Doc Loans</Link>
                    </li>
                    <li>
                      <Link to="/asset-finance">Asset Finance</Link>
                    </li>
                    <li>
                      <Link to="/commercial-property-loans">
                        Commercial Loans
                      </Link>
                    </li>
                  </ul>
                </div>
                {/* <div className="service-sidebar__item service-sidebar__item--contact">
                  <div
                    className="service-sidebar__bg"
                    style={{ backgroundImage: `url(${BGS1})` }}></div>
                  <div className="service-sidebar__contact">
                    <div className="service-sidebar__icon">
                      <i className="icon-phone-ringing"></i>
                    </div>
                    <h3 className="service-sidebar__title">
                      Quick loan proccess
                    </h3>
                    <hr className="service-sidebar__separator" />
                    <p className="service-sidebar__tagline">
                      Talk to an expert
                    </p>
                    <Link
                      to="tel:+1-(246)333-0089"
                      className="service-sidebar__phone">
                      + 1- (246) 333-0089
                    </Link>
                  </div>
                </div> */}
              </div>
            </div>
            <div className="col-lg-9">
              <h3 className="service-details__title">Refinance Loan</h3>

              <div className="service-details__image">
                <img src={BGS1} alt="" />
                <div className="service-details__icon">
                  <i className="icon-payment"></i>
                </div>
              </div>
              <div className="service-details__content">
                <br />

                <p>
                  Refinancing your home loan means moving your home loan balance
                  (the outstanding of your loan) from one lender to another.
                </p>
                <p>
                  It is important to review your home loan frequently. This
                  helps you identify opportunities to save on interest or get
                  better value out of a new loan. This is a complimentary
                  benefit when you engage our Brokers. Secure Finance
                  strengthens our client's financial standing through actively
                  reviewing the secured loans every year.
                </p>
                <h4 style={{ color: "#0983bc" }}>
                  <b>How does it work?</b>
                </h4>
                <div className="row">
                  <div className="col-md-12">
                    <h2 style={{ color: "#013148" }}>
                      <b>Why Refinance Your Loan?</b>
                    </h2>
                  </div>
                  <div className="col-md-12">
                    <br />
                    <ol style={{ color: "#0983bc" }}>
                      <li>
                        <h6 style={styles}>
                          <b style={{ color: "#0983bc" }}>
                            Lower Interest Rate:
                          </b>
                        </h6>
                        <p style={styles}>
                          &nbsp;&nbsp;One of the most common reasons for
                          refinancing is to secure a lower interest rate. If
                          market interest rates have decreased since you
                          initially took out your mortgage or if your credit
                          score has improved, you may be eligible for a lower
                          rate, which could result in significant savings over
                          the life of the loan.
                        </p>
                      </li>
                      <br />
                      <li>
                        <h6 style={styles}>
                          <b style={{ color: "#0983bc" }}>
                            Change in Loan Term:
                          </b>
                        </h6>
                        <p style={styles}>
                          &nbsp;&nbsp;Refinancing also allows you to change the
                          term of your loan. For example, you may choose to
                          switch from a 30-year mortgage to a 15-year mortgage
                          to pay off your loan faster and save on interest
                          payments. Alternatively, you could extend the loan
                          term to reduce your monthly payments.
                        </p>
                      </li>
                      <br />
                      <li>
                        <h6 style={styles}>
                          <b style={{ color: "#0983bc" }}>Access Equity:</b>
                        </h6>
                        <p style={styles}>
                          &nbsp;&nbsp;If your home has increased in value since
                          you purchased it or you've paid down a significant
                          portion of your mortgage, you may have built up equity
                          in the property. Refinancing can allow you to access
                          this equity by borrowing against it, either through a
                          cash-out refinance or a home equity loan or line of
                          credit.
                        </p>
                      </li>
                      <br />
                      <li>
                        <h6 style={styles}>
                          <b style={{ color: "#0983bc" }}>Consolidate Debt:</b>
                        </h6>
                        <p style={styles}>
                          &nbsp;&nbsp;Refinancing can also be used to
                          consolidate high-interest debt, such as credit card
                          debt or personal loans, into your mortgage. By rolling
                          these debts into your mortgage, you may be able to
                          secure a lower overall interest rate and simplify your
                          finances by having one monthly payment.
                        </p>
                      </li>
                      <br />
                      <li>
                        <h6 style={styles}>
                          <b style={{ color: "#0983bc" }}>Switch Loan Type:</b>
                        </h6>
                        <p style={styles}>
                          &nbsp;&nbsp;Another reason to refinance is to switch
                          from an adjustable-rate mortgage (ARM) to a fixed-rate
                          mortgage or vice versa. This can provide more
                          stability if you're concerned about fluctuating
                          interest rates or allow you to take advantage of lower
                          initial rates offered by ARMs.
                        </p>
                      </li>
                      <br />
                      <li>
                        <h6 style={styles}>
                          <b style={{ color: "#0983bc" }}>
                            Remove or Add a Co-Borrower:
                          </b>
                        </h6>
                        <p style={styles}>
                          &nbsp;&nbsp;Refinancing also provides an opportunity
                          to remove or add a co-borrower from the loan. For
                          example, if you initially purchased the property with
                          a partner but have since separated, you may want to
                          refinance to remove their name from the mortgage.
                        </p>
                      </li>
                      <br />
                      <li>
                        <h6 style={styles}>
                          <b style={{ color: "#0983bc" }}>Review Loan Terms:</b>
                        </h6>
                        <p style={styles}>
                          &nbsp;&nbsp;Refinancing gives you the chance to review
                          and update the terms of your loan, such as prepayment
                          penalties, closing costs, and repayment options. You
                          can choose a loan that better aligns with your current
                          financial situation and goals.
                        </p>
                      </li>
                      <br />
                    </ol>
                  </div>
                  <div className="col-md-12">
                    <p>
                      It's important to carefully consider the costs and
                      benefits of refinancing before proceeding, including any
                      application fees, closing costs, and potential impacts on
                      your credit score. Additionally, it's advisable to shop
                      around and compare offers from multiple lenders to ensure
                      you're getting the best deal possible. Consulting with a
                      financial advisor or mortgage broker can also help you
                      make an informed decision about whether refinancing is
                      right for you.
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <h2 style={{ color: "#013148" }}>
                      <b>What Are The Benefits?</b>
                    </h2>
                  </div>
                  <div className="col-md-12">
                    <br />
                    <ol style={{ color: "#0983bc" }}>
                      <li>
                        <h6 style={styles}>
                          <b style={{ color: "#0983bc" }}>
                            Lower Interest Rate:
                          </b>
                        </h6>
                        <p style={styles}>
                          &nbsp;&nbsp;One of the most common reasons for
                          refinancing is to secure a lower interest rate. If
                          market interest rates have decreased since you
                          initially took out your mortgage or if your credit
                          score has improved, you may be eligible for a lower
                          rate, which could result in significant savings over
                          the life of the loan.
                        </p>
                      </li>
                      <br />
                      <li>
                        <h6 style={styles}>
                          <b style={{ color: "#0983bc" }}>
                            Change in Loan Term:
                          </b>
                        </h6>
                        <p style={styles}>
                          &nbsp;&nbsp;Refinancing also allows you to change the
                          term of your loan. For example, you may choose to
                          switch from a 30-year mortgage to a 15-year mortgage
                          to pay off your loan faster and save on interest
                          payments. Alternatively, you could extend the loan
                          term to reduce your monthly payments.
                        </p>
                      </li>
                      <br />
                      <li>
                        <h6 style={styles}>
                          <b style={{ color: "#0983bc" }}>Access Equity:</b>
                        </h6>
                        <p style={styles}>
                          &nbsp;&nbsp;If your home has increased in value since
                          you purchased it or you've paid down a significant
                          portion of your mortgage, you may have built up equity
                          in the property. Refinancing can allow you to access
                          this equity by borrowing against it, either through a
                          cash-out refinance or a home equity loan or line of
                          credit.
                        </p>
                      </li>
                      <br />
                      <li>
                        <h6 style={styles}>
                          <b style={{ color: "#0983bc" }}>Consolidate Debt:</b>
                        </h6>
                        <p style={styles}>
                          &nbsp;&nbsp;Refinancing can also be used to
                          consolidate high-interest debt, such as credit card
                          debt or personal loans, into your mortgage. By rolling
                          these debts into your mortgage, you may be able to
                          secure a lower overall interest rate and simplify your
                          finances by having one monthly payment.
                        </p>
                      </li>
                      <br />
                      <li>
                        <h6 style={styles}>
                          <b style={{ color: "#0983bc" }}>Switch Loan Type:</b>
                        </h6>
                        <p style={styles}>
                          &nbsp;&nbsp;Another reason to refinance is to switch
                          from an adjustable-rate mortgage (ARM) to a fixed-rate
                          mortgage or vice versa. This can provide more
                          stability if you're concerned about fluctuating
                          interest rates or allow you to take advantage of lower
                          initial rates offered by ARMs.
                        </p>
                      </li>
                      <br />
                      <li>
                        <h6 style={styles}>
                          <b style={{ color: "#0983bc" }}>
                            Remove or Add a Co-Borrower:
                          </b>
                        </h6>
                        <p style={styles}>
                          &nbsp;&nbsp;Refinancing also provides an opportunity
                          to remove or add a co-borrower from the loan. For
                          example, if you initially purchased the property with
                          a partner but have since separated, you may want to
                          refinance to remove their name from the mortgage.
                        </p>
                      </li>
                      <br />
                      <li>
                        <h6 style={styles}>
                          <b style={{ color: "#0983bc" }}>Review Loan Terms:</b>
                        </h6>
                        <p style={styles}>
                          &nbsp;&nbsp;Refinancing gives you the chance to review
                          and update the terms of your loan, such as prepayment
                          penalties, closing costs, and repayment options. You
                          can choose a loan that better aligns with your current
                          financial situation and goals.
                        </p>
                      </li>
                      <br />
                    </ol>
                  </div>
                </div>
                {/* <div className="row row-gutter-y-30">
                                <div className="col-md-6">
                                    <img src="assets/images/services/service-d-1-2.png" alt="" />
                                </div>
                                <div className="col-md-6">
                                    <h3 className="service-details__subtitle">Service benefits</h3>
                                    <p className="service-details__text">Duis aute irure dolor in reprehenderit in voluptate velit
                                        esse cillum.</p>
                                    <ul className="list-unstyled ml-0 service-details__list">
                                        <li>
                                            <i className="fa fa-check-circle"></i>
                                            Refresing to get such a personal touch.
                                        </li>
                                        <li>
                                            <i className="fa fa-check-circle"></i>
                                            Duis aute irure dolor in in voluptate.
                                        </li>
                                        <li>
                                            <i className="fa fa-check-circle"></i>
                                            Velit esse cillum eu fugiat pariatur.
                                        </li>
                                        <li>
                                            <i className="fa fa-check-circle"></i>
                                            Duis aute irure dolor in in voluptate.
                                        </li>
                                    </ul>
                                </div>
                            </div> */}

                <div className="row row-gutter-y-0">
                  <div className="col-md-12 text-center">
                    <Link
                      to="/talk-to-a-broker"
                      className="thm-btn thm-btn-broker-btn rounded-pill">
                      <b>Engage our Broker today</b>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default RefinanceLoansBody;
