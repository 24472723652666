import BGS1 from "../../../assets/loan-pics/Investment pic.png";
import BMG from "../../../assets/new-images/Picture1.png";

import { Link } from "react-router-dom";
import React, { useState } from "react";

function InvestmentLoansBody() {
  const styles = {
    display: "inline",
    margin: 0,
    color: "#000",
    fontFamily: "Montserrat",
  };
  return (
    <>
      <section className="service-details pt-120 pb-120">
        <div className="container">
          <div className="row row-gutter-y-30">
            <div className="col-lg-3">
              <div className="service-sidebar">
                <div className="service-sidebar__item service-sidebar__item--menu">
                  <ul className="service-sidebar__menu">
                    <li>
                      <Link to="/first-home-buyers-loan">
                        First Home Buyers Loan
                      </Link>
                    </li>
                    <li>
                      <Link to="/owner-occupied-loans">
                        Owner Occupied Loans
                      </Link>
                    </li>
                    <li className="active">
                      <Link to="/investment-loans">Investment Loans</Link>
                    </li>
                    <li>
                      <Link to="/refinance-loans">Refinance Loans</Link>
                    </li>
                    <li>
                      <Link to="/construction-loans">Construction Loans</Link>
                    </li>
                    <li>
                      <Link to="/guarantor-home-loans">Guarantor Loans</Link>
                    </li>
                    <li>
                      <Link to="/loan-for-professionals">
                        Loans for Professionals
                      </Link>
                    </li>
                    <li>
                      <Link to="/smsf-loans">SMSF Loans</Link>
                    </li>
                    <li>
                      <Link to="/low-doc-home-loans">Low-Doc Loans</Link>
                    </li>
                    <li>
                      <Link to="/asset-finance">Asset Finance</Link>
                    </li>
                    <li>
                      <Link to="/commercial-property-loans">
                        Commercial Loans
                      </Link>
                    </li>
                  </ul>
                </div>
                {/* <div className="service-sidebar__item service-sidebar__item--contact">
                  <div
                    className="service-sidebar__bg"
                    style={{ backgroundImage: `url(${BGS1})` }}></div>
                  <div className="service-sidebar__contact">
                    <div className="service-sidebar__icon">
                      <i className="icon-phone-ringing"></i>
                    </div>
                    <h3 className="service-sidebar__title">
                      Quick loan proccess
                    </h3>
                    <hr className="service-sidebar__separator" />
                    <p className="service-sidebar__tagline">
                      Talk to an expert
                    </p>
                    <Link
                      to="tel:+1-(246)333-0089"
                      className="service-sidebar__phone">
                      + 1- (246) 333-0089
                    </Link>
                  </div>
                </div> */}
              </div>
            </div>
            <div className="col-lg-9">
              <h3 className="service-details__title">Investment Loans</h3>

              <div className="service-details__image">
                <img src={BGS1} alt="" />
                <div className="service-details__icon">
                  <i className="icon-payment"></i>
                </div>
              </div>
              <div className="service-details__content">
                <p>
                  An investment interest rate applies to all home loans that are
                  secured to purchase an investment property. Investment
                  interest rates are typically higher than those of the
                  owner-occupied home loans. This is because investors are
                  considered higher risk than borrowers who plan to live in
                  their property.
                </p>

                <div className="row">
                  <div className="col-md-12">
                    <h2 style={{ color: "#013148" }}>
                      <b>Where To Start?</b>
                    </h2>
                  </div>
                  <div className="col-md-12">
                    <br />
                    <ol style={{ color: "#0983bc" }}>
                      <li>
                        <h6 style={styles}>
                          <b style={{ color: "#0983bc" }}>
                            Application Process:
                          </b>
                        </h6>
                        <p style={styles}>
                          &nbsp;&nbsp;The borrower applies for an investment
                          property loan from a bank, credit union, or other
                          financial institution. The lender assesses the
                          borrower's financial situation, credit history, and
                          the property being purchased to determine eligibility
                          and loan terms.
                        </p>
                      </li>
                      <br />
                      <li>
                        <h6 style={styles}>
                          <b style={{ color: "#0983bc" }}>Loan Approval</b>
                        </h6>
                        <p style={styles}>
                          &nbsp;&nbsp;If the borrower meets the lender's
                          criteria, the loan is approved, and the borrower
                          receives a loan offer outlining the terms and
                          conditions, including the loan amount, interest rate,
                          repayment schedule, and any fees or charges.
                        </p>
                      </li>
                      <br />
                      <li>
                        <h6 style={styles}>
                          <b style={{ color: "#0983bc" }}>Property Purchase:</b>
                        </h6>
                        <p style={styles}>
                          &nbsp;&nbsp;Once the loan is approved, the borrower
                          proceeds with purchasing the investment property. This
                          may involve engaging a conveyancer or solicitor to
                          handle the legal aspects of the transaction, such as
                          conducting property searches, preparing contracts, and
                          facilitating the settlement process.
                        </p>
                      </li>
                      <br />
                      <li>
                        <h6 style={styles}>
                          <b style={{ color: "#0983bc" }}>Loan Settlement:</b>
                        </h6>
                        <p style={styles}>
                          &nbsp;&nbsp;On the settlement date, the lender
                          provides the funds to complete the purchase of the
                          investment property. The borrower becomes the legal
                          owner of the property, and the lender registers a
                          mortgage over the property as security for the loan.
                        </p>
                      </li>
                      <br />
                      <li>
                        <h6 style={styles}>
                          <b style={{ color: "#0983bc" }}>Rental Income:</b>
                        </h6>
                        <p style={styles}>
                          &nbsp;&nbsp;The borrower rents out the property to
                          tenants, generating rental income. This income is
                          typically used to cover expenses associated with the
                          property, such as mortgage repayments, property
                          management fees, maintenance costs, and insurance.
                        </p>
                      </li>
                      <br />
                      <li>
                        <h6 style={styles}>
                          <b style={{ color: "#0983bc" }}>Interest Rate:</b>
                        </h6>
                        <p style={styles}>
                          &nbsp;&nbsp;The interest rate on an investment
                          property loan may be higher than that of
                          owner-occupied loans. Lenders typically view
                          investment properties as higher risk because they rely
                          on rental income, which can fluctuate, to cover loan
                          repayments.
                        </p>
                      </li>
                      <br />
                      <li>
                        <h6 style={styles}>
                          <b style={{ color: "#0983bc" }}>Loan Repayment:</b>
                        </h6>
                        <p style={styles}>
                          &nbsp;&nbsp;The borrower is responsible for making
                          regular repayments to the lender according to the
                          agreed-upon schedule. Repayments may include both
                          principal and interest or interest-only payments,
                          depending on the loan agreement.
                        </p>
                      </li>
                      <br />
                      <li>
                        <h6 style={styles}>
                          <b style={{ color: "#0983bc" }}>Tax Implications:</b>
                        </h6>
                        <p style={styles}>
                          &nbsp;&nbsp;Investment property loans have tax
                          implications that borrowers should consider. Expenses
                          associated with the property, such as mortgage
                          interest, property management fees, and maintenance
                          costs, may be tax-deductible, reducing the borrower's
                          taxable income.
                        </p>
                      </li>
                      <br />
                      <li>
                        <h6 style={styles}>
                          <b style={{ color: "#0983bc" }}>
                            Capital Appreciation
                          </b>
                        </h6>
                        <p style={styles}>
                          &nbsp;&nbsp;In addition to rental income, investors
                          may also benefit from capital appreciation if the
                          value of the investment property increases over time.
                          This can potentially result in capital gains when the
                          property is sold.
                        </p>
                      </li>
                    </ol>
                  </div>
                  <div className="col-md-12">
                    <p>
                      It's important for investors to carefully consider their
                      financial situation, investment strategy, and long-term
                      goals when taking out an investment property loan.
                      Additionally, investors should conduct thorough research
                      and seek professional advice to ensure they make informed
                      decisions and manage their investment property
                      effectively.
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-12">
                    <h2 style={{ color: "#013148" }}>
                      <b>
                        How do I use equity of a current property to purchase
                        another property?
                      </b>
                    </h2>
                    <p>
                      Using the equity in a current property to purchase another
                      property can be a smart way to leverage your existing
                      assets to expand your property portfolio or invest in a
                      new property.
                    </p>
                  </div>
                  <h6>
                    <b>How it works:</b>
                  </h6>

                  <div className="col-md-12">
                    <br />
                    <ol style={{ color: "#0983bc" }}>
                      <li>
                        <h6 style={styles}>
                          <b style={{ color: "#0983bc" }}>
                            Determine Your Equity:
                          </b>
                        </h6>
                        <p style={styles}>
                          &nbsp;&nbsp;Equity is the difference between the
                          current market value of your property and the amount
                          you owe on your mortgage. You can calculate your
                          equity by subtracting your outstanding mortgage
                          balance from the property's current market value.
                        </p>
                      </li>
                      <br />
                      <li>
                        <h6 style={styles}>
                          <b style={{ color: "#0983bc" }}>
                            Check Lender Requirements:
                          </b>
                        </h6>
                        <p style={styles}>
                          &nbsp;&nbsp;Different lenders have varying
                          requirements for using equity to purchase another
                          property. Some lenders may allow you to borrow up to a
                          certain percentage of your property's equity, while
                          others may have stricter criteria. Check with your
                          current lender or consult with other lenders to
                          understand their policies.
                        </p>
                      </li>
                      <br />
                      <li>
                        <h6 style={styles}>
                          <b style={{ color: "#0983bc" }}>
                            Get a Property Valuation:
                          </b>
                        </h6>
                        <p style={styles}>
                          &nbsp;&nbsp;Before you can access the equity in your
                          property, you'll need to get a property valuation to
                          determine its current market value. This valuation
                          will help you calculate how much equity you have
                          available to use for purchasing another property.
                        </p>
                      </li>
                      <br />
                      <li>
                        <h6 style={styles}>
                          <b style={{ color: "#0983bc" }}>
                            Calculate Borrowing Capacity:
                          </b>
                        </h6>
                        <p style={styles}>
                          &nbsp;&nbsp;Once you know the amount of equity
                          available in your property, you can calculate your
                          borrowing capacity. This will depend on factors such
                          as your income, expenses, credit history, and the
                          lender's criteria. You can use online borrowing
                          calculators or consult with a mortgage broker to
                          determine how much you can borrow.
                        </p>
                      </li>
                      <br />
                      <li>
                        <h6 style={styles}>
                          <b style={{ color: "#0983bc" }}>
                            Apply for a Home Equity Loan or Line of Credit:
                          </b>
                        </h6>
                        <p style={styles}>
                          &nbsp;&nbsp;There are several ways to access the
                          equity in your property, including home equity loans
                          or lines of credit (HELOCs). These types of loans
                          allow you to borrow against the equity in your
                          property, using it as collateral. You can then use the
                          funds from the loan to purchase another property.
                        </p>
                      </li>
                      <br />
                      <li>
                        <h6 style={styles}>
                          <b style={{ color: "#0983bc" }}>
                            Consult with a Mortgage Broker or Financial Advisor:
                          </b>
                        </h6>
                        <p style={styles}>
                          &nbsp;&nbsp;It's a good idea to seek professional
                          advice when using equity to purchase another property.
                          A mortgage broker or financial advisor can help you
                          understand your options, navigate the lending process,
                          and find the best loan product for your needs.
                        </p>
                      </li>
                      <br />
                      <li>
                        <h6 style={styles}>
                          <b style={{ color: "#0983bc" }}>
                            Consider Tax Implications:
                          </b>
                        </h6>
                        <p style={styles}>
                          &nbsp;&nbsp;Using equity to purchase another property
                          may have tax implications, such as capital gains tax
                          (CGT) and stamp duty. Consult with a tax advisor or
                          accountant to understand how leveraging equity will
                          affect your tax obligations.
                        </p>
                      </li>
                      <br />
                      <li>
                        <h6 style={styles}>
                          <b style={{ color: "#0983bc" }}>
                            Research and Purchase Another Property:
                          </b>
                        </h6>
                        <p style={styles}>
                          &nbsp;&nbsp;Once you have access to the funds, you can
                          use them to purchase another property. Conduct
                          thorough research, assess potential investment
                          opportunities, and consider factors such as location,
                          rental yields, and potential for capital growth before
                          making a purchase.
                        </p>
                      </li>
                      <br />
                      <li>
                        <h6 style={styles}>
                          <b style={{ color: "#0983bc" }}>Manage Your Loans:</b>
                        </h6>
                        <p style={styles}>
                          &nbsp;&nbsp;Keep track of your loans and repayments to
                          ensure you can comfortably manage your debt. Monitor
                          interest rates and consider refinancing options if
                          there are better deals available.
                        </p>
                      </li>
                    </ol>
                  </div>
                  <div className="col-md-12">
                    <p>
                      Using equity to purchase another property can be a
                      powerful wealth-building strategy, but it's essential to
                      carefully consider the risks and benefits and seek
                      professional advice to make informed decisions.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default InvestmentLoansBody;
