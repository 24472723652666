// index.js
import React, { useState } from "react";
import Sidebar from "./Sidebar";
import MainContent from "./MainContent";
import Img from "../../assets/images/team/user-icon.png";

import "./user.css";
import Dashboard from "../Dashboard/Default";

const User = () => {
  const [user, setUser] = useState({
    name: "User",
    profilePic: Img,
    applications: [
      { id: 1, type: "Home Loan", time: "2023-01-01", clientInfo: "Client A" },
      { id: 2, type: "Refinance", time: "2023-02-01", clientInfo: "Client B" },
    ],
    status: { amount: 100000, status: "Draft" },
    interestRate: 3.5,
    referrals: 5,
  });

  return (
    <div className="app-container">
      <Sidebar user={user} />
      <MainContent user={user} setUser={setUser} />
      {/* <Dashboard /> */}
    </div>
  );
};

export default User;
