import BGPH1 from "../../assets/images/team/our-team.png";
import React from "react";
import { Link } from "react-router-dom";

function Main({ title, subtitle, link, img }) {
  return (
    <>
      <section className="page-header">
        {img ? (
          <div
            className="page-header__bg"
            style={{
              backgroundPosition: "center",
              backgroundImage: `url(${img})`,
              borderBottomRightRadius: "200px",
            }}></div>
        ) : (
          <div
            className="page-header__bg"
            style={{
              backgroundColor: "#013148",
              borderBottomRightRadius: "200px",
            }}></div>
        )}

        <div className="container">{/* <h2>{link}</h2> */}</div>
        <ul className="thm-breadcrumb list-unstyled">
          <li className="mt-4" style={{ color: "black" }}>
            <span style={{ color: "black" }}>{title}</span>
          </li>
          {subtitle ? (
            <li className="mt-4" style={{}}>
              <span style={{ color: "black" }}>{subtitle}</span>
            </li>
          ) : (
            ""
          )}
          {/* <li className="mt-4" style={{}}>
            <span style={{ color: "black" }}>{subtitle}</span>
          </li> */}
          <li className="link mt-4" style={{}}>
            {/* text-decoration: underline; */}
            <span>{link}</span>
          </li>
        </ul>
      </section>
    </>
  );
}

export default Main;
