import React from "react";
import Bredcom from "../Bredcom/Main";
import AssetFinanceBody from "../Services/Menservisedeatils/AssetFinanceBody";
import BG from "../../assets/images/Banners/assest-loan.jpg";

const AssetFinance = () => {
  return (
    <>
      <Bredcom
        title={"Home"}
        subtitle={"Home Loans"}
        link={"Asset Finance"}
        img={BG}
      />
      <AssetFinanceBody />
    </>
  );
};

export default AssetFinance;
