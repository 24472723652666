import React from "react";
import { Helmet } from "react-helmet";
import "../../../assets/css/calc.css";

function PropertySellingCostCalculator() {
  return (
    <>
      <Helmet>
        <title>Property Selling Cost Calculator - Secure Finance</title>
        <meta
          name="description"
          content="Use our Property Selling Cost Calculator to estimate the total costs of selling a property, including real estate agent fees, lender fees, and other selling costs. Input your details to view your estimated total property selling cost."
        />
        <link
          rel="canonical"
          href="https://securefinance.com.au/property-selling-cost-calculator"
        />
      </Helmet>
      <section className="benefit-one pt-60 pb-60">
        <div className="container">
          <div className="row row-gutter-y-60">
            <iframe
              className="VisiCalcClass"
              id="Property_Selling_Cost_Calculator"
              src="//www.visionabacus.net/Tools/B3/SuiteA/A400/Property_Selling_Cost_Calculator/SecureFinance"
              frameBorder="0"
              scrolling="no"
              title="Property Selling Cost Calculator"></iframe>
          </div>
        </div>
      </section>
    </>
  );
}

export default PropertySellingCostCalculator;
