import React from "react";
import Bredcom from "../../Bredcom/Main";
import First from "../Menteam/First";
import Img4 from "../../../assets/images/team/our-team.png";
import AboutUS from "../../Menabout/AboutSF";

const Team = () => {
  return (
    <>
      <Bredcom title={"Home"} link={"About"} img={Img4} />
      <AboutUS />
      <First />
    </>
  );
};

export default Team;
