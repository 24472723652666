import React from "react";
import { Helmet } from "react-helmet";
import "../../../assets/css/calc.css";

function LoanComparisonCalculator() {
  return (
    <>
      <Helmet>
        <title>Loan Comparison Calculator - Secure Finance</title>
        <meta
          name="description"
          content="Use our Loan Comparison Calculator to compare two loan options and determine potential savings. Input common loan details, upfront fees, ongoing fees, introductory rates, and ongoing rates to see which loan offers the best value."
        />
        <link
          rel="canonical"
          href="https://securefinance.com.au/loan-comparison-calculator"
        />
      </Helmet>
      <section className="benefit-one pt-60 pb-60">
        <div className="container">
          <div className="row row-gutter-y-60">
            <iframe
              className="VisiCalcClass"
              id="Loan_Comparison_Calculator"
              src="//www.visionabacus.net/Tools/B3/SuiteA/A300/Loan_Comparison_Calculator/SecureFinance"
              frameBorder="0"
              scrolling="no"
              title="Loan Comparison Calculator "></iframe>
          </div>
        </div>
      </section>
    </>
  );
}

export default LoanComparisonCalculator;
