// UserProfile.js
import React, { useState } from "react";

const UserProfile = ({ user }) => {
  const [showNotifications, setShowNotifications] = useState(false);
  const [notificationCount, setNotificationCount] = useState(3);

  const toggleNotifications = () => {
    setShowNotifications(!showNotifications);
  };

  const clearNotifications = () => {
    setNotificationCount(0);
    setShowNotifications(false);
  };

  const generateDummyNotifications = () => {
    return (
      <div className="notification-dropdown">
        <p>Notification 1</p>
        <p>Notification 2</p>
        <p>Notification 3</p>
      </div>
    );
  };
  return (
    <>
      <div className="top-bar">
        <div className="user-details">
          <button className="notification-button" onClick={toggleNotifications}>
            <i className="fas fa-bell"></i>
            {notificationCount > 0 && (
              <span className="notification-badge">{notificationCount}</span>
            )}
          </button>
          {showNotifications && generateDummyNotifications()}
          <img className="profile-pic1" src={user.profilePic} alt="Profile" />
        </div>
      </div>
      {/* <div className="user-details">
        <h2>User Profile</h2>
        <p>Name: {user.name}</p>
      </div> */}
    </>
  );
};

export default UserProfile;
