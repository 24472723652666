import { Link } from "react-router-dom";
import React from "react";
import Bredcom from '../../Bredcom/Main'
import First from "../../Menhomeloanegl/First";

const HomeLoanEligibility = () => {
  
  return (
    <>
      <Bredcom title={"Home"} subtitle={"HOME LOAN ELIGIBILITY CALCULATOR"} link={"Home Loan Eligibility Calculator"}/>
      <First/>
    </>
  );
};

export default HomeLoanEligibility;
