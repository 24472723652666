import React from "react";
import { Helmet } from "react-helmet";
import "../../../assets/css/calc.css";

function MortgageSwitchingCalculator() {
  return (
    <>
      <Helmet>
        <title>Mortgage Switching Calculator - Secure Finance</title>
        <meta
          name="description"
          content="Use our Mortgage Refinance Calculator to evaluate the potential savings and costs of refinancing your mortgage. Input your current loan details, new loan information, and view scenarios comparing monthly repayments, total interest, and fees."
        />
        <link
          rel="canonical"
          href="https://securefinance.com.au/mortgage-switching-calculator"
        />
      </Helmet>
      <section className="benefit-one pt-60 pb-60">
        <div className="container">
          <div className="row row-gutter-y-60">
            <iframe
              className="VisiCalcClass"
              id="Mortgage_Switching_Calculator"
              src="//www.visionabacus.net/Tools/B3/SuiteA/G300/Mortgage_Switching_Calculator/SecureFinance"
              frameBorder="0"
              scrolling="no"
              title="Mortgage Switching Calculator"></iframe>
          </div>
        </div>
      </section>
    </>
  );
}

export default MortgageSwitchingCalculator;
