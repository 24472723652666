// StatusCard.js
import React from "react";

const StatusCard = ({ status }) => {
  return (
    <div className="card">
      <h2>Status</h2>
      <p>Loan Amount: ${status.amount}</p>
      <p>Status: {status.status}</p>
    </div>
  );
};

export default StatusCard;
