import React from 'react';
import TawkTo from 'react-tawkto';

const LiveChatButton = () => {
  return (
    <TawkTo
      propertyId="655c6aed91e5c13bb5b22159"
      widgetId="1hfog3ces"
    />
  );
};

export default LiveChatButton;

