import React from "react";
import Bredcom from "../Bredcom/Main";
import First from "../Services/Menservisedeatils/First";
import BG from "../../assets/images/Banners/firrst-home-buyer.jpg";

const FirstHomeBuyersLoans = () => {
  return (
    <>
      <Bredcom
        title={"Home"}
        subtitle={"Home Loans"}
        link={"First Home Buyers Loans"}
        img={BG}
      />
      <First />
    </>
  );
};

export default FirstHomeBuyersLoans;
