import React from "react";
import { Helmet } from "react-helmet";
import "../../../assets/css/calc.css";

function CompoundInterestCalculator() {
  return (
    <>
      <Helmet>
        <title>Compound Interest Calculator - Secure Finance</title>
        <meta
          name="description"
          content="Use our Compound Interest Calculator to estimate the interest earned on your deposit over time. Input your deposit amount, interest rate, and saving term to view your results with annual, monthly, and daily compounding."
        />
        <link
          rel="canonical"
          href="https://securefinance.com.au/compound-interest-calculator"
        />
      </Helmet>
      <section className="benefit-one pt-60 pb-60">
        <div className="container">
          <div className="row row-gutter-y-60">
            <iframe
              className="VisiCalcClass"
              id="Compound_Interest_Calculator"
              src="//www.visionabacus.net/Tools/B3/SuiteA/A500/Compound_Interest_Calculator/SecureFinance"
              frameBorder="0"
              scrolling="no"
              title="Compound Interest Calculator"></iframe>
          </div>
        </div>
      </section>
    </>
  );
}

export default CompoundInterestCalculator;
