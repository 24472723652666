import React from "react";
import Bredcom from "../../Bredcom/Main";
import Clint from "../Menteamdeatils/Clint";
import Gouthami from "../Menteamdeatils/Gouthami";

const GouthamiDetails = () => {
  return (
    <>
      <Bredcom title={"Home"} subtitle={"TEAM"} link={"Gouthami Minupala"} />
      <Gouthami />
      {/* <Clint /> */}
    </>
  );
};

export default GouthamiDetails;
