import React from "react";
import Bredcom from "../../Bredcom/Main";
import Clint from "../Menteamdeatils/Clint";
import First from "../Menteamdeatils/First";

const TeamDetails = () => {
  return (
    <>
      <Bredcom title={"Home"} subtitle={"TEAM"} link={"Team Details"} />
      <First />
      <Clint />
    </>
  );
};

export default TeamDetails;
