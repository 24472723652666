import React, { useState } from "react";
import UserProfile from "./UserProfile";
import ApplicationsCard from "./ApplicationsCard";
import StatusCard from "./StatusCard";
import InterestRateCard from "./InterestRateCard";
import ReferAFriendCard from "./ReferAFriendCard";

const MainContent = ({ user, setUser }) => {
  const [showNotifications, setShowNotifications] = useState(false);
  const [notificationCount, setNotificationCount] = useState(3);

  const toggleNotifications = () => {
    setShowNotifications(!showNotifications);
  };

  const clearNotifications = () => {
    setNotificationCount(0);
    setShowNotifications(false);
  };

  const generateDummyNotifications = () => {
    return (
      <div className="notification-dropdown">
        <p>Notification 1</p>
        <p>Notification 2</p>
        <p>Notification 3</p>
      </div>
    );
  };

  return (
    <div className="main-content">
      {/* <div className="top-bar">
        <div className="top-right-icons">
          <img className="profile-pic" src={user.profilePic} alt="Profile" />
          <button className="notification-button" onClick={toggleNotifications}>
            <i className="fas fa-bell"></i>
            {notificationCount > 0 && (
              <span className="notification-badge">{notificationCount}</span>
            )}
          </button>
          {showNotifications && generateDummyNotifications()}
        </div>
      </div> */}
      <UserProfile user={user} />
      <div className="card-container">
        <div className="col-md-12 card-row">
          <ApplicationsCard applications={user.applications} />
        </div>
        <div className="col-md-12 card-row">
          <StatusCard status={user.status} />
          <InterestRateCard interestRate={user.interestRate} />
          <ReferAFriendCard referrals={user.referrals} />
        </div>
      </div>
    </div>
  );
};

export default MainContent;
