import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
// import BG from "../../assets/uploads";
const Blogs = () => {
  const [blogs, setBlogs] = useState([]);

  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        const response = await axios.get("http://localhost:3001/api/blogs");
        console.log(response.data);
        setBlogs(response.data);
      } catch (error) {
        console.error("Error fetching blogs:", error.message);
      }
    };

    fetchBlogs();
  }, []);

  return (
    <section className="blog-grid pt-120 pb-120">
      <div className="container">
        <div className="row row-gutter-y-30">
          {blogs.map((blog, index) => (
            <div className="col-lg-4 col-md-6 col-sm-12" key={blog._id}>
              <div className="blog-card">
                <div className="blog-card__image">
                  <div className="blog-card__date">
                    <span>05</span> Mar
                  </div>
                  {/* <img src="../../assets/uploads/1708413499042.jpg" /> */}
                  {/* <img src={`assets/uploads/+${blog.mainImage}`} /> */}
                  {blog.mainImage ? (
                    <img
                      src={`/assets/uploads/${blog.mainImage}`}
                      alt="Blog Main Image"
                      style={{ maxWidth: "100%", height: "230px" }}
                    />
                  ) : (
                    <img
                      src="assets/images/blog/blog-1-1.png"
                      alt="Which growth strategies you should adopt"
                    />
                    // <img
                    //   src={`/assets/uploads/no-image.png`}
                    //   alt="Blog Main Image"
                    //   style={{ maxWidth: "100%", height: "100px" }}
                    // />
                  )}
                  <Link to="/newsdetails"></Link>
                </div>

                <div className="blog-card__content">
                  <div className="blog-card__meta">
                    <Link to="/newsdetails">
                      <i className="far fa-user-circle"></i> by Admin
                    </Link>
                    <Link to="/newsdetails">
                      <i className="far fa-comments"></i> 2 Comments
                    </Link>
                  </div>
                  <h3 className="blog-card__title">
                    <Link to="/newsdetails">{blog.title}</Link>
                  </h3>
                  <p
                    className="blog-card__text"
                    dangerouslySetInnerHTML={{ __html: blog.content }}
                  />
                  <Link to="/newsdetails" className="blog-card__link">
                    Read More
                  </Link>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Blogs;
