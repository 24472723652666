import React from "react";
import Bredcom from "../Bredcom/Main";
import OwnerOccupiedBody from "../Services/Menservisedeatils/OwnerOccupiedBody";
import BG from "../../assets/images/Banners/owner-occupied-loans.jpg";

const OwnerOccupiedLoans = () => {
  return (
    <>
      <Bredcom
        title={"Home"}
        subtitle={"Home Loans"}
        link={"Owner Occupied Loans"}
        img={BG}
      />
      <OwnerOccupiedBody />
    </>
  );
};

export default OwnerOccupiedLoans;
