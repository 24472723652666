import React from "react";
import Bredcom from "../Bredcom/Main";
import BG from "../../assets/loan-pics/loans.jpg";
import HomBuyersRejoiceDetails from "../News/Mennewsdeatils/HomeBuyersRejoiceDetails";

const HomeBuyersRejoiceResourses = () => {
  return (
    <>
      <Bredcom
        title={"Home"}
        subtitle={"Resourses"}
        link={"Home Buyers Rejoice"}
      />
      <HomBuyersRejoiceDetails />
    </>
  );
};

export default HomeBuyersRejoiceResourses;
