import React from "react";
import Bredcom from "../Bredcom/Main";
import CommercialPropertyLoansBody from "../Services/Menservisedeatils/CommercialPropertyLoansBody";
import BG from "../../assets/images/Banners/commercial-property.jpg";

const CommercialPropertyLoans = () => {
  return (
    <>
      <Bredcom
        title={"Home"}
        subtitle={"Home Loans"}
        link={"Commercial Property Loans"}
        img={BG}
      />
      <CommercialPropertyLoansBody />
    </>
  );
};

export default CommercialPropertyLoans;
