import BGS1 from "../../../assets/loan-pics/Loan pic.png";
import BGS from "../../../assets/new-images/SMSFLoansprocess.png";

import { Link } from "react-router-dom";
import React, { useState } from "react";

function LowDocHomeLoansBody() {
  const [clicked, setClicked] = useState(null);
  const faqs = [
    {
      question: "Who can benefit from a low doc loan?",
      answer: `<p>Potential borrowers who can’t supply the documentation to satisfy a lender’s criteria for a standard home loan may be able to get a low doc loan. For example, your business (and your income) may be growing, enabling you to meet the mortgage repayments you’ll need to make for the home loan you want. But this increase might not be reflected yet in your most recent financial statements or the tax return that you have available at the time of your home loan application. Many businesses that generate significant revenues employ creative accounting practices in order to minimise their tax liabilities – however, this in turn can limit a borrower’s ability to obtain a standard home loan.</p>
      <p>In these circumstances, a low doc loan may be your only option. Be aware though that you’ll still need to convince the lender of your ability to make the repayments. That will be easier to demonstrate if you have a sound credit history (i.e. you have met your repayments on previous loans or on your credit cards) and if you prepare a realistic budget of your weekly/monthly revenue and expenses.</p>
      `,
    },
    {
      question: "How much can I borrow?",
      answer: `<p>Different lenders will have different loan-to-value ratios (LVRs). This LVR figure represents the amount you need to borrow expressed as a percentage of the value of the home you wish to buy. For a standard home loan, the LVR is usually 80%, though it varies by lender. The LVRs for low doc loans typically range from 60% to 80%. The higher the LVR, the higher interest rate the lender will usually charge to compensate for the increased risk.</p>
      <p>Under Australian consumer credit protection legislation, lenders are legally obliged to comply with responsible lending provisions. This means that they must lend responsibly by accurately assessing your financial situation and your ability to repay any loans or credit they agree to provide. Failure to comply with those provisions carries significant penalties.
      `,
    },
    {
      question: "Is there anything I should look out for?",
      answer: `
      <p>Low doc home loans are viewed as higher risk by lenders. Therefore, they:</p>

      <li>Have a higher interest rate and associated fees compared to standard home loans</li>
      <li>Usually require a higher deposit.</li>
      <li>Usually require you to take out mortgage insurance if the loan value ratio (LVR) is greater than 60%. Loan mortgage insurance helps to protect the lender if you’re unable to make your future repayments.</li>
      <p>You may also use our Calculators to get a estimate of the new fees.</p>
        <p>In addition, it may be more difficult for you refinance your low doc loan in the future if your circumstances change, compared with a standard home loan. If you’re stuck on a low doc loan with a high interest rate, this can be costly.</p>

      `,
    },
    {
      question: "How will banks calculate my income?",
      answer: `
      <p>Low doc loans allow you to use a variety of alternative ways to prove your income. Calculating your likely income if it fluctuates is obviously a very important part of their assessment. For a low doc home loan, you can provide items such as:</p>
<ul>
<li>A signed declaration declaring your income. It’s important that this declaration is accurate and realistic for your age and occupation, considering all the current and future potential circumstances of your business.</li>
<li>Business Activity (BAS) Statements for the past 6 or 12 months or more (ideally showing increasing turnover/revenue/profits). Many borrowers will limit your maximum LVR without these statements.</li>
<li>Your business and personal tax returns.</li>
<li>Your business bank statements. Ideally this should demonstrate the regular and positive cash flow of your business.</li>
<li>An accountant’s letter verifying your income claims.</li>
<li>The most recent financial statements for your business (ideally prepared by an accountant). For example, profit and loss statements and a balance sheet showing your business’s assets and liabilities.</li>
<p>The more of these you can provide that show evidence of high turnover (and income) for your business, the better. Lenders tend to view self-employed people as a higher risk and they are more conservative in their lending practices with them.</p>
</ul>`,
    },
    {
      question: "What is asset to income ratio?",
      answer: `
        <p>Some self-employed people are asset-rich but cash poor. Lenders will need to see evidence of your regular, positive cash flow to be confident that you will be able to meet your loan repayments. It’s also good for them to see evidence of assets that your business has built up over time. It shows that you are reinvesting in your business and that you intend for it to be around for a long time. A rule of thumb is that your business assets should be equal to two times your business income, though this will vary by the type of business.</p>`,
    },
    {
      question: "Do I need ABN/GST registration?",
      answer: `
        <p>If you’re self-employed, you’ll have an Australian Business Number (ABN) and will most likely be registered for GST. In Australia, it’s compulsory for businesses with an annual gross income (i.e. turnover) of more than $75,000 to register for GST. Most lenders will want to see evidence that your business is a viable ongoing concern by having your ABN, GST registration and associated financial statements for a period of two years or more.</p>`,
    },
    {
      question: "How do I get approved for a low doc loan?",
      answer: `
        <p>Not all lenders provide low doc loans and the ones that do have different lending criteria. For example, they’ll have different minimum documentation requirements and different maximum LVRs. Their interest rates and associated fees will also vary accordingly.</p>
<p>In general, even with a low doc loan you need to provide as much verified information as you can to increase your chances of approval. But you need to make sure that the information you supply is well-presented, accurate and fully supports your ability to make the loan repayments. We can help you to do this.</p>
<p>The location of the property you want to buy will also be a key consideration for the lender. A property in a desirable location isn’t as high a risk for the lender, even though they may perceive you to be a higher risk as a low doc home loan applicant. Well-maintained homes in capital cities will tend to be viewed more favourably by lenders than those in more isolated, regional locations that may be more difficult for them to sell if you were to default on your mortgage repayments.</p>
`,
    },
    {
      question: "The importance of your credit history",
      answer: `
        <p>Your credit history is important with any application and a low doc home loan< is no different. Lenders can check with credit reporting agencies to see if you or your business have a good record of repaying credit. The best way to develop a good credit history is to pay your bills on time. Under Australian legislation you are entitled to contact a credit reporting agency to access your own credit report. You also have the right to have any errors on your report promptly corrected by the credit provider.</p>`,
    },
    {
      question: "What is collateral security?",
      answer: `
        <p>Collateral security includes assets you can put up as security against the home loan. These may be required by a lender if your LVR is high. Property is the most preferred form of collateral security for most lenders.</p>`,
    },
    {
      question: "How much other debt can I have?",
      answer: `<p>Lenders will assess your overall level of debt as part of their evaluation of your ability to repay the home loan you’re applying for. As part of their responsible lending legal obligations, they need to ensure you’ll be able to meet your repayments. This includes any other debts you may have, (e.g. business-related debt). Most lenders have maximum levels of debt exposure they are prepared to accept from low doc home loan applicants.</p>`,
    },
    {
      question: "Can I top-up or release equity with a low-doc loan?",
      answer: `
        <p>If you’re applying for a low doc home equity loan, most lenders will require proof of what the funds will be used for, especially if they’re being provided directly to you. Their concern would be that the funds may not be used for income-producing or asset-building purposes, making you a higher risk borrower for them.</p>`,
    },
    {
      question: "Are No Doc loans still available?",
      answer: `
        <p>'No doc’ loans are as their name suggests. They require no documentation or proof of income. If you can secure one, you’ll usually only be required to sign a declaration that you can afford the loan repayments.</p>
<p>But lenders are still bound by their legal responsible lending obligations, making the approval of a no doc home loan more unlikely unless you are very well known to them. And if you do get one, it will typically be at a very high interest rate.</p>
<p>You would be far better off applying for a low doc loan with a comparatively lower rate. If you can genuinely afford the low doc loan repayments, your application will usually be approved by the lender.</p>
`,
    },
  ];

  return (
    <>
      <section className="service-details pt-120 pb-120">
        <div className="container">
          <div className="row row-gutter-y-30">
            <div className="col-lg-3">
              <div className="service-sidebar">
                <div className="service-sidebar__item service-sidebar__item--menu">
                  <ul className="service-sidebar__menu">
                    <li>
                      <Link to="/first-home-buyers-loan">
                        First Home Buyers Loan
                      </Link>
                    </li>
                    <li>
                      <Link to="/owner-occupied-loans">
                        Owner Occupied Loans
                      </Link>
                    </li>
                    <li>
                      <Link to="/investment-loans">Investment Loans</Link>
                    </li>
                    <li>
                      <Link to="/refinance-loans">Refinance Loans</Link>
                    </li>
                    <li>
                      <Link to="/construction-loans">Construction Loans</Link>
                    </li>
                    <li>
                      <Link to="/guarantor-home-loans">Guarantor Loans</Link>
                    </li>
                    <li>
                      <Link to="/loan-for-professionals">
                        Loans for Professionals
                      </Link>
                    </li>
                    <li>
                      <Link to="/smsf-loans">SMSF Loans</Link>
                    </li>
                    <li className="active">
                      <Link to="/low-doc-home-loans">Low-Doc Loans</Link>
                    </li>
                    <li>
                      <Link to="/asset-finance">Asset Finance</Link>
                    </li>
                    <li>
                      <Link to="/commercial-property-loans">
                        Commercial Loans
                      </Link>
                    </li>
                  </ul>
                </div>
                {/* <div className="service-sidebar__item service-sidebar__item--contact">
                  <div
                    className="service-sidebar__bg"
                    style={{ backgroundImage: `url(${BGS1})` }}></div>
                  <div className="service-sidebar__contact">
                    <div className="service-sidebar__icon">
                      <i className="icon-phone-ringing"></i>
                    </div>
                    <h3 className="service-sidebar__title">
                      Quick loan proccess
                    </h3>
                    <hr className="service-sidebar__separator" />
                    <p className="service-sidebar__tagline">
                      Talk to an expert
                    </p>
                    <Link
                      to="tel:+1-(246)333-0089"
                      className="service-sidebar__phone">
                      + 1- (246) 333-0089
                    </Link>
                  </div>
                </div> */}
              </div>
            </div>
            <div className="col-lg-9">
              <h3 className="service-details__title">Low Doc Home Loans</h3>

              <div className="service-details__image">
                <img src={BGS1} alt="" />
                <div className="service-details__icon">
                  <i className="icon-payment"></i>
                </div>
              </div>
              <div className="service-details__content">
                <p>Mortgages for the Self-Employed</p>
                <br />
                <h4 style={{ color: "#0983bc" }}>
                  <b>What is a low doc home loan?</b>
                </h4>
                <p>
                  A low doc loan is an abbreviation for a low documentation
                  loan, also commonly referred to as an alt doc or alternative
                  documentation loan. It’s primarily suited to self-employed
                  borrowers, but may also be appropriate for professional
                  investors, seasonal and contract workers.
                </p>
                <br />
                <p>
                  We are always happy to have a commitment-free discussion, so
                  please feel free to get in touch with any questions about low
                  doc loans.
                </p>
                <p>
                  One of the main criteria that lenders use in assessing a home
                  loan application is proof of income in the form of payslips or
                  tax returns. If you are unable to verify your income by one of
                  these methods, then a low doc home loan is an option for you
                  to consider.
                </p>
                <p>
                  Alternative forms of verification may be in the form of
                  business activity statements (BAS), an accountant’s letter, or
                  financial statements (among others). The concept is the same;
                  the lender wants to see that there is income to support the
                  loan you are seeking.
                </p>
                <p>
                  Most low doc loans also offer the same popular additional
                  features of standard home loans, such as an interest-only
                  repayment option, the ability to make extra repayments or fix
                  your interest rate, offset facilities and lines of credit.
                </p>
                <p>
                  It’s important to be aware that they’re harder to secure than
                  they were a decade ago. Many lenders have tightened their
                  lending practices in line with increased regulatory pressure,
                  and some lenders no longer offer alt doc loans.
                </p>

                <p>
                  As with all consumer finance, the responsible lending
                  provisions of Australian credit law apply. The lender must
                  assess that the borrower has the capacity to make their
                  repayments before they approve a loan application. The
                  difference here is that the documentation required to
                  establish income is more flexible.
                </p>

                <br />
                <br />
                <h4 style={{ color: "#0983bc" }}>
                  <b>Frequently Asked Questions</b>
                </h4>
                <p>
                  We go through a number of frequently asked questions about low
                  doc home loans below. If you have any further queries or would
                  like assistance in organising a low doc loan, please get in
                  touch for an obligation-free conversation about your needs.
                </p>
                <div className="row">
                  <div className="col-md-12">
                    <div
                      className="accrodion-grp service-details__accrodion"
                      data-grp-name="service-details__accrodion-1">
                      {faqs.map((item, index) => (
                        <div
                          className={`accrodion  wow fadeInUp ${
                            index === clicked && "active"
                          }`}
                          key={index}
                          data-wow-delay="0ms">
                          <div
                            className="accrodion-title"
                            onClick={() =>
                              setClicked(index === clicked ? null : index)
                            }>
                            <h4>{item.question}</h4>
                            <span className="accrodion-icon"></span>
                          </div>
                          {index === clicked && (
                            <div className="accrodion-content">
                              <div className="inner">
                                <p
                                  style={{
                                    paddingLeft: "20px",
                                    textAlign: "justify",
                                  }}
                                  dangerouslySetInnerHTML={{
                                    __html: item.answer,
                                  }}
                                />
                                {/* Additional content if needed */}
                              </div>
                            </div>
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                <br />
                <h4 style={{ color: "#0983bc" }}>
                  <b>We’re Here to Help</b>
                </h4>
                <p>
                  As you can see, low doc loans are an option if you’re
                  self-employed or in any other situation where your income
                  fluctuates. We’re experienced in this form of home loan
                  financing and can advise you if it’s suitable for your
                  circumstances. We’ll save you time by advising which lenders
                  may be more likely to approve your application based on the
                  documentation you can provide. We can also help you negotiate
                  the best possible home loan deal with a lender. Even slight
                  variations in interest rates and other loan terms and
                  conditions can make a massive difference to the amount you’ll
                  repay over the life of a home loan.
                </p>
                {/* <div className="row row-gutter-y-30">
                                <div className="col-md-6">
                                    <img src="assets/images/services/service-d-1-2.png" alt="" />
                                </div>
                                <div className="col-md-6">
                                    <h3 className="service-details__subtitle">Service benefits</h3>
                                    <p className="service-details__text">Duis aute irure dolor in reprehenderit in voluptate velit
                                        esse cillum.</p>
                                    <ul className="list-unstyled ml-0 service-details__list">
                                        <li>
                                            <i className="fa fa-check-circle"></i>
                                            Refresing to get such a personal touch.
                                        </li>
                                        <li>
                                            <i className="fa fa-check-circle"></i>
                                            Duis aute irure dolor in in voluptate.
                                        </li>
                                        <li>
                                            <i className="fa fa-check-circle"></i>
                                            Velit esse cillum eu fugiat pariatur.
                                        </li>
                                        <li>
                                            <i className="fa fa-check-circle"></i>
                                            Duis aute irure dolor in in voluptate.
                                        </li>
                                    </ul>
                                </div>
                            </div> */}

                {/* <div className="row row-gutter-y-20">
                  <div className="col-md-12 text-center">
                    <Link to="#">Engage our Broker today</Link>
                  </div>
                </div> */}
                <div className="row row-gutter-y-0">
                  <div className="col-md-12 text-center">
                    <Link
                      to="/talk-to-a-broker"
                      className="thm-btn thm-btn-broker-btn rounded-pill">
                      <b>Engage our Broker today</b>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default LowDocHomeLoansBody;
