import BGS1 from "../../../assets/loan-pics/Construction loan 2.jpg";
import BGS from "../../../assets/new-images/SMSFLoansprocess.png";

import { Link } from "react-router-dom";
import React, { useState } from "react";

function ConstructionLoansBody() {
  const [clicked, setClicked] = useState(null);
  const faqs = [
    {
      question: "Ask your current lender for a better dealOffset Account",
      answer: `<p>Tell your current lender you are planning to switch to a cheaper loan offered by a different lender. To keep your business, your lender may reduce the interest rate on your current loan.</p>
      <p>If you have at least 20% equity in your home, you'll have more to bargain with. Having a good credit score will also help with negotiations.</p>
      
      <p>Also, some lenders will only refinance with a new 25- or 30-year loan term. You could end up with a longer loan term than the years left to pay off your current mortgage.</p>

      <p>The longer you have a loan, the more you'll pay in interest. If you do decide to switch, negotiate a loan of a similar length to your current one.</p>
      `,
    },
    {
      question: "How much can you borrow? Loan to Value (LVR) ratio",
      answer: `<p>Lenders will generally only lend up to 80% of the value of your home. This is known as the loan-to-value ratio or LVR. If the property had to be sold to repay the loan, the other 20% helps cover the costs of selling and any reduction in the market value.</p>
      To find out your LVR, add your total secured loans, divide by current property value and multiply by 100.
<br/><br/>
      <h5 style="color: #0983bc"><b>Example</b></h5>
      <p>Current home loan: $500,000<br/>
      Property Currently Valued: $625,000<br/>
      LVR: 80%<br/>
      <br/>
      If your LVR’s 80% or higher, you may need to pay Lenders Mortgage Insurance (LMI) Mortgage insurance protects the lender if their loan isn’t repaid and you may also pay a higher interest rate.      
      </p>
      `,
    },
    {
      question: "Compare the fees and charges",
      answer: `
      <p>Get at least two different quotes on home loans for your situation.</p>

      Your loan and repayment type will determine the average interest rate for new home loans. As the Reserve Bank of Australia (RBA) has announced an increase in interest rates (November 2023), so the average rate may now be higher.

      <p style="color: #0983bc"><b>Fees to Compare:</b></p>
      <li>Fixed rate loan - If you are on a fixed rate loan, you may need to pay a break fee.</li>
      <li>Discharge (or termination) fee - A fee when you close your current loan.</li>
      <li>Application fee - Upfront fee when you apply for a new loan.</li>
      <li>Switching fee - A fee for refinancing internally (staying with your current lender but switching to a different loan).</li>
      <li>Stamp duty - You may be liable for stamp duty when you refinance. Check with your lender.</li>
      <p>You may also use our Calculators to get a estimate of the new fees.</p>
      <br/>
      <div class="col-md-12 text-center">
        <a href="#">All Our Calculators</a>
      </div>

      `,
    },
    {
      question: "Check if you'll save by switching",
      answer: `
      <p>There are 3 main ways to go about this:</p>
<ol>
<li style="color: #0983bc"><b>Speak to a Lender directly</b></li>
</br>
<p>Go to each lender online, over the phone or in person, and don’t forget to check they have a valid Australian Credit Licence.</p>

Despite getting the information from the source, there are also a few restrictions when it comes to liaising with a lender directly. It will take time to deal with each lender, you will be missing out on other (potentially better) options from other lenders and you may not understand the terms different lenders use.
<br/><br/>
<li style="color: #0983bc"><b>Use Comparison Sites</b></li>
<br/>
<p>You can search most comparison sites for sections or keywords relating to your reasons for refinancing. They normally have a range of product features in tables ordered by lender, to easily compare similar features and fees.</p>

Most info on comparison websites comes direct from the lenders who list on their site. So, it’s worth reading up on how they get paid and the basis on which they sort the results.
<br/><br/>
Similar to speaking directly with a lender, you will then be the judge of the type of loan you’d find beneficial to you.
<br/><br/>
<p>However similarly, you may be missing out of greater deals with better features.</p>

<li style="color: #0983bc"><b>Speak to a Broker</b></li>
<br/>
<p>One of the significant key benefit when speaking to a broker is that  we will complete your application and deal with the lenders on your behalf.</p>

Brokers are paid by the lenders via commissions, based on loan amounts and your home loan term.
<br/><br/>
It will save you time as the broker will do a lender comparison for you. A broker may identify lenders you may not have considered.
<br/><br/>
And the biggest incentive for using a Broker for many would be that a Broker understands bank jargon and translates it for you and know the information lenders need and the know-how to complete the application forms.
<br/>
<p>Get started today!</p>
</ol>
      `,
    },
  ];

  return (
    <>
      <section className="service-details pt-120 pb-120">
        <div className="container">
          <div className="row row-gutter-y-30">
            <div className="col-lg-3">
              <div className="service-sidebar">
                <div className="service-sidebar__item service-sidebar__item--menu">
                  <ul className="service-sidebar__menu">
                    <li>
                      <Link to="/first-home-buyers-loan">
                        First Home Buyers Loan
                      </Link>
                    </li>
                    <li>
                      <Link to="/owner-occupied-loans">
                        Owner Occupied Loans
                      </Link>
                    </li>
                    <li>
                      <Link to="/investment-loans">Investment Loans</Link>
                    </li>
                    <li>
                      <Link to="/refinance-loans">Refinance Loans</Link>
                    </li>
                    <li className="active">
                      <Link to="/construction-loans">Construction Loans</Link>
                    </li>
                    <li>
                      <Link to="/guarantor-home-loans">Guarantor Loans</Link>
                    </li>
                    <li>
                      <Link to="/loan-for-professionals">
                        Loans for Professionals
                      </Link>
                    </li>
                    <li>
                      <Link to="/smsf-loans">SMSF Loans</Link>
                    </li>
                    <li>
                      <Link to="/low-doc-home-loans">Low-Doc Loans</Link>
                    </li>
                    <li>
                      <Link to="/asset-finance">Asset Finance</Link>
                    </li>
                    <li>
                      <Link to="/commercial-property-loans">
                        Commercial Loans
                      </Link>
                    </li>
                  </ul>
                </div>
                {/* <div className="service-sidebar__item service-sidebar__item--contact">
                  <div
                    className="service-sidebar__bg"
                    style={{ backgroundImage: `url(${BGS1})` }}></div>
                  <div className="service-sidebar__contact">
                    <div className="service-sidebar__icon">
                      <i className="icon-phone-ringing"></i>
                    </div>
                    <h3 className="service-sidebar__title">
                      Quick loan proccess
                    </h3>
                    <hr className="service-sidebar__separator" />
                    <p className="service-sidebar__tagline">
                      Talk to an expert
                    </p>
                    <Link
                      to="tel:+1-(246)333-0089"
                      className="service-sidebar__phone">
                      + 1- (246) 333-0089
                    </Link>
                  </div>
                </div> */}
              </div>
            </div>
            <div className="col-lg-9">
              <h3 className="service-details__title">Construction Loans</h3>

              <div className="service-details__image">
                <img src={BGS1} alt="" />
                <div className="service-details__icon">
                  <i className="icon-payment"></i>
                </div>
              </div>
              <div className="service-details__content">
                <p>
                  Whether you’re looking at a major renovation, taking a
                  wrecking ball to an existing property, and re-building from
                  scratch, a Construction Loan may meet your needs.
                </p>
                <br />
                <h4 style={{ color: "#0983bc" }}>
                  <b>What is it?</b>
                </h4>
                <p>
                  A construction home loan gives you the option to pay a
                  licensed builder throughout the process of your renovation in
                  chunks or installments. Known as 'progressive drawdowns’ or
                  ‘progress payments’, this means that rather than paying for
                  the construction all at once in a lump sum, you may pay in
                  installments as your build progresses through the various
                  stages of construction.
                </p>
                <p>
                  A Construction Loan is only available for a property built and
                  kept for personal or residential purposes and not for
                  dwellings that you plan to sell immediately after.
                </p>
                <p>
                  Speak to one of our Brokers to discuss your construction
                  requirements.
                </p>

                <h4 style={{ color: "#0983bc" }}>
                  <b>How Does it Work?</b>
                </h4>
                <p>5 stages of construction:</p>

                <br />
                <ul className="service-details__list">
                  <li>
                    {/* <i className="fa fa-check-circle"></i> */}
                    Slab: This would be laying the foundation (groundwork,
                    plumbing, piping, waterproofing).
                  </li>
                  <li>
                    {/* <i className="fa fa-check-circle"></i> */}
                    Frame: Building up the frames and key pillars of the house.
                  </li>
                  <li>
                    {/* <i className="fa fa-check-circle"></i> */}
                    Lockup: This would be putting up the external walls,
                    windows, and doors
                  </li>
                  <li>
                    {/* <i className="fa fa-check-circle"></i> */}
                    Fit out: Digging out gutters, any additional plumbing, and
                    electrical wiring.
                  </li>
                  <li>
                    {/* <i className="fa fa-check-circle"></i> */}
                    Completion: The finishing touches to make the house livable.
                  </li>
                </ul>
                <br />
                <p>
                  An invoice will be issued once each stage is completed. Before
                  paying the builder, your lender performs an inspection to
                  verify that each stage of the construction accordingly.
                </p>
                <br />
                <h4 style={{ color: "#0983bc" }}>
                  <b>
                    Home Loan with Construction option and a Standard Home Loan?
                  </b>
                </h4>
                <p>
                  A standard home loan charges you interest on the full loan
                  amount from settlement, while a construction loan divides the
                  loan into stages in accordance with the construction progress.
                  A construction loan also offers interest-only repayments
                  during the construction. It will later revert to the standard
                  principal and interest loan once construction is complete.
                </p>
                <p>
                  This means that you’ll only make interest repayments on funds
                  that have been drawn down at that point in the process.
                </p>
                <div className="row">
                  <div className="col-md-12">
                    {/* <div
                      className="accrodion-grp service-details__accrodion"
                      data-grp-name="service-details__accrodion-1">
                      {faqs.map((item, index) => (
                        <div
                          className={`accrodion  wow fadeInUp ${
                            index === clicked && "active"
                          }`}
                          key={index}
                          data-wow-delay="0ms">
                          <div
                            className="accrodion-title"
                            onClick={() => setClicked(index)}>
                            <h4>{item.question}</h4>
                            <span className="accrodion-icon"></span>
                          </div>
                          {index === clicked && (
                            <div className="accrodion-content">
                              <div className="inner">
                                <p
                                  style={{ paddingLeft: "50px" }}
                                  dangerouslySetInnerHTML={{
                                    __html: item.answer,
                                  }}
                                />
                                <div />
                              </div>
                            </div>
                          )}
                        </div>
                      ))}
                    </div> */}
                  </div>
                </div>
                {/* <div className="row row-gutter-y-30">
                                <div className="col-md-6">
                                    <img src="assets/images/services/service-d-1-2.png" alt="" />
                                </div>
                                <div className="col-md-6">
                                    <h3 className="service-details__subtitle">Service benefits</h3>
                                    <p className="service-details__text">Duis aute irure dolor in reprehenderit in voluptate velit
                                        esse cillum.</p>
                                    <ul className="list-unstyled ml-0 service-details__list">
                                        <li>
                                            <i className="fa fa-check-circle"></i>
                                            Refresing to get such a personal touch.
                                        </li>
                                        <li>
                                            <i className="fa fa-check-circle"></i>
                                            Duis aute irure dolor in in voluptate.
                                        </li>
                                        <li>
                                            <i className="fa fa-check-circle"></i>
                                            Velit esse cillum eu fugiat pariatur.
                                        </li>
                                        <li>
                                            <i className="fa fa-check-circle"></i>
                                            Duis aute irure dolor in in voluptate.
                                        </li>
                                    </ul>
                                </div>
                            </div> */}

                {/* <div className="row row-gutter-y-20">
                  <div className="col-md-12 text-center">
                    <Link to="#">Engage our Broker today</Link>
                  </div>
                </div> */}
                <div className="row row-gutter-y-0">
                  <div className="col-md-12 text-center">
                    <Link
                      to="/talk-to-a-broker"
                      className="thm-btn thm-btn-broker-btn rounded-pill">
                      <b>Engage our Broker today</b>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default ConstructionLoansBody;
