import { Link } from "react-router-dom";
import React from "react";
import BGImg2 from "../../assets/sf-n-logo.png";
import BGImg from "../../assets/images/media/secure_finance-2.png";
import Modal from "react-modal";
// import { faTimes } from "@fortawesome/free-solid-svg-icons";
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
  overlay: { zIndex: 1000 },
};
const Footer = () => {
  const [open, setIsOpen] = React.useState(false);
  // openModal({ open: true });
  let subtitle;
  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    subtitle.style.color = "#f00";
  }
  const openModal = () => {
    setIsOpen(!open); //This will negate the previous state
  };

  const closeModal = () => {
    setIsOpen(!open); //This will negate the previous state
  };
  return (
    <>
      <footer className="main-footer">
        <div className="container">
          <div
            className="row row-gutter-y-30"
            style={{ justifyContent: "space-between" }}
          >
            <div className="col-lg-3 col-md-3">
              <div className="footer-widget footer-widget--about">
                <Link to="/" className="footer-widget__logo">
                  <img
                    src={BGImg}
                    style={{
                      maxWidth: "100%",
                      maxHeight: "100%",
                      marginRight: "0px",
                    }}
                    alt="secure-finance-logo"
                  />
                </Link>
                <p
                  className="footer-widget__text"
                  style={{ textAlign: "left" }}
                >
                  Secure Finance was founded to help Australians make their
                  dream of homeownership come true. Through building strong
                  relations with partners and clients alike, we hope to forge
                  the path to financial freedom together.
                </p>

                <div className="footer-widget__social mt-30">
                  <a
                    href="https://twitter.com/SecureFinance1"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fab fa-twitter"></i>
                  </a>

                  <a
                    href="https://www.facebook.com/SecureFinanceServices"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fab fa-facebook"></i>
                  </a>

                  <a
                    href="https://www.linkedin.com/company/secure-finance-solution/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fab fa-linkedin"></i>
                  </a>

                  <a
                    href="https://www.instagram.com/securefinanceservices/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fab fa-instagram"></i>
                  </a>
                </div>
                {/* <ul className="list-unstyled footer-widget__info">
                                <li>
                                    <i className="icon-email"></i>
                                    <Link to="mailto:needhelp@finlon.com">needhelp@finlon.com</Link>
                                </li>
                                <li>
                                    <i className="icon-telephone"></i>
                                    <Link to="tel:926668880000">92 666 888 0000 </Link>
                                </li>
                            </ul> */}
              </div>
            </div>
            {/* <div className="col-lg-2 col-md-3">
              <div className="footer-widget footer-widget--links ">
                <ul className="list-unstyled footer-widget__menu ">
                  <li>
                    <Link to="/about">Home Loans</Link>
                  </li>
                  <li>
                    <Link to="/news">Resources</Link>
                  </li>
                  <li>
                    <li>
                      <Link to="/contact">News</Link>
                    </li>
                    <li>
                      <Link to="/services">Calculators</Link>
                    </li>
                    <Link to="/contact">About</Link>
                  </li>
                  <li>
                    <Link to="/contact">Talk To A Broker</Link>
                  </li>
                </ul>
              </div>
            </div> */}
            <div className="col-lg-2 col-md-3">
              <div className="footer-widget footer-widget--links ">
                <h3 className="footer-widget__title">For Clients</h3>
                <ul className="list-unstyled footer-widget__menu ">
                  <li>
                    <Link to="/about">About Our Team</Link>
                  </li>
                  <li>
                    <Link to="/calculator">Calculators</Link>
                  </li>

                  {/* <li>
                    <Link to="/contact">FAQs</Link>
                  </li> */}
                  {/* <li>
                    <Link to="/services">Our Newsletter</Link>
                  </li> */}
                  <li>
                    <Link to="/resources">Resources</Link>
                  </li>
                  <li>
                    <Link to="/contact">Talk To A Broker</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-2 col-md-3">
              <div className="footer-widget footer-widget--links ">
                <h3 className="footer-widget__title">For Partners</h3>
                <ul className="list-unstyled footer-widget__menu ">
                  <li>
                    <Link to="/about">About Our Partners</Link>
                  </li>
                  <li>
                    <Link to="#">Become A Partner</Link>
                  </li>
                  <li>
                    <li>
                      <Link to="/contact">Partner Log-In</Link>
                    </li>
                    <li>
                      <Link to="/resources">Resources</Link>
                    </li>
                  </li>
                </ul>
              </div>
            </div>
            {/* <div className="col-lg-3 col-md-6">
                        <div className="footer-widget footer-widget--time">
                            <h3 className="footer-widget__title">
                                Timing
                            </h3>
                            <p className="footer-widget__text">
                                Mon Fri: 7:00am - 6:00pm <br/> Saturday: 9:00am - 5:00pm <br /> Sunday: Closed
                            </p>

                        </div>
                    </div> */}
            <div className="col-lg-3 col-md-3">
              <div className="footer-widget footer-widget--newsletter">
                <h3 className="footer-widget__title">Contact Us</h3>
                <ul
                  className="list-unstyled footer-widget__info"
                  style={{ fontStyle: "Montserrat", fontSize: "14px" }}
                >
                  <li>
                    <i className="icon-pin mb-4"></i>
                    <Link to="/map">
                      204/11 Solent Circuit, <br /> Norwest, NSW 2153, Australia
                    </Link>
                  </li>
                  <li>
                    <i className="icon-telephone mb-1"></i>
                    <Link to="tel:0404262056">0404 262 056</Link>
                  </li>
                  <li>
                    <i className="icon-email"></i>
                    <Link to="mailto:info@securefinance.com.au">
                      info@securefinance.com.au
                    </Link>
                  </li>
                  <li>
                    <i className="icon-user"></i>
                    <a className="contact-info-one__link" onClick={openModal}>
                      <div>
                        <Modal
                          isOpen={open}
                          onRequestClose={closeModal}
                          style={customStyles}
                          contentLabel="Example Modal"
                        >
                          <button
                            className="close-icon"
                            onClick={() => {
                              setIsOpen(false);
                            }}
                          >
                            {/* <Link className="close-button">
                            <FontAwesomeIcon icon={faTimes} />
                          </Link> */}
                          </button>
                          <iframe
                            title="Calendly Inline Widget"
                            src="https://calendly.com/securefinance-meetings"
                            style={{
                              width: "100%",
                              height: "500px",
                              border: "0",
                            }}
                            scrolling="no"
                          ></iframe>
                        </Modal>
                      </div>
                      Arrange a Virtual Meeting
                    </a>
                    {/* <Link to="/virtual-meeting">Arrange a Virtual Meeting</Link> */}
                  </li>
                </ul>
                {/* <form className="footer-widget__mailchimp">
                                <input type="email" placeholder="Email address" />
                                <p className="footer-widget__mailchimp__text">
                                    <i className="fa fa-check"></i>
                                    I agree to all your terms and policies
                                </p>
                            </form> */}
              </div>
            </div>
          </div>
        </div>
      </footer>
      <div className="bottom-footer">
        <div className="container">
          <p className="bottom-footer__text text-center">
            Copyright &copy; 2023 All Rights Reserved.
          </p>
        </div>
      </div>
    </>
  );
};

export default Footer;
