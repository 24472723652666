import React from "react";
import { Link } from "react-router-dom";
import BGImg from "../../../assets/user.png";
import BGS from "../../../assets/new-images/SMSFLoansprocess.png";
import BGS1 from "../../../assets/loan-pics/Assets Loan pic.jpg";
import BGS2 from "../../../assets/loan-pics/Construction loan 2.jpg";
import BGS3 from "../../../assets/loan-pics/first-home-buyers-loans.jpg";
import BGS4 from "../../../assets/loan-pics/Guarantor pic 2.jpg";
import BGS5 from "../../../assets/loan-pics/Investment pic.png";
import BGS6 from "../../../assets/loan-pics/Professional pic.jpg";
import BGS7 from "../../../assets/loan-pics/Loan pic.png";
import BGS8 from "../../../assets//loan-pics/owner occupied pic.jpg";
import BGS9 from "../../../assets/resourses/mortgage-blog-image.jpg";
import BGS10 from "../../../assets/loan-pics/SMSF-loans.jpg";

const loans = [
  {
    imageSrc: BGS3,
    title: "First Home Loans",
    text: "You'll never forget the first time you applied for a home loan. Just like you’ll never forget the time you were handed your first set of keys.",
    link: "/first-home-buyers-loan",
  },
  {
    imageSrc: BGS8,
    title: "Owner Occupied",
    text: "As the term suggests, an Owner-Occupied Loan is a loan you take to purchase a property you intend to reside in long term.",
    link: "/owner-occupied-loans",
  },
  {
    imageSrc: BGS5,
    title: "Investment Loans",
    text: "An investment interest rate applies to all home loans that are secured to purchase an investment property. Investment interest rates are typically higher.",
    link: "/investment-loans",
  },
  {
    imageSrc: BGS9,
    title: "Refinance Loan",
    text: "Refinancing your home loan means moving your home loan balance (the outstanding of your loan) from one lender to another.",
    link: "/refinance-loans",
  },
  {
    imageSrc: BGS2,
    title: "Construction Loans",
    text: "Whether you’re looking at a major renovation, taking a wrecking ball to an existing property, and re-building from scratch, a Construction Loan may meet your needs.",
    link: "/construction-loans",
  },
  {
    imageSrc: BGS4,
    title: "Guarantor Loans",
    text: "Guarantor home loans are a great option for borrowers who do not have the deposit required to purchase a home, or may enable you to avoid paying lenders mortgage insurance.",
    link: "/guarantor-home-loans",
  },
  {
    imageSrc: BGS6,
    title: "Loan for Professionals",
    text: "This specific loan is for specific professions (eg, doctor, dentist, accountant, or other highly-paid medical or other professional)",
    link: "/loan-for-professionals",
  },
  {
    imageSrc: BGS10,
    title: "SMSF Loans",
    text: "An SMSF loan or LRBA allows you to leverage the funds in your SMSF to purchase an investment property.",
    link: "/smsf-loans",
  },
  {
    imageSrc: BGS7,
    title: "Low Doc Home Loans",
    text: "A low doc loan is an abbreviation for a low documentation loan, also commonly referred to as an alt doc or alternative documentation loan.",
    link: "/low-doc-home-loans",
  },
  {
    imageSrc: BGS1,
    title: "Asset Finance",
    text: "Asset and Equipment Finance is a type of credit that is used to purchase a piece of equipment or asset for the purpose of own use.",
    link: "/asset-finance",
  },
  {
    imageSrc: BGS,
    title: "Commercial Loans",
    text: "A commercial property loan finances the purchase of property that is used for business purposes. Business purposes can include anything from general office.",
    link: "/commercial-property-loans",
  },
];
function First() {
  return (
    <>
      <section className="blog-grid pt-100 pb-40">
        <div className="container">
          <div className="row row-gutter-y-30">
            {loans.map((loan, index) => (
              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="blog-card">
                  <div className="blog-card__image">
                    <img
                      src={loan.imageSrc}
                      alt={loan.title}
                      style={{ height: "250px" }}
                    />
                    <Link to={loan.link}></Link>
                  </div>
                  <div className="blog-card__content">
                    <h3 className="blog-card__title">
                      <Link to={loan.link}>{loan.title}</Link>
                    </h3>
                    <p className="blog-card__text">{`${loan.text.substring(
                      0,
                      90
                    )} ...`}</p>
                    <Link to={loan.link} className="blog-card__link">
                      Read More
                    </Link>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
}

export default First;
