import React from "react";
import Bredcom from "../Bredcom/Main";
import InvestmentLoansBody from "../Services/Menservisedeatils/InvestmentLoansBody";
import BG from "../../assets/images/Banners/investment-loans.jpg";

const InvestmentLoans = () => {
  return (
    <>
      <Bredcom
        title={"Home"}
        subtitle={"Home Loans"}
        link={"Investment Loans"}
        img={BG}
      />
      <InvestmentLoansBody />
    </>
  );
};

export default InvestmentLoans;
