import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import BG from "../../../assets/notebook.png";
import BG1 from "../../../assets/sf-n-logo.png";
import Modal from "react-modal";
import { Link } from "react-router-dom";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import {
  faCalculator,
  faExclamationCircle,
  faChartArea,
  faHandshake,
  faQuestionCircle,
  faSearchLocation,
  faMapMarkerAlt,
  faPenFancy,
  faTimes,
  faHome,
  faMoneyBill,
} from "@fortawesome/free-solid-svg-icons";
import "./FourBoxRadio.css";

const RadioBox = ({ name, onChange, text, value, selectedValue }) => (
  <label className="four-box radio-box">
    <input
      style={{ display: "none" }}
      type="radio"
      name={name}
      value={value}
      onChange={onChange}
      checked={selectedValue === value}
    />
    <div className="icon">
      {value === "Figuring out my borrowing power" && (
        <FontAwesomeIcon icon={faCalculator} style={{ fontSize: "1em" }} />
      )}
      {value === "Getting advice about my options" && (
        <FontAwesomeIcon icon={faExclamationCircle} />
      )}
      {value === "Comparing my home loan options" && (
        <FontAwesomeIcon icon={faChartArea} />
      )}
      {value === "Obtaining a loan as soon as possible" && (
        <FontAwesomeIcon icon={faHandshake} />
      )}
      {value === "signedContract" && (
        <FontAwesomeIcon icon={faPenFancy} style={{ fontSize: "1em" }} />
      )}
      {value === "foundProperty" && <FontAwesomeIcon icon={faMapMarkerAlt} />}
      {value === "stillLooking" && <FontAwesomeIcon icon={faSearchLocation} />}
      {value === "lookingNow" && <FontAwesomeIcon icon={faQuestionCircle} />}
      {value === "I’ll Live There" && <FontAwesomeIcon icon={faHome} />}
      {value === "Investment Property" && (
        <FontAwesomeIcon icon={faMoneyBill} />
      )}
    </div>
    <p>{text}</p>
  </label>
);

const FourBoxRadio = ({ onChange, selectedValue }) => {
  const [showInput, setShowInput] = useState(true);
  const [showInput1, setShowInput1] = useState(false);
  const [showInput2, setShowInput2] = useState(false);
  const [showInput3, setShowInput3] = useState(false);
  const [showInput4, setShowInput4] = useState(false);
  const [showInput5, setShowInput5] = useState(false);
  const [showInput6, setShowInput6] = useState(false);
  const [showInput7, setShowInput7] = useState(false);
  const [showInput8, setShowInput8] = useState(false);
  const [showInput9, setShowInput9] = useState(false);
  const [value1, setRadioValue1] = useState("");
  const [value2, setRadioValue2] = useState("");
  const [value3, setRadioValue3] = useState("");
  const [income, setIncomeType] = useState("");
  const [price, setPrice] = useState("");
  const [fullName, setFullName] = useState("");
  const [price1, setPrice1] = useState("");
  const [error, setError] = useState("");
  const [error1, setError1] = useState("");
  const [amount, setAmount] = useState("");
  const [frequency, setFrequency] = useState("Annually");
  const [selectedOption, setSelectedOption] = useState("None");
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [phone, setPhone] = useState("");
  const [phoneType, setPhoneType] = useState("");

  const handleInputFocus = () => {
    // Clear error on input focus
    setError("");
  };
  const handleInputFocus1 = () => {
    // Clear error on input focus
    setError1("");
  };
  const handleInputFocus2 = () => {
    // Clear error on input focus
    setError("");
  };
  const handleKeyDown = (e) => {
    // Check if the pressed key is "Enter"
    if (e.key === "Enter") {
      handleValidateClick();
    }
  };
  const handleValidateClick = () => {
    // Validate input
    if (!price.trim()) {
      setError("Purchase price  must not be empty");
    } else if (!/^\d+$/.test(price)) {
      setError("Please enter only numbers");
    } else {
      const numericValue = parseInt(price, 10);
      if (numericValue < 20000 || numericValue > 20000000) {
        setError("Purchase price must be between $20,000 and $20,000,000");
      } else {
        setShowInput1(false);
        setShowInput2(true);
        setError("");
      }
    }
  };
  const handleKeyDown1 = (e) => {
    // Check if the pressed key is "Enter"
    if (e.key === "Enter") {
      handleValidateClick1();
    }
  };
  const handleValidateClick1 = () => {
    // Validate input
    if (!price1.trim()) {
      setError1("Deposit must be between $10,000 and $20,000,000");
    } else if (!/^\d+$/.test(price1)) {
      setError1("Please enter only numbers");
    } else {
      const numericValue = parseInt(price1, 10);
      if (numericValue < 10000 || numericValue > 20000000) {
        setError1("Deposit must be below purchase price");
      } else {
        setShowInput2(false);
        setShowInput3(true);
        setError1("");
      }
    }
  };
  const handleKeyDown2 = (e) => {
    // Check if the pressed key is "Enter"
    if (e.key === "Enter") {
      handleValidateClick3();
    }
  };
  const handleValidateClick2 = (e) => {
    setIncomeType(e);
    setShowInput5(false);
    setShowInput6(true);
  };
  const handleValidateClick3 = () => {
    setShowInput6(false);
    setShowInput7(true);
  };
  const handleKeyDown3 = (e) => {
    // Check if the pressed key is "Enter"
    if (e.key === "Enter") {
      handleValidateClick4();
    }
  };
  const handleValidateClick4 = () => {
    setShowInput7(false);
    setShowInput8(true);
  };
  const handleKeyDown4 = (e) => {
    // Check if the pressed key is "Enter"
    if (e.key === "Enter") {
      handleValidateClick5();
    }
  };
  const handleValidateClick5 = () => {
    setShowInput8(false);
    setShowInput9(true);
    setModalIsOpen(true);
    // setError1("");
  };
  const [to, setTo] = useState("");
  const [subject, setSubject] = useState("");
  const [text, setText] = useState("");
  const navigate = useNavigate();
  const handleSubmit = async () => {
    setTo("haseebj53@gmail.com");
    setSubject("test");
    setText({
      "Which of the following is most important to you?": value1,
      "How close are you to buying?": value2,
      "Will you live there, or will it be an investment property?": value3,
      "How do you want to structure your repayments?": income,
      "What is the purchase price?": price,
      "How much deposit have you saved?": price1,
      "Full Name": fullName,
      "How many other properties do you own?": selectedOption,
      "Phone Number": phone,
      "Phone Type": phoneType,
      "What's your combined income?": amount,
      "What's your income frequency type?": frequency,
    });
    const requestBody = {
      to: "recipient@example.com",
      subject: "Subject of the email",
      data: "Email content or data",
    };
    try {
      // Use fetch API
      const response = await fetch("http://localhost:3001/send-email", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ to, subject, text }),
      });

      // If using Axios
      // const response = await axios.post("http://localhost:3001/send-email", { to, subject, data });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const responseData = await response.json();
      console.log("Email sent:", responseData, text);
      navigate("/");
    } catch (error) {
      console.error("Error sending email:", error);
    }
  };
  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    const numericValue = inputValue.replace(/\D/g, "");
    setPrice(numericValue);
  };
  const handleInputChange1 = (e) => {
    const inputValue = e.target.value;
    const numericValue = inputValue.replace(/\D/g, "");
    setPrice1(numericValue);
  };
  const handleInputName = (e) => {
    const inputValue = e.target.value;
    setFullName(inputValue);
  };
  const handleRadioChange = (event) => {
    const value = event.target.value;
    onChange(value);
    setRadioValue1(value);
    setShowInput(false);
    setShowInput1(true);
  };
  const handleRadioChange1 = (event) => {
    const value = event.target.value;
    onChange(value);
    setRadioValue2(value);
    setShowInput3(false);
    setShowInput4(true);
  };
  const handleRadioChange2 = (event) => {
    const value = event.target.value;
    onChange(value);
    setRadioValue3(value);
    setShowInput4(false);
    setShowInput5(true);
  };

  const handleSelectChange = (event) => {
    // Update the selected option when the dropdown value changes
    setSelectedOption(event.target.value);
  };

  const handleAmountChange = (event) => {
    // Validate input (allow only numbers)
    const input = event.target.value.replace(/[^0-9]/g, "");
    setAmount(input);
  };

  const handleFrequencyChange = (event) => {
    setFrequency(event.target.value);
  };

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };
  const handlePhoneNumber = (event) => {
    // Validate input (allow only numbers)
    const input = event.target.value.replace(/[^0-9]/g, "");
    setPhone(input);
  };

  const handlePhoneTypeChange = (e) => {
    const selectedPhoneType = e.target.value;
    setPhoneType(selectedPhoneType);
  };

  return (
    <div className="four-box-container">
      <div className="four-box-sidebar">
        <img
          className="logo1"
          src={BG1}
          width="100%"
          alt="Logo"
          style={{ marginTop: "50px" }}
        />
        {/* Sidebar Content */}
        <img
          className="logo"
          src={BG}
          width="100%"
          alt="Logo"
          style={{ marginTop: "50px" }}
        />
        {/* <div className="contact-info">
          <h3>Mortgage Comparison</h3> <br />
          <h4>Compare online in 30 seconds.</h4>
        </div> */}

        <div className="contact-info">
          <h5>Mortgage Comparison</h5> <br />
          <h6>Compare online in 30 seconds.</h6>
          <p>Email: info@securefinance.com.au</p>
          <p>Phone: (123) 456-7890</p>
        </div>

        <div className="sidebar-text">
          <hr />
          <p>
            © 2023 Secure Finance Pty Ltd. ABN 81 609 882 804 Australian Credit
            Licence Number 508 308. Head Office in Norwest, NSW. By registering
            you are agreeing with our Privacy Policy.
          </p>
        </div>
      </div>

      <div className="four-box-content">
        {showInput && (
          <>
            <h5>Which of the following is most important to you?</h5>
            <div className="four-box-radio-group">
              <RadioBox
                name="user_meta-howClose"
                onChange={handleRadioChange}
                text="Figuring out my borrowing power"
                value="Figuring out my borrowing power"
                selectedValue={selectedValue}
              />
              <RadioBox
                name="user_meta-howClose"
                onChange={handleRadioChange}
                text="Getting advice about my options"
                value="Getting advice about my options"
                selectedValue={selectedValue}
              />
              <RadioBox
                name="user_meta-howClose"
                onChange={handleRadioChange}
                text="Comparing my home loan options"
                value="Comparing my home loan options"
                selectedValue={selectedValue}
              />
              <RadioBox
                name="user_meta-howClose"
                onChange={handleRadioChange}
                text="Obtaining a loan as soon as possible"
                value="Obtaining a loan as soon as possible"
                selectedValue={selectedValue}
              />
            </div>
          </>
        )}
        {showInput1 && (
          <div className={`input-section ${showInput1 ? "active" : ""}`}>
            <h4>What is the purchase price?</h4>
            <h6>
              If you’re not sure, tell us the maximum price you’re looking at.
            </h6>
            {/*
      Add a console log to check if the input section is rendering correctly
    */}
            {/* {console.log("Input Section Rendered")} */}
            <img
              className="img-price"
              src={require("../../../assets/icons/onboarding_documents.png")}
              alt="Price Illustration"
            />
            <input
              type="text"
              name="price"
              placeholder="Enter purchase price"
              value={`$ ${price}`}
              onChange={handleInputChange}
              onFocus={handleInputFocus}
              onKeyDown={handleKeyDown}
              style={{ borderColor: error ? "red" : "" }}
              // Disable input if there is an error or showInput2 is true
            />
            {error && <p style={{ color: "red" }}>{error}</p>}

            {/* <input
              type="text"
              name="price"
              placeholder="Enter purchase price"
              value={`$${price}`}
              onChange={handleInputChange}
              onFocus={handleInputFocus}
              style={{ borderColor: error ? "red" : "" }}
            />
            {error && <p style={{ color: "red" }}>{error}</p>} */}
            <div style={{ display: "flex", alignItems: "center" }}>
              <Link
                style={{ marginRight: "10px" }}
                disabled={error || showInput}
                className="thm-btn thm-btn--dark-hover rounded"
                onClick={handleValidateClick}>
                Next Step →
              </Link>
              <span style={{ margin: "0 50px" }}>|</span>
              <p style={{ margin: "0" }}>Press ENTER</p>
            </div>

            {/* <Link
              disabled={error || showInput2}
              className="thm-btn thm-btn--dark-hover rounded"
              onClick={handleValidateClick}>
              <b>Next Step →</b>
            </Link>
            <p>press ENTER</p> */}
            {/* <button>Next Step →</button> */}
          </div>
        )}
        {showInput2 && (
          <div className={`input-section ${showInput2 ? "active" : ""}`}>
            <h4>How much deposit have you saved?</h4>
            <h6>
              If you haven’t finished saving, your approximate end goal is fine.
            </h6>
            {/*
      Add a console log to check if the input section is rendering correctly
    */}
            {/* {console.log("Input Section Rendered")} */}
            {/* <img
              className="img-price"
              src={require("../../assets/icons/onboarding_documents.png")}
              alt="Price Illustration"
            /> */}

            <input
              type="text"
              name="deposit"
              placeholder="Enter deposit"
              value={`$ ${price1}`}
              onChange={handleInputChange1}
              onFocus={handleInputFocus1}
              onKeyDown={handleKeyDown1}
              style={{ borderColor: error1 ? "red" : "" }}
            />
            {error1 && <p style={{ color: "red" }}>{error1}</p>}
            {/* Your CurrencyField component or input field goes here */}
            {/* Your Navigation component or button goes here */}
            <div style={{ display: "flex", alignItems: "center" }}>
              <Link
                className="thm-btn thm-btn--dark-hover rounded"
                onClick={handleValidateClick1}>
                <b>Next Step →</b>
              </Link>
              <span style={{ margin: "0 50px" }}>|</span>
              <p style={{ margin: "0" }}>Press ENTER</p>
            </div>
            {/* <span style={{ margin: "0 50px" }}>|</span> */}
            {/* <p style={{ margin: "0" }}>Press ENTER</p> */}
            {/* <button>Next Step →</button> */}
          </div>
        )}

        {showInput3 && (
          <>
            <h5>How close are you to buying?</h5>
            <div className="four-box-radio-group">
              <RadioBox
                name="user_meta-howClose"
                onChange={handleRadioChange1}
                text="I've signed the contract"
                value="signedContract"
                selectedValue={selectedValue}
              />
              <RadioBox
                name="user_meta-howClose"
                onChange={handleRadioChange1}
                text="I’ve found a great property"
                value="foundProperty"
                selectedValue={selectedValue}
              />
              <RadioBox
                name="user_meta-howClose"
                onChange={handleRadioChange1}
                text="I am still looking for the right place"
                value="stillLooking"
                selectedValue={selectedValue}
              />
              <RadioBox
                name="user_meta-howClose"
                onChange={handleRadioChange1}
                text="I’m just starting to look around"
                value="lookingNow"
                selectedValue={selectedValue}
              />
            </div>
          </>
        )}
        {showInput4 && (
          <>
            <div className={`input-section ${showInput4 ? "active" : ""}`}>
              <h5>
                Will you live there, or will it be an investment property?
              </h5>
              <p>
                Lenders often provide discounts for owner-occupied properties.
              </p>

              <div
                className="four-box-radio-group"
                style={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr",
                  gap: "25px",
                  justifyContent: "space-between",
                }}>
                <RadioBox
                  name="user_meta-howClose"
                  onChange={handleRadioChange2}
                  text="I’ll Live There"
                  value="I’ll Live There"
                  selectedValue={selectedValue}
                />
                <RadioBox
                  name="user_meta-howClose"
                  onChange={handleRadioChange2}
                  text="Investment Property"
                  value="Investment Property"
                  selectedValue={selectedValue}
                />
              </div>
            </div>
          </>
        )}
        {showInput5 && (
          <div className={`input-section ${showInput5 ? "active" : ""}`}>
            <h5>How do you want to structure your repayments?</h5>
            <p>
              Principal & Interest generally attracts a lower interest rate and
              helps you pay down the loan sooner.
            </p>

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}>
              <Link
                className="thm-btn thm-btn--dark-hover rounded"
                onClick={() => {
                  handleValidateClick2("Principal and Interest");
                }}
                style={{ flex: 1, marginRight: "10px" }}>
                <b>Principal and Interest</b>
              </Link>
              <Link
                className="thm-btn thm-btn--dark-hover rounded"
                onClick={() => {
                  handleValidateClick2("Interest Only");
                }}
                style={{ flex: 1, marginLeft: "10px" }}>
                <b>Interest Only</b>
              </Link>
            </div>
          </div>
        )}
        {showInput6 && (
          <>
            <div className={`input-section ${showInput6 ? "active" : ""}`}>
              <h5>How many other properties do you own?</h5>
              <p>
                Some lenders offer better rates or cashbacks if you refinance
                additional properties.
              </p>

              <div style={{ position: "relative" }}>
                <select
                  id="dropdown"
                  value={selectedOption}
                  onChange={handleSelectChange}
                  onKeyDown={handleKeyDown2}
                  style={{
                    padding: "10px", // Adjust padding as needed
                    borderRadius: "8px", // Adjust border-radius as needed
                    width: "100%", // Adjust width as needed
                  }}>
                  <option value="">Choose an option</option>
                  <option value="none">None</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6+">6+</option>
                </select>
              </div>
              <br />
              <div style={{ display: "flex", alignItems: "center" }}>
                <Link
                  className="thm-btn thm-btn--dark-hover rounded"
                  onClick={handleValidateClick3}>
                  <b>Next Step →</b>
                </Link>
                <span style={{ margin: "0 50px" }}>|</span>
                <p style={{ margin: "0" }}>Press ENTER</p>
              </div>
            </div>
          </>
        )}
        {showInput7 && (
          <>
            <div>
              <h5>What's your combined income?</h5>
              <p>
                Your (plus your partner’s) income, including any rental income,
                before tax.
              </p>

              <div className="combined-input-container">
                <div className="amount-input-container">
                  <span className="prefix-dollar">$</span>
                  <input
                    type="text"
                    value={amount}
                    onChange={handleAmountChange}
                    onKeyDown={handleKeyDown3}
                    placeholder="Enter amount"
                    className="amount-input"
                  />
                  <select
                    value={frequency}
                    onChange={handleFrequencyChange}
                    className="select-input">
                    <option value="Annually">Annually</option>
                    <option value="Fortnightly">Fortnightly</option>
                    <option value="Monthly">Monthly</option>
                    <option value="Weekly">Weekly</option>
                  </select>
                </div>
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <Link
                  className="thm-btn thm-btn--dark-hover rounded"
                  onClick={handleValidateClick4}>
                  <b>Skip</b>
                </Link>
                <span style={{ margin: "0 50px" }}>|</span>
                <p style={{ margin: "0" }}>Press ENTER</p>
              </div>
            </div>
          </>
        )}
        {showInput8 && (
          <>
            <div className={`input-section ${showInput8 ? "active" : ""}`}>
              <h5>Finally, what's your name?</h5>
              <p>Our search results are personalised for you!</p>
              <div className={`input-section ${showInput8 ? "active" : ""}`}>
                <input
                  type="text"
                  name="fullName"
                  required
                  placeholder="Enter your full name"
                  value={`${fullName}`}
                  onChange={handleInputName}
                  onFocus={handleInputFocus2}
                  onKeyDown={handleKeyDown4}
                />
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Link
                    className="thm-btn thm-btn--dark-hover rounded"
                    onClick={handleValidateClick5}>
                    <b>Finish</b>
                  </Link>
                  <span style={{ margin: "0 50px" }}>|</span>
                  <p style={{ margin: "0" }}>Press ENTER</p>
                </div>
              </div>
              <img
                className="img-price1"
                src={require("../../../assets/images/page_illustrations/success.png")}
                alt="Page-Illustration"
              />
            </div>
          </>
        )}
        {showInput9 && (
          <Modal
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            className="modal-overlay"
            overlayClassName="custom-overlay">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}>
              <button className="close-icon" onClick={closeModal}>
                <Link className="close-button">
                  <FontAwesomeIcon icon={faTimes} />
                </Link>
              </button>
              <div className="col-md-5">
                <h1
                  style={{
                    fontSize: "24px",
                    marginBottom: "20px",
                    lineHeight: "1.5",
                    textAlign: "justify",
                  }}>
                  We’ve identified 450 loans that may be suitable for you.
                </h1>
                <h3
                  style={{
                    fontSize: "14px",
                    color: "#575E6C",
                    lineHeight: "1.5",
                    textAlign: "justify",
                  }}>
                  Please confirm your contact details below so we can qualify
                  you for the best unadvertised deals.
                </h3>

                <div
                  className={`input-section ${
                    showInput9 ? "active" : ""
                  }`}></div>
                <div className="combined-input-container">
                  <div className="amount-input1-container">
                    <input
                      type="text"
                      value={phone}
                      onChange={handlePhoneNumber}
                      placeholder="Enter Phone Number"
                      className="amount-input1"
                    />

                    <select
                      value={phoneType}
                      onChange={handlePhoneTypeChange}
                      className="select-input1">
                      <option value="Mobile">Mobile</option>
                      <option value="Landline">Landline</option>
                      <option value="Office">Office</option>
                      <option value="Fax">Fax</option>
                    </select>
                  </div>
                  <br />
                  <br />
                </div>
                <Link
                  style={{
                    paddingLeft: "30px",
                    paddingRight: "30px",
                    marginLeft: "10px",
                  }}
                  className="thm-btn thm-btn--dark-hover rounded"
                  onClick={handleSubmit}>
                  <b>Submit</b>
                </Link>
              </div>
              <div className="col-md-5">
                <img
                  className="imgprice"
                  src={require("../../../assets/images/page_illustrations/sms.png")}
                  alt="Page-Illustration"
                />
                <ul className="list-unstyled ml-0 service-details__list">
                  <li>
                    <i className="fa fa-check-circle"></i>
                    We negotiate better rates
                  </li>
                  <li>
                    <i className="fa fa-check-circle"></i>
                    Our service is completely free
                  </li>
                  <li>
                    <i className="fa fa-check-circle"></i>
                    Utilise our team of specialists
                  </li>
                  <li>
                    <i className="fa fa-check-circle"></i>
                    Easy online application process
                  </li>
                </ul>
              </div>
            </div>
          </Modal>
        )}
      </div>
    </div>
  );
};

export default FourBoxRadio;
