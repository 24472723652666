import React, { useRef, useEffect } from "react";
import BGImg from "../../assets/images/media/meeting.jpg";
import Img2 from "../../assets/new-icons/web-home.png";
import Img1 from "../../assets/new-icons/web-refinance.png";
import Fade from "react-reveal/Fade";
// import useScrollAnimation from './Animatetext/useScrollAnimation';
import SlideUpOnScroll from "./SlideUpOnScroll";

function Company() {
  // const element1Ref = useRef(null);
  // const element2Ref = useRef(null);

  // useScrollAnimation(0.75, 1.5)(element1Ref.current);
  // useScrollAnimation(0.6, 2)(element2Ref.current);

  return (
    <>
      <section className="about-four pt-40 pb-40">
        <div className="about-four__shape"></div>
        <div className="container">
          <div className="row row-gutter-y-150 mt-0">
            {/* <SlideUpOnScroll
              id="section1"
              duration={1000}
              tension={20}
              friction={14}> */}
            {/* <Fade bottom big> */}
            <p className="block-title__tagline text-center ">WHERE TO START</p>
            <div>
              {/* <p className="block-title__tagline text-center">
                      WHERE TO START
                    </p> */}
              <h2 className="block-title__title text-center">
                Buying a Family Home or an Investment Property?
              </h2>
            </div>
            {/* </Fade> */}
            {/* <Fade bottom big cascade> */}
            {/* </SlideUpOnScroll> */}
            <div className="col-lg-8">
              <div className="about-four__content">
                {/* <div className="block-title">
                    <h2
                      className="block-title__title fade-up-in"
                      style={{ textAlign: "left" }}>
                      <h2>
                        Buying a family home or <br /> Purchasing an investment
                        property
                      </h2>
                    </h2>
                  </div> */}
                {/* <SlideUpOnScroll
                    id="section3"
                    duration={2000}
                    tension={500}
                    friction={12}> */}
                {/* <Fade bottom big cascade> */}
                <div className="about-four__box" style={{ marginLeft: "0px" }}>
                  <img
                    src={Img2}
                    style={{
                      width: "150px",
                      height: "auto",

                      alignContent: "start",
                      alignItems: "start",
                      alignSelf: "start",
                    }}
                  />
                  {/* <div className="about-four__box__icon">
                        <i className="icon-house"></i>
                    </div> */}
                  <div className="about-four__box__content mt-2">
                    <h3 className="about-four__box__title">
                      <b>Looking to Purchase your 1st home?</b> <br />
                    </h3>
                    <p style={{ width: "56%" }}>
                      Whether you are still searching for your ideal home,
                      Secure Finance is here to guide you as you embark upon
                      this new journey into homeownership!
                    </p>
                  </div>
                </div>
                {/* </Fade> */}
                {/* </SlideUpOnScroll> */}
                {/* <SlideUpOnScroll
                    id="section4"
                    duration={2300}
                    tension={500}
                    friction={12}> */}
                {/* <Fade bottom big cascade> */}
                <div className="about-four__box" style={{ margin: "0" }}>
                  <img
                    src={Img1}
                    style={{
                      width: "150px",
                      height: "auto",
                      alignContent: "start",
                      alignItems: "start",
                      alignSelf: "start",
                    }}
                  />
                  {/* <div className="about-four__box__icon">
                        <i className="icon-loan"></i>
                    </div> */}
                  <div className="about-four__box__content">
                    <h3 className="about-four__box__title">
                      <b>Want a lower rate for your existing loan?</b>
                      <br />
                    </h3>
                    <p style={{ width: "56%" }}>
                      Better features or you simply want know if there is a home
                      loan that better meet your needs? Review your Refinancing
                      options with us!
                    </p>
                  </div>
                </div>
                {/* </Fade> */}
                {/* </SlideUpOnScroll> */}
                {/* <p className="about-four__text">
                    Alteration in some form by injected humour. Duis aute irure
                    dolor lipsum is simply free text available in the local
                    markets in reprehenderit.
                    </p> */}

                {/* <div className="row row-gutter-y-20">
                    <div className="col-md-6">
                        <div className="about-four__feature">
                        <div className="about-four__feature__content">
                            <div className="about-four__feature__icon">
                            <i className="icon-confirmation"></i>
                            </div>
                            <h3 className="about-four__feature__title">
                            Direct card payment
                            </h3>
                        </div>
                        <div className="about-four__feature__text">
                            Lorem ipsum dolor sit ame ed consectetur nod.
                        </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="about-four__feature">
                        <div className="about-four__feature__content">
                            <div className="about-four__feature__icon">
                            <i className="icon-confirmation"></i>
                            </div>
                            <h3 className="about-four__feature__title">
                            Direct card payment
                            </h3>
                        </div>
                        <div className="about-four__feature__text">
                            Lorem ipsum dolor sit ame ed consectetur nod.
                        </div>
                        </div>
                    </div>
                    </div> */}
              </div>
            </div>
            {/* </Fade> */}
            <div className="col-lg-4 main-menu__nav-1 mt-2">
              <div className="about-four__image">
                <div className="about-four__image__bg"></div>
                <div className="about-four__image__shape"></div>
                <img
                  src={BGImg}
                  style={{
                    maxWidth: "200px",
                    maxHeight: "350px",
                    marginTop: "20px",
                  }}
                  alt="where-to-start-your-loan"
                />
                {/* <div className="about-four__image__caption">
                    <h3 className="about-four__image__caption__year">
                        25<i>+</i>
                    </h3>
                    <p className="about-four__image__caption__text">
                        Years Experience
                    </p>
                    </div> */}
                {/* <div className="about-four__image__floated">Finance</div> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Company;
