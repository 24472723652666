import React from "react";
import { Link } from "react-router-dom";
import BG from "../../../assets/resourses/home-buyer-application.jpg";
import BG1 from "../../../assets/resourses/young-woman-checking-her-phone.jpg";
import BG2 from "../../../assets/resourses/property-investment.png";
import BG3 from "../../../assets/resourses/home-buyers-rejoice.png";
import BG4 from "../../../assets/resourses/HomeBargainsSuburbDetails.jpg";
import FirstHomeBuyerSmall from "../../../assets/resourses/firstHomeBuyersSmall.jpg";
import TurnToBroker from "../../../assets/resourses/turnToBroker.jpg";
import SeaAndTree from "../../../assets/resourses/seaAndTree.jpg";

const serviceCardsData = [
  {
    image: SeaAndTree,
    icon: "icon-car",
    /*title: "Why three-in-four Aussies turn to a broker for home loan help",*/
    /*text: "You might have seen a headline or two about a particular big bank being at war with brokers. Nothing could be further from the truth. Our mission is – and always will be – putting you first.",*/
    title: "Is a tree or sea change on your horizon?",
    text: "Fresh air, no bumper-to-bumper traffic and affordable home prices. There’s plenty of appeal in regional living, including a chance to reduce your home loan.",
    Link: "/tree-or-sea-change",
  },
  {
    image: TurnToBroker,
    icon: "icon-car",
    /*title: "Why three-in-four Aussies turn to a broker for home loan help",*/
    /*text: "You might have seen a headline or two about a particular big bank being at war with brokers. Nothing could be further from the truth. Our mission is – and always will be – putting you first.",*/
    title: "Why three-in-four Aussies turn to a broker",
    text: "You might have seen a headline or two about a particular big bank being at war with brokers.",
    Link: "/turn-to-broker",
  },
  {
    image: FirstHomeBuyerSmall,
    icon: "icon-car",
    title: "First home buyers turn to Bank of Nan and Pop",
    text: "Nan and Pop have always been good for birthday money, but one-in-10 grandparents are taking their generosity to the next level: helping their grandkids buy a first home. ",
    Link: "/first-home-buyers",
  },
  {
    image: BG,
    icon: "icon-car",
    title: "Self-Employed Home Loan Applications",
    text: "Applying for a mortgage when you’re self-employed may have you jumping through more hoops.",
    Link: "/home-loan-applications",
  },
  {
    image: BG1,
    icon: "icon-diamond",
    title: "Interest Rates",
    text: "Happy days! The Reserve Bank kept rates steady in February. But a shake-up in the number of.",
    Link: "/interest-rates",
  },
  {
    image: BG2,
    icon: "icon-house",
    title: "Property Investment",
    text: "Lending to property investors is soaring once again. We lift the lid on what’s driving investor.",
    Link: "/property-investment",
  },
  {
    image: BG3,
    icon: "icon-house",
    title: "Buyers Rejoice",
    text: "Great news for home buyers! After an extended run of low listings, the number of homes coming onto the market is skyrocketing",
    Link: "/home-buyers-rejoice",
  },
  {
    image: BG4,
    icon: "icon-house",
    title: "Bargain Suburbs",
    text: "Location may be a big driver of property prices, but in any given suburb a few streets can be all that separates paying top dollar for a home or potentially scoring a bargain.",
    Link: "/home-bargain-suburbs",
  },
  /* {
    image: BG4,
    icon: "icon-house",
    title: "Bargain Suburbs",
    text: "Location may be a big driver of property prices, but in any given suburb a few streets can be all that separates paying top dollar for a home or potentially scoring a bargain.",
    Link: "/home-bargain-suburbs",
  },*/
];

const First = () => {
  return (
    <>
      <section className="service-grid pt-120 pb-140">
        <div className="container">
          <div className="row row-gutter-y-50">
            {serviceCardsData.map((card, index) => (
              <div className="col-lg-4 col-md-6 col-sm-12" key={index}>
                <div className="service-card">
                  <div className="service-card__image">
                    <img
                      height={"242px"}
                      width={"364px"}
                      src={card.image}
                      alt={card.title}
                    />
                    <Link to={card.Link}></Link>
                  </div>
                  <div
                    className="service-card__content"
                    style={{ maxHeight: "150px" }}
                  >
                    <div className="service-card__content__inner">
                      {/* <div className="service-card__icon">
                        <i className={card.icon}></i>
                      </div> */}
                      <h3 className="service-card__title">
                        <Link to={card.Link}>{card.title}</Link>
                      </h3>
                      <p className="service-card__text modernWay">
                        {card.text}
                      </p>
                      <Link to={card.Link} className="service-card__link">
                        <i className="fa fa-angle-right"></i>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default First;
