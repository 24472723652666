// InterestRateCard.js
import React from "react";

const InterestRateCard = ({ interestRate }) => {
  return (
    <div className="card">
      <h2>Interest Rate</h2>
      <p>{interestRate}%</p>
    </div>
  );
};

export default InterestRateCard;
