import React, { useState, useEffect } from "react";
import { NavLink, Navigate } from "react-router-dom";
import Form from "react-bootstrap/Form";
import "../scss/style.scss";
import Aux from "../hoc/_Aux";
import Breadcrumb from "../Breadcrumb";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const SignUp1 = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [userLogged, setUserLogged] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [token, setToken] = useState("");
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  useEffect(() => {
    const fetchData = async () => {
      try {
        // Set loading to true while waiting for the response
        setLoading(true);

        // Perform your HTTP request (replace 'your_api_endpoint' with the actual endpoint)
        const response = await fetch("http://localhost:3030/authentication", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ strategy: "local", email, password }),
        });

        // Check if the request was successful (status code 2xx)
        if (response.ok) {
          const result = await response.json();
          setTimeout(() => {
            setUserLogged(true);
            //   handleGetUsers();
          }, 5000);
          toast.success("Login successful", {
            position: toast.POSITION.TOP_CENTER,
          });
          // Update state with the received data
          setData(result);
          console.log("Login successful:", result);
        } else {
          // Handle HTTP error
          setError("Failed to fetch data");
          const errorMessage = await response.text();
          // toast.error("Login failed", {
          //   position: toast.POSITION.TOP_CENTER,
          // });
          setErrorMessage(errorMessage);
          console.error("Login failed:", errorMessage);
        }
      } catch (error) {
        // Handle other errors, e.g., network issues
        // toast.error("Login failed", {
        //   position: toast.POSITION.TOP_CENTER,
        // });
        console.error("Error during login:", error);
        setError("Error fetching data");
      } finally {
        // Set loading to false when the request is complete (either success or failure)
        setLoading(false);
      }
    };

    // Call the fetchData function when the component mounts
    fetchData();
  }, []);

  const handleLogin = async () => {
    try {
      const response = await fetch("http://localhost:3001/login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, password }),
      });
      console.log(response);
      if (response.ok) {
        setTimeout(() => {
          setUserLogged(true);
          //   handleGetUsers();
        }, 5000);
        const data = await response.json();
        toast.success("Login successful", {
          position: toast.POSITION.TOP_CENTER,
        });
        setData(data);
        setToken(() => data.accessToken);

        console.log("Login successful:", data);
      } else {
        const errorMessage = await response.text();
        toast.error("Login failed", {
          position: toast.POSITION.TOP_CENTER,
        });
        setErrorMessage(errorMessage);
        console.error("Login failed:", errorMessage);
      }
    } catch (error) {
      toast.error("Login failed", {
        position: toast.POSITION.TOP_CENTER,
      });
      console.error("Error during login:", error);
    }
  };
  const handleGetUsers = async () => {
    console.log(token);
    try {
      const response = await fetch("http://localhost:3030/users", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.ok) {
        const data = await response.json();
        console.log("Signup successful:", data);
      } else {
        const errorMessage = await response.text();
        console.error("fetching failed:", errorMessage);
      }
    } catch (error) {
      console.error("Error during user fetch:", error);
    }
  };
  if (userLogged) {
    return <Navigate to="/upload-blogs" />;
  }

  return (
    <Aux>
      <Breadcrumb />
      <div className="auth-wrapper">
        <div className="auth-content">
          <div className="auth-bg">
            <span className="r" />
            <span className="r s" />
            <span className="r s" />
            <span className="r" />
          </div>
          <ToastContainer position="top-center" autoClose={5000} />
          <div className="card">
            <div className="card-body text-center">
              <div className="mb-4">
                <i className="feather icon-unlock auth-icon" />
              </div>
              <h3 className="mb-4">Login</h3>
              <div className="input-group mb-3">
                <input
                  type="email"
                  className="form-control"
                  placeholder="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className="input-group mb-4">
                <input
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="form-control"
                  placeholder="password"
                />
              </div>
              <div className="form-group text-left">
                <div className="checkbox checkbox-fill d-inline">
                  <input
                    type="checkbox"
                    name="checkbox-fill-1"
                    id="checkbox-fill-a1"
                  />
                </div>
              </div>
              <button
                className="btn btn-primary shadow-2 mb-4"
                onClick={handleLogin}>
                Login
              </button>
              <p className="mb-2 text-muted">
                Forgot password?
                <NavLink to="/auth/reset-password-1">Reset</NavLink>
              </p>
              <p className="mb-0 text-muted">
                Don’t have an account? <NavLink to="/signup">Signup</NavLink>
              </p>
            </div>
          </div>
        </div>
      </div>
    </Aux>
  );
};

export default SignUp1;
