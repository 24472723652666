import React from "react";
import Bredcom from "../Bredcom/Main";
import LowDocHomeLoansBody from "../Services/Menservisedeatils/LowDocHomeLoansBody";
import BG from "../../assets/images/Banners/low-doc-home-loans.jpg";

const LowDocHomeLoans = () => {
  return (
    <>
      <Bredcom
        title={"Home"}
        subtitle={"Home Loans"}
        link={"Low Doc Home Loans"}
        img={BG}
      />
      <LowDocHomeLoansBody />
    </>
  );
};

export default LowDocHomeLoans;
