import React from "react";
import Bredcom from "../Bredcom/Main";
import BG from "../../assets/loan-pics/loans.jpg";
import CreateBlog from "./CreateBlog";

const BlogsForm = () => {
  return (
    <>
      {/* <Bredcom title={"Home"} subtitle={"Resourses"} link={"Create Blog"} /> */}
      <CreateBlog />
    </>
  );
};

export default BlogsForm;
