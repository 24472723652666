import React from "react";
import Bredcom from '../../Bredcom/Main'
import First from "../../Mencompare/First";

const CompareEMI = () => {
  
  return (
    <>
      <Bredcom title={"Home"} subtitle={"EMI COMPARISON"} link={"EMI Comparison"}/>
      <First/>
    </>
  );
};

export default CompareEMI;
