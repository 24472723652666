import logo from "../../assets/images/media/bubbles.svg";

import React, { useState } from "react";
import Modal from "react-modal";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
  overlay: { zIndex: 1000 },
};
function Map() {
  const [open, setIsOpen] = React.useState(false);
  // openModal({ open: true });
  let subtitle;
  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    subtitle.style.color = "#f00";
  }
  const openModal = () => {
    setIsOpen(!open); //This will negate the previous state
  };

  const closeModal = () => {
    setIsOpen(!open); //This will negate the previous state
  };
  return (
    <>
      <section className="contact-info-one">
        <div className="container">
          <h1 className="contact-info-one__title text-center mt-5">
            Which home loan best suits you?
          </h1>
          <h6 className="contact-info-one__subtitle text-center">
            At times the solution does not seem so straightforward. Our team is
            here to understand your true needs and to offer our expert opinion
            on your best options. Our brokers will guide you through, be it your
            venture into homeownership or investment, and iron out any lingering
            doubts.
          </h6>

          <div className="row">
            <div className="col-md-3 col-sm-12">
              <div className="contact-info-one__item">
                <div className="contact-info-one__icon col-md-12">
                  <i className="icon-telephone"></i>
                </div>
                <br />
                <div className="contact-info-one__content col-sm-12 text-center">
                  {/* <p className="contact-info-one__text">Have any question?</p> */}
                  <a
                    className="contact-info-one__link"
                    href="tel:+61-(04)262-056">
                    0404 262 056
                  </a>
                </div>
              </div>
            </div>

            <div className="col-md-3 col-sm-12">
              <div className="contact-info-one__item">
                <div className="contact-info-one__icon col-sm-12">
                  <svg
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    width="50"
                    height="50"
                    viewBox="0 0 16 16">
                    <path
                      fill="#01BBBB"
                      d="M5 6h2v2h-2zM8 6h2v2h-2zM11 6h2v2h-2zM2 12h2v2h-2zM5 12h2v2h-2zM8 12h2v2h-2zM5 9h2v2h-2zM8 9h2v2h-2zM11 9h2v2h-2zM2 9h2v2h-2zM13 0v1h-2v-1h-7v1h-2v-1h-2v16h15v-16h-2zM14 15h-13v-11h13v11z"></path>
                  </svg>
                </div>
                <div className="contact-info-one__content col-sm-12 text-center">
                  {/* <p className="contact-info-one__text">Book a Face-to-Face Meeting</p> */}
                  <a
                    className="contact-info-one__link "
                    href="/face-to-face-meeting">
                    Book a Face-to-Face Meeting
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-3 col-sm-12">
              <div className="contact-info-one__item">
                <div className="contact-info-one__icon col-sm-12">
                  {/* <i className="icon-bubbles"></i> */}
                  <svg
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    width="50"
                    height="50"
                    viewBox="0 0 18 16">
                    <path
                      fill="#01BBBB"
                      d="M17 14.081c0 0.711 0.407 1.327 1 1.628v0.249c-0.166 0.023-0.335 0.035-0.508 0.035-1.063 0-2.021-0.446-2.699-1.16-0.41 0.109-0.844 0.168-1.293 0.168-2.485 0-4.5-1.791-4.5-4s2.015-4 4.5-4c2.485 0 4.5 1.791 4.5 4 0 0.865-0.309 1.665-0.834 2.32-0.107 0.232-0.166 0.489-0.166 0.761zM3.604 3.456c-1.035 0.841-1.604 1.922-1.604 3.044 0 0.63 0.175 1.24 0.52 1.815 0.356 0.592 0.89 1.134 1.547 1.566 0.474 0.312 0.793 0.812 0.878 1.373 0.028 0.187 0.046 0.376 0.053 0.564 0.117-0.097 0.23-0.201 0.342-0.312 0.377-0.377 0.887-0.586 1.414-0.586 0.084 0 0.168 0.005 0.252 0.016 0.327 0.042 0.662 0.063 0.994 0.063v2c-0.424-0-0.84-0.027-1.246-0.079-1.718 1.718-3.77 2.027-5.753 2.072v-0.421c1.071-0.525 2-1.48 2-2.572 0-0.152-0.012-0.302-0.034-0.448-1.809-1.192-2.966-3.012-2.966-5.052 0-3.59 3.582-6.5 8-6.5 4.351 0 7.89 2.822 7.997 6.336-0.642-0.286-1.341-0.46-2.067-0.509-0.18-0.876-0.709-1.7-1.535-2.371-0.552-0.448-1.202-0.803-1.932-1.054-0.777-0.267-1.606-0.402-2.464-0.402s-1.687 0.135-2.464 0.402c-0.73 0.251-1.38 0.605-1.932 1.054z"></path>
                  </svg>
                </div>
                <div className="contact-info-one__content col-sm-12 text-center">
                  {/* <p className="contact-info-one__text">Book a Virtual Meeting</p> */}
                  <a className="contact-info-one__link" onClick={openModal}>
                    <div>
                      <Modal
                        isOpen={open}
                        onRequestClose={closeModal}
                        style={customStyles}
                        contentLabel="Example Modal">
                        <button
                          className="close-icon"
                          onClick={() => {
                            setIsOpen(false);
                          }}>
                          <Link className="close-button">
                            <FontAwesomeIcon icon={faTimes} />
                          </Link>
                        </button>
                        <iframe
                          title="Calendly Inline Widget"
                          src="https://calendly.com/securefinance-meetings"
                          style={{
                            width: "100%",
                            height: "500px",
                            border: "0",
                          }}
                          scrolling="no"></iframe>
                      </Modal>
                    </div>
                    Book a Virtual Meeting
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-3 col-sm-12">
              <div className="contact-info-one__item">
                <div className="contact-info-one__icon col-sm-12">
                  <i className="icon-email"></i>
                </div>
                <div className="contact-info-one__content col-sm-12 text-center">
                  {/* <p className="contact-info-one__text">Write us email</p> */}
                  <a
                    className="contact-info-one__link"
                    href="mailto:info@securefinance.com.au">
                    info@securefinance.com.au
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Map;
