import { initializeApp } from "firebase/app";
import {
  getAuth,
  RecaptchaVerifier,
  signInWithPhoneNumber,
} from "firebase/auth";
// Twilio: If you lose your phone, or don’t have access to your verification device, this code is your failsafe to access your account.
// NKEZH3VDN79H78939MQYW3UN
import { Link } from "react-router-dom";
import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
// import { auth } from "../../firebase";
import BGImg from "../../assets/sf-n-logo.png";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import ReCAPTCHA from "react-google-recaptcha";

const firebaseConfig = {
  apiKey: "AIzaSyDw5lOlu1aPtqd8Rk-IhDq5WDBBFHoc1Rw",
  authDomain: "finance-496ac.firebaseapp.com",
  projectId: "finance-496ac",
  storageBucket: "finance-496ac.appspot.com",
  messagingSenderId: "46895283110",
  appId: "1:46895283110:web:3313affdb2481512f9ed67",
};
const showSuccessNotification = (message) => {
  toast.success(message, {
    position: toast.POSITION.TOP_CENTER,
    autoClose: 3000,
  });
};

const showErrorNotification = (message) => {
  toast.error(message, {
    position: toast.POSITION.TOP_CENTER,
    autoClose: 3000,
  });
};

const OtpInput = ({ index, value, onChange, onFocus, onKeyDown }) => {
  return (
    <input
      type="text"
      maxLength="1"
      value={value}
      onChange={onChange}
      onFocus={onFocus}
      onKeyDown={onKeyDown}
      tabIndex={index + 1}
      className="otp-input"
    />
  );
};
function SignIn() {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const [verificationCode, setVerificationCode] = useState("");
  const [confirmationResult, setConfirmationResult] = useState(null);
  const [recaptchaValue, setRecaptchaValue] = useState("");

  const [userId, setUserId] = useState("");
  // const [phoneNumber, setPhoneNumber] = useState('');
  const [otp, setOtp] = useState("");
  const [message, setMessage] = useState("");
  const sendOTP = async () => {
    try {
      const response = await axios.post("http://localhost:3001/api/send-otp", {
        userId,
        phoneNumber,
      });
      setMessage(response.data.message);
    } catch (error) {
      console.error(error);
    }
  };

  const verifyOTP = async () => {
    try {
      const response = await axios.post(
        "http://localhost:3001/api/verify-otp",
        {
          userId,
          enteredOtp: otp,
        }
      );
      setMessage(response.data.message);
    } catch (error) {
      console.error(error);
      setMessage("Error verifying OTP");
    }
  };

  const formatPhoneNumber = (input) => {
    const formattedNumber = input.replace(/\D/g, "");
    const limitedNumber = formattedNumber.substring(0, 9);
    const formattedPhoneNumber = limitedNumber.replace(
      /(\d{3})\s*(\d{3})\s*(\d{3})/,
      "$1 $2 $3"
    );
    const finalPhoneNumber = formattedPhoneNumber.replace(/^0+/, "");
    const cleanedNumber = finalPhoneNumber.replace(/\s/g, "");
    console.log(cleanedNumber, finalPhoneNumber);
    if (finalPhoneNumber.length === 11) {
      setErrorMessage("");
      console.log(61 + cleanedNumber);
      setPhoneNumber(61 + phoneNumber);
      console.log(phoneNumber);
    } else {
      setErrorMessage("Please provide an Australian mobile phone number");
    }
    setPhoneNumber(61 + cleanedNumber);
    console.log(phoneNumber);
  };

  // const handleSendCode = async () => {
  //   const app = initializeApp(firebaseConfig);
  //   const auth = getAuth(app);

  //   try {
  //     const recaptchaVerifier = new RecaptchaVerifier("recaptcha-container", {
  //       size: "invisible",
  //     });

  //     const confirmation = await signInWithPhoneNumber(
  //       auth,
  //       phoneNumber,
  //       recaptchaVerifier
  //     );
  //     setConfirmationResult(confirmation);
  //   } catch (error) {
  //     console.error("Error sending code:", error.message);
  //   }
  // };
  // const handleVerifyOTP = async () => {
  //   const response = await fetch("http://localhost:3000/verify-otp", {
  //     method: "POST",
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //     body: JSON.stringify({ userEnteredOTP }),
  //   });

  //   const result = await response.json();
  //   console.log(result);
  // };

  const [userEnteredOTP, setUserEnteredOTP] = useState("");
  const [shouldRenderOtpInput, setShouldRenderOtpInput] = useState(false);

  const [generationStatus, setGenerationStatus] = useState("");

  const handleGenerateOTP = async () => {
    try {
      // Make a POST request to the backend to generate OTP
      const response = await fetch("http://localhost:3000/generate-otp", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        // You can send any additional data required by your backend for OTP generation
        // (e.g., user ID, phone number, etc.)
      });

      const result = await response.json();
      if (result.success) {
        setGenerationStatus("OTP generated successfully");
        showSuccessNotification("OTP generated successfully");
        setShouldRenderOtpInput(true);
      } else {
        setGenerationStatus("Failed to generate OTP");
        showErrorNotification("Failed to generate OTP");
      }
    } catch (error) {
      console.error("Error generating OTP:", error.message);
      setGenerationStatus("Error generating OTP");
      showErrorNotification("Error generating OTP");
    }
  };

  const handleVerifyCode = async () => {
    try {
      if (confirmationResult) {
        await confirmationResult.confirm(verificationCode);
        console.log("Phone number verified successfully!");
      } else {
        console.error("Confirmation result is undefined.");
      }
    } catch (error) {
      console.error("Error verifying code:", error.message);
    }
  };

  // const [otp, setOtp] = useState(["", "", "", "", "", ""]);

  const handleOtpChange = (index, inputValue, dynamicId) => {
    const newOtp = [...otp];
    newOtp[index] = inputValue;
    setOtp(newOtp);

    // Move to the next input field
    if (inputValue && index < otp.length - 1) {
      const nextInput = document.getElementById(dynamicId + 1);
      console.log(
        "nextInput",
        nextInput.id,

        newOtp,
        inputValue,
        index,
        otp.length - 1
      );
      if (nextInput) {
        nextInput.focus();
      }
    }
  };

  const handleKeyDown = (index, event) => {
    // Handle backspace to move focus to the previous input field
    if (event.key === "Backspace" && index > 0) {
      document.getElementById(`otp-input-${index - 1}`).focus();
    }
  };

  const handleVerifyOTP = () => {
    // Add logic to verify the OTP here
    console.log("Verifying OTP:", otp.join(""));
  };

  return (
    <>
      <section className="contact-one pt-120 pb-120">
        <div className="container">
          <div className="row  row-gutter-y-30">
            <Link to="/" className="text-center">
              <img
                src={BGImg}
                style={{
                  marginTop: "-5px",
                  maxWidth: "240px",
                  maxHeight: "280px",
                }}
                alt="secure-finance"
              />
            </Link>
            <div className="col-lg-12">
              <div className="contact-one__content">
                <div className="block-title">
                  <h2 className="block-title__title text-center">
                    Sign in to Secure Finance
                  </h2>
                </div>
                <div className="col-md-12 d-flex justify-content-center">
                  <div className="col-md-6 text-center">
                    <p className="contact-one__text  text-center">
                      To access your application or create a new account, enter
                      your mobile phone number and receive a one-time passcode
                      via SMS.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="row row-gutter-y-20 row-gutter-x-20">
                <div className="col-md-12 d-flex justify-content-center">
                  <div className="col-md-4">
                    {/* <input
                        type="number"
                        placeholder="Phone Number"
                        name="number"
                        className="form-control rounded"
                      /> */}

                    {generationStatus === "OTP generated successfully" ? (
                      <div className="otp-container">
                        {shouldRenderOtpInput &&
                          otp.map((value, index) => (
                            <OtpInput
                              key={index}
                              index={index}
                              value={value}
                              onChange={(e) => {
                                const dynamicId = `otp-input-${index}`;
                                e.target.id = dynamicId; // Set the id dynamically
                                handleOtpChange(
                                  index,
                                  e.target.value,
                                  dynamicId
                                );
                              }}
                              onFocus={(e) => {
                                const dynamicId = `otp-input-${index}`;
                                e.target.id = dynamicId; // Set the id dynamically
                                console.log("Element ID:", dynamicId);

                                const otpInputElement =
                                  document.getElementById(dynamicId);
                                if (otpInputElement) {
                                  otpInputElement.focus();
                                }
                              }}
                              onKeyDown={(e) => handleKeyDown(index, e)}
                            />
                          ))}
                      </div>
                    ) : (
                      <div class="input-container">
                        <div class="country-code">
                          <span class="mobile-icon">
                            <i className="icon-telephone"></i>
                          </span>
                          (<span id="countryCode">+61</span>)
                        </div>
                        <input
                          type="text"
                          className="input-field"
                          placeholder="XXX XXX XXX"
                          value={phoneNumber}
                          onChange={(e) => formatPhoneNumber(e.target.value)}
                        />
                      </div>
                    )}

                    {/* <input
                      type="text"
                      placeholder="Enter verification code"
                      value={verificationCode}
                      onChange={(e) => setVerificationCode(e.target.value)}
                    />
                    <button onClick={handleVerifyCode}>Verify Code</button> */}
                    {/* <div>
                        <input
                          type="text"
                          placeholder="Enter verification code"
                          value={code}
                          onChange={(e) => setCode(e.target.value)}
                        />
                        <button onClick={handleVerifyCode}>Verify Code</button>
                      </div> */}
                    <p
                      style={{
                        color: "red",
                        fontSize: "14px",
                      }}>
                      {errorMessage ? (
                        <div>
                          <i className="fa fa-times-circle"></i>
                          {errorMessage}
                        </div>
                      ) : null}
                    </p>
                  </div>
                </div>
                <div className="col-md-12 text-center">
                  {generationStatus === "OTP generated successfully" ? (
                    <Link
                      type="button"
                      className="thm-btn thm-btn--dark-hover rounded"
                      onClick={handleVerifyOTP}>
                      Verify ONE-TIME PASSCODE
                    </Link>
                  ) : (
                    <Link
                      type="button"
                      className="thm-btn thm-btn--dark-hover rounded"
                      onClick={sendOTP}>
                      GET ONE-TIME PASSCODE
                    </Link>
                  )}
                </div>
                <div className="col-md-12 d-flex justify-content-center">
                  <div className="col-md-6 text-center">
                    <p style={{ fontSize: "14px" }}>
                      We are collecting your personal information in order to
                      assist you with your home loan application or any other
                      ancillary products. By clicking the 'GET ONE-TIME
                      PASSCODE' button you have read, consented and agree to be
                      bound <br />
                      by{" "}
                      <Link style={{ textDecoration: "underline" }}>
                        Secure Finance’s Privacy Policy.
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ToastContainer />
        </div>
      </section>
    </>
  );
}

export default SignIn;
