import React from "react";
import { Helmet } from "react-helmet";
import "../../../assets/css/calc.css";

function HomeLoanOffsetCalculator() {
  return (
    <>
      <Helmet>
        <title>Home Loan Offset Calculator - Secure Finance</title>
        <meta
          name="description"
          content="Use our Home Loan Offset Calculator to see how an offset account can save you interest and reduce your loan term. Input your loan amount, interest rate, loan term, and offset account balance to view your results."
        />
        <link
          rel="canonical"
          href="https://securefinance.com.au/home-loan-offset-calculator"
        />
      </Helmet>
      <section className="benefit-one pt-60 pb-60">
        <div className="container">
          <div className="row row-gutter-y-60">
            <iframe
              className="VisiCalcClass"
              id="Home_Loan_Offset_Calculator"
              src="//www.visionabacus.net/Tools/B3/SuiteA/A300/Home_Loan_Offset_Calculator/SecureFinance"
              frameBorder="0"
              scrolling="no"
              title="Home Loan Offset Calculator"></iframe>
          </div>
        </div>
      </section>
    </>
  );
}

export default HomeLoanOffsetCalculator;
