import { Link } from "react-router-dom";
import React from "react";
import Img2 from "../../assets/new-icons/application-approval.png";
import Img1 from "../../assets/new-icons/submit-for-loan.png";
import Img from "../../assets/new-icons/borrowing-capacity.png";
// import Fade from "react-reveal/Fade";

function Safe() {
  const services = [
    {
      img: Img,
      icon: "icon-magnifying-glass",
      title: "Step 1",
      subtitle: "Borrowing Capacity",
      text: "To start we will assess your Borrowing Capacity and gather the required documentation. If you already have a property in mind and would like to apply for the loan at a later date, you may consider obtaining a pre-approval.",
      link: "/servicesdetails",
    },
    {
      img: Img1,
      icon: "icon-audit",
      title: "Step 2",
      subtitle: "Submit For Loan",
      text: "Once you have obtained the Contract of Sale or would like to proceed with a refinance, our brokers will assist you to submit your loan application. If successful, you will receive an Approval or Conditional Approval from the lender.",
      link: "/servicesdetails",
    },
    {
      img: Img2,
      icon: "icon-confirmation",
      title: "Step 3",
      subtitle: "Approval",
      text: "Once all remaining requirements are met, the bank will then issue a Settlement Letter in which the loan start date will be stated. And from here, you are now a homeowner with an amicable repayment plan.",
      link: "/servicesdetails",
    },
  ];

  return (
    <>
      {/* <section className="work-process pb-120">
        <div className="work-process__shape-1"></div>
        <div className="work-process__shape-2"></div>
        <div className="work-process__shape-3"></div>
        <div className="container">
          <div className="block-title text-center">
            <p className="block-title__tagline">Work process</p>
            <h2 className="block-title__title">Credit repair works in 3 eas <br /> simple steps</h2>
          </div>
          <div className="row row-gutter-y-30">
            {workProcessSteps.map((step, index) => (
              <div
                className="col-lg-4 col-md-6 col-sm-12 wow fadeInUp"
                data-wow-duration="1500ms"
                data-wow-delay={`${index * 100}ms`}
                key={index}
              >
                <div className="work-process__item">
                  <div className="work-process__icon">
                    <i className={step.icon}></i>
                  </div>
                  <h3 className="work-process__title">
                    <Link to="#">{step.title}</Link>
                  </h3>
                  <p className="work-process__text">{step.text}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section> */}

      <section
        className="service-two pb-40 pt-40 mb--120 "
        style={{ textAlign: "justify" }}>
        <div className="service-two__shape"></div>
        <div className="container">
          <div className="service-two__inner" data-wow-duration="1500ms">
            <div className="block-title text-center">
              {/* <SlideUpOnScroll
                id="section1"
                duration={1200}
                tension={100}
                friction={14}> */}
              {/* <Fade bottom big cascade> */}
              <div>
                <p className="block-title__tagline">HOW IT WORKS</p>

                {/* </SlideUpOnScroll> */}
                {/* <SlideUpOnScroll
                id="section2"
                duration={1500}
                tension={300}
                friction={12}> */}

                <h2 className="block-title__title">
                  How the process will take place
                </h2>
              </div>
              {/* </Fade> */}

              {/* </SlideUpOnScroll> */}
            </div>
            <div className="row row-gutter-y-20 row-gutter-x-60 ">
              {services.map((service, index) => (
                // <Fade right big cascade>
                <div
                  className="col-lg-4 col-md-12 service-card-two-container"
                  key={index}>
                  {/* <SlideSideOnScroll
                    id={`section${index}`}
                    duration={`${index * 400 + 2000}`}
                    tension={300}
                    friction={12}> */}
                  <div
                    className="service-card-two"
                    style={{ borderRadius: "30px" }}>
                    <h6 className="service-card-two__title">
                      <Link to={service.link}>{service.title}</Link>
                    </h6>
                    <div className="service-card-two__shape mt-5"></div>
                    <div className="service-card-two__icon">
                      <img src={service.img} />
                      {/* <i className={service.icon}></i> */}
                    </div>
                    <h6 className="service-card-two__subtitle text-center">
                      {service.subtitle}
                    </h6>
                    <div className="service-card-two__content">
                      <p className="service-card-two__text">{service.text}</p>
                      <Link
                        to={service.link}
                        style={{ textDecoration: "underline" }}
                        className="service-card-two__link">
                        More Detail
                      </Link>
                    </div>
                  </div>
                  {/* </SlideSideOnScroll> */}
                </div>
                // </Fade>
              ))}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Safe;
