import React from "react";
import { Helmet } from "react-helmet";
import "../../../assets/css/calc.css";

function HowLongtoRepayCalculator() {
  return (
    <>
      <Helmet>
        <title>How Long To Repay Calculator - Secure Finance</title>
        <meta
          name="description"
          content="Use our How Long To Repay Calculator to estimate the time 
          required to repay your loan based on your loan amount, interest rate,
           and monthly repayment. View your loan term, total interest payable, 
           and total payments."
        />
        <link
          rel="canonical"
          href="https://securefinance.com.au/how-long-to-repay-calculator"
        />
      </Helmet>
      <section className="benefit-one pt-60 pb-60">
        <div className="container">
          <div className="row row-gutter-y-60">
            <iframe
              className="VisiCalcClass"
              id="How_Long_to_Repay_Calculator"
              src="//www.visionabacus.net/Tools/B3/SuiteA/A300/How_Long_to_Repay_Calculator/SecureFinance"
              frameBorder="0"
              scrolling="no"
              title="How Long To Repay Calculator"></iframe>
          </div>
        </div>
      </section>
    </>
  );
}

export default HowLongtoRepayCalculator;
