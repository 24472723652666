import { Link, useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";
import BGImg from "../../assets/images/media/secure_finance-2.png";
import Button from "react-bootstrap/Button";
const table = [
  { id: "0", title: "First Home Buyers Loans" },
  { id: "1", title: "Owner Occupied Loans" },
  { id: "2", title: "Investment Loans" },
  { id: "3", title: "Refinance Loans" },
];
const table1 = [
  { id: "4", title: "Construction Loans" },
  { id: "5", title: "Guarantor Loans" },
  { id: "6", title: "Loans for Professionals" },
  { id: "7", title: "SMSF Loans" },
];
const table2 = [
  { id: "8", title: "Low-Doc Loans" },
  { id: "9", title: "Asset Finance" },
  { id: "10", title: "Commercial Loans" },
];

const Navbar = () => {
  const [mobile, setmobile] = useState(false);
  const [search, setsearch] = useState(false);
  const [homeDrop, sethomeDrop] = useState(false);
  const [headerDrop, setheaderDrop] = useState(false);
  const [servicesDrop, setservicesDrop] = useState(false);
  const [calculatorsDrop, setcalculatorsDrop] = useState(false);
  const [featuresDrop, setfeaturesDrop] = useState(false);
  const [newsDrop, setnewsDrop] = useState(false);
  const [pagesDrop, setpagesDrop] = useState(false);
  const location = useLocation();
  const path = location.pathname;
  const [menu, setmenu] = useState({});

  const toggleMenu = () => {
    setservicesDrop(!servicesDrop);
  };

  const closeMenu = () => {
    setservicesDrop(false);
  };
  const closeMobileMenu = () => {
    setmobile(false);
    sethomeDrop(false);
    setheaderDrop(false);
    setservicesDrop(false);
    setcalculatorsDrop(false);
    setfeaturesDrop(false);
    setnewsDrop(false);
    setpagesDrop(false);
  };

  const activeMenu = () => {
    if (
      path === "/" ||
      path === "/home-02" ||
      path === "/home-03" ||
      path === "/first-home-buyers-loan" ||
      path === "/owner-occupied-loans" ||
      path === "/investment-loans" ||
      path === "/refinance-loans" ||
      path === "/construction-loans"
    ) {
      setmenu({ home: true });
    } else if (
      path === "/team" ||
      path === "/our-partners" ||
      path === "/teamdetails" ||
      path === "/creditaudit" ||
      path === "/creditrepair" ||
      path === "/careers" ||
      path === "/faqs" ||
      path === "/applynow"
    ) {
      setmenu({ about: true });
    } else if (
      path === "/income-tax-calculator" ||
      path === "/borrowing-capacity-calculator" ||
      path === "/stamp-duty-calculator" ||
      path === "/lmi-calculator" ||
      path === "/repayment-calculator"
    ) {
      setmenu({ calculators: true });
    } else if (path === "/newsmain" || path === "/newsdetails") {
      setmenu({ news: true });
    } else if (path === "/contact") {
      setmenu({ talktoabroker: true });
    } else if (path === "/guide-for-first-home-buyers") {
      setmenu({ resourses: true });
    }
  };

  const [sticky, setSticky] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", isSticky);
    return () => {
      window.removeEventListener("scroll", isSticky);
    };
  }, []);

  useEffect(() => {
    window.scroll(0, 0);
    activeMenu();
  }, [path]);
  const isSticky = () => {
    const scrollTop = window.scrollY;
    scrollTop >= 141 ? setSticky(true) : setSticky(false);
  };
  const rowsPerColumn = 4;
  const rowCount = Math.ceil(table.length / rowsPerColumn);

  // Create an array of arrays, where each inner array represents a column
  const columns = Array.from({ length: rowCount }, (_, index) =>
    table.slice(index * rowsPerColumn, (index + 1) * rowsPerColumn)
  );
  return (
    <>
      <div className="page-wrapper">
        <div className="topbar">
          <div className="container-fluid">
            <div className="topbar__info">
              <Link to="/map">
                <i className="icon-pin"></i> 204/11 Solent Circuit, Norwest, NSW
                2153, Australia
              </Link>
            </div>
            <div className="topbar__info" style={{ paddingLeft: "150px" }}>
              <Link to="mailto:info@securefinance.com.au">
                <i className="icon-email" style={{ marginRight: "10px" }}></i>{" "}
                info@securefinance.com.au
              </Link>
            </div>
            {/* <div className="topbar__links">
                        <Link to="#">Login</Link>
                        <Link to="#">Company News</Link>
                        <Link to="/faqs">FAQs</Link>
                    </div> */}
            <div className="topbar__social">
              <a
                href="https://www.facebook.com/SecureFinanceServices"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fab fa-facebook"></i>
              </a>
              <a
                href="https://www.instagram.com/securefinanceservices/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fab fa-instagram"></i>
              </a>
              <a
                href="https://twitter.com/SecureFinance1"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fab fa-twitter"></i>
              </a>
              <a
                href="https://www.linkedin.com/company/secure-finance-solution/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fab fa-linkedin"></i>
              </a>
            </div>
          </div>
        </div>
        <nav
          className={`main-menu ${
            sticky && "stricky-header stricked-menu stricky-fixed"
          }`}
        >
          <div className="container-fluid">
            <Link
              to="#"
              onClick={() => setmobile(true)}
              className="main-menu__toggler mobile-nav__toggler"
            >
              <i className="fa fa-bars" style={{ fontSize: "25px" }}></i>
            </Link>
            <div className="main-menu__logo">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="main-menu__logo__shape-1"
                viewBox="0 0 317 120"
              >
                <path d="M259.856,120H0V0H274l43,37.481Z" />
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="main-menu__logo__shape-2"
                viewBox="0 0 317 120"
              >
                <path d="M259.856,120H0V0H274l43,37.481Z" />
              </svg>
              <Link to="/">
                <img
                  src={BGImg}
                  style={{
                    marginTop: "-5px",
                    maxWidth: "240px",
                    maxHeight: "280px",
                  }}
                  alt="secure-finance"
                />
              </Link>
              {/* <div className="slider-one__image" style={{ backgroundImage: `url(${BGImg})`}} width="140" height="51"></div> */}
            </div>
            <div className="main-menu__nav ">
              <ul className="main-menu__list">
                <li className={`home ${menu.home && "current"}`}>
                  <Link to="/all-loans">Home Loans</Link>
                  {/* <ul style={{ listStyle: 'none', width:'600px' }}>
                    {
                        table.map((item, index) => (
                            <><li className='col-6' style={{ width:'300px' }} key={item.id}><Link>{item.title}</Link></li><li className='col-6' style={{ width:'300px' }}  key={item.id}><Link>{item.title}</Link></li></>
                        ))
                    }
                </ul> */}
                  <ul>
                    <table>
                      <tr>
                        <td>
                          {
                            <>
                              <li>
                                <Link
                                  to="/first-home-buyers-loan"
                                  style={{ textWrap: "nowrap" }}
                                >
                                  First Home Buyers Loans
                                </Link>
                              </li>
                              <li>
                                <Link
                                  to="/owner-occupied-loans"
                                  style={{ textWrap: "nowrap" }}
                                >
                                  Owner Occupied Loans
                                </Link>
                              </li>
                              <li>
                                <Link
                                  to="/investment-loans"
                                  style={{ textWrap: "nowrap" }}
                                >
                                  Investment Loans
                                </Link>
                              </li>
                              <li>
                                <Link
                                  to="/refinance-loans"
                                  style={{ textWrap: "nowrap" }}
                                >
                                  Refinance Loans
                                </Link>
                              </li>
                            </>
                            // table.map((item) => (
                            //     <li key={item.id}><Link style={{ fontSize:'12px'}}>{item.title}</Link></li>
                            // ))
                          }
                        </td>
                        <td>
                          {
                            <>
                              <li>
                                <Link
                                  to="/construction-loans"
                                  style={{ textWrap: "nowrap" }}
                                >
                                  Construction Loans
                                </Link>
                              </li>
                              <li>
                                <Link
                                  to="/guarantor-home-loans"
                                  style={{ textWrap: "nowrap" }}
                                >
                                  Guarantor Loans
                                </Link>
                              </li>
                              <li>
                                <Link
                                  to="/loan-for-professionals"
                                  style={{ textWrap: "nowrap" }}
                                >
                                  Loans for Professionals
                                </Link>
                              </li>
                              <li>
                                <Link
                                  to="/smsf-loans"
                                  style={{ textWrap: "nowrap" }}
                                >
                                  SMSF Loans
                                </Link>
                              </li>
                            </>
                            // table.map((item) => (
                            //     <li key={item.id}><Link style={{ fontSize:'12px'}}>{item.title}</Link></li>
                            // ))
                          }
                        </td>
                        <td>
                          {
                            <>
                              <li>
                                <Link
                                  to="/low-doc-home-loans"
                                  style={{ textWrap: "nowrap" }}
                                >
                                  Low-Doc Loans
                                </Link>
                              </li>
                              <li>
                                <Link
                                  to="/asset-finance"
                                  style={{ textWrap: "nowrap" }}
                                >
                                  Asset Finance
                                </Link>
                              </li>
                              <li>
                                <Link
                                  to="/commercial-property-loans"
                                  style={{ textWrap: "nowrap" }}
                                >
                                  Commercial Loans
                                </Link>
                              </li>
                              <li>
                                <Link
                                  to="/all-loans"
                                  style={{ textWrap: "nowrap" }}
                                >
                                  See All Loans
                                </Link>
                              </li>
                            </>
                            // table.map((item) => (
                            //     <li key={item.id}><Link style={{ fontSize:'12px'}}>{item.title}</Link></li>
                            // ))
                          }
                        </td>

                        {/* <td style={{width:'33%',}}> {
                        table1.map((item) => (
                            <li key={item.id}><Link style={{ fontSize:'12px'}}>{item.title}</Link></li>
                        ))
                    }</td>
                    <td style={{width:'33%'}}> {
                        table2.map((item) => (
                            <li key={item.id}  ><Link style={{ fontSize:'12px'}}>{item.title}</Link></li>
                        ))
                    }</td> */}
                      </tr>
                    </table>
                    {/* {
                        table.map((item) => (
                            <li key={item.id}><Link>{item.title}</Link></li>
                        ))
                    } */}
                  </ul>
                  {/* <ul>
                                    <li><Link to="/">First Home Buyers Loans</Link> </li>
                                    <li><Link to="/">Owner Occupied Loans</Link> </li>
                                    <li><Link to="/">Investment Loans</Link> </li>
                                    <li><Link to="/home-02">Refinance Loans</Link></li>

                                    <li><Link to="/home-02">Construction Loans</Link></li>
                                    <li><Link to="/home-02">Guarantor Loans</Link></li>
                                    <li><Link to="/home-02">Loans for Professionals</Link></li>

                                    <li><Link to="/home-03">SMSF Loan</Link></li>
                                    <li><Link to="/home-03">Low-Doc Loans</Link></li>
                                    <li><Link to="/home-03">Asset Finance</Link></li>

                                    <li><Link to="/home-03">Commercial Loans</Link></li>

                                    <li className="dropdown">
                                        <Link to="#">Header Styles</Link>
                                        <ul>
                                            <li><Link to="/">Header One</Link></li>
                                            <li><Link to="/home-02">Header Two</Link></li>
                                            <li><Link to="/home-03">Header Three</Link></li>
                                        </ul>
                                    </li>
                                </ul> */}
                </li>

                <li className={`resourses ${menu.resourses && "current"}`}>
                  <Link to="/resources">Resources</Link>
                  <ul style={{ minWidth: "150px", width: "max-content" }}>
                    {/* <li>
                      <Link to="/guide-for-first-home-buyers">
                        First Home Buyers
                      </Link>
                    </li> */}
                    <li>
                      <Link to="/tree-or-sea-change">The Gum Trees</Link>
                    </li>
                    <li>
                      <Link to="/turn-to-broker">Aussies Turn to a Broker</Link>
                    </li>
                    <li>
                      <Link to="/first-home-buyers">First Home Buyers</Link>
                    </li>
                    <li>
                      <Link to="/home-loan-applications">
                        Home Loan Applications
                      </Link>
                    </li>
                    <li>
                      <Link to="/interest-rates">Interest Rates</Link>
                    </li>
                    <li>
                      <Link to="/property-investment">Property Investment</Link>
                    </li>
                    <li>
                      <Link to="/home-buyers-rejoice">Home Buyers Rejoice</Link>
                    </li>
                    <li>
                      <Link to="/home-bargain-suburbs">
                        Home Bargain Suburbs
                      </Link>
                    </li>
                    {/* <li>
                      <Link to="#">First Home Buyers</Link>
                    </li> */}
                  </ul>
                </li>
                {/*} <li className={`news ${menu.news && "current"}`}>
                  <Link to="/not-found">News</Link> 
                  {/* <ul>
                                    <li><Link to="/team">Team</Link></li>
                                    <li><Link to="/teamdetails">Team Details</Link></li>
                                    <li><Link to="/creditrepair">Credit Repair</Link></li>
                                    <li><Link to="/creditaudit">Credit Audit</Link></li>
                                    <li><Link to="/careers">Careers</Link></li>
                                    <li><Link to="/faqs">Faqs</Link></li>
                                    <li><Link to="/applynow">Apply Now</Link></li>
                                </ul> 
                </li> */}
                <li className={`calculators ${menu.calculators && "current"}`}>
                  <Link to="/calculator">Calculators</Link>
                  <ul style={{ minWidth: "150px", width: "max-content" }}>
                    <li>
                      <Link to="/borrowing-capacity-calculator">
                        Borrowing Capacity Calculator
                      </Link>
                    </li>
                    <li>
                      <Link to="/income-tax-calculator">
                        Income Tax Calculator
                      </Link>
                    </li>
                    <li>
                      <Link to="/stamp-duty-calculator">
                        Stamp Duty Calculator
                      </Link>
                    </li>
                    <li>
                      <Link to="/lmi-calculator">LMI Calculator</Link>
                    </li>
                    <li>
                      <Link to="/repayment-calculator">
                        Repayment Calculator
                      </Link>
                    </li>
                  </ul>
                </li>
                <li className={`about ${menu.about && "current"}`}>
                  <Link to="/about">About</Link>
                  <ul style={{ minWidth: "150px", width: "max-content" }}>
                    <li>
                      <Link to="/team">Our Team</Link>
                    </li>
                    <li>
                      <Link to="/our-partners">Our Partners</Link>
                    </li>
                  </ul>
                </li>
                <li
                  className={`talktoabroker ${
                    menu.talktoabroker && "current"
                  } wrap`}
                >
                  <Link to="/talk-to-a-broker">Talk To A Broker</Link>
                  {/* <ul>
                                    <li><Link to="/newsmain">News</Link></li>
                                    <li><Link to="/newsdetails">News Details</Link></li>
                                </ul> */}
                </li>
                {/* <li className={`contact ${menu.contact && "current"}`}><Link to="/contact">Contact</Link></li> */}
              </ul>
            </div>
            <div className="main-menu__right">
              {/* <Link
                to="#"
                onClick={() => setmobile(true)}
                className="main-menu__toggler mobile-nav__toggler">
                <i className="fa fa-bars" style={{ fontSize: "25px" }}></i>
              </Link> */}
              <Link
                to="#"
                onClick={() => setsearch(true)}
                className="main-menu__search search-toggler"
              >
                <i
                  className="icon-magnifying-glass"
                  style={{ fontSize: "25px" }}
                ></i>
              </Link>
              <Link
                to="/login"
                className="main-menu__toggler mobile-nav__buttons"
              >
                <Button
                  variant="outline-primary"
                  className="rounded-pill button11"
                  size="lg"
                >
                  <div
                    style={{
                      fontFamily: "Montserrat",
                      fontSize: "14px",
                      fontWeight: "bold",
                      marginRight: "-20px",
                    }}
                  >
                    <i className="fa fa-user" style={{ fontSize: "25px" }}></i>
                  </div>
                </Button>
              </Link>
              <Link to="/login" className={`main-menu__nav-1`}>
                <Button
                  variant="outline-primary"
                  className="rounded-pill button1"
                  size="lg"
                >
                  <div
                    style={{
                      fontFamily: "Montserrat",
                      fontSize: "14px",
                      fontWeight: "bold",
                    }}
                  >
                    Log In
                  </div>
                </Button>
              </Link>
              {/* <Link
                to="/login"
                style={{ backgroundColor: "#01BBBB", color: "white" }}
                className="thm-btn rounded-pill thm-btn-outline">
                <b>Log In</b>
              </Link> */}

              {/* <Link to="tel:926668880000" className="main-menu__contact">
                            <span className="main-menu__contact__icon">
                                <i className="icon-phone"></i>
                            </span>
                            <span className="main-menu__contact__text">
                                <strong>92 666 888 0000</strong>
                                Mon to Fri: 9 am to 6 pm
                            </span>
                        </Link> */}
            </div>
          </div>
        </nav>
      </div>

      <div className={`search-popup ${search && "active"}`}>
        <div
          className="search-popup__overlay search-toggler"
          onClick={() => setsearch(false)}
        ></div>
        <div className="search-popup__content">
          <form action="#">
            <label htmlFor="search" className="sr-only">
              search here
            </label>
            <input type="text" id="search" placeholder="Search Here..." />
            <button
              type="submit"
              aria-label="search submit"
              className="thm-btn"
            >
              <i className="icon-magnifying-glass"></i>
            </button>
          </form>
        </div>
      </div>

      <div className={`mobile-nav__wrapper ${mobile ? "expanded" : ""}`}>
        <div
          className="mobile-nav__overlay mobile-nav__toggler"
          onClick={() => setmobile(false)}
        ></div>
        <div className="mobile-nav__content">
          <span
            className="mobile-nav__close mobile-nav__toggler"
            onClick={() => setmobile(false)}
          >
            <i className="fa fa-times"></i>
          </span>

          <div className="logo-box">
            <Link to="/">
              <img
                src={BGImg}
                style={{
                  marginTop: "-95px",
                  maxWidth: "240px",
                  marginBottom: "-55px",
                }}
                alt="secure-finance"
              />
            </Link>
          </div>

          <div className="mobile-nav__container">
            <ul className="main-menu__list">
              <li className="dropdown current">
                <Link to="#" className={homeDrop ? "expanded" : ""}>
                  Home Loans
                  <button
                    aria-label="dropdown toggler"
                    className={homeDrop ? "expanded" : ""}
                    onClick={() => sethomeDrop(homeDrop ? false : true)}
                  >
                    <i className="fa fa-angle-down"></i>
                  </button>
                </Link>
                {homeDrop && (
                  <ul style={{ display: "block" }}>
                    {/* <li className="current">
                                            <Link to="/" onClick={() => setmobile(false)}>Home Loans</Link>
                                        </li> */}
                    {/* <li><Link to="/home-02" onClick={() => setmobile(false)}>Home Two</Link></li>
                                        <li><Link to="/home-03" onClick={() => setmobile(false)}>Home Three</Link></li> */}
                    <>
                      <li>
                        <Link
                          to="/first-home-buyers-loan"
                          style={{ textWrap: "nowrap" }}
                        >
                          First Home Buyers Loans
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/owner-occupied-loans"
                          style={{ textWrap: "nowrap" }}
                        >
                          Owner Occupied Loans
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/investment-loans"
                          style={{ textWrap: "nowrap" }}
                        >
                          Investment Loans
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/refinance-loans"
                          style={{ textWrap: "nowrap" }}
                        >
                          Refinance Loans
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/construction-loans"
                          style={{ textWrap: "nowrap" }}
                        >
                          Construction Loans
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/guarantor-home-loans"
                          k
                          style={{ textWrap: "nowrap" }}
                        >
                          Guarantor Loans
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/loan-for-professionals"
                          style={{ textWrap: "nowrap" }}
                        >
                          Loans for Professionals
                        </Link>
                      </li>
                      <li>
                        <Link to="/smsf-loans" style={{ textWrap: "nowrap" }}>
                          SMSF Loans
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/low-doc-home-loans"
                          style={{ textWrap: "nowrap" }}
                        >
                          Low-Doc Loans
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/asset-finance"
                          style={{ textWrap: "nowrap" }}
                        >
                          Asset Finance
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/commercial-property-loans"
                          style={{ textWrap: "nowrap" }}
                        >
                          Commercial Loans
                        </Link>
                      </li>
                      <li>
                        <Link to="/all-loans" style={{ textWrap: "nowrap" }}>
                          See All Loans
                        </Link>
                      </li>
                    </>
                    {/* <li className="dropdown">
                                            <Link to="#" className={headerDrop ? "expanded" : ""}>Header Styles
                                                <button aria-label="dropdown toggler" className={headerDrop ? "expanded" : ""} onClick={() => setheaderDrop(headerDrop ? false : true)}>
                                                    <i className="fa fa-angle-down"></i>
                                                </button>
                                            </Link>
                                            {headerDrop &&
                                                <ul style={{ display: "block" }}>
                                                    <li className="current"><Link to="/" onClick={() => setmobile(false)}>Header One</Link></li>
                                                    <li><Link to="/home-02" onClick={() => setmobile(false)}>Header Two</Link></li>
                                                    <li><Link to="/home-03" onClick={() => setmobile(false)}>Header Three</Link></li>
                                                </ul>}
                                        </li> */}
                  </ul>
                )}
              </li>
              <li className="dropdown">
                <Link to="#" className={servicesDrop ? "expanded" : ""}>
                  Resources
                  <button
                    aria-label="dropdown toggler"
                    className={servicesDrop ? "expanded" : ""}
                    onClick={toggleMenu}
                  >
                    <i className="fa fa-angle-down"></i>
                  </button>
                </Link>
                {servicesDrop && (
                  <ul style={{ display: "block" }}>
                    <li>
                      <Link to="/tree-or-sea-change" onClick={closeMobileMenu}>
                        The Gum Trees
                      </Link>
                    </li>
                    <li>
                      <Link to="/turn-to-broker" onClick={closeMobileMenu}>
                        Aussies Turn to a Broker
                      </Link>
                    </li>
                    <li>
                      <Link to="/first-home-buyers" onClick={closeMobileMenu}>
                        First Home Buyers
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/home-loan-applications"
                        onClick={closeMobileMenu}
                      >
                        Home Loan Applications
                      </Link>
                    </li>
                    <li>
                      <Link to="/interest-rates" onClick={closeMobileMenu}>
                        Interest Rates
                      </Link>
                    </li>
                    <li>
                      <Link to="/property-investment" onClick={closeMobileMenu}>
                        Property Investment
                      </Link>
                    </li>
                    <li>
                      <Link to="/home-buyers-rejoice" onClick={closeMobileMenu}>
                        Home Buyers Rejoice
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/home-bargain-suburbs"
                        onClick={closeMobileMenu}
                      >
                        Home Bargain Suburbs
                      </Link>
                    </li>
                  </ul>
                )}
              </li>
              {/*
              <li className="dropdown">
                <Link to="#" className={servicesDrop ? "expanded" : ""}>
                  Resources
                  <button
                    aria-label="dropdown toggler"
                    className={servicesDrop ? "expanded" : ""}
                    onClick={() => setservicesDrop(servicesDrop ? false : true)}
                  >
                    <i className="fa fa-angle-down"></i>
                  </button>
                </Link>
                {servicesDrop && (
                  <ul style={{ display: "block" }}>
                    <li>
                      <Link to="/turn-to-broker" onClick={true}>Aussies Turn to a Broker</Link>
                    </li>
                    <li>
                      <Link to="/first-home-buyers" onClick={true}>First Home Buyers</Link>
                    </li>
                    <li>
                      <Link to="/home-loan-applications" onClick={true}>Home Loan Applications</Link>
                    </li>
                    <li>
                      <Link to="/interest-rates" onClick={true}>Interest Rates</Link>
                    </li>
                    <li>
                      <Link to="/property-investment" onClick={true}>Property Investment</Link>
                    </li>
                    <li>
                      <Link to="/home-buyers-rejoice" onClick={true}>Home Buyers Rejoice</Link>
                    </li>
                    <li>
                      <Link to="/home-bargain-suburbs" onClick={true}>Home Bargain Suburbs</Link>
                    </li>
                  </ul>
                )}
              </li>
             */}

              {/* <li className="dropdown">
                <Link to="/not-found" className={pagesDrop ? "expanded" : ""}>
                  News
                  {/* <button aria-label="dropdown toggler" className={pagesDrop ? "expanded" : ""} onClick={() => setpagesDrop(pagesDrop ? false : true)}>
                                        <i className="fa fa-angle-down"></i>
                                    </button> 
                </Link>
                {/* {pagesDrop &&
                                    <ul style={{ display: "block" }}>
                                        <li><Link to="/team" onClick={() => setmobile(false)}>Team</Link></li>
                                        <li><Link to="/teamdetails" onClick={() => setmobile(false)}>Team Details</Link></li>
                                        <li><Link to="/creditrepair" onClick={() => setmobile(false)}>Credit Repair</Link></li>
                                        <li><Link to="/creditaudit" onClick={() => setmobile(false)}>Credit Audit</Link></li>
                                        <li><Link to="/careers" onClick={() => setmobile(false)}>Careers</Link></li>
                                        <li><Link to="/faqs" onClick={() => setmobile(false)}>Faqs</Link></li>
                                        <li><Link to="/applynow" onClick={() => setmobile(false)}>Apply Now</Link></li>
                                    </ul>} 
              </li>*/}
              <li className="dropdown">
                <Link className={servicesDrop ? "expanded" : ""}>
                  Calculators
                  <button
                    aria-label="dropdown toggler"
                    className={calculatorsDrop ? "expanded" : ""}
                    onClick={() =>
                      setcalculatorsDrop(calculatorsDrop ? false : true)
                    }
                  >
                    <i className="fa fa-angle-down"></i>
                  </button>
                </Link>
                {calculatorsDrop && (
                  <ul style={{ display: "block" }}>
                    <li>
                      <Link to="/borrowing-capacity-calculator">
                        Borrowing Capacity Calculator
                      </Link>
                    </li>
                    <li>
                      <Link to="/income-tax-calculator">
                        Income Tax Calculator
                      </Link>
                    </li>
                    <li>
                      <Link to="/stamp-duty-calculator">
                        Stamp Duty Calculator
                      </Link>
                    </li>
                    <li>
                      <Link to="/lmi-calculator">LMI Calculator</Link>
                    </li>
                    <li>
                      <Link to="/repayment-calculator">
                        Repayment Calculator
                      </Link>
                    </li>
                  </ul>
                )}
              </li>
              <li className="dropdown">
                <Link className={featuresDrop ? "expanded" : ""}>
                  About
                  <button
                    aria-label="dropdown toggler"
                    className={featuresDrop ? "expanded" : ""}
                    onClick={() => setfeaturesDrop(featuresDrop ? false : true)}
                  >
                    <i className="fa fa-angle-down"></i>
                  </button>
                </Link>
                {featuresDrop && (
                  <ul style={{ display: "block" }}>
                    <li>
                      <Link to="/team">Our Team</Link>
                    </li>
                    <li>
                      <Link to="/our-partners">Our Partners</Link>
                    </li>
                    {/* <li>
                      <Link to="/not-found">Our Client Testimonials</Link>
                    </li> */}
                    {/* <li>
                      <Link to="/not-found">FAQ</Link>
                    </li> */}
                  </ul>
                )}
              </li>
              {/* <li className="dropdown">
                            <Link to="#" className={newsDrop ? "expanded" : ""}>News
                                <button aria-label="dropdown toggler" className={newsDrop ? "expanded" : ""} onClick={() => setnewsDrop(newsDrop ? false : true)}>
                                    <i className="fa fa-angle-down"></i>
                                </button>
                            </Link>
                                {newsDrop &&
                                    <ul style={{ display: "block" }}>
                                        <li><Link to="/newsmain" onClick={() => setmobile(false)}>News</Link></li>
                                        <li><Link to="/newsdetails" onClick={() => setmobile(false)}>News Details</Link></li>
                                    </ul>}
                            </li> */}
              <li>
                <Link to="/contact" onClick={() => setmobile(false)}>
                  Talk To A Broker
                </Link>
              </li>
            </ul>
          </div>

          <ul className="mobile-nav__contact list-unstyled">
            <li>
              <i className="icon-email"></i>
              <Link to="mailto:info@securefinance.com.au">
                info@securefinance.com.au
              </Link>
            </li>
            <li>
              <i className="icon-telephone"></i>
              <Link to="tel:666-888-0000">666 888 0000</Link>
            </li>
          </ul>

          <div className="mobile-nav__social">
            <a
              href="https://www.facebook.com/SecureFinanceServices"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fab fa-facebook"></i>
            </a>
            <a
              href="https://www.instagram.com/securefinanceservices/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fab fa-instagram"></i>
            </a>
            <a
              href="https://twitter.com/SecureFinance1"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fab fa-twitter"></i>
            </a>
            <a
              href="https://www.linkedin.com/company/secure-finance/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fab fa-linkedin"></i>
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default Navbar;
