// HomeLoanApplicationsDetails
import React from "react";
import { Link } from "react-router-dom";
import BG from "../../../assets/resourses/home-buyer-application.jpg";

function HomeLoanApplicationsDetails() {
  return (
    <>
      <section className="blog-details pt-100 pb-120">
        <div className="container">
          <div className="row row-gutter-y-30">
            <div className="col-lg-8">
              <div className="blog-card__image">
                <div className="blog-card__date">
                  <span>05</span> Mar
                </div>
                <img src={BG} alt="" />
              </div>
              <div className="blog-card__meta">
                <Link to="/newsdetails">
                  <i className="far fa-user-circle"></i> by Admin
                </Link>
                <Link to="/newsdetails">
                  <i className="far fa-comments"></i> 2 Comments
                </Link>
              </div>
              <h3 className="blog-card__title">
                4 Tips for Self-Employed Home Loan Applications
              </h3>
              <div className="blog-details__content">
                <br />
                <b>
                  Applying for a mortgage when you’re self-employed may have you
                  jumping through more hoops. But it needn’t deter you from
                  getting into the property market. Here are 4 tips to help you
                  apply for a mortgage like a boss.
                </b>
                <br />
                <br />

                <p>
                  Being your own boss sure has its advantages: the flexibility
                  of setting your own hours, building your own business to
                  represent your values, having someone else fetch you coffee…
                </p>

                <p>
                  But when it comes to home loans, you may have more to prove
                  than the average applicant.
                </p>

                <p>
                  You see, lenders may view you as a little more risky. That’s
                  because, in their eyes, you may not have a steady paycheck to
                  make those all-important repayments.
                </p>

                <p>
                  But being self-employed needn’t stop you from getting your
                  slice of the great Australian dream.
                </p>

                <p>
                  Planning ahead and knowing what lenders generally look for
                  could give you an edge when it comes to mortgage application
                  success.
                </p>
              </div>
              <h4 style={{ color: "#01BBBB" }}>
                1. Get your finances in order
              </h4>
              <div className="blog-details__content">
                <p>
                  As a self-employed applicant, having rock-solid finances is
                  important.
                </p>
                <p>
                  Even if your business is booming, most lenders will see you as
                  more of a risk for defaulting. That’s because self-employed
                  incomes can be less consistent.
                </p>
                <p>
                  Lenders want to know that the likelihood of you making regular
                  repayments is high.
                </p>
                <p>
                  And to mitigate risk, loan options available to you may have a
                  lower loan-to-value ratio (meaning you may need a higher
                  deposit) and/or have a higher interest rate.
                </p>
                <p>
                  So, to prepare to apply, consider getting your finances in
                  check by:
                </p>
                <div className="row row-gutter-y-30 mt-1">
                  <div className="col-md-12">
                    <ul className="list-unstyled ml-0 blog-details__list">
                      <li>
                        <i className="fa fa-check-circle"></i>
                        Building up a healthy credit score.
                      </li>
                      <li>
                        <i className="fa fa-check-circle"></i>
                        Lowering your living expenses by focusing on the
                        essentials.
                      </li>
                      <li>
                        <i className="fa fa-check-circle"></i>
                        Saving up a healthy deposit (aka genuine savings) and a
                        cash buffer.
                      </li>
                      <li>
                        <i className="fa fa-check-circle"></i>
                        Running your business on accounting software such as
                        Xero, MYOB, or Hnry so you can provide up-to-date and
                        accurate profit and loss statements.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <br />
              <h4 style={{ color: "#01BBBB" }}>2. Gather your documents</h4>
              <div className="blog-details__content">
                <p>
                  It’s important to keep your business and personal finance
                  documents up to date, so you’ll be ready to rock and roll.
                </p>
                <p>
                  For verification of income, many lenders require two years'
                  worth of lodged business and personal tax returns.
                </p>
                <p>
                  It’s important to keep your business and personal finance
                  documents up to date, so you’ll be ready to rock and roll. For
                  verification of income, many lenders require two years worth
                  of lodged business and personal tax returns.
                </p>
                <p>
                  It’s a great idea to tell your accountant in advance that
                  you’re planning on applying for a home loan. That’s because
                  some of the financial wizardry they apply to lower your tax
                  bill might work against your application and lower your
                  borrowing capacity.
                </p>
                <p>
                  Also, keep in mind that business owners who do lots of “cash
                  jobs” can find it harder to obtain a home loan because they
                  have less income to show for their work.
                </p>
                <p>
                  On top of running your credit score, some lenders may want
                  statements from loans and credit cards for proof you can make
                  regular repayments.
                </p>
                <p>
                  They may also want to see verification of assets such as any
                  property, savings and investments.
                </p>
                <p>
                  Some lenders may want to see the whole kit and kaboodle when
                  applying for a loan. Some may need less.
                </p>
                <p>
                  And some offer low-doc loans if you don’t have extensive
                  documentation. But they may come with higher interest rates or
                  the need to pay lenders mortgage insurance (or both).
                </p>
                <p>
                  Exactly what documents are required depends on the lender and
                  the type of loan.
                </p>
              </div>

              <h4 style={{ color: "#01BBBB" }}>3. Choose your lender wisely</h4>
              <div className="blog-details__content">
                <p>
                  Not all lenders are comfortable providing self-employed loans
                  for the reasons mentioned above.
                </p>
                <p>
                  And every time you apply for a home loan your credit history
                  is “pinged”. The more this occurs, the more of a red flag this
                  may pose to lenders.
                </p>
                <p>
                  So targeting lenders that have a track record of approving
                  self-employed loans might be a wise move.
                </p>
                <p>
                  Having a reputable mortgage professional on your side may be
                  helpful here. Which brings us to our next point …
                </p>
              </div>
              <h4 style={{ color: "#01BBBB" }}>
                4. Get in touch with us today
              </h4>
              <div className="blog-details__content">
                <p>
                  Just as you’ll want to give your accountant plenty of notice,
                  so too will you want to reach out to a mortgage broker sooner
                  rather than later.
                </p>
                <p>
                  Just as you’ll want to give your accountant plenty of notice,
                  so too will you want to reach out to a mortgage broker sooner
                  rather than later. That’s because we can help you work out
                  your borrowing capacity, and provide you with other tips that
                  you can start working on now that may eventually help make
                  your application more attractive to lenders.
                </p>
                <p>
                  So if you’re self-employed and think you’ll be seeking a home
                  loan in 2024, get in touch today.
                </p>
                <p>
                  <b>Disclaimer: </b>The content of this article is general in
                  nature and is presented for informative purposes. It is not
                  intended to constitute tax or financial advice, whether
                  general or personal nor is it intended to imply any
                  recommendation or opinion about a financial product. It does
                  not take into consideration your personal situation and may
                  not be relevant to circumstances. Before taking any action,
                  consider your own particular circumstances and seek
                  professional advice. This content is protected by copyright
                  laws and various other intellectual property laws. It is not
                  to be modified, reproduced or republished without prior
                  written consent.
                </p>
              </div>
              <div className="blog-details__meta">
                <div className="blog-details__tags">
                  <span>Tags</span>
                  <Link to="#">Mortgage</Link>
                  <Link to="#">Home Loan</Link>
                  <Link to="#">First Home</Link>
                </div>
                {/* <div className="blog-details__social">
                  <Link to="#">
                    <i className="fab fa-twitter"></i>
                  </Link>
                  <Link to="#">
                    <i className="fab fa-facebook"></i>
                  </Link>
                  <Link to="#">
                    <i className="fab fa-instagram"></i>
                  </Link>
                  <Link to="#">
                    <i className="fab fa-pinterest-p"></i>
                  </Link>
                </div> */}
              </div>
              {/* <div className="blog-details__author">
                <div className="blog-details__author__image">
                  <img src="assets/images/blog/blog-author-1-1.jpg" alt="" />
                </div>
                <div className="blog-details__author__content">
                  <h3 className="blog-details__author__name">Author Details</h3>
                  <p className="blog-details__author__text">
                    Lorem ipsum is simply free text by copytyping refreshing.
                    Neque porro est qui dolorem ipsum quia quaed veritatis et
                    quasi architecto.
                  </p>
                </div>
              </div> */}
              {/* <div className="blog-details__comment">
                <h3 className="blog-details__title">2 Comments</h3>
                <div className="blog-details__comment__item">
                  <div className="blog-details__comment__image">
                    <img src="assets/images/blog/blog-comment-1-1.jpg" alt="" />
                  </div>
                  <div className="blog-details__comment__content">
                    <h3 className="blog-details__comment__name">
                      Kevin Martin
                    </h3>
                    <p className="blog-details__comment__text">
                      Lorem ipsum is simply free text used by copytyping
                      refreshing. Neque porro est qui dolorem ipsum quia quaed
                      inventore veritatis et quasi architecto beatae vitae dicta
                      sunt explicabo.
                    </p>
                  </div>
                  <Link to="#" className="thm-btn">
                    Reply
                  </Link>
                </div>
                <div className="blog-details__comment__item">
                  <div className="blog-details__comment__image">
                    <img src="assets/images/blog/blog-comment-1-2.jpg" alt="" />
                  </div>
                  <div className="blog-details__comment__content">
                    <h3 className="blog-details__comment__name">
                      Kevin Martin
                    </h3>
                    <p className="blog-details__comment__text">
                      Lorem ipsum is simply free text used by copytyping
                      refreshing. Neque porro est qui dolorem ipsum quia quaed
                      inventore veritatis et quasi architecto beatae vitae dicta
                      sunt explicabo.
                    </p>
                  </div>
                  <Link to="#" className="thm-btn">
                    Reply
                  </Link>
                </div>
              </div> */}
              {/* <div className="blog-details__form">
                <h3 className="blog-details__title">Leave a comment</h3>
                <form
                  action="assets/sendemail.php"
                  className="form-one contact-form-validated">
                  <div className="row row-gutter-y-20 row-gutter-x-20">
                    <div className="col-md-6">
                      <input type="text" placeholder="Full name" name="name" />
                    </div>
                    <div className="col-md-6">
                      <input
                        type="email"
                        placeholder="Email address"
                        name="email"
                      />
                    </div>
                    <div className="col-md-6">
                      <input
                        type="text"
                        placeholder="Phone number"
                        name="phone"
                      />
                    </div>
                    <div className="col-md-6">
                      <input type="text" placeholder="Subject" name="subject" />
                    </div>
                    <div className="col-md-12">
                      <textarea placeholder="Message" name="message"></textarea>
                    </div>
                    <div className="col-md-12">
                      <button type="submit" className="thm-btn">
                        Send a Message
                      </button>
                    </div>
                  </div>
                </form>
              </div> */}
            </div>
            <div className="col-lg-4">
              <div className="blog-sidebar">
                <div className="blog-sidebar__item blog-sidebar__item--search">
                  <form action="#" className="blog-sidebar__search">
                    <input type="search" placeholder="Search" />
                    <button type="submit" className="blog-sidebar__search__btn">
                      <i className="icon-magnifying-glass"></i>
                    </button>
                  </form>
                </div>
                {/* <div className="blog-sidebar__item blog-sidebar__item--posts">
                  <h3 className="blog-sidebar__title">Recent Posts</h3>
                  <ul className="list-unstyled blog-sidebar__post">
                    <li className="blog-sidebar__post__item">
                      <div className="blog-sidebar__post__image">
                        <img src="assets/images/blog/lp-1-1.jpg" alt="" />
                      </div>
                      <div className="blog-sidebar__post__content">
                        <span className="blog-sidebar__post__author">
                          <i className="far fa-user-circle"></i>
                          by admin
                        </span>
                        <h3 className="blog-sidebar__post__title">
                          <Link to="/newsdetails">Types of Loans</Link>
                        </h3>
                      </div>
                    </li>
                    <li className="blog-sidebar__post__item">
                      <div className="blog-sidebar__post__image">
                        <img src="assets/images/blog/lp-1-2.jpg" alt="" />
                      </div>
                      <div className="blog-sidebar__post__content">
                        <span className="blog-sidebar__post__author">
                          <i className="far fa-user-circle"></i>
                          by admin
                        </span>
                        <h3 className="blog-sidebar__post__title">
                          <Link to="/newsdetails">How to Buy a House</Link>
                        </h3>
                      </div>
                    </li>
                    <li className="blog-sidebar__post__item">
                      <div className="blog-sidebar__post__image">
                        <img src="assets/images/blog/lp-1-3.jpg" alt="" />
                      </div>
                      <div className="blog-sidebar__post__content">
                        <span className="blog-sidebar__post__author">
                          <i className="far fa-user-circle"></i>
                          by admin
                        </span>
                        <h3 className="blog-sidebar__post__title">
                          <Link to="/newsdetails">
                            Lenders Mortgage Insurance (LMI)
                          </Link>
                        </h3>
                      </div>
                    </li>
                  </ul>
                </div> */}
                <div className="blog-sidebar__item blog-sidebar__item--categories">
                  <h3 className="blog-sidebar__title">Categories</h3>
                  <ul className="list-unstyled blog-sidebar__categories">
                    <li>
                      <Link to="/home-loan-applications" className="active">
                        Home Loan Applications
                      </Link>
                    </li>
                    <li>
                      <Link to="/interest-rates">Interest Rates</Link>
                    </li>
                    <li>
                      <Link to="/property-investment">Property Investment</Link>
                    </li>
                    <li>
                      <Link to="/home-buyers-rejoice">Home Buyers Rejoice</Link>
                    </li>
                    <li>
                      <Link to="/home-bargain-suburbs">
                        Home Bargain Suburbs
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="blog-sidebar__item blog-sidebar__item--tags">
                  <h3 className="blog-sidebar__title">Tags</h3>
                  <ul className="list-unstyled blog-sidebar__tags">
                    <li>
                      <Link to="#">Mortage</Link>
                    </li>
                    <li>
                      <Link to="#">Business Loan</Link>
                    </li>
                    <li>
                      <Link to="#">Personal Loan</Link>
                    </li>
                    <li>
                      <Link to="#">Banking</Link>
                    </li>
                    <li>
                      <Link to="#">Finance</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default HomeLoanApplicationsDetails;
