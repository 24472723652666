import React from "react";
import Bredcom from "../Bredcom/Main";
import SMSFLoansBody from "../Services/Menservisedeatils/SMSFLoansBody";
import BG from "../../assets/images/Banners/smsf-loans.jpg";

const SMSFLoans = () => {
  return (
    <>
      <Bredcom
        title={"Home"}
        subtitle={"Home Loans"}
        link={"SMSF Loans"}
        img={BG}
      />
      <SMSFLoansBody />
    </>
  );
};

export default SMSFLoans;
