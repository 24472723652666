import React from "react";
import { Helmet } from "react-helmet";
import "../../../assets/css/calc.css";

function BudgetPlannerCalculator() {
  return (
    <>
      <title>Budget Planner Calculator - Secure Finance</title>
      <meta
        name="description"
        content="Manage finances with our Budget Planner. Calculate total income, expenses, track budget effectively."
      />
      <link
        rel="canonical"
        href="https://securefinance.com.au/budget-planner-calculator"
      />
      <Helmet></Helmet>
      <section className="benefit-one pt-60 pb-60">
        <div className="container">
          <div className="row row-gutter-y-60">
            <iframe
              className="VisiCalcClass"
              id="Budget_Planner"
              src="//www.visionabacus.net/Tools/B3/SuiteA/A400/Budget_Planner/SecureFinance"
              frameBorder="0"
              scrolling="no"
              title="Budget Planner Calculator"></iframe>
          </div>
        </div>
      </section>
    </>
  );
}

export default BudgetPlannerCalculator;
