import React from "react";
import { Helmet } from "react-helmet";
import "../../../assets/css/calc.css";

function IntroductoryRateLoanCalculator() {
  return (
    <>
      <Helmet>
        <title>Introductory Rate Loan Calculator - Secure Finance</title>
        <meta
          name="description"
          content="Use our Introductory Rate Loan Calculator to estimate 
          your repayments and total interest payable with an introductory
           interest rate. Input your loan amount, term, introductory rate, 
           and variable rate to view your results"
        />
        <link
          rel="canonical"
          href="https://securefinance.com.au/introductory-rate-loan-calculator"
        />
      </Helmet>
      <section className="benefit-one pt-60 pb-60">
        <div className="container">
          <div className="row row-gutter-y-60">
            <iframe
              className="VisiCalcClass"
              id="Introductory_Rate_Loan_Calculator"
              src="//www.visionabacus.net/Tools/B3/SuiteA/A300/Introductory_Rate_Loan_Calculator/SecureFinance"
              frameBorder="0"
              scrolling="no"
              title="Introductory Rate Loan Calculator"></iframe>
          </div>
        </div>
      </section>
    </>
  );
}

export default IntroductoryRateLoanCalculator;
