import React from "react";

function OurPartners() {
  return (
    <>
      <section className="about-two pt-120 pb-120">
        <div className="container">
          <div className="row row-gutter-y-50">
            {/* <div className="col-lg-6">
              <div className="about-two__image">
                <img src="assets/images/resources/about-2-1.png" alt="" />
              </div>
            </div> */}
            <div className="col-lg-12">
              <div className="about-two__content">
                <div className="block-title text-left">
                  <h2
                    className="block-title__title text-center"
                    style={{ color: "#01bbbb" }}>
                    BE OUR PARTNER
                  </h2>
                </div>
                <p className="about-two__text">
                  <h4 style={{ fontWeight: "bolder", color: "#0983bc" }}>
                    What you need to do:
                  </h4>
                  <ul className="list-unstyled ml-0 about-two__list">
                    <li>
                      <i className="fa fa-arrow-circle-right"></i>
                      Sign a ‘Referral Source Application’ form.
                    </li>
                    <li>
                      <i className="fa fa-arrow-circle-right"></i>
                      Make sure you’re registered for GST.
                    </li>
                    <li>
                      <i className="fa fa-arrow-circle-right"></i>
                      Provide an Australian Business Number (ABN).
                    </li>
                    <li>
                      <i className="fa fa-arrow-circle-right"></i>
                      Sign a ‘Referral Source Agreement’.
                    </li>
                    <li>
                      <i className="fa fa-arrow-circle-right"></i>
                      Pay a fee to complete background checks (overseas fees may
                      apply if the applicant has lived overseas for more than 12
                      months in the last 5 years).
                    </li>
                    <li>
                      <i className="fa fa-arrow-circle-right"></i>
                      Ensure you don’t discuss specific home loan types that may
                      suit your client (only provide the client’s name and
                      number to us).
                    </li>
                  </ul>
                  <br />
                  <h4 style={{ fontWeight: "bolder", color: "#0983bc" }}>
                    What we will then do:
                  </h4>
                  <ul className="list-unstyled ml-0 about-two__list">
                    <li>
                      <i className="fa fa-arrow-circle-right"></i>
                      Arrange company, firm and other searches as required.
                    </li>
                    <li>
                      <i className="fa fa-arrow-circle-right"></i>
                      Provide you with a ‘Referral Source Agreement’ for
                      signing.
                    </li>
                    <li>
                      <i className="fa fa-arrow-circle-right"></i>
                      Provide you with a ‘Referral Source Number’.
                    </li>
                    <li>
                      <i className="fa fa-arrow-circle-right"></i>
                      Provide you with copies of the ‘Referral Source Customer
                      Referral’ form
                    </li>
                    <li>
                      <i className="fa fa-arrow-circle-right"></i>A referral to
                      the Commonwealth Bank is of real value to your client.
                    </li>
                  </ul>
                  <br />
                  <h4 style={{ fontWeight: "bolder", color: "#0983bc" }}>
                    Here’s why:
                  </h4>
                  <ul className="list-unstyled ml-0 about-two__list">
                    <li>
                      <i className="fa fa-arrow-circle-right"></i>
                      The Commonwealth Bank group is Australia’s largest home
                      lender.
                    </li>
                    <li>
                      <i className="fa fa-arrow-circle-right"></i>
                      We’re proud to excel in customer service excellence.
                    </li>
                    <li>
                      <i className="fa fa-arrow-circle-right"></i>
                      We’re determined to offer strength in uncertain times.
                    </li>
                    <li>
                      <i className="fa fa-arrow-circle-right"></i>
                      We’ve got Australia’s largest branch network.
                    </li>
                    <li>
                      <i className="fa fa-arrow-circle-right"></i>
                      We’re Australia’s most convenient bank including a 24-hour
                      Australian call center.
                    </li>
                    <li>
                      <i className="fa fa-arrow-circle-right"></i>
                      In most cases, conditional approval can be provided at the
                      time of application.
                    </li>
                    <li>
                      <i className="fa fa-arrow-circle-right"></i>
                      Your clients benefit from direct support with a local
                      Personal Lender, Mobile Lenders and Relationship Managers
                      for Premier Banking.
                    </li>
                  </ul>
                  <br />
                  <h4 style={{ fontWeight: "bolder", color: "#0983bc" }}>
                    How can we reward you?
                  </h4>
                  <p>
                    Secure Finance has a generous business referral program
                    where we offer to assist your clients with their current and
                    future finance needs. We have an experienced and motivated
                    team and we have invested heavily in developing lead
                    sourcing models where we are able to identify prospective
                    finance clients in your database and in turn fulfill their
                    finance needs. That surely brings great rewards for your
                    business as well.
                  </p>
                  <p>
                    We offer different commission models, based on loan amounts
                    financed, to complement your business needs and we are happy
                    to discuss in more details when we get in touch!
                  </p>
                  <br />
                  <h4 style={{ fontWeight: "bolder", color: "#0983bc" }}>
                    Who our clients are?
                  </h4>
                  <p>
                    We have a great mix of clients’ base from first home buyers
                    to sophisticated investors and most of our clients have
                    stuck with us right from the start and have grown with us.
                  </p>
                  <p>
                    Our clients are both owner occupiers and investors in equal
                    proportions whereas we also look after their SMSF, business,
                    and equipment finance needs.
                  </p>
                </p>
                {/* <ul className="list-unstyled ml-0 about-two__list">
                  <li>
                    <i className="fa fa-arrow-circle-right"></i>
                    Nsectetur cing elit.
                  </li>
                  <li>
                    <i className="fa fa-arrow-circle-right"></i>
                    Suspe ndisse suscipit sagittis leo.
                  </li>
                  <li>
                    <i className="fa fa-arrow-circle-right"></i>
                    Entum estibulum digni posuere.
                  </li>
                  <li>
                    <i className="fa fa-arrow-circle-right"></i>
                    Donec tristique ante dictum rhoncus.
                  </li>
                </ul> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default OurPartners;
