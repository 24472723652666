import React from "react";
import { Helmet } from "react-helmet";
import "../../../assets/css/calc.css";

function SavingCalculator() {
  return (
    <>
      <Helmet>
        <title>Saving Calculator - Secure Finance</title>
        <meta
          name="description"
          content="Use our Savings Calculator to estimate the growth of your savings
          over time. Input your initial amount, regular deposit, deposit frequency,
          interest rate, and saving term to view your total savings, investment,
          and interest earned."
        />
        <link
          rel="canonical"
          href="https://securefinance.com.au/saving-calculator"
        />
      </Helmet>
      <section className="benefit-one pt-60 pb-60">
        <div className="container">
          <div className="row row-gutter-y-60">
            <iframe
              className="VisiCalcClass"
              id="Saving_Calculator"
              src="//www.visionabacus.net/Tools/B3/SuiteA/G200/Saving_Calculator/SecureFinance"
              frameBorder="0"
              scrolling="no"
              title="Saving Calculator"></iframe>
          </div>
        </div>
      </section>
    </>
  );
}

export default SavingCalculator;
