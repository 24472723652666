import React, { useState } from "react";
import { Link } from "react-router-dom";
import BG from "../../../assets/images/team/Sakib_Picture 2.jpg";

function SakibManzoor() {
  return (
    <>
      <section className="team-details pt-120 pb-40">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div
                className="team-details__image wow fadeInUp"
                data-wow-duration="1500ms">
                <img src={BG} alt="" />
              </div>
            </div>
            <div className="col-lg-6">
              <div
                className="team-details__content"
                style={{ textAlign: "justify", paddingRight: "60px" }}>
                <div className="team-details__floated">Sakib</div>
                <div className="block-title text-left">
                  <p className="block-title__tagline">
                    Principle Finance Broker
                  </p>
                  <h2 className="block-title__title">Sakib Manzoor</h2>
                </div>
                {/* <div className="team-details__social">
                  <Link to="#">
                    <i className="fab fa-twitter"></i>
                  </Link>
                  <Link to="#">
                    <i className="fab fa-facebook"></i>
                  </Link>
                  <Link to="#">
                    <i className="fab fa-pinterest"></i>
                  </Link>
                  <Link to="#">
                    <i className="fab fa-instagram"></i>
                  </Link>
                </div> */}
                <p className="team-details__highlight">
                  REVOLUTIONIZING THE MORTGAGE MARKET
                </p>

                <p className="team-details__text">
                  Secure Finance was founded by Sakib Manzoor in 2014 and since
                  its inception, Secure Finance is now a team of 12 strong with
                  plans for further expansion in the coming years.
                </p>
                <p className="team-details__text">
                  From $20M in home loan settlements in the first year, we have
                  now passed the $1B mark in settlements.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="container">
        <hr className="block-separator" />
      </div>
      <section className="contact-info-one">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 pt-40 pb-40">
              <div className="team-details__content">
                <p className="team-details__text">
                  Sakib worked in senior sales and account management roles from
                  2000 until 2013 including working with American Express where
                  he looked after top 1% credit card spenders holding Amex
                  Titanium Cards.
                </p>
                <p className="team-details__text">
                  Sakib has an MBA and Post Graduate Diploma in Management from
                  MGSM and he has also completed a bachelor’s in commerce and
                  Finance along with Diplomas in Finance Broking as well as
                  Financial Planning.
                </p>
                <p className="team-details__text">
                  Most recently, Secure Finance once again bagged our win in the
                  Top 100 Brokers 2023 awarded by Mortgage Professional
                  Australia – marking the 6th consecutive year as a recognized
                  and significant player in the Australian Property Market.
                </p>
                <p className="team-details__text">
                  On a personal front, Sakib is an active participant in the
                  property market, often appearing as the keynote speaker and
                  making headlines in podcasts, interviews, and other new
                  channels.
                </p>
                <p className="team-details__text">
                  He actively encourages others to invest in property and to
                  invest now. He maintains a portfolio of returning clients and
                  families, often working on the big picture of financial
                  freedom and success for the long run and for future
                  generations.
                </p>
                <p className="team-details__text">
                  Like many other captains at the helm of the company, Sakib
                  constantly seeks out new ways of problem-solving and
                  efficiency. While property and mortgage may seem archaic and
                  dry to many, Sakib envisions to transform this obsolete
                  perception of the industry.
                </p>
                <p className="team-details__text">
                  And on the weekends, you may spot Sakib spending leisure time
                  with his wife and family.
                </p>
              </div>
            </div>
          </div>

          {/* <div className="col-lg-6">
              <div className="team-progress__content">
                <div className="team-progress__item">
                  <h4 className="team-progress__title">Consulting</h4>
                  <div className="team-progress__bar">
                    <div
                      className="team-progress__bar__inner count-bar"
                      data-percent="67%"
                      style={{ width: "67%" }}>
                      <div className="team-progress__bar__text count-text">
                        67%
                      </div>
                    </div>
                  </div>
                </div>
                <div className="team-progress__item">
                  <h4 className="team-progress__title">Credit Card</h4>
                  <div className="team-progress__bar">
                    <div
                      className="team-progress__bar__inner count-bar"
                      data-percent="46%"
                      style={{ width: "46%" }}>
                      <div className="team-progress__bar__text count-text">
                        46%
                      </div>
                    </div>
                  </div>
                </div>
                <div className="team-progress__item">
                  <h4 className="team-progress__title">Personal Loan</h4>
                  <div className="team-progress__bar">
                    <div
                      className="team-progress__bar__inner count-bar"
                      data-percent="28%"
                      style={{ width: "28%" }}>
                      <div className="team-progress__bar__text count-text">
                        28%
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
        </div>
      </section>
      <div className="container">
        <hr className="block-separator" />
      </div>
      <section className="contact-info-one">
        <div className="container">
          <div className="row">
            <div className="col-md-4 col-sm-12">
              <div className="contact-info-one__item">
                <div className="contact-info-one__icon">
                  <i className="icon-telephone"></i>
                </div>
                <div className="contact-info-one__content">
                  <p className="contact-info-one__text">Have any question?</p>
                  <a
                    className="contact-info-one__link"
                    href="tel:+61 (404) 262 056">
                    0404 262 056
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-sm-12">
              <div className="contact-info-one__item">
                <div className="contact-info-one__icon">
                  <i className="icon-email"></i>
                </div>
                <div className="contact-info-one__content">
                  <p className="contact-info-one__text">Write us email</p>
                  <a
                    className="contact-info-one__link"
                    href="mailto:needhelp@company.com">
                    info@securefinance.com.au
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-sm-12">
              <div className="contact-info-one__item">
                <div className="contact-info-one__icon">
                  <i className="icon-pin"></i>
                </div>
                <div className="contact-info-one__content">
                  <p className="contact-info-one__text">Visit anytime</p>
                  <a className="contact-info-one__link" href="#">
                    204/11-13 Solent Circuit <br /> Norwest NSW 2153
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default SakibManzoor;
