import React from "react";
import { Helmet } from "react-helmet";
import "../../../assets/css/calc.css";

function LeasingCalculator() {
  return (
    <>
      <Helmet>
        <title>Leasing Calculator - Secure Finance</title>
        <meta
          name="description"
          content="Use our Asset Finance Calculator to estimate your monthly repayments, total interest payable, and total payment amount for asset purchases. Input the purchase price, residual value, leasing term, and interest rate to view your results."
        />
        <link
          rel="canonical"
          href="https://securefinance.com.au/leasing-calculator"
        />
      </Helmet>
      <section className="benefit-one pt-60 pb-60">
        <div className="container">
          <div className="row row-gutter-y-60">
            <iframe
              className="VisiCalcClass"
              id="Leasing_Calculator"
              src="//www.visionabacus.net/Tools/B3/SuiteA/A300/Leasing_Calculator/SecureFinance"
              frameBorder="0"
              scrolling="no"
              title="Leasing Calculator"></iframe>
          </div>
        </div>
      </section>
    </>
  );
}

export default LeasingCalculator;
