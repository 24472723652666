// ApplicationsCard.js

import React, { useEffect, useRef } from "react";
// import { Pie, Line } from "react-chartjs-2";

const ApplicationsCard = ({ applications }) => {
  const pieChartData = {
    labels: ["Approved", "Pending", "Rejected"],
    datasets: [
      {
        data: [10, 5, 2],
        backgroundColor: ["#36A2EB", "#FFCE56", "#FF6384"],
      },
    ],
  };

  const graphData = {
    labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun"],
    datasets: [
      {
        label: "Applications Over Time",
        data: [5, 10, 8, 15, 12, 20],
        borderColor: "#36A2EB",
        fill: false,
      },
    ],
  };

  const chartOptions = {
    maintainAspectRatio: false,
  };

  // Use refs to keep track of chart instances
  const pieChartRef = useRef(null);
  const lineChartRef = useRef(null);

  useEffect(() => {
    // Destroy existing charts on component unmount
    return () => {
      if (pieChartRef.current) {
        pieChartRef.current.chartInstance.destroy();
      }
      if (lineChartRef.current) {
        lineChartRef.current.chartInstance.destroy();
      }
    };
  }, []);
  return (
    <div className=" card full-width-card">
      <h2>Applications</h2>
      <div className="row col-md-12">
        <div className="col-md-6 applications-content">
          {applications.map((app) => (
            <div key={app.id} className="application">
              <p className="application-type">{app.type}</p>
              <p className="application-info">
                <span className="info-label">Time:</span> {app.time}
              </p>
              <p className="application-info">
                <span className="info-label">Client Info:</span>
                {app.clientInfo}
              </p>
            </div>
          ))}
        </div>
        <div className="col-md-6 charts-container">
          <div className="col-md-3" style={{ marginBottom: "20px" }}>
            {/* <Pie ref={pieChartRef} data={pieChartData} options={chartOptions} /> */}
          </div>
          <div className="col-md-3">
            {/* <Line ref={lineChartRef} data={graphData} options={chartOptions} /> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ApplicationsCard;
