import React from "react";
import { Helmet } from "react-helmet";
import "../../../assets/css/calc.css";

function CreditCardCalculator() {
  return (
    <>
      <Helmet>
        <title>Credit Card Calculator - Secure Finance</title>
        <meta
          name="description"
          content="Use our Credit Card Calculator to estimate the time and interest required to pay off your credit card balance. Input your balance, interest rate, and repayment details to view the impact of minimum and higher repayments"
        />
        <link
          rel="canonical"
          href="https://securefinance.com.au/credit-card-calculator"
        />
      </Helmet>
      <section className="benefit-one pt-60 pb-60">
        <div className="container">
          <div className="row row-gutter-y-60">
            <iframe
              className="VisiCalcClass"
              id="Credit_Card_Calculator"
              src="//www.visionabacus.net/Tools/B3/SuiteA/A500/Credit_Card_Calculator/SecureFinance"
              frameBorder="0"
              scrolling="no"
              title="Credit Card Calculator"></iframe>
          </div>
        </div>
      </section>
    </>
  );
}

export default CreditCardCalculator;
