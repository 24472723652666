import React from "react";
import { Link } from "react-router-dom";
import Img from "../../assets/new-icons/borrowing-capacity-calculator.png";
import Img1 from "../../assets/new-icons/LMI-calculator.png";
import Img2 from "../../assets/new-icons/income-tax-calculator.png";
import Img3 from "../../assets/new-icons/stamp-duty-calculator.png";
// import SlideUpOnScroll from "../Menhome/SlideUpOnScroll";
import "../Menhome/newstyles.css";
// import Fade from "react-reveal/Fade";
import Button from "react-bootstrap/Button";

function Loan() {
  const services = [
    {
      img: Img,
      icon: "icon-bill",
      title: "MY BORROWING CAPACITY",
      text: "What is your borrowing capacity & which bank will lend you the most?",
      link: "/borrowing-capacity-calculator",
    },
    {
      img: Img1,
      icon: "icon-select",
      title: "MY LMI",
      text: "What’s your LMI payable for the loan you seek?",
      link: "/lmi-calculator",
    },
    {
      img: Img2,
      icon: "icon-growth",
      title: "MY INCOME TAX",
      text: "Work out how much tax you should be paying.",
      link: "/income-tax-calculator",
    },
    {
      img: Img3,
      icon: "icon-growth",
      title: "MY STAMP DUTY",
      text: "What fees will be incurred when you buy a house or land?",
      link: "/stamp-duty-calculator",
    },
  ];

  return (
    <>
      <section className="calculators-one pt-40 pb-40">
        <div className="container">
          <div className="block-title-one text-center">
            <div className="row row-gutter-y-20">
              <div className="col-md-12">
                <div className="block-title text-center">
                  {/* <SlideUpOnScroll
                    id="section1"
                    duration={1000}
                    tension={120}
                    friction={14}> */}
                  {/* <Fade bottom big cascade> */}
                  <div>
                    <p className="block-title__tagline m-3">LOAN CALCULATORS</p>
                    {/* </SlideUpOnScroll> */}
                    {/* <SlideUpOnScroll
                    id="section2"
                    duration={1500}
                    tension={500}
                    friction={12}> */}
                    <h3 className="block-title__title_loan mx-5">
                      Understand your financial capacity and loan margins <br />{" "}
                      with our calculators
                    </h3>
                    {/* </SlideUpOnScroll> */}
                  </div>
                  {/* </Fade> */}
                </div>
              </div>
              <div className="row row-gutter-y-20">
                {services.map((service, index) => (
                  // <Fade left big cascade>

                  // <div
                  //   className="row col-lg-6 col-md-6"
                  //   key={index}
                  //   style={{ display: "flex", alignItems: "center" }}>
                  //   <div className="col-md-4 image-container">
                  //     <img src={service.img} />
                  //   </div>
                  //   <div className="col-md-8">
                  //     <h3 className="service-card-four__title_loan">
                  //       <Link to={service.link}>{service.title}</Link>
                  //     </h3>
                  //     <p
                  //       className="service-card-four__text_loan col-md-12"
                  //       style={{
                  //         fontFamily: "Montserrat",
                  //         fontSize: "14px",
                  //       }}>
                  //       {service.text}
                  //     </p>
                  //   </div>
                  // </div>

                  <div className="col-md-6">
                    <div className="about-four__feature">
                      <div className="about-four__feature__content">
                        <div className="about-four__feature__icon">
                          {/* <i className="icon-confirmation"></i> */}
                          <img style={{ width: "150px" }} src={service.img} />
                        </div>
                        <h3 className="about-four__feature__title">
                          <Link to={service.link}>{service.title}</Link>
                        </h3>
                      </div>
                      <div className="about-four__feature__text">
                        {/* <h3 className="service-card-four__title_loan">
                          <Link to={service.link}>{service.title}</Link>
                        </h3> */}
                        <p
                          className="service-card-four__text_loan col-md-12"
                          style={{
                            fontFamily: "Montserrat",
                            fontSize: "14px",
                          }}>
                          {service.text}
                        </p>
                      </div>
                    </div>
                  </div>
                  // </Fade>
                ))}
              </div>
            </div>
            <div className="text-center mt-5">
              {/* <Link>More Calculators...</Link> */}
              {/* <Link
                to="/not-found"
                style={{ backgroundColor: "#01BBBB", color: "white" }}
                className="thm-btn main-menu__btn rounded-pill">
                <b>More Calculators</b>
              </Link> */}
              <Button
                variant="outline-primary"
                className="rounded-pill button1"
                size="lg">
                <div
                  style={{
                    fontFamily: "Montserrat",
                    fontSize: "14px",
                    fontWeight: "bold",
                  }}>
                  More Calculators
                </div>
              </Button>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Loan;
