import React from "react";

function VirtualMeeting() {
    return (
        <>
            <section className="faq-form pt-120 pb-120">
                <div className="container">
                    <div className="block-title text-center">
                        <p className="block-title__tagline" style={{fontFamily : 'Montserrat', marginBottom: '20px'}}>BOOK A FACE-TO-FACE MEETING</p>
                        <h2 className="block-title__title" style={{fontFamily : 'Montserrat'}}>
                        Visit our office for a Complimentary<br />  Consultation Session
                        </h2>
                    </div>
                    <form action="assets/sendemail.php" className="form-one contact-form-validated">
                        <div className="row row-gutter-y-20 row-gutter-x-20">
                            <div className="col-md-6">
                                <input type="text" placeholder="First Name" name="name" />
                            </div>
                            <div className="col-md-6">
                            <input type="text" placeholder="Last Name" name="name" />
                            </div>
                            <div className="col-md-6">
                            <input type="email" placeholder="Email Address" name="email" />
                                
                            </div>
                            <div className="col-md-6">
                            <input type="text" placeholder="Phone Number" name="phone" />
                               
                            </div>
                            <div className="col-md-6">
                                    {/* <label for="start-date">
                                        <h4>Select Date</h4>
                                    </label> */}
                                    <input
                                        type="date"
                                        id="start"
                                        name="trip-start"
                                        value="2023-12-03"
                                    />
                                </div>
                                <div className="col-md-6">
                                    {/* <label for="start-date">
                                        <h4>Select Date</h4>
                                    </label> */}
                                    <input
                                        type="time"
                                        id="timestart"
                                        name="time-start"
                                        value="10:00"
                                    />
                                </div>
                            <div className="col-md-12">
                                <input type="text" placeholder="Subject" name="subject" />
                            </div>

                            <div className="col-md-12">
                                <textarea placeholder="Message" name="message"></textarea>
                            </div>

                            <div className="col-md-12 text-center">
                                <button type="submit" className="thm-btn thm-btn--dark-hover rounded-pill">
                                Submit Booking
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </section>
        </>
    );
}

export default VirtualMeeting;